import {request} from "../../axiosRequest";
import {CATEGORY_IMAGES_SERVICES, CDN_URL} from "../../actions/Constants";
export const GET_PRODUCTS_BY_CATEGORY_REQUEST = 'GET_PRODUCTS_BY_CATEGORY_REQUEST';
export const GET_PRODUCTS_BY_CATEGORY_SUCCESS = 'GET_PRODUCTS_BY_CATEGORY_SUCCESS';
export const GET_PRODUCTS_BY_CATEGORY_FAILURE = 'GET_PRODUCTS_BY_CATEGORY_FAILURE';
export const PRODUCTS_TO_CART = 'PRODUCTS_TO_CART';
export const PRODUCTS_TO_CART_SHOWS = 'PRODUCTS_TO_CART_SHOWS';
export const PRODUCTS_TO_CART_DETAILS = 'PRODUCTS_TO_CART_DETAILS';
export const PRODUCTS_TO_CART_LENGTHS = 'PRODUCTS_TO_CART_LENGTHS';
export const PRODUCTS_TO_CART_CUSTOM_OPTIONS = 'PRODUCTS_TO_CART_CUSTOM_OPTIONS';
export const ORDER_PROCESS_TOTAL_PRICE = 'ORDER_PROCESS_TOTAL_PRICE';
export const SET_CATEGORY_INFO = 'SET_CATEGORY_INFO';
export const PRODUCTS_TO_CART_SHIPPING = 'PRODUCTS_TO_CART_SHIPPING';
export const CLEANUP_PRODUCTS_TO_CART = 'CLEANUP_PRODUCTS_TO_CART';
export const SET_ITEMS_TO_DELETE = 'SET_ITEMS_TO_DELETE';
export const SET_FIRST_CUT_PRODUCT = 'SET_FIRST_CUT_PRODUCT';
export const SET_ADDITIONAL_CUT_PRODUCT = 'SET_ADDITIONAL_CUT_PRODUCT';
export const SET_CUT_PRODUCTS = 'SET_CUT_PRODUCTS';
export const UPDATE_ORDER_PROCESS = 'UPDATE_ORDER_PROCESS';
export const UPDATE_ADDITIONAL_PKG_PRODUCTS = 'UPDATE_ADDITIONAL_PKG_PRODUCTS';

export const orderProcessAllData = (data) => ({
    type: UPDATE_ORDER_PROCESS,
    data: data
})

export const setCutProducts = (data) =>({
    type: SET_CUT_PRODUCTS,
    data: data
});

export const getProductsByCategorySuccess = (data) =>({
    type: GET_PRODUCTS_BY_CATEGORY_SUCCESS,
    data: data
});

export const getProductsByCategoryRequest = () =>({
    type: GET_PRODUCTS_BY_CATEGORY_REQUEST,
});

export const getProductsByCategoryFailure = (error) =>({
    type: GET_PRODUCTS_BY_CATEGORY_FAILURE,
    error: error
});

export const setProductsToCart = (data) => ({
    type: PRODUCTS_TO_CART,
    data: data
});

export const setProductsShows = (data) =>({
    type: PRODUCTS_TO_CART_SHOWS,
    data: data
});
export const setProductsToCartDetails = (data) =>({
    type: PRODUCTS_TO_CART_DETAILS,
    data: data
});
export const setProductsLengths = (data, callback) =>({
    type: PRODUCTS_TO_CART_LENGTHS,
    data: data
}
);
export const setProductsCustomOptions = (data) =>({
    type: PRODUCTS_TO_CART_CUSTOM_OPTIONS,
    data: data
});
export const setShippingProducts = (data) => ({
    type: PRODUCTS_TO_CART_SHIPPING,
    data: data
})
export const setOrderProcessTotalPrice = (data) =>({
    type: ORDER_PROCESS_TOTAL_PRICE,
    data: data
});

export const setCategoryInfo = (data) => ({
    type: SET_CATEGORY_INFO,
    data: data
})

export const cleanUpOrderProcessState = () => ({
    type: CLEANUP_PRODUCTS_TO_CART
});

export const setItemsToDelete = (data) => ({
    type: SET_ITEMS_TO_DELETE,
    data: data
});

export const updateAdditionalPkgProductsStorage = (data) => ({
    type: UPDATE_ADDITIONAL_PKG_PRODUCTS,
    data: data
})

export const updateAdditionalPkgProducts = (products) => {
    return (dispatch) => {
        dispatch(updateAdditionalPkgProductsStorage(products))
    }
}

export const getProductsByCategory = (categoryId) => {
    return (dispatch) => {
        dispatch(getProductsByCategoryRequest());
        console.log("Loading")
        request.get(`productsPerType/category/${categoryId}`, true)
            .then(response => {
                return response;
            })
            .then(result => {
                console.log(result);
                let data = result.data;
                if(data.success && data.code === 200){
                    data.data['categoryId'] = categoryId;
                    let categoryInfo = data.data['categoryInfo'];

                    data.data['categoryInfo'] = {
                        category_id: categoryInfo.id,
                        category_name: categoryInfo.name,
                        category_artist_id: categoryInfo.extension_attributes.artist.id,
                        category_artist_name: categoryInfo.extension_attributes.artist.name,
                        admat_info: categoryInfo.extension_attributes.admat_info,
                        amex_script: getCategoryAttributeValue(categoryInfo, 'amex_script'),
                        citi_script: getCategoryAttributeValue(categoryInfo, 'citi_script'),
                        category_background_image: getBackgroundImage(categoryInfo),
                        art_product_config: categoryInfo.extension_attributes.art_product_config

                    };

                    dispatch(getProductsByCategorySuccess(data.data));
                }
                else{
                    let errorData = data.data;
                    errorData["code"] = data.code;
                    dispatch(getProductsByCategoryFailure(errorData));
                }

            })
            .catch(error => {
                    console.log(error);
                dispatch(getProductsByCategoryFailure(error))
                }
            )

    }
};
const getCategoryAttributeValue = (category, attribute_code) => {
    let customAttributes = category.custom_attributes;
    for(let index in customAttributes) {
        let customAttribute = customAttributes[index];
        console.log('customattribute', customAttribute);
        if(customAttribute.attribute_code == attribute_code) {
            return customAttribute.value;
        }
    }
    return '';
}

let getBackgroundImage = (category) => {
    let customAttributes = category.custom_attributes;
    let background = `${CDN_URL}assets/backgrounds/bg-order.jpg`;
    for(let index in customAttributes) {
        let customAttribute = customAttributes[index];
        console.log('customattribute', customAttribute);
        if(customAttribute.attribute_code == 'background_image') {
            background = CATEGORY_IMAGES_SERVICES + 'backgroundImage/' + customAttribute.value;
        }
    }
    return background;
}
