import React from "react"
import {connect} from "react-redux"
import OrderProcessTour from "../../components/OrderProcessTour";

class OrderProcessTourContainer extends React.Component{
    render(){
        let categoryId = this.props.match.params.categoryId;
        return (
            <OrderProcessTour
                categoryId={categoryId}
            />
        )
    }
}

export default connect(null, null)(OrderProcessTourContainer)