import {request} from "../../axiosRequest";

export const CATEGORIES_HOME_REQUEST = 'CATEGORIES_HOME_REQUEST';
export const CATEGORIES_HOME_SUCCESS = 'CATEGORIES_HOME_SUCCESS';
export const CATEGORIES_HOME_FAILURE = 'CATEGORIES_HOME_FAILURE';

export const categoriesToDisplaySuccess = (data) =>({
    type: CATEGORIES_HOME_SUCCESS,
    data: data
})

export const categoriesToDisplayRequest = () =>({
    type: CATEGORIES_HOME_REQUEST,
})

export const categoriesToDisplayFailure = (error) =>({
    type: CATEGORIES_HOME_FAILURE,
    error: error
})


export const categoriesToDisplay = () => {
    return (dispatch) => {
        dispatch(categoriesToDisplayRequest());
        request.get('tourDemos/home/active')
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result.data
                if(data['success'] && data['code'] == 200) {
                    dispatch(categoriesToDisplaySuccess(data['data']))
                }
                else {
                    dispatch(categoriesToDisplayFailure(data['data']))
                }
            })
            .catch(error => {
                    dispatch(categoriesToDisplayFailure(error))
                }
            )

    }
};