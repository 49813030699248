import {request} from "../../axiosRequest";
import {shoppingCartAttempt, shoppingCartCleanUp} from "../../components/ShoppingCartListItems/actions";
import {shoppingCartTotalsCleanup} from "../../components/shoppingCartTotals/actions";

export const PAYMENT_INFORMATION_AVAILABLE_REQUEST = 'PAYMENT_INFORMATION_AVAILABLE_REQUEST'
export const PAYMENT_INFORMATION_AVAILABLE_SUCCESS = 'PAYMENT_INFORMATION_AVAILABLE_SUCCESS'
export const PAYMENT_INFORMATION_AVAILABLE_FAILURE = 'PAYMENT_INFORMATION_AVAILABLE_FAILURE'

export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
export const CHECKOUT_FINISHED = 'CHECKOUT_FINISHED';


export const paymentInformationAvailableRequest = () => ({
    type: PAYMENT_INFORMATION_AVAILABLE_REQUEST
});
export const paymentInformationAvailableSuccess = (data) => ({
    type: PAYMENT_INFORMATION_AVAILABLE_SUCCESS,
    data: data
});
export const paymentInformationAvailableFailure = (error) => ({
    type: PAYMENT_INFORMATION_AVAILABLE_FAILURE,
    error: error
});

export const getPaymentInformationAvailable = () =>{
    return (dispatch) => {
        dispatch(paymentInformationAvailableRequest())
        return request.get("carts/paymentMethods",true)
            .then(response => { return response; })
            .then(result => {
                console.log("estamos en result");
                console.log(result)
                let data = result.data;
                if(data.success && data.code == 200){
                    dispatch(paymentInformationAvailableSuccess(data.data))
                }
                else{
                    dispatch(paymentInformationAvailableFailure(data.data))
                }
            })
            .catch( error => {
                dispatch(paymentInformationAvailableFailure(error));
            })
    }
}

export const placeOrderAttemptRequest = () => ({
    type: PLACE_ORDER_REQUEST
})
export const placeOrderAttemptSuccess = (data) => ({
    type: PLACE_ORDER_SUCCESS,
    data: data
})
export const placeOrderAttemptFailure = (error) => ({
    type: PLACE_ORDER_FAILURE,
    error: error
})
export const checkOutFinished = () => ({
    type: CHECKOUT_FINISHED
})

export const placeOrderAttempt = (data) => {
    let postData = {
        paymentInformation:
            {
                paymentMethod: data['paymentMethod'],
                billing_address: data["billingAddress"]
            }
    };
    let customerId = data.customerId;
    return (dispatch) => {
        dispatch(placeOrderAttemptRequest());
        return request.post('carts/setPaymentInformation', postData, true)
            .then(response => {return response})
            .then(result => {
                let data = result.data;
                if(data.success && data.code == 200){
                    let newCartId = data.data['new_cart_id'];
                    delete data.data['new_cart_id'];
                    let orders = [];
                    for (let index in data.data) {
                        orders.push(data.data[index])
                    }
                    data.data = orders;
                    dispatch(placeOrderAttemptSuccess(data.data));
                    dispatch(shoppingCartAttempt(customerId,newCartId));
                    // dispatch(shoppingCartCleanUp());
                    // dispatch(shoppingCartTotalsCleanup());
                }
                else{
                    console.log('Place order error', data.data);
                    let message = "Something is wrong. Please try later...";
                    if(typeof data.data == "string") {
                        message = data.data;
                    }
                    else{
                        if("message" in data.data) {
                            message = data.data.message;
                        }
                    }
                    dispatch(placeOrderAttemptFailure(message));
                }
                console.log(result)
            })
            .catch(error => {
                dispatch(placeOrderAttemptFailure(error))
                console.log(error);
            })
    }
}