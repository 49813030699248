import React from "react"
import {connect} from "react-redux"
import OrderFormStep3General from "../../components/OrderFormStep3General";
import OrderProcessNavigation from "../../components/OrderProcessNavigation";

const mapStateToProps = state => ({
    ...state.orderProcess,
})


class OrderFormStep3 extends React.Component {
    render() {
        let totalPrice = this.props.orderTotalPrice;
        let categoryInfo = this.props.categoryInfo;
        if(!categoryInfo) {
            categoryInfo = {};
        }
        return (
            <step3-general>
                <OrderProcessNavigation totalPrice={totalPrice} categoryInfo={categoryInfo}
                    step="step3"
                    itemId={this.props.itemId}
                />
                <main id="main" className="black" role="main"
                      style={{backgroundImage: `url(${categoryInfo.category_background_image})`, backgroundColor: "black"}}>
                    <div id="content" className="container fullscreen-checkout">
                        <div id="order-form" className="step-three row">
                            <div className="col-xs-12">
                                <OrderFormStep3General
                                    goBack={this.props.goBack}
                                    comeFrom={this.props.comeFrom}
                                    itemId={this.props.itemId}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </step3-general>
        )
    }
}

export default connect(mapStateToProps, null)(OrderFormStep3)
