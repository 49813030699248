import React from "react"
import {connect} from "react-redux"
import NotFound from "../NotFound";
import {adminCustomer} from "./actions";
import {Link} from "react-router-dom";

const mapStateToProps = state => ({
    ...state.customerInformation,
})

const mapDispatchToProps = {
    adminCustomer,
}

class AdminLogAsUser extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            email: null
        }
    }

    componentDidMount() {
        let token = this.props.match.params.token;
        let email = this.props.match.params.email;
        this.setState({
            token: token,
            email: email
        })
        if(token) {
            this.props.adminCustomer(token, email);
        }
    }

    render(){
        let token = this.state.token;
        let customerInfo = this.props.customerInfo;
        let isFetchingCustomerInformation = this.props.isFetchingCustomerInformation;
        if(isFetchingCustomerInformation) {
            return <main id="main" role="main">
                <section id="content" className="container content-wrapper loading-wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="loading-white">
                                <i className="icon-loading"></i>
                                <span>Loading information</span>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        }
        if(!token || !customerInfo) {
            return <NotFound/>
        }
        return (
            <main id="main" role="main">
                <section id="content" className="container cms-content-wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            <h1>You are logged in as { customerInfo.firstname} {customerInfo.lastname}</h1>
                            <p>
                                To start an order, select an artist
                                <Link to="/artists"> here.</Link>
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogAsUser)
