import React from "react"
import {connect} from "react-redux"
import AdditionalEmailItem from "../AdditionalEmailItem";

class AdditionalEmails extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tdc_additional_emails:[
                {
                    emails: '',
                    apply_to: []
                }
            ]
        };
        this.addNewEmailSection = this.addNewEmailSection.bind(this);
        this.updateTdcAdditionalEmails = this.updateTdcAdditionalEmails.bind(this);
        this.removeAdditionalEmail = this.removeAdditionalEmail.bind(this);
    }
    componentDidMount() {
        console.log('additional email did mount', this.props.tdc_additional_emails)
        if(this.props.tdc_additional_emails) {
            this.setState({
                tdc_additional_emails: [...this.props.tdc_additional_emails]
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.tdc_additional_emails !=  this.props.tdc_additional_emails) {
            this.setState({
                tdc_additional_emails: [...this.props.tdc_additional_emails]
            })
        }
    }

    addNewEmailSection() {
        let tdcAdditionalEmails = [...this.state.tdc_additional_emails];
        let item = {'emails':'', 'apply_to': []};
        tdcAdditionalEmails.push(item);
        this.setState({tdc_additional_emails: tdcAdditionalEmails});
    }
    removeAdditionalEmail(index) {
        let tdcAdditionalEmails = [...this.state.tdc_additional_emails];
        tdcAdditionalEmails.splice(index, 1);
        this.setState({
            tdc_additional_emails: [...tdcAdditionalEmails]
        })
        this.props.updateAdditionalEmails(tdcAdditionalEmails);
    }
    updateTdcAdditionalEmails(index, emails, apply_to) {
        let tdcAdditionalEmails = [...this.state.tdc_additional_emails];
        tdcAdditionalEmails[index] = {
            emails: emails,
            apply_to: apply_to
        }
        this.setState({
            tdc_additional_emails: [...tdcAdditionalEmails]
        })
        this.props.updateAdditionalEmails(tdcAdditionalEmails);
    }
    render(){
        let tdcAdditionalEmails = [...this.state.tdc_additional_emails];
        let {errors} = this.props;
        return (
            <section className="row section-account">
                <div className="col-xs-12">
                    <header className="mb-20">
                        <h3 className="h-five">Additional Email Addresses</h3>
                    </header>
                    {errors && Object.keys(errors).length > 0 && <div className="alert error">
                        <p>Please complete the fields outlined in red below before proceeding.</p>
                    </div>}
                    {tdcAdditionalEmails.map((value, index, array) =>
                        <AdditionalEmailItem
                            key={index}
                            updateTdcAdditionalEmails={this.updateTdcAdditionalEmails}
                            removeAdditionalEmail={this.removeAdditionalEmail}
                            index={index}
                            apply_to={[...value['apply_to']]}
                            emails={value['emails']}
                            errors={errors?errors[index]:{}}
                        />
                    )}
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="repeat-control">
                                <button type="button" onClick={this.addNewEmailSection}>
                                    Add Another Email Address
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default connect(null, null)(AdditionalEmails)
