import {
    SEARCH_TOURS_FAILURE,
    SEARCH_TOURS_REQUEST,
    SEARCH_TOURS_SUCCESS,
} from "./actions";

export default (state = {
    isFetchingSearchTours: false,
    searchToursError: null,
    searchTourData: null,
}, action) => {
    switch (action.type) {
        case SEARCH_TOURS_REQUEST:
            return {
                ...state,
                isFetchingSearchTours: true,
                searchToursError: null
            };
        case  SEARCH_TOURS_FAILURE:
            return {
                ...state,
                isFetchingSearchTours: false,
                searchTourData: null,
                searchToursError: action.error
            };
        case SEARCH_TOURS_SUCCESS:
            return {
                ...state,
                searchToursError: null,
                isFetchingSearchTours: false,
                searchTourData: action.data
            };
        default:
            return state;
    }
}