import React from "react"
import {connect} from "react-redux"


class OrderBillingAddress extends React.Component{
    po_number(poNumber) {
        if(poNumber){
            return 'Purchase Order Number - ' + poNumber
        }
    }
    render() {
        let {billingAddress, paymentMethod, poNumber} = this.props;
        console.log("billding address", this.props);
        return (
            <div className="row section-information-columns">
                <div className="col-xs-12">
                    <div className="section-information-column">
                        <h2 className="h-five">Billing Information</h2>
                        <span>{`${billingAddress.firstname}  ${billingAddress.lastname}`}</span>
                        <span>{billingAddress.company}</span>
                        <span>{billingAddress.street}</span>
                        <span>{billingAddress.city}, {billingAddress.region}, {billingAddress.postcode}</span>
                        <span>{billingAddress.country_id}</span>
                        <span>T: {billingAddress.telephone}</span>
                        {billingAddress.fax && <span>F: {billingAddress.fax}</span>}
                    </div>
                    <div className="section-information-column">
                        <h2 className="h-five">Payment Method</h2>
                        <span>{paymentMethod}</span>
                        <span>{this.po_number(poNumber)}</span>
                    </div>
                </div>
            </div>
        )
    }

}

export default connect(null, null)(OrderBillingAddress)
