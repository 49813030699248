export const PRODUCT = {
    sku: "HD TV",
}
export const custom_options = {
    due_date: "due_date",
    on_sale_date_time_text: 'on_sale_date_time_text',
    additional_venue_mention_check: {
        code: 'additional',
        sku: {
            additional_venue_mention: 'additional_venue_mention'
        },
    },
    additional_venue_mention_text: "additional_venue_mention_text",
    send_tv_for_approval: {
        code: 'send_tv',
        sku: {
            send_tv_for_approval: 'send_tv_for_approval'
        }
    },
    send_tv_for_approval_to: 'send_tv_for_approval_to',
    closed_caption: {
        code: 'c_caption',
        sku: {
            closed_caption: 'closed_caption'
        }
    },

}
export const fieldCopy = {
    [custom_options.due_date]: '',
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.send_tv_for_approval.code]: null,
    [custom_options.send_tv_for_approval.sku.send_tv_for_approval]: false,
    [custom_options.send_tv_for_approval_to]: '',
    [custom_options.closed_caption.code]: null,
    [custom_options.closed_caption.sku.closed_caption]: false,
    [custom_options.additional_venue_mention_text]: '',
    [custom_options.additional_venue_mention_check.code]: null,
    [custom_options.additional_venue_mention_check.sku.additional_venue_mention]: false,
}

export const fields = JSON.parse(JSON.stringify(fieldCopy));
