import {request} from "../../axiosRequest";
import {userLogout} from "../../components/Logout/actions";

export const CUSTOMER_INFORMATION_REQUEST = 'CUSTOMER_INFORMATION_REQUEST';
export const CUSTOMER_INFORMATION_ERROR = 'CUSTOMER_INFORMATION_ERROR';
export const CUSTOMER_INFORMATION_RECEIVED = 'CUSTOMER_INFORMATION_RECEIVED';

export const customerInformationRequest = () => ({
    type: CUSTOMER_INFORMATION_REQUEST
});

export const customerInformationReceived = (data) => ({
    type: CUSTOMER_INFORMATION_RECEIVED,
    data: data
});

export const customerInformationError = (data) => ({
    type: CUSTOMER_INFORMATION_ERROR,
    error: data
});

export const customerInformationFetch = () => {
    return (dispatch) => {
        dispatch(customerInformationRequest())
        return request.get(`customers/info`, true)
            .then( response => {return response})
            .then(result => {
                console.log('Customer Information Actions');
                console.log(result['data']);
                let data = result['data'];
                let items = data['data'];
                console.log("customer received");
                dispatch(customerInformationReceived(items))
            })
            .catch(error => {
                if(error.response && error.response.status == 401)
                {
                    dispatch(userLogout())
                }
                dispatch(customerInformationError(error))

            })
    }
};


export const REMOVE_ADDRESS_REQUEST = 'REMOVE_ADDRESS_REQUEST';
export const REMOVE_ADDRESS_SUCCESS = 'REMOVE_ADDRESS_SUCCESS';
export const REMOVE_ADDRESS_FAILURE = 'REMOVE_ADDRESS_FAILURE';
export const REMOVE_ADDRESS_CLEANUP = 'REMOVE_ADDRESS_CLEANUP';


export const removeAddressRequest = () => ({
    type:REMOVE_ADDRESS_REQUEST
});
export const removeAddressSuccess = (data) => ({
    type: REMOVE_ADDRESS_SUCCESS,
    data: data
});
export const removeAddressFailure = (error) => ({
    type: REMOVE_ADDRESS_FAILURE,
    error: error
});

export const removeAddressCleanUp = () => ({
    type: REMOVE_ADDRESS_CLEANUP,
});

export function deleteAddress(addressId, customerInfo) {
    return (dispatch) => {
        dispatch(removeAddressRequest());
        return request.get(`customers/address/${addressId}/delete`, true)
            .then(response => {
                return response
            })
            .then(result => {
                let data = result.data;
                if (data.success && data.code == 200) {
                    dispatch(removeAddressSuccess(true));
                    dispatch(removeLocalAddress(addressId, customerInfo));

                } else {
                    dispatch(removeAddressFailure(data.data))
                }
            })
            .catch(error => {
                dispatch(removeAddressFailure(error))
            })
    }
}

export const removeLocalAddress = (addressId, customerInfo) => {
    return (dispatch) => {
        let addresses = [...customerInfo.addresses];
        for(let addressIndex in addresses) {
            let address = addresses[addressIndex];
            console.log('address.id.toString()', [address.id, addressId.toString()])
            if(address.id.toString() == addressId) {
                console.log('address.id.toString()', [address.id.toString(), addressId.toString()])
                customerInfo.addresses.splice(addressIndex, 1);
            }
        }
        console.log('customerInfo', customerInfo);
        dispatch(customerInformationReceived(customerInfo))

    }
};