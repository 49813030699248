import React from 'react';
import './App.css';
import Dashboard from "../Dashboard";
import {request} from "../../axiosRequest";
import {connect} from "react-redux";
import {customerInformationFetch} from "../CustomerInformation/actions";
import {userLoginAttemptSuccess} from "../../components/LoginForm/actions";
import {userLogout} from "../../components/Logout/actions";
import ProtectedRoute from "../../components/ProtectedRoute";
import OrderDetail from "../OrderDetail"
import OrderToPrint from "../../components/OrderToPrint"
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import EditAccountInformation from "../EditAccountInformation";
import ShoppingCart from "../ShoppingCart";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Checkout from "../Checkout"
import {shoppingCartAttempt} from "../../components/ShoppingCartListItems/actions";
import Tours from "../Tours";
import Orders from "../Orders";
import NotFound from "../NotFound";
import ToursPage from "../ToursPage";
import AddToCartSelectProduct from "../OrderFormStep1";
import ShoppingCartEditItem from "../ShoppingCartEditItem";
import Account from "../Account"
import Home from "../../components/Home";
import AddressBook from "../AddressBook";
import AccountEditAddress from "../AccountEditAddress";
import ArtistTours from "../ArtistTours";
import ArtistRoster from "../ArtistRoster";
import ToursModal from "../ToursModal";
import OrderShipping from "../OrderShipping";
import OrderRevise from "../OrderRevise";
import ReOrder from "../ReOrder"
import OrderProcessTourContainer from "../OrderProcessTourContainer"
import AccountValidateToken from "../AccountValidateToken"
import {getStaticPagesInfo} from "../../components/StaticPage/actions";
import StaticPage from "../../components/StaticPage";
import AdminLogAsUser from "../AdminLogAsUser";
import ScrollRestoration from "./ScrollRestoration"


const mapStateToProps = state =>({
    ...state.auth,
    ...state.customerInformation,
    ...state.cart
});

const mapDispatchToProps = {
    customerInformationFetch,
    userLoginAttemptSuccess,
    userLogout,
    shoppingCartAttempt,
    getStaticPagesInfo
}


class App extends React.Component{
    constructor(props){
        super(props)
        const customerAuthToken = window.localStorage.getItem('customerAuthToken');
        const customerEmail = window.localStorage.getItem('customerEmail');
        if(customerAuthToken){
            request.setToken(customerAuthToken);
            this.props.userLoginAttemptSuccess({customerEmail: customerEmail, customerAuthToken: customerAuthToken});
        }
        this.logout = this.logout.bind(this);
        this.setMinHeightToMain = this.setMinHeightToMain.bind(this);
    }
    componentDidMount() {
        this.setMinHeightToMain()
        window.addEventListener('resize', this.setMinHeightToMain)
    }

    setMinHeightToMain() {
        let footerHeight = this.footerHeight();
        let mainElement = document.querySelectorAll('[role="main"]');
        if(mainElement && mainElement.length > 0) {
            mainElement = mainElement[0];
            mainElement.style.setProperty('min-height', `calc(100vh - ${footerHeight}px`)
        }
    }
    footerHeight() {
        let footer = document.getElementById('footer');
        if(footer){
            return footer.offsetHeight
        }
        return 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {customerEmail, customerInformationFetch, shoppingCartAttempt, customerInfo,
        isAuthenticated, isFetchingCartItemsList} = this.props;
        let localCartIsFetching = false;
        if(prevProps.customerEmail !== customerEmail && customerEmail != null){
            customerInformationFetch();
        }
        if(prevProps.customerInfo !== customerInfo && customerInfo != null) {
            if(
                prevProps.customerInfo != null &&
                prevProps.customerInfo.id != customerInfo.id &&
                !isFetchingCartItemsList)
            {
                localCartIsFetching = true;
                shoppingCartAttempt(customerInfo.id)
            }
            if(prevProps.customerInfo == null &&
                !isFetchingCartItemsList &&
                !localCartIsFetching) {
                localCartIsFetching = true;
                shoppingCartAttempt(customerInfo.id)
            }
        }
        if(this.props.cart == null &&
            customerInfo != null &&
            !isFetchingCartItemsList &&
            !localCartIsFetching &&
            !this.props.fetchingCartError

        ) {
            shoppingCartAttempt(customerInfo.id)
        }
    }

    logout(){
        this.props.userLogout();
    }

    render() {
        let customerInfo = this.props.customerInfo;
        let isAuthenticated = this.props.isAuthenticated;
        let cart = this.props.cart;
        let cartItemQty = 0;
        if(cart) {
            cartItemQty = cart.items_qty;
        }
        let firstName = '';
        if(customerInfo)
        {
            firstName = customerInfo.firstname
        }
        return(

          <app-div>
              <Router history={this.props.history}>
                  <ScrollRestoration />
                  <Header isAuthenticated={isAuthenticated} cartItemQty={cartItemQty}
                        firstName={firstName}
                  />
                  <Switch>
                      <Route path="/account/:action(login|createAccount|resetPassword)" component={Account} />
                      <Route path="/customer/setpassword/:token" component={AccountValidateToken} />
                      <ProtectedRoute path="/dashboard" component={Dashboard} />
                      <ProtectedRoute path="/orderDetails/:orderId" component={OrderDetail}/>
                      <ProtectedRoute path="/order/addShipping/:orderId" component={OrderShipping}/>
                      <ProtectedRoute path="/order/reviseOrder/:orderId" component={OrderRevise}/>
                      <ProtectedRoute path="/reOrder/:orderId" component={ReOrder} exact/>
                      <ProtectedRoute path="/customer/information" component={EditAccountInformation}/>
                      <ProtectedRoute path="/customer/addressBook" component={AddressBook} exact/>
                      <ProtectedRoute path="/customer/address/:addressId(new)" component={AccountEditAddress}/>
                      <ProtectedRoute path="/cart/selectProducts/:categoryId" component={AddToCartSelectProduct}/>
                      <ProtectedRoute path="/artists/" component={ArtistRoster} exact/>
                      <ProtectedRoute path="/artists/:artistId" component={ArtistTours} exact/>
                      <ProtectedRoute path="/order/process/tours/:categoryId" component={OrderProcessTourContainer}/>
                      <ProtectedRoute path="/cart/edit/item/:itemID" component={ShoppingCartEditItem} exact/>
                      <ProtectedRoute path="/cart" component={ShoppingCart} exact/>
                      <ProtectedRoute path="/checkout" component={Checkout}/>
                      <ProtectedRoute path="/orders/" component={Orders} exact/>
                      <ProtectedRoute path="/orders/print/:orderId" component={OrderToPrint} exact/>
                      <Route path="/tours/:tourId" component={ToursPage} exact/>
                      <Route path="/tours/:tourId/modal" component={ToursModal}/>
                      <Route path="/tours" component={Tours}/>
                      <Route path="/:pageIdentifier" component={StaticPage} exact={true}/>
                      <Route path="/admin/:token/:email" component={AdminLogAsUser} exact={true}/>
                      <Route path="/" component={Home} exact/>
                      <Route path="*" component={NotFound}/>
                  </Switch>
                  <Footer/>
              </Router>
          </app-div>
        );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
