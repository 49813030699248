import React from "react"
import {connect} from "react-redux"
import EditAccountInformationForm from "../../components/EditAccountInformationForm";
import {Link, withRouter} from 'react-router-dom'

class EditAccountInformation extends React.Component{
    render(){
        return (
            <main id="main" role="main">
                <section id="content" className="container content-wrapper">
                    <div className="row section-header">
                        <div className="col-xs-12">
                            <ul className="breadcrumb">
                                <li>
                                    <Link to="/dashboard">
                                        Account Dashboard
                                    </Link>
                                </li>
                            </ul>
                            <h1 className="h-two blue">Edit Account Information</h1>
                        </div>
                    </div>
                    <EditAccountInformationForm/>
                </section>
            </main>
        )
    }
}

export default withRouter(connect(null, null)(EditAccountInformation))
