import React from "react"
import {connect} from "react-redux"

class ErrorMessage extends React.Component{
    render(){
        let message = 'Please complete the fields outlined in red below before proceeding.'
        if(this.props.message) {
            message = this.props.message;
        }
        return (
            <div className="row mb-20">
                <div className="col-xs-12">
                    <div className="alert error">
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, null)(ErrorMessage)
