import React from "react"
import {connect} from "react-redux"
import NotFound from "../../containers/NotFound";
import {getStaticPagesInfo} from "./actions"
import LoadingWhite from "../../LoadingWhite";


const mapDispatchToProps = {
    getStaticPagesInfo,
}

const mapStateToProps = state => ({
    ...state.staticPages,
})


class StaticPage extends React.Component{
    componentDidMount() {
        if(this.props.match.params.pageIdentifier) {
            this.props.getStaticPagesInfo(this.props.match.params.pageIdentifier)
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.pageIdentifier != prevProps.match.params.pageIdentifier)
        {
            console.log('inside identifier', this.props.match.params.pageIdentifier)
            if(this.props.match.params.pageIdentifier) {
                this.props.getStaticPagesInfo(this.props.match.params.pageIdentifier)
            }
        }
    }

    render(){
        let page = this.props.match.params.pageIdentifier;
        let isFetchingStaticPages = this.props.isFetchingStaticPages;
        let staticPagesInfo = this.props.staticPagesInfo;

        if(isFetchingStaticPages) {
            return (
                <main id="main" role="main">
                    <section id="content" className="container cms-content-wrapper">
                       <LoadingWhite
                           label={"Loading"}
                       />
                </section>
            </main>)
        }
        if(!staticPagesInfo) {
            return <NotFound/>
        }
        return (
            <main id="main" role="main">
                <section id="content" className="container cms-content-wrapper">
                    {staticPagesInfo && <div dangerouslySetInnerHTML={{__html: staticPagesInfo}}>

                    </div>}
                </section>
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticPage)