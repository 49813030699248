import {
    REVISE_ORDER_REQUEST,
    REVISE_ORDER_FAILURE,
    REVISE_ORDER_SUCCESS
} from "./actions";

export default ( state = {
    reviseOrderDetails: null,
    isFetchingReviseOrderDetails: false,
    reviseOrderError: null
}, action) => {
    switch (action.type) {
        case REVISE_ORDER_REQUEST:
            return {
                ...state,
                isFetchingReviseOrderDetails: true,
            }
        case REVISE_ORDER_SUCCESS:
            return {
                ...state,
                isFetchingReviseOrderDetails: false,
                reviseOrderDetails: action.data,
                reviseOrderError: null
            }
        case REVISE_ORDER_FAILURE:
            return {
                ...state,
                isFetchingReviseOrderDetails: false,
                reviseOrderError: action.error
            }

        default:
            return state;

    }

}