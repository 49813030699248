import {request} from "../../axiosRequest";

export const GET_STATIC_PAGES_INFO_REQUEST = 'GET_STATIC_PAGES_INFO_REQUEST';
export const GET_STATIC_PAGES_INFO_SUCCESS = 'GET_STATIC_PAGES_INFO_SUCCESS';
export const GET_STATIC_PAGES_INFO_FAILURE = 'GET_STATIC_PAGES_INFO_FAILURE';

export const getStaticPagesInfoSuccess = (data) =>({
    type: GET_STATIC_PAGES_INFO_SUCCESS,
    data: data
})

export const getStaticPagesInfoRequest = () =>({
    type: GET_STATIC_PAGES_INFO_REQUEST,
})

export const getStaticPagesInfoFailure = (error) =>({
    type: GET_STATIC_PAGES_INFO_FAILURE,
    error: error
})


export const getStaticPagesInfo = (identifier) => {
    return (dispatch) => {
        dispatch(getStaticPagesInfoRequest());
        request.get(`page/urlKey/${identifier}`)
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result.data;
                if(data['success'] && data['code'] == 200) {
                    console.log('data page', data);
                    let items = data.data.items
                    console.log('data page', data);
                    if(items.length > 0){
                        dispatch(getStaticPagesInfoSuccess(items[0].content))
                    }
                    else {
                        dispatch(getStaticPagesInfoSuccess(null))
                    }
                }
                else {
                console.log('data page', data);
                    dispatch(getStaticPagesInfoFailure(data))
                }
            })
            .catch(error => {
                    dispatch(getStaticPagesInfoFailure(error.message))
                }
            )

    }
};