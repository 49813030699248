export const custom_options = {
    single_title: 'single_title',
    release_date: 'release_date',

    pre_order: {
        code: 'pre_order',
        sku: {
            pre_order: 'pre_order_option'
        },
    },
    available_now: {
        code: 'available_now',
        sku: {
            available_now: 'available_now_option'
        },
    },
};

export const fields = {
    [custom_options.single_title]: '',
    [custom_options.release_date]: '',
    [custom_options.pre_order.code]: null,
    [custom_options.available_now.code]: null,
}