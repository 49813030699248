import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import ConvertParseObject from '.././../components/ConvertParseObject';
import {bindActionCreators} from 'redux';
import * as holidayActions from './actions';


const HtmlToReactParser = require('html-to-react').Parser;
let htmlToReactParser = new HtmlToReactParser();

function mapDispatchToProps(dispatch) {
    return bindActionCreators(holidayActions, dispatch);
}

function mapStateToProps(state) {
    return {
        ...state.holidayMessageReducer
    };
}


const HolidayMessage = ({
  holidayMessageHtml,
  getHolidayMessage
}) => {
    const [reactElement, setReactElement] = useState(null);

    useEffect(() => {
        getHolidayMessage();
    }, [])

    useEffect(() => {
        if(holidayMessageHtml){
            let html = holidayMessageHtml.replace(/[\r\n]+/gm, "");
            setReactElement(htmlToReactParser.parse(html));
        }
    }, [holidayMessageHtml])

    if(reactElement) {
        return <ConvertParseObject element={reactElement}/>
    }
    return null;
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayMessage)
