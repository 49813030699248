import React from "react"
import {connect} from "react-redux"
import AccountAddressBookItem from "../../components/AccountAddressBookItem";
import {Link, withRouter} from "react-router-dom";
import {CDN_URL} from "../../actions/Constants";

const mapStateToProps = state => ({
    ...state.customerInformation
})


class AddressBook extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            billingAddress: null,
            additionalAddress: null
        }
        this.getAddressPerType = this.getAddressPerType.bind(this);
    }
    componentDidMount() {
        let addressData = this.getAddressPerType();
        this.setState({
            billingAddress: addressData.billingAddress,
            additionalAddress: addressData.additionalAddress
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.customerInfo != this.props.customerInfo)
        {
            let addressData = this.getAddressPerType();
            this.setState({
                billingAddress: addressData.billingAddress,
                additionalAddress: addressData.additionalAddress
            })
        }
    }

    getAddressPerType() {
        let customerInfo = this.props.customerInfo;
        let additionalAddress = [];
        let billingAddress = null;
        if(customerInfo) {
            for(let addressIndex in customerInfo.addresses) {
                let address = customerInfo.addresses[addressIndex];
                if (address.id == customerInfo.default_billing) {
                    billingAddress = address;
                }
                else {
                    additionalAddress.push(address)
                }
            }
        }
        return {additionalAddress: additionalAddress, billingAddress: billingAddress};
    }
    render(){
        let customerInfo = {...this.props.customerInfo};
        if(customerInfo){
            let {additionalAddress, billingAddress} = {...this.state};
            return (
                <main id="main" role="main">
                    <section id="content" className="container content-wrapper">
                        <div className="row section-header">
                            <div className="col-md-8 col-sm-7">
                                <ul className="breadcrumb">
                                    <li><Link to="/dashboard">Account Dashboard</Link></li>
                                </ul>
                                <h1 className="h-two blue">Address Book</h1>
                            </div>
                            <div className="col-md-4 col-sm-5">
                                <Link to={`/customer/address/new`} className="button large blue icon">
                                    Add New Address
                                    <i aria-hidden="true">
                                        <img src={`${CDN_URL}assets/icon-button-address.svg`} alt="Add New Address"/>
                                    </i>
                                </Link>
                            </div>
                        </div>
                        <div className="row section-information-columns">
                            <div className="col-xs-12">
                                {billingAddress && <AccountAddressBookItem address={billingAddress}
                                                defaultBilling={true}
                                />}
                                {additionalAddress && additionalAddress.map((address) =>
                                 <AccountAddressBookItem
                                     address={address}
                                     key={address.id}
                                     defaultBilling={false}
                                     customerInfo={customerInfo}
                                    />
                                )}
                                {customerInfo && customerInfo.addresses && customerInfo.addresses.length <= 1 && <address-item>
                                    <div className="section-information-column">
                                        <h2 className="h-five">Additional Addresses</h2>
                                        <div className="no-addresses">
                                            <i aria-hidden="true">
                                                <img src={`${CDN_URL}assets/icon-no-addresses.svg`}/>
                                            </i>
                                            <p className="p-four">You have no additional address entries in your address book.</p>
                                            <Link to="/customer/address/new" className="button xsmall blue mt-20">Add</Link>
                                        </div>
                                    </div>
                                </address-item>}
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        else{
            return (
                <main id="main" role="main">
                    <section id="content" className="container content-wrapper loading-wrapper">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="loading-white">
                                    <i className="icon-loading"></i>
                                    <span>Loading Your Addresses</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps, null)(AddressBook))
