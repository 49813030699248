import {
    GET_STATIC_PAGES_INFO_FAILURE,
    GET_STATIC_PAGES_INFO_SUCCESS,
    GET_STATIC_PAGES_INFO_REQUEST
} from "./actions";

export default (state = {
    staticPagesInfo: null,
    isFetchingStaticPages: false,
    staticPagesFetchError: null
}, action) => {
    switch (action.type) {
        case GET_STATIC_PAGES_INFO_REQUEST:
            return {
                ...state,
                isFetchingStaticPages: true,
            }
        case  GET_STATIC_PAGES_INFO_FAILURE:
            return {
                ...state,
                isFetchingStaticPages: false,
                staticPagesFetchError: action.error
            }
        case GET_STATIC_PAGES_INFO_SUCCESS:
            return {
                ...state,
                isFetchingStaticPages: false,
                staticPagesInfo: action.data,
                staticPagesFetchError: null
            }
        default:
            return state;
    }
}