import {request} from "../../axiosRequest";
import {DEFAULT_PAGE_SIZE} from "../../actions/Constants";

export const ORDERS_LIST_REQUEST = 'ORDERS_LIST_REQUEST';
export const ORDERS_LIST_ERROR = 'ORDERS_LIST_ERROR';
export const ORDERS_LIST_RECEIVED = 'ORDERS_LIST_RECEIVED';

export const ORDERS_SET_CURRENT_PAGE = 'ORDERS_SET_CURRENT_PAGE';
export const ORDERS_SET_PAGE_SIZE = 'ORDERS_SET_PAGE_SIZE';

export const ORDERS_FILTER = 'ORDERS_FILTER';
export const ORDERS_SORT_BY = 'ORDERS_SORT_BY';

export const CSV_REQUEST = 'CSV_REQUEST';
export const CSV_ERROR = 'CSV_ERROR';
export const CSV_SUCCESS = 'CSV_SUCCESS';


export const recentOrderListRequest = () => ({
    type: ORDERS_LIST_REQUEST,
})
export const recentOrderListReceived = (data) => ({
    type: ORDERS_LIST_RECEIVED,
    data: data
})
export const recentOrderListError = (error) => ({
    type: ORDERS_LIST_ERROR,
    error: error
})

export const setOrdersListCurrentPage = (currentPage) => ({
    type: ORDERS_SET_CURRENT_PAGE,
    currentPage: currentPage
})

export const setOrdersListPageSize = (pageSize) => ({
    type:ORDERS_SET_PAGE_SIZE,
    pageSize: pageSize
})

export const setOrdersFilter = (filter) => ({
    type: ORDERS_FILTER,
    filter: filter
})
export const setOrdersSortBy = (sortBy) => ({
    type: ORDERS_SORT_BY,
    sortBy: sortBy
})

export const downloadCsvFetch = () => ({
    type: CSV_REQUEST
})
export const downloadCsvError = (error) => ({
    type: CSV_ERROR,
    error: error
})
export const downloadCsvSuccess = () => ({
    type: CSV_SUCCESS,
})


export const ordersListFetch = (customerId, currentPage = 1, pageSize = DEFAULT_PAGE_SIZE,
                                filter = {}, sortBy = {}) => {
    return (dispatch) => {
        dispatch(recentOrderListRequest());
        let data = {currentPage: currentPage, pageSize: pageSize};
        // let filter = {"status": {"value" : "ing", "condition": "like"}};
        if(Object.keys(filter).length !== 0){
            data["filterBy"] = JSON.stringify(filter);
        }
        console.log('sortBy', sortBy);
        if(Object.keys(sortBy).length !== 0){
            data["sortBy"] = JSON.stringify(sortBy);
        }
        else{
            data["sortBy"] = JSON.stringify({"entity_id": "desc"});
        }
        return request.get(`customers/orders`, true, data)
            .then(response => {
                return response;
            }).then(result => {
                console.log('Recent Orders Actions');
                console.log(result);
                console.log(result['data']['data']['items']);
                let resultData = result.data;
                if(resultData.success && resultData.code === 200){
                    let data = [];
                    data["orders"] = resultData.data;
                    data["currentPage"] = currentPage;
                    data["pageSize"] = pageSize;
                    let allTours = data.orders.all_tours;
                    try{
                        allTours = allTours.map((tour) => {
                            return JSON.parse(tour);
                        });
                        data.orders.all_tours = allTours;
                    }
                    catch (e) {

                    }

                    dispatch(recentOrderListReceived(data));
                }
                else {
                    if(resultData.code == 401) {
                        dispatch(recentOrderListError(resultData))
                    }
                    else{
                        dispatch(recentOrderListError(resultData.data))
                    }
                }
            })
            .catch(error =>
                dispatch(recentOrderListError(error))
            )
    }
}



export const getCsvOrdersRequest = (customerId, filter = {}, sortBy = {}) => {
    return (dispatch) => {
        dispatch(downloadCsvFetch());
        let data = {};
        if(Object.keys(filter).length !== 0){
            data["filterBy"] = JSON.stringify(filter);
        }
        console.log('sortBy', sortBy);
        if(Object.keys(sortBy).length !== 0){
            data["sortBy"] = JSON.stringify(sortBy);
        }
        else{
            data["sortBy"] = JSON.stringify({"entity_id": "desc"});
        }
        return request.get(`orders/customer/${customerId}/export`, true, data)
            .then(response => {
                return response;
            })
            .then((result) => {
                dispatch(downloadCsvSuccess());
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                dispatch(downloadCsvError(error.message))
            })
    }
}
