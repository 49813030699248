import React from "react"
import {connect} from "react-redux"
import {setSelectedProducts} from "../../components/OrderFormStep1ProductList/actions";
import OrderFormStep3 from "../OrderFormStep3";
import {setCategoryInfo, setShippingProducts} from "../OrderFormStep1/actions";
import {getShippingOrderAttempt} from "./actions";
import NotFound from "../NotFound";
import LoadingWhite from "../../LoadingWhite";
import {getCategoryInfoFromItemOptions, getCategoryInfoFromOrder} from "../../helpers";

const mapStateToProps = state => ({
    ...state.customerInformation,
    ...state.orderProcess,
    ...state.addShipping,
})

const mapDispatchToProps = {
    setSelectedProducts,
    setShippingProducts,
    getShippingOrderAttempt,
    setCategoryInfo
}
class OrderShipping extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedProducts: null,
            categoryInfo: null,
            shippingProducts: null
        }
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        let {orderId} = this.props.match.params;
        if(orderId) {
            this.props.getShippingOrderAttempt(orderId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.orderId != this.props.match.params.orderId) {
            this.props.getOrderAttempt(this.props.match.params.orderId);
        }

        if(prevProps.shippingOrderDetails != this.props.shippingOrderDetails) {
            let order = this.props.shippingOrderDetails;
            if (order) {
                // let selectedProduct = order.extension_attributes.main_product;
                let selectedProduct = this.getShippingProductFromOrder();
                selectedProduct.extension_attributes = {
                    ...selectedProduct.extension_attributes,
                    shipping: selectedProduct
                }

                let categoryInfo = getCategoryInfoFromOrder(order);
                let group_item = this.getGroupItem(order.items[0]);
                let shippingProducts = {
                    [selectedProduct.id]: [{group_item: group_item}]
                }
                let selectedProducts = {
                    [selectedProduct.id]: selectedProduct
                };
                this.setState({
                    selectedProducts: selectedProducts,
                    categoryInfo: categoryInfo,
                    shippingProducts: shippingProducts
                })
                this.props.setSelectedProducts(selectedProducts);
                this.props.setShippingProducts(shippingProducts);
                this.props.setCategoryInfo(categoryInfo);
            }
        }
    }
    getShippingProductFromOrder() {
        let order = this.props.shippingOrderDetails;
        if(order) {
            let items = order.items;
            if(items) {
                for(let itemIndex in items) {
                    let item = items[itemIndex]
                    let originalProduct = item.extension_attributes.original_product;
                    if(originalProduct.extension_attributes && originalProduct.extension_attributes.product_type
                        && originalProduct.extension_attributes.product_type.id == 'shipping'
                    ) {
                        return originalProduct;
                    }
                    else {
                        if(originalProduct.extension_attributes && originalProduct.extension_attributes.shipping) {
                            let shippingProduct = originalProduct.extension_attributes.shipping;
                            shippingProduct.extension_attributes = {
                                ...shippingProduct.extension_attributes,
                                product_type: {
                                    id: 'shipping'
                                }
                            }
                            return shippingProduct;
                        }
                    }
                }
            }
        }
    }

    getGroupItem(item) {
        let additionalData = item.additional_data;
        if(additionalData) {
            additionalData = JSON.parse(additionalData);
            if(additionalData && 'groupItem' in additionalData) {
                return additionalData['groupItem']
            }
        }
        return  null

    }
    goBack() {
        this.props.history.goBack();
    }

    render(){
        let {selectedProducts, categoryInfo} = {...this.state};
        let {isFetchingShippingOrderDetails} = {...this.props};
        console.log('selectedProducts', selectedProducts);
        console.log('customerInfo', categoryInfo);
        let itemsToDelete = [];
        let {orderId} = this.props.match.params;

        if(!orderId) {
            return (
                <NotFound/>
            )
        }
        if(isFetchingShippingOrderDetails) {
            return (
                <main id="main" className="black" role="main"
                      // style={{backgroundImage: `url(${categoryInfo.category_background_image})`, backgroundSize: "auto", backgroundRepeat: "no-repeat", backgroundColor: "black"}}
                >
                    <div id="content" className="container fullscreen-checkout">

                    <LoadingWhite/>
                    </div>
                </main>
            )
        }
        return (
            <section-add-shipping>
               {categoryInfo &&
               <OrderFormStep3
                    comeFrom={'addShipping'}
                    categoryInfo={categoryInfo}
                    goBack={this.goBack}
                />}

            </section-add-shipping>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderShipping)