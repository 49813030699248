export const PRODUCT = {
    sku: "Custom Art",
}
export const custom_options = {
    color: 'color',
    due_date: 'due_date',
    sizes_needed: 'sizes_needed',
};

export let fields = {
    [custom_options.due_date]: '',
    [custom_options.color]: '',
    [custom_options.sizes_needed]: '',
}