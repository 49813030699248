import {request} from "../../axiosRequest";

export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';
export const CREATE_ACCOUNT_FETCHING = 'CREATE_ACCOUNT_FETCHING';
export const CREATE_ACCOUNT_CLEANUP = 'CREATE_ACCOUNT_CLEANUP';


export const createAccountAttemptSuccess = (data) =>({
    type: CREATE_ACCOUNT_SUCCESS,
    data: data
})

export const createAccountAttemptFetching = () =>({
    type: CREATE_ACCOUNT_FETCHING,
})

export const createAccountAttemptCleanUp = () =>({
    type: CREATE_ACCOUNT_CLEANUP,
})

export const createAccountAttemptFailure = (data) =>({
    type: CREATE_ACCOUNT_FAILURE,
    error: data
})


export const createAccountAttempt = (username, password, firstName, lastName, middleName, address) => {
    return (dispatch) => {
        let postData = {
          customer: {
              email: username,
              firstname: firstName,
              lastname: lastName,
              middlename: middleName,
              addresses: [address],
              custom_attributes: [
                  {
                      attribute_code: 'is_active',
                      value: 0
                  }
              ]

          },
            password: password,
        };
        dispatch(createAccountAttemptFetching())
        request.post('customers/create', postData,false)
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result['data'];
                if(data['success'] && data['code'] == 200){
                    dispatch(createAccountAttemptSuccess(data['data']));
                }
                else{
                    dispatch(createAccountAttemptFailure(data))
                }
                console.log(data);
            })
            .catch(error => {
                    dispatch(createAccountAttemptFailure(error));

                }
            )

    }
};