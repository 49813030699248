import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";
import MediaPlayer from "../../MediaPlayer";
import {CDN_URL} from "../../../actions/Constants";

class Item extends React.Component{
    extensionType = {
        audio: ['mp3', 'ogg', 'wma'],
        video: ['mpg', 'avi', 'webm', 'mp4', 'swf'],
        image: ['jpg', 'jpeg', 'gif', 'png']
    }
    constructor(props) {
        super(props);

        this.mediaRef = React.createRef();
        this.stopMedia = this.stopMedia.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.stopAllMedia != this.props.stopAllMedia) {
            if(this.props.stopAllMedia) {
                this.stopMedia();
            }
        }
    }
    stopMedia()
    {
        if(this.mediaRef.current) {
            this.mediaRef.current.pause();
        }
    }
    render(){
        let value = this.props.data;
        console.log('value', value);
        if(!value) {
            return <div className="modal-slider-item">
                <div className="slider-file-error">
                    <img src={`${CDN_URL}assets/icon-slider-file-missing.svg`} alt="File Missing"/>
                    <p>We're having trouble finding that file. Please use the left and right arrows to view more samples.</p>
                </div>
            </div>
        }
        if(this.extensionType.audio.indexOf(value.ext) >= 0) {
            return <div className="modal-slider-item">
                {value.artistPhotoUrl && value.artistPhotoUrl != "" && <div className="artist-wrapper"><img className="artist" src={value.artistPhotoUrl} alt=""/></div>}
                <p><span>{value.productType}</span> - {value.cutName}</p>
                <div className="audio-player-wrapper">
                    <MediaPlayer
                        ref={this.mediaRef}
                        src={value.fileUrl}
                        vendor={'audio'}
                        stopAllMedia={this.props.stopAllMedia}
                    />
                </div>
            </div>
        }
        if(this.extensionType.image.indexOf(value.ext) >= 0) {
            console.log(value)
            return <div className="modal-slider-item">
                <img className="artwork" src={value.fileUrl} alt=""/>
                <p><span>{value.productType}</span> - {value.cutName}</p>
            </div>
        }
        if (this.extensionType.video.indexOf(value.ext) >= 0) {
            return <div className="modal-slider-item">
                <MediaPlayer
                    ref={this.mediaRef}
                    src={value.fileUrl}
                    vendor={'video'}
                    stopAllMedia={this.props.stopAllMedia}
                />
                <p><span>{value.productType}</span> - {value.cutName}</p>
            </div>
        }
        return <div className="modal-slider-item">
            <div className="slider-file-error">
                <img src={`${CDN_URL}assets/icon-slider-file-missing.svg`} alt="File Missing"/>
                <p>We're having trouble finding that file. Please use the left and right arrows to view more samples.</p>
            </div>
        </div>
    }
}

export default connect(null, null)(Item)
