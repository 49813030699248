import {
    CUSTOMER_INFORMATION_REQUEST,
    CUSTOMER_INFORMATION_RECEIVED,
    CUSTOMER_INFORMATION_ERROR,
    REMOVE_ADDRESS_CLEANUP,
    REMOVE_ADDRESS_FAILURE,
    REMOVE_ADDRESS_REQUEST,
    REMOVE_ADDRESS_SUCCESS,
} from "./actions";


import {

    CUSTOMER_UPDATE_REQUEST,
    CUSTOMER_UPDATE_SUCCESS,
    CUSTOMER_UPDATE_FAILURE, UPDATE_PASSWORD_FAILURE, UPDATE_PASSWORD_SUCCESS
} from "../../components/EditAccountInformationForm/actions";

import {
    EDIT_ADDRESS_REQUEST,
    EDIT_ADDRESS_SUCCESS
} from "../../components/AccountEditAddressForm/actions"
import {USER_LOGOUT, USER_LOGOUT_CHANGE_SUCCESS} from "../../components/Logout/actions";


export default (state = {
    customerInfo: null,
    isFetchingCustomerInformation: false,
    customerUpdateRequest: false,
    customerUpdated: false,
    updatePasswordError: null,
    removeAddressError: null,
    isFetchingRemoveAddress: false,
    removeAddressData: null,
    userLogoutSuccessfully: false
}, action) => {
    switch (action.type) {
        case CUSTOMER_INFORMATION_REQUEST:
            return {
                ...state,
                isFetchingCustomerInformation: true,
                customerInfo: action.data
            }
        case  CUSTOMER_INFORMATION_ERROR:
            return {
                ...state,
                isFetchingCustomerInformation: false,
                customerInfo: null
            }
        case CUSTOMER_INFORMATION_RECEIVED:
            return {
                ...state,
                isFetchingCustomerInformation: false,
                customerInfo: action.data
            }
        case CUSTOMER_UPDATE_REQUEST:
            return {
                ...state,
                customerUpdateRequest: true
            }
        case CUSTOMER_UPDATE_FAILURE:
            return {
                ...state,
                customerUpdateRequest: false,
                customerUpdateError: action.error
            }
        case CUSTOMER_UPDATE_SUCCESS:
            return {
                ...state,
                customerUpdateRequest: false,
                customerInfo: action.data,
                customerUpdated: true
            }
        case EDIT_ADDRESS_REQUEST:
            return {
                ...state,
                isFetchingCustomerInformation: true
            }
        case EDIT_ADDRESS_SUCCESS:
            return {
                ...state,
                isFetchingCustomerInformation: false,
                customerInfo: action.data
            };
        case UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                isFetchingCustomerInformation: false,
                updatePasswordError: action.error
            }
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetchingCustomerInformation: false,
                updatePasswordError: null
            }
        case USER_LOGOUT:
            return {
                ...state,
                customerInfo: null,
                userLogoutSuccessfully: true
            };
        case USER_LOGOUT_CHANGE_SUCCESS:
            return {
                ...state,
                customerInfo: null,
                userLogoutSuccessfully: false
            };
        case REMOVE_ADDRESS_REQUEST:
            return {
                ...state,
                isFetchingRemoveAddress: true,
                removeAddressError: null
            };
        case  REMOVE_ADDRESS_FAILURE:
            return {
                ...state,
                isFetchingRemoveAddress: false,
                searchTourData: null,
                removeAddressError: action.error
            };
        case REMOVE_ADDRESS_SUCCESS:
            return {
                ...state,
                removeAddressError: null,
                isFetchingRemoveAddress: false,
                removeAddressData: action.data
            };
        case REMOVE_ADDRESS_CLEANUP:
            return {
                ...state,
                removeAddressError: null,
                isFetchingRemoveAddress: false,
                removeAddressData: null
            };
        default:
            return state;
    }
}