import {
    ORDERS_SET_CURRENT_PAGE, ORDERS_SET_PAGE_SIZE,
    ORDERS_LIST_ERROR,
    ORDERS_LIST_RECEIVED,
    ORDERS_LIST_REQUEST, ORDERS_SORT_BY, ORDERS_FILTER, CSV_SUCCESS, CSV_ERROR, CSV_REQUEST
} from "./actions";
import {DEFAULT_PAGE_SIZE} from "../../actions/Constants";

export default (state = {
    orders: [],
    ordersTotal: 0,
    isFetchingOrders: false,
    fetchingOrdersError: false,
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 1,
    totalCount: 0,
    totalOrdersAmount: 0,
    allTours: [],
    filter: {},
    sortBy: {entity_id: 'desc'},
    isFetchingCSV: false,
    fetchingCSVError: null,
}, action) => {
    switch (action.type) {
        case ORDERS_LIST_REQUEST:
            return {
                ...state,
                isFetchingOrders: true,
                fetchingOrdersError: null,
                orders: action.data
            }
        case  ORDERS_LIST_ERROR:
            return {
                ...state,
                isFetchingOrders: false,
                fetchingOrdersError: action.error,
                orders: []
            }
        case ORDERS_LIST_RECEIVED:
            return {
                ...state,
                isFetchingOrders: false,
                orders: action.data.orders.items,
                totalCount: action.data.orders.total_count,
                totalOrdersAmount: action.data.orders.total_orders_amount,
                ordersTotal: action.data.orders.total,
                allTours: action.data.orders.all_tours,
                fetchingOrdersError: null
            }
        case ORDERS_SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.currentPage,
                isFetchingOrders: false
            }
        case ORDERS_SET_PAGE_SIZE:
            return {
                ...state,
                currentPage: 1,
                pageSize: action.pageSize,
                isFetchingOrders: false
            }
        case ORDERS_FILTER:
            return {
                ...state,
                filter: action.filter,
                currentPage: 1
            }
        case ORDERS_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy,
                isFetchingOrders: false,
                currentPage: 1
            };
        case CSV_SUCCESS:
            return {
                ...state,
                isFetchingCSV: false,
                fetchingCSVError: null
            };
        case CSV_ERROR:
            return {
                ...state,
                isFetchingCSV: false,
                fetchingCSVError: action.error
            };
        case CSV_REQUEST:
            return {
                ...state,
                isFetchingCSV: true,
                fetchingCSVError: null
            };
        default:
            return state;
    }
}