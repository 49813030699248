export const custom_options = {
    due_date: 'due_date',
    revision_instructions: 'revision_instructions',
    revision_product: 'revision_product',
    revision_package: {
        code: 'revision_package',
        sku: {
            amex_hd_tv_pkg2: 'amex_hd_tv_venue_capacity_3000',
            amex_hd_tv_pkg3: 'amex_hd_tv_venue_capacity_1000_2999',
            amex_hd_tv_pkg4: 'amex_hd_tv_venue_capacity_under_1000',

            amex_tv_pkg2: 'amex_tv_venue_capacity_3000',
            amex_tv_pkg3: 'amex_tv_venue_capacity_1000_2999',
            amex_tv_pkg4: 'amex_tv_venue_capacity_under_1000',            
            
            radio_spot_pkg2: 'radio_spot_venue_capacity_3000',
            radio_spot_pkg3: 'radio_spot_venue_capacity_1000_2999',
            radio_spot_pkg4: 'radio_spot_venue_capacity_under_1000',
            
            amex_radio_spot_pkg2: 'amex_radio_spot_venue_capacity_3000',
            amex_radio_spot_pkg3: 'amex_radio_spot_venue_capacity_1000_2999',
            amex_radio_spot_pkg4: 'amex_radio_spot_venue_capacity_under_1000',
            
            sd_tv_pkg2: 'sd_tv_venue_capacity_3000',
            sd_tv_pkg3: 'sd_tv_venue_capacity_1000_2999',
            sd_tv_pkg4: 'sd_tv_venue_capacity_under_1000',
            
            hd_tv_pkg2: 'hd_tv_venue_capacity_3000',
            hd_tv_pkg3: 'hd_tv_venue_capacity_1000_2999',
            hd_tv_pkg4: 'hd_tv_venue_capacity_under_1000',
        }
    }

}
export const fields = {
    [custom_options.due_date]: '',
    [custom_options.revision_instructions]: '',
    [custom_options.revision_product]: '',
    [custom_options.revision_package.code]: '',
}