import React from "react"
import {connect} from "react-redux"
import {FIELDS_CODE} from "../../../actions/Constants";
import CustomCheckbox from "../../CustomCheckbox";

class AdditionalEmailItem extends React.Component{
    constructor(props) {
        super(props);
        this.handleApplyTo = this.handleApplyTo.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.removeAdditionalEmail = this.removeAdditionalEmail.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.emails != this.props.emails || prevProps.apply_to != this.props.apply_to) {
            this.setState({
                emails: this.props.emails,
                apply_to: [...this.props.apply_to]
            })
        }
    }

    handleApplyTo(info) {
        let {name, value} = info;
        let apply_to = [...this.props.apply_to];
        if(apply_to.indexOf(value) != -1) {
            apply_to.splice(apply_to.indexOf(value), 1)
        }
        else {
            apply_to.push(value)
        }
        this.props.updateTdcAdditionalEmails(this.props.index, this.props.emails, [...apply_to]);
    }
    handleEmailChange(event) {
        let {name, value} = event.target;
        this.props.updateTdcAdditionalEmails(this.props.index, value, [...this.props.apply_to]);
    }
    removeAdditionalEmail(event) {
        event.preventDefault();
        let index = this.props.index;
        this.props.removeAdditionalEmail(index);
    }
    render(){
        let {index, data, errors} = {...this.props};
        let {emails, apply_to} = {...this.props};
        console.log('renderer', apply_to)
        return (
            <div className="repeat-section">
                <div className="row mb-30">
                    <div className="col-md-8">
                        <label className="required">Email Addresses (comma separated)</label>
                        <input type="text"
                               name={'emails'}
                            value={emails}
                            onChange={this.handleEmailChange}
                               className={errors && 'emails' in errors?'error':''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <label className="required">Use additional email addresses for:</label>
                        <ul className="mt-30 row">
                            <li className="col-md-4">

                                <CustomCheckbox
                                    checked={apply_to.indexOf('sales_email_creditmemo_template') != -1}
                                    label='New Credit Memo'
                                    type="checkbox"
                                    name={'sales_email_creditmemo_template'}
                                    onchange={this.handleApplyTo}
                                    feedBackInfo={{
                                        value: 'sales_email_creditmemo_template',
                                    }}
                                />

                            </li>
                            <li className="col-md-4">
                                    <CustomCheckbox
                                        checked={apply_to.indexOf('sales_email_creditmemo_comment_template') != -1}
                                        label='Credit Memo Update'
                                        type="checkbox"
                                        name={'sales_email_creditmemo_comment_template'}
                                        onchange={this.handleApplyTo}
                                        feedBackInfo={{
                                            value: 'sales_email_creditmemo_comment_template',
                                        }}
                                    />
                            </li>
                            <li className="col-md-4">
                                <CustomCheckbox
                                    checked={apply_to.indexOf('sales_email_invoice_template') != -1}
                                    label='New Invoice'
                                    type="checkbox"
                                    name={'sales_email_invoice_template'}
                                    onchange={this.handleApplyTo}
                                    feedBackInfo={{
                                        value: 'sales_email_invoice_template',
                                    }}
                                />
                            </li>
                            <li className="col-md-4">
                                 <CustomCheckbox
                                    checked={apply_to.indexOf('sales_email_invoice_comment_template') != -1}
                                    label='Invoice Update'
                                    type="checkbox"
                                    name={'sales_email_invoice_comment_template'}
                                    onchange={this.handleApplyTo}
                                    feedBackInfo={{
                                        value: 'sales_email_invoice_comment_template',
                                    }}
                                />
                            </li>
                            <li className="col-md-4">
                                <CustomCheckbox
                                    checked={apply_to.indexOf('sales_email_order_template') != -1}
                                    label='New Order'
                                    type="checkbox"
                                    name={'sales_email_order_template'}
                                    onchange={this.handleApplyTo}
                                    feedBackInfo={{
                                        value: 'sales_email_order_template',
                                    }}
                                />
                            </li>
                            <li className="col-md-4">
                                <CustomCheckbox
                                    checked={apply_to.indexOf('sales_email_order_comment_template') != -1}
                                    label='Order Update'
                                    type="checkbox"
                                    name={'sales_email_order_comment_template'}
                                    onchange={this.handleApplyTo}
                                    feedBackInfo={{
                                        value: 'sales_email_order_comment_template',
                                    }}
                                />
                            </li>
                            <li className="col-md-4">
                                <CustomCheckbox
                                    checked={apply_to.indexOf('sales_email_shipment_template') != -1}
                                    label='New Shipment'
                                    type="checkbox"
                                    name={'sales_email_shipment_template'}
                                    onchange={this.handleApplyTo}
                                    feedBackInfo={{
                                        value: 'sales_email_shipment_template',
                                    }}
                                />
                            </li>
                            <li className="col-md-4">
                                <CustomCheckbox
                                    checked={apply_to.indexOf('sales_email_shipment_comment_template') != -1}
                                    label='Shipment Update'
                                    type="checkbox"
                                    name={'sales_email_shipment_comment_template'}
                                    onchange={this.handleApplyTo}
                                    feedBackInfo={{
                                        value: 'sales_email_shipment_comment_template',
                                    }}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
                <button type="button" className="repeat-remove" title="Remove Item"
                    onClick={this.removeAdditionalEmail}>
                    Remove Item
                </button>
            </div>
        )
    }
}

export default connect(null, null)(AdditionalEmailItem)
