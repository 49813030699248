import {
    ORDER_TO_PRINT_REQUEST,
    ORDER_TO_PRINT_FAILURE,
    ORDER_TO_PRINT_SUCCESS
} from "./actions";

export default ( state = {
    orderToPrint: null,
    isFetchingOrderToPrintDetails: false,
    orderToPrintDetailsError: null
}, action) => {
    switch (action.type) {
        case ORDER_TO_PRINT_REQUEST:
            return {
                ...state,
                isFetchingOrderToPrintDetails: true,
                orderToPrintDetailsError: null
            }
        case ORDER_TO_PRINT_SUCCESS:
            return {
                ...state,
                isFetchingOrderToPrintDetails: false,
                orderToPrint: action.data,
                orderToPrintDetailsError: null
            }
        case ORDER_TO_PRINT_FAILURE:
            return {
                ...state,
                isFetchingOrderToPrintDetails: false,
                orderToPrintDetailsError: action.error
            }

        default:
            return state;

    }

}