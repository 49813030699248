import React from "react"
import {Link} from "react-router-dom";
import {CATEGORY_IMAGES_SERVICES, CDN_URL} from "../../actions/Constants";


export class RecentOrder extends React.Component{
    constructor(props){
        super(props)

        console.log(props);
    }
    addDefaultSrc(ev){
        ev.target.src = `${CDN_URL}assets/default-thumbnail.png`;
    }
    render() {
        let item = this.props.item;
        let category_image = '';
        if(item.category_image) {
            category_image = CATEGORY_IMAGES_SERVICES + item.category_image;
        } else {
            category_image = `${CDN_URL}assets/default-thumbnail.png`;
        }
        return(
            <li className="col-sm-3 col-xs-6" key={item.order}>
                <Link to={"/orderDetails/"+ item.order}>
                    <span>
                        {category_image != "" ? (
                            <img src={category_image} alt={`${item.category_artist_name} - ${item.category_name}`} onError={this.addDefaultSrc}/>
                        ) : (
                            <img src={this.addDefaultSrc} alt={`${item.category_artist_name} - ${item.category_name}`}/>
                        )}
                    </span>
                    <h4>{item.category_artist_name}</h4>
                    <p>{item.category_name}</p>
                </Link>
            </li>
        )
    }

}

