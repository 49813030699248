import React from "react"
import {connect} from "react-redux"
import {getAdmatInfo, getInfoToShow} from "../../helpers"
import {SlideToggleContent} from "../SlideToggleContent";
import ShoppingCartItemShipping from "../ShoppingCartItemShipping";
import ReactSlideToggle from "react-slide-toggle"

class CheckoutCartItem extends React.Component{
    constructor(props) {
        super(props);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.state = {
            expanded: false
        }
    }
    componentDidMount() {

    }

    toggleExpanded() {
        this.setState({
            expanded: !this.state.expanded
        })
    }
    getAdmatInfo(item)
    {
        let product = this.props.product;
        console.log('admat info', getAdmatInfo(item))
        return getAdmatInfo(item);
    }
    getCartItemProductType(item) {
        console.log('revision', item.extension_attributes)
        if(
            item
            && item.extension_attributes
            && item.extension_attributes.original_product
            && item.extension_attributes.original_product
            && item.extension_attributes.original_product.extension_attributes
            && item.extension_attributes.original_product.extension_attributes.product_type
        ) {
            return item.extension_attributes.original_product.extension_attributes.product_type.id;
        }
        return undefined
    }
    render(){
        let item = this.props.item;
        let {index, shippingRelated, cutsRelated} = this.props;
        let showInformation = getInfoToShow(item);
        let expanded = this.state.expanded;
        let admatInfo = this.getAdmatInfo(item);
        return (
            <li>
                <dl>
                    <ReactSlideToggle
                        duration={1000}
                        collapsed={true}
                        onMount={({ toggleState, toggle }) => {
                            let element = document.getElementById(`slide-toggle-box-`+item.item_id);
                            element.style.setProperty('overflow',"hidden")
                        }}
                        render={({
                                     toggle,
                                     setCollapsibleElement,
                                     toggleState,
                                     isMoving,
                                     hasReversed,
                                     range,
                                     progress
                                 }) => (
                                     <div
                                         className={
                                             "slide-toggle slide-toggle--special " +
                                             (toggleState || "").toLowerCase()
                                         }
                                     >
                                         <dt
                                             className={"slide-toggle__header " + (toggleState || "").toLowerCase()}
                                             onClick={toggle}>
                                             {index + 1 + '. '+ item.name}
                                         </dt>
                                        <dd id={"slide-toggle-box-" + item.item_id} ref={setCollapsibleElement}
                                            className="slide-toggle__box"
                                            style={{ overflow:"hidden" }}
                                        >
                                            <div
                                                className="slide-toggle__box-inner"
                                            >
                                                <dl>
                                                    {showInformation.show_date && <dt>Show Date</dt>}
                                                    {showInformation.show_date &&<dd>{showInformation.show_date}</dd>}

                                                    {showInformation.show_time && <dt>Show Time(s)</dt>}
                                                    {showInformation.show_time && <dd>{showInformation.show_time}</dd>}

                                                    {showInformation.venue_name && <dt>Venue Name</dt>}
                                                    {showInformation.venue_name && <dd>{showInformation.venue_name}</dd>}

                                                    {showInformation.city_market_name && <dt>City / Market</dt>}
                                                    {showInformation.city_market_name && <dd>{showInformation.city_market_name}</dd>}

                                                    {showInformation.special_guests && <dt>Special Guest / Instructions</dt>}
                                                    {showInformation.special_guests && <dd>{showInformation.special_guests}</dd>}

                                                    {showInformation.special_instructions && <dt>Special Guest / Instructions</dt>}
                                                    {showInformation.special_instructions && <dd>{showInformation.special_instructions}</dd>}

                                                    {admatInfo && admatInfo.length > 0 && <dt>Admat</dt>}
                                                    {admatInfo.map((item, index) => {
                                                        return <dd className="filename" key={index}>{item['admat_file']}</dd>
                                                    })}

                                                    {shippingRelated.map((itemShipping) => {
                                                        console.log('itemShipping', itemShipping)
                                                        return <shipping-info key={itemShipping.item_id}>
                                                            <dl>
                                                                <dt>{itemShipping.name}</dt>
                                                                {itemShipping && <ShoppingCartItemShipping
                                                                    cartItem={itemShipping}
                                                                />}
                                                            </dl>
                                                        </shipping-info>
                                                    })}
                                                    {cutsRelated.map((cutProduct) => {
                                                        console.log('itemShipping', cutProduct)
                                                        return <shipping-info key={cutProduct.item_id}>
                                                            <dl>
                                                                <dt>{cutProduct.name}</dt>
                                                                {cutProduct && <ShoppingCartItemShipping
                                                                    cartItem={cutProduct}
                                                                />}
                                                            </dl>
                                                        </shipping-info>
                                                    })}
                                                    {(this.getCartItemProductType(item) === 'revision_product')
                                                    && <shipping-info key={item.item_id}>
                                                        <dl>
                                                            {item && <ShoppingCartItemShipping
                                                                cartItem={item}
                                                            />}
                                                        </dl>
                                                    </shipping-info>}
                                                    {(this.getCartItemProductType(item) === 'shipping')
                                                    && <shipping-info key={item.item_id}>
                                                        <dl>
                                                            {item && <ShoppingCartItemShipping
                                                                cartItem={item}
                                                            />}
                                                        </dl>
                                                    </shipping-info>}
                                                </dl>
                                            </div>
                                        </dd>
                                    </div>
                        )}

                    />
{/*                    <dt className={expanded? "expanded transition": ''} onClick={this.toggleExpanded}>
                        {index + 1 + '. '+ item.name}
                    </dt>
                    <SlideToggleContent isVisible={expanded} >
                    <dd>
                        <dl>
                            {showInformation.show_date && <dt>Show Date</dt>}
                            {showInformation.show_date &&<dd>{showInformation.show_date}</dd>}

                            {showInformation.show_time && <dt>Show Time(s)</dt>}
                            {showInformation.show_time && <dd>{showInformation.show_time}</dd>}

                            {showInformation.venue_name && <dt>Venue Name</dt>}
                            {showInformation.venue_name && <dd>{showInformation.venue_name}</dd>}

                            {showInformation.city_market_name && <dt>City / Market</dt>}
                            {showInformation.city_market_name && <dd>{showInformation.city_market_name}</dd>}

                            {showInformation.special_guests && <dt>Special Guest / Instructions</dt>}
                            {showInformation.special_guests && <dd>{showInformation.special_guests}</dd>}

                            {showInformation.special_instructions && <dt>Special Guest / Instructions</dt>}
                            {showInformation.special_instructions && <dd>{showInformation.special_instructions}</dd>}

                            {admatInfo && admatInfo.length > 0 && <dt>Admat</dt>}
                            {admatInfo.map((item, index) => {
                                return <dd className="filename" key={index}>{item['admat_file']}</dd>
                            })}

                            {shippingRelated.map((itemShipping) => {
                                console.log('itemShipping', itemShipping)
                                return <shipping-info key={itemShipping.item_id}>
                                        <dl>
                                            <dt>{itemShipping.name}</dt>
                                            {itemShipping && <ShoppingCartItemShipping
                                                cartItem={itemShipping}
                                            />}
                                        </dl>
                                    </shipping-info>
                            })}
                            {cutsRelated.map((cutProduct) => {
                                console.log('itemShipping', cutProduct)
                                return <shipping-info key={cutProduct.item_id}>
                                        <dl>
                                            <dt>{cutProduct.name}</dt>
                                            {cutProduct && <ShoppingCartItemShipping
                                                cartItem={cutProduct}
                                            />}
                                        </dl>
                                    </shipping-info>
                            })}
                            {(this.getCartItemProductType(item) === 'revision_product')
                            && <shipping-info key={item.item_id}>
                                <dl>
                                    {item && <ShoppingCartItemShipping
                                        cartItem={item}
                                    />}
                                </dl>
                            </shipping-info>}
                            {(this.getCartItemProductType(item) === 'shipping')
                            && <shipping-info key={item.item_id}>
                                <dl>
                                    {item && <ShoppingCartItemShipping
                                        cartItem={item}
                                    />}
                                </dl>
                            </shipping-info>}
                        </dl>
                    </dd>
                    </SlideToggleContent>*/}
                </dl>
            </li>
        )
    }
}

export default connect(null, null)(CheckoutCartItem)
