import {request} from "../../axiosRequest";
import {shoppingCartAttempt} from "../ShoppingCartListItems/actions";

export const REMOVE_ITEM_FROM_CART_REQUEST = 'REMOVE_ITEM_FROM_CART_REQUEST';
export const REMOVE_ITEM_FROM_CART_SUCCESS = 'REMOVE_ITEM_FROM_CART_SUCCESS';
export const REMOVE_ITEM_FROM_CART_FAILURE = 'REMOVE_ITEM_FROM_CART_FAILURE';

export const removeItemFromCartSuccess = (data) =>({
    type: REMOVE_ITEM_FROM_CART_SUCCESS,
    data: data
})

export const removeItemFromCartRequest = () =>({
    type: REMOVE_ITEM_FROM_CART_REQUEST,
})

export const removeItemFromCartFailure = (error) =>({
    type: REMOVE_ITEM_FROM_CART_FAILURE,
    error: error
})


export const removeItemFromCart = (itemId, customerId, cartId = null) => {
    return (dispatch) => {
        dispatch(removeItemFromCartRequest())
        request.post(`carts/delete/items/${itemId}`, {cart_id: cartId, 'customer_id': customerId})
            .then(response => {
                return response;
            })
            .then(result => {
                console.log("Removing items");
                console.log(result)
                let data = result.data;
                if(result.status == 200){
                    if(data.success && data.code == 200) {
                        dispatch(removeItemFromCartSuccess(data.data));
                        dispatch(shoppingCartAttempt(customerId))
                    }
                    else{
                        dispatch(removeItemFromCartFailure(data))
                    }
                }
                else{
                    dispatch(removeItemFromCartFailure(result.data))
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(removeItemFromCartFailure(error));
            })

    }
};