import {custom_options as common_options} from "../AlbumCommonOptions/definition"
import {fields as common_fields} from "../AlbumCommonOptions/definition"

export const custom_options = {
    ...common_options,
    instagram_vertical_images: {
        code: 'instagram_vertical_images',
        sku: {
            instagram_vertical_images: 'instagram_vertical_images_option'
        },
    },
    album_artwork: {
        code: 'album_artwork',
        sku: {
            album_artwork: 'album_artwork_option'
        },
    },
};

export const fields = {
    ...common_fields,
    [custom_options.instagram_vertical_images.code]: null,
    [custom_options.album_artwork.code]: null,

}