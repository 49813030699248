import React from 'react';
import RecentOrders from "../RecentOrders/index";
import CustomerAddressList from "../../components/CustomerAddressList";
import {connect} from "react-redux"
import {CustomerInformation} from "../../components/CustomerInformation";
import {Link} from "react-router-dom";
import {getToursByCriteria} from "./actions";
import {CDN_URL} from "../../actions/Constants";
import HolidayMessage from "../../components/HolidayMessage";

const mapStateToProps = state => ({
    ...state.customerInformation,
    ...state.dashboard,
});
const mapDispatchToProps = {
    getToursByCriteria,
};

class Dashboard extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            tourSearchCriteria: '',
            tourSearchResult: [],
            noResults: null
        };
        this.timeOut = 0;
        this.searchTours = this.searchTours.bind(this);
        this.updateSearchResult = this.updateSearchResult.bind(this);
        this.searchToursOnSubmit = this.searchToursOnSubmit.bind(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.isFetchingSearchTours != this.props.isFetchingSearchTours && this.props.isFetchingSearchTours) {
            this.setState({
                tourSearchResult: [],
                noResults: false
            })
        }
    }

    searchToursOnSubmit(event)
    {
        event.preventDefault();
        this.searchTours(
            {target:
                    {
                        name: 'tourSearchCriteria',
                        value: this.state.tourSearchCriteria
                    }
                }
            )
    }
    searchTours(event) {
        if(event.preventDefault != undefined) {
            event.preventDefault();
        }
        let {name, value} = event.target;
        this.setState({[name]: value});
        if(this.timeOut){
            clearTimeout(this.timeOut)
        }
        this.timeOut = setTimeout(() => {
            let searchCriteria = value;

            if(searchCriteria && searchCriteria.length >= 2) {
                this.props.getToursByCriteria(searchCriteria,
                    (data) => {
                    this.updateSearchResult(data)
                        if(data.length == 0) {
                            this.setState({
                                noResult: true
                            })
                        }
                        else {
                            this.setState({
                                noResult: false
                            })
                        }
                })
            }
            else {
                this.setState({
                    tourSearchResult: [],
                    noResult: false
                })
            }
            document.getElementById('tourSearchCriteria').focus();
        }, 500);

    }
    updateSearchResult(data)
    {
        this.setState({
            tourSearchResult: data
        })
    }
    render(){
        let {isFetchingCustomerInformation, isFetchingSearchTours} = this.props;
        let customerInfo = this.props.customerInfo;
        let tourSearchResult = this.state.tourSearchResult;
        let noResults = this.state.noResult;
        if(isFetchingCustomerInformation || customerInfo == null) {
            return (
                <main id="main" role="main">
                    <section id="content" className="container content-wrapper loading-wrapper">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="loading-white">
                                    <i className="icon-loading"></i>
                                    <span>Loading Your Dashboard</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        else {
            return (
                <main id="main" role="main">
                    <section id="hero-search" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-search.jpg')`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-md-offset-1 col-xs-12">
                                    <HolidayMessage/>
                                    <div className="center">
                                        <h1 className="h-one white">Hello, {customerInfo.firstname}</h1>
                                        <p className="p-one white">
                                            Need to order Promo Materials?&nbsp; Search for a tour here:
                                        </p>
                                        <form onSubmit={this.searchToursOnSubmit}>
                                            <input type="text" placeholder="Search Tours"
                                                   id='tourSearchCriteria'
                                                   name='tourSearchCriteria'
                                                    onChange={this.searchTours}
                                                   autoComplete={"off"}
                                                   // readOnly={isFetchingSearchTours}
                                            />
                                            <ul className="search-autocomplete">
                                                {isFetchingSearchTours && <li>
                                                    <div className="loading-small">
                                                        <i className="icon-loading"></i>
                                                        <span>Searching Tour Roster</span>
                                                    </div>
                                                </li>}
                                                {!isFetchingSearchTours && noResults && <li>
                                                    <div className="no-results">
                                                        <i aria-hidden="true">
                                                            <img src={`${CDN_URL}assets/icon-search-no-results.svg`} alt="No Tours Found"/>
                                                        </i>
                                                        <span>We couldn't find anything. Please try browsing the entire <Link to="/artists">Tour Roster</Link>.</span>
                                                    </div>
                                                </li>}
                                                {tourSearchResult && tourSearchResult.map((tour) => {
                                                    if(tour.type == 'artist'){
                                                      return   <li key={tour.id}>
                                                            <Link to={{pathname: `/artists/${tour.id}`}}>
                                                                {tour.name}
                                                            </Link>
                                                        </li>
                                                    }
                                                    else {
                                                       return  <li key={tour.id}>
                                                            <Link to={{
                                                                pathname: `/order/process/tours/${tour.id}`,
                                                            }}
                                                            >
                                                            {tour.name}
                                                            </Link>
                                                        </li>
                                                }}
                                                )}
                                            </ul>
                                            <button type="submit" style={{display: "none"}}>Search Tours</button>
                                        </form>
                                        <small>
                                            <Link to="/tours">
                                                TAKE THE TOUR
                                            </Link>
                                            <p>New here?  Find out what we do.</p>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="content" className="container content-wrapper">
                        <div className="row">
                            <div className="col-xs-12">
                                <h2 className="h-two blue">Account Dashboard</h2>
                                <section className="row section-welcome">
                                    <div className="col-xs-12">
                                        <h3 className="h-four">Hello, {customerInfo.firstname}</h3>
                                        <p className="p-two grey">From your Account Dashboard you have the ability
                                            to view a snapshot of your recent account activity and update your
                                            account information.</p>
                                    </div>
                                </section>
                                <RecentOrders customerInfo={customerInfo}/>
                            </div>
                        </div>
                        <div className="section-title">
                            <h3 className="h-three blue">Account Information</h3>
                        </div>
                        <CustomerInformation customerInfo={customerInfo}/>
                        {customerInfo.addresses && <CustomerAddressList
                                                    customerInfo={{...customerInfo}}
                                                    addresses={[...customerInfo.addresses]}
                                                    defaultBillingAddressId={customerInfo.default_billing}
                        />}
                    </section>
                </main>
                    // }

            )
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
