import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom"
import {deleteAddress} from "../../containers/CustomerInformation/actions";
import ModalConfirmation from "../ModalConfirmation";

const mapDispatchToProps = {
    deleteAddress,
}


class AccountAddressBookItem extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            hideDialog: true
        }
        this.deleteAddress = this.deleteAddress.bind(this);
        this.showDeleteAddressDialog = this.showDeleteAddressDialog.bind(this);
        this.hideDeleteAddressDialog = this.hideDeleteAddressDialog.bind(this);
    }

    deleteAddress() {
        let address = this.props.address;
        let customerInfo = this.props.customerInfo;
        this.props.deleteAddress(address.id, customerInfo);
        this.hideDeleteAddressDialog();
    }
    showDeleteAddressDialog() {
        this.setState({hideDialog: false});
    }
    hideDeleteAddressDialog() {
        this.setState({hideDialog: true});
    }
    render(){
        let address = this.props.address;
        let hideDialog = this.state.hideDialog
        let defaultBilling = this.props.defaultBilling;
        return (
            <address-item>
                <div className="section-information-column">
                    <h2 className="h-five">
                        {defaultBilling && "Default Billing Address"}
                        {!defaultBilling&& "Additional Address"}
                    </h2>
                    <span>{`${address.firstname} ${address.lastname}`}</span>
                    <span>{address.company}</span>
                    <span>{address.street.toString()}</span>
                    <span>{address.city}, {address.region.region}, {address.postcode}</span>
                    <span>{address.country_id}</span>
                    <span>T: {address.telephone}</span>
                    {address.fax && <span>F: {address.fax}</span>}
       {/*             {hideDialog && <div className="address-options">
                        <Link to={`/customer/address/${address.id}`} className="button xxxsmall blue">Edit</Link>
                        {!defaultBilling &&
                        <Link to={"#"} onClick={this.showDeleteAddressDialog} className="button xxxsmall red ml-5">Delete</Link>
                        }
                    </div>}
                    <ModalConfirmation
                        onConfirm={this.deleteAddress}
                        onCancel={this.hideDeleteAddressDialog}
                        hidden={hideDialog}
                    />*/}
                </div>
            </address-item>
        )
    }
}

export default connect(null, mapDispatchToProps)(AccountAddressBookItem)
