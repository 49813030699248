import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE, LOGIN_STATUS, LOGIN_REQUEST
} from "./actions";
import {USER_LOGOUT} from "../Logout/actions";

export default ( state = {
    isAuthenticated: false,
    customerEmail: null,
    customerAuthToken: null,
    loginSuccess: false,
    loginError: null,
    isFetchingLoginRequest: false,
    impersonated: false,
}, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isFetchingLoginRequest: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                customerEmail: action.data.customerEmail,
                customerAuthToken: action.data.customerAuthToken,
                isAuthenticated: true,
                loginSuccess: true,
                loginError: null,
                isFetchingLoginRequest: false,
                impersonated:  action.data.impersonated
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                customerEmail: null,
                customerAuthToken: null,
                isAuthenticated: false,
                loginError: action.error,
                isFetchingLoginRequest: false
            };
        case LOGIN_STATUS:
            return {
                ...state,
                loginSuccess: action.data
            };

        case USER_LOGOUT:
            return {
                ...state,
                customerEmail: null,
                customerAuthToken: null,
                isAuthenticated: false,
                }
        default:
            return state;

    }

}
