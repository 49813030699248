import React from "react"
import {connect} from "react-redux"
import MaskedInput from "react-maskedinput";
import {FIELDS_CODE} from "../../actions/Constants";
import CustomCheckbox from "../CustomCheckbox";
import ErrorMessage from "../ErrorMessage";

class CheckoutPaymentInformation extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            payment: 'checkmo',
            credit_card: {
                cc_cid: '',
                // cc_type:'',
                cc_exp_year: "",
                cc_exp_month: '',
                cc_number: '',
                cc_exp_date: ''
            },
            purchase_order: {
                po_number: ''
            },

        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePaymentData = this.handlePaymentData.bind(this);
    }
    componentDidMount() {
        if(this.props.paymentSelected) {
            this.setState({
                ...this.props.paymentSelected
            })
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('credit', this.state.credit_card.cc_exp_date)
        console.log('credit', prevState.credit_card.cc_exp_date)
        if(prevState.credit_card.cc_exp_date != this.state.credit_card.cc_exp_date) {
            let date = this.state.credit_card.cc_exp_date.split('/');
            let credit_card = {...this.state.credit_card};
            credit_card.cc_exp_month =  date[0];
            credit_card.cc_exp_year =  date.length >= 1?date[1]:'';
            this.setState({
                credit_card: {...credit_card}
            })
        }
        if(prevState != this.state) {
            this.props.updateSelectedPaymentMethod({...this.state});
        }

    }

    handleChange(info) {
        let {value, name} = {...info};
        this.setState({
            [name]: value
        })
    }
    handlePaymentData(event) {
        let payment = this.state.payment;
        let {value, name} = event.target;
        let data = {...this.state[payment]};
        data[name] = value;
        this.setState({[payment] : data})
    }
    render() {
        let {payment, purchase_order, credit_card} = {...this.state};
        let errors = {};
        if(this.props.paymentSelected && this.props.paymentSelected.errors) {
             errors = this.props.paymentSelected.errors;
        }

        return (
            <checkout-div>
                <div className="row">
                    <div className="col-xs-12">
                        {Object.keys(errors).length != 0 && <ErrorMessage
                            message={'Please select your preferred billing method from the options below.'}
                        />}
                        <label className={Object.keys(errors).length != 0?"error":''}>Please select your preferred billing method:</label>
                        <ul>
                            <li>
                                <label>
                                    <CustomCheckbox checked={payment == 'checkmo'? true: false}
                                                    label='Invoice Me'
                                                    type="radio"
                                                    name="payment"
                                                    onchange={this.handleChange}
                                                    feedBackInfo={{
                                                        value: 'checkmo',
                                                        name: 'payment'
                                                    }}
                                    />
                                </label>
                            </li>
                            <li>
                                <label>
                                    <CustomCheckbox checked={payment == 'purchase_order'? true: false}
                                                    label='Purchase Order'
                                                    type="radio"
                                                    name="payment"
                                                    onchange={this.handleChange}
                                                    feedBackInfo={{
                                                        value: 'purchase_order',
                                                        name: 'payment'
                                                    }}
                                    />
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row" style={{display: payment!='purchase_order'?"none":'block' }}>
                    <div className="form-group col-sm-6">
                        <label className="required">Purchase Order Number</label>
                        <input type="text"
                               className={'purchase_order' in errors?'error':''}
                            onChange={this.handlePaymentData}
                            name="po_number"
                            value={purchase_order.po_number}
                        />
                    </div>
                </div>
                <div className="row" style={{display: payment!='credit_card'?"none":'block' }}>
                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label className="required">Card Number</label>
                        <MaskedInput type="text"
                                 className={'cc_number' in errors?'error':''}
                                mask="1111 1111 1111 1111"
                                placeholder="1111 1111 1111 1111"
                                value={credit_card.cc_number}
                                onChange={this.handlePaymentData}
                                name='cc_number'
                        />
                    </div>
                    <div className="form-group col-lg-3 col-md-3 col-sm-3 col-xs-6">
                        <label className="required">Expires</label>
                        <MaskedInput
                            className={'cc_exp_date' in errors?'error':''}
                            type='text'
                            mask="11/1111"
                            placeholder="MM/YYYY"
                            size="6"
                            value={credit_card.cc_exp_date}
                            onChange={this.handlePaymentData}
                            name='cc_exp_date'
                        />
                    </div>
                    <div className="form-group col-lg-3 col-md-3 col-sm-3 col-xs-6">
                        <label className="required">CVC</label>
                        <MaskedInput type="text"
                                     className={'cc_cid' in errors?'error':''}
                                     mask="111"
                                     placeholder="000"
                                     size="3"
                               value={credit_card.cc_cid}
                               onChange={this.handlePaymentData}
                               name='cc_cid'
                        />
                    </div>
                </div>
            </checkout-div>
        )
    }
}

export default connect(null, null)(CheckoutPaymentInformation)
