import {request} from "../../axiosRequest";


export const FOOTER_BLOCK_REQUEST = 'FOOTER_BLOCK_REQUEST';
export const FOOTER_BLOCK_SUCCESS = 'FOOTER_BLOCK_SUCCESS';
export const FOOTER_BLOCK_FAILURE = 'FOOTER_BLOCK_FAILURE';
export const FOOTER_BLOCK_STATUS = 'FOOTER_BLOCK_STATUS';

export const footerBlockSuccess = (data) =>({
    type: FOOTER_BLOCK_SUCCESS,
    data: data
})

export const footerBlockRequest = () =>({
    type: FOOTER_BLOCK_REQUEST,
})

export const footerBlockFailure = (data) =>({
    type: FOOTER_BLOCK_FAILURE,
    error: data
})

export const footerBlockAttempt = () => {
    return (dispatch) => {
        let blockIdentifier = 'footer_links'
        dispatch(footerBlockRequest());
        request.get(`block/identifier/${blockIdentifier}`)
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result['data'];
                if(data['success'] && data['code'] == 200){
                    console.log('footer data', data)
                    let block = data.data.items
                    if(block && block.length > 0) {
                        let blockItem = block[0]
                        let blockContent = blockItem['content'];
//                         blockContent = blockContent.replace(/<a /g, "<Link ");
                        dispatch(footerBlockSuccess(blockContent))
                    }

                }
                else{
                dispatch(footerBlockFailure())
                    console.log('footer error', data)
                }

            })
            .catch(error => {
                    console.log('Login failed');
                    console.log(error);
                    dispatch(footerBlockFailure(error));
                }
            )

    }
};