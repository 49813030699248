export const PRODUCT = {
    sku: "Custom TV",
}
export const custom_options = {
    on_sale_date_time_text: 'on_sale_date_time_text',
    today_day: 'today_day',
    custom_production_notes: 'custom_production_notes',
};

export const fields = {
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.today_day]: '',
    [custom_options.custom_production_notes]: '',
}
