export const PRODUCT = {
    sku: "AMEX TV",
}
export const custom_options = {
    due_date: 'due_date',
    on_sale_date_time_text: 'on_sale_date_time_text',
    send_tv_for_approval: {
        code: 'send_tv',
        sku: {
            send_tv_for_approval: 'send_tv_for_approval'
        }
    },
    send_tv_for_approval_to: 'send_tv_for_approval_to',
    closed_caption: {
        code: 'c_caption',
        sku: {
            closed_caption: 'closed_caption'
        }
    },

}

export let fields = {
    [custom_options.due_date]: '',
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.send_tv_for_approval.code]: null,
    [custom_options.send_tv_for_approval.sku.send_tv_for_approval]: false,
    [custom_options.send_tv_for_approval_to]: '',
    [custom_options.closed_caption.code]: null,
    [custom_options.closed_caption.sku.closed_caption]: false,
}
