/*import {request} from "../../axiosRequest";

export const SUBCATEGORIES_REQUEST = "SUBCATEGORIES_REQUEST"
export const SUBCATEGORIES_SUCCESS = "SUBCATEGORIES_SUCCESS"
export const SUBCATEGORIES_FAILURE = "SUBCATEGORIES_FAILURE"

export const subcategoriesRequest = () => ({
    type: SUBCATEGORIES_REQUEST
})
export const subcategoriesSuccess = (data) => ({
    type: SUBCATEGORIES_SUCCESS,
    data: data
})
export const subcategoriesFailure = (error) => ({
    type: SUBCATEGORIES_FAILURE,
    error: error
})

export const getSubCategories = (categoryId) => {
    let data = {
        'filterBy': JSON.stringify({parent_id: {value: categoryId}}),
        'sortBy': JSON.stringify({created_at: 'desc'})
    };
    return (dispatch) => {
        dispatch(subcategoriesRequest());
        console.log("GET categories")
        console.log(categoryId);
        return request.get('categories',true, data)
            .then(response => {return response})
            .then(result => {
                let data = result.data;
                if(data.success && data.code == 200){
                    data = data.data;
                    data["categoryId"] = categoryId
                    console.log("Success....")
                    console.log(data)
                    dispatch(subcategoriesSuccess(data))

                }
                else{
                    console.log(result)
                    dispatch(subcategoriesFailure(data.data))
                    console.log("Error....")
                }
            })
            .catch(error => {
                console.log(error);
                console.log("Error....")
                dispatch(subcategoriesFailure(error))
            })
    }
}*/
import {request} from "../../axiosRequest";
export const TOUR_DEMO_REQUEST = "TOUR_DEMO_REQUEST"
export const TOUR_DEMO_SUCCESS = "TOUR_DEMO_SUCCESS"
export const TOUR_DEMO_FAILURE = "TOUR_DEMO_FAILURE"

export const tourDemosRequest = () => ({
    type: TOUR_DEMO_REQUEST
})
export const tourDemosSuccess = (data) => ({
    type: TOUR_DEMO_SUCCESS,
    data: data
})
export const tourDemosFailure = (error) => ({
    type: TOUR_DEMO_FAILURE,
    error: error
})

export const getTourDemos = (categoryCode) => {

    return (dispatch) => {
        dispatch(tourDemosRequest());
        console.log("GET categories")
        console.log(categoryCode);
        return request.get(`tourDemos/categoryCode/${categoryCode}`,false)
            .then(response => {return response})
            .then(result => {
                let data = result.data;
                if(data.success && data.code == 200){
                    data = data.data;
                    data["categoryId"] = categoryCode
                    console.log("Success....")
                    console.log(data)
                    dispatch(tourDemosSuccess(data))

                }
                else{
                    console.log(result)
                    dispatch(tourDemosFailure(data.data))
                    console.log("Error....")
                }
            })
            .catch(error => {
                console.log(error);
                console.log("Error....")
                dispatch(tourDemosFailure(error))
            })
    }
}