import {
    GET_PRODUCT_OPTIONS_FAILURE,
    GET_PRODUCT_OPTIONS_SUCCESS,
    GET_PRODUCT_OPTIONS_REQUEST, CLEAN_EDIT_CART_ITEM
} from "./actions";

export default (state = {
    originalProduct: null,
    isFetchingEditCartItem: false,
    error: null
}, action) => {
    switch (action.type) {
        case GET_PRODUCT_OPTIONS_REQUEST:
            return {
                ...state,
                isFetchingEditCartItem: true,
            }
        case  GET_PRODUCT_OPTIONS_FAILURE:
            return {
                ...state,
                isFetchingEditCartItem: false,
                error: action.error
            }
        case GET_PRODUCT_OPTIONS_SUCCESS:
            return {
                ...state,
                isFetchingEditCartItem: false,
                originalProduct: action.data
            }
        case CLEAN_EDIT_CART_ITEM:
            return {
                ...state,
                isFetchingEditCartItem: null,
                originalProduct: null
            }
        default:
            return state;
    }
}