import {
    FOOTER_BLOCK_FAILURE,
    FOOTER_BLOCK_SUCCESS,
    FOOTER_BLOCK_REQUEST
} from "./actions";

export default (state = {
    footerBLockData: null,
    isFetchingFooterBLock: false,
    footerBlockError: null
}, action) => {
    switch (action.type) {
        case FOOTER_BLOCK_REQUEST:
            return {
                ...state,
                isFetchingFooterBLock: true,
                footerBlockError: null
            }
        case  FOOTER_BLOCK_FAILURE:
            return {
                ...state,
                isFetchingFooterBLock: false,
                footerBlockError: action.error
            }
        case FOOTER_BLOCK_SUCCESS:
            return {
                ...state,
                isFetchingFooterBLock: false,
                footerBLockData: action.data,
                footerBlockError: null
            }
        default:
            return state;
    }
}