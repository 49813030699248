import React from "react"
import {connect} from "react-redux"
import {getCsvOrdersRequest, ordersListFetch} from "./actions";
import OrdersList from "../../components/OrdersList";
import OrdersFilter from "../../components/OrdersFilter";
import {Link} from "react-router-dom";
import {CDN_URL} from "../../actions/Constants";

const mapStateToProps = state => ({
    ...state.customerInformation,
    ...state.ordersList,

})

const mapDispatchToProps = {
    ordersListFetch,
    getCsvOrdersRequest,
}

class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersTotal: 0
        }
        this.updateOrdersTotal = this.updateOrdersTotal.bind(this);
        this.downloadCsv = this.downloadCsv.bind(this);
    }
    updateOrdersTotal(ordersTotal){
        this.setState({
            ordersTotal: ordersTotal
        })
    }
    downloadCsv(event) {
        event.preventDefault();
        if(this.props.customerInfo && !this.props.isFetchingCSV) {
            let customerId = this.props.customerInfo.id;
            let sortBy = this.props.sortBy;
            let filterBy = this.props.filter;
            this.props.getCsvOrdersRequest(customerId, filterBy, sortBy);
        }
    }
    render(){
        let {ordersTotal} = this.state;
        let isFetchingCSV = this.props.isFetchingCSV;
        let fetchingCSVError = this.props.fetchingCSVError;
        let fetchingOrdersError = this.props.fetchingOrdersError;
        let totalCount = this.props.totalCount;
        return (
            <main id="main" role="main">
                <section id="content" className="container content-wrapper">
                    <div className="row section-header">
                        <div className="col-md-10 col-sm-9">
                            <ul className="breadcrumb">
                                <li><Link to="/dashboard">Account Dashboard</Link></li>
                            </ul>
                            <h1 className="h-two blue">My Orders</h1>
                        </div>
                    </div>
                   {(fetchingCSVError || fetchingOrdersError) && <div className="alert error">
                        <p>{fetchingCSVError}</p>
                        <p>{fetchingOrdersError}</p>
                    </div>}
                    <div className="row filter-export">
                        <div className="col-md-8 col-sm-7 col-xs-12">
                            <span className="filter-totals-label">Filter Results Total:</span>
                            <span className="filter-totals">
                                {ordersTotal.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',})}
                            </span>
                        </div>
                        {totalCount != 0 && <div className="col-md-4 col-sm-5 col-xs-12">
                            <Link to={`#`} className="button large blue icon"
                                  onClick={this.downloadCsv}
                            >
                                Export CSV File
                                <i aria-hidden="true">
                                    <img src={`${CDN_URL}assets/icon-button-export.svg`} alt="Export CSV File"/>
                                </i>
                                {isFetchingCSV && <label>
                                    downloading csv....
                                </label>}
                            </Link>
                        </div>}
                    </div>
                <OrdersFilter
                    allTours={this.props.allTours}
                />
                <OrdersList updateOrdersTotal={this.updateOrdersTotal}
                />
                </section>
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
