import React from "react"
import {connect} from "react-redux"
import MediaPlayer from "../../components/MediaPlayer"
import VideoPlayer from "../MediaPlayer/Aux/VideoPlayer";
import {CDN_URL} from "../../actions/Constants";

class ToursModalItem extends React.Component{
    extensionType = {
        audio: ['mp3', 'ogg', 'wma'],
        video: ['mpg', 'avi', 'webm', 'mp4', 'swf'],
        image: ['jpg', 'jpge', 'gif', 'png']
    }
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }
    render(){
        let value = this.props.data;
        if(!value || value.demoExtension == '') {
            return <div className="modal-slider-item">
                <div className="slider-file-error">
                    <img src={`${CDN_URL}assets/icon-slider-file-missing.svg`} alt="File Missing"/>
                    <p>We're having trouble finding that file. Please use the left and right arrows to view more examples.</p>
                </div>
            </div>
        }
        if(this.extensionType.audio.indexOf(value.demoExtension) >= 0) {
            return <div className="modal-slider-item">
                {value.artistImage && value.artistImage != "" && <div className="artist-wrapper"><img className="artist" src={value.artistImage} alt=""/></div>}
                <p><span>{value.artistName}</span> - {value.categoryName}</p>
                <div className="audio-player-wrapper">
                    <MediaPlayer
                        src={value.demoFileUrl}
                        vendor={'audio'}
                        onMouseEnterMediaControls={this.props.onMouseEnterMediaControls}
                        onMouseLeaveMediaControls={this.props.onMouseLeaveMediaControls}
                        stopAllMedia={this.props.stopAllMedia}
                    />
                </div>
            </div>
        }
        if(this.extensionType.image.indexOf(value.demoExtension) >= 0) {
            console.log(value)
            return <div className="modal-slider-item">
                <img className="artwork" src={value.demoFileUrl} alt=""/>
                <p><span>{value.artistName}</span> - {value.categoryName}</p>
            </div>
        }
        if (this.extensionType.video.indexOf(value.demoExtension) >= 0) {
            return <div className="modal-slider-item">
                <MediaPlayer
                    src={value.demoFileUrl}
                    vendor={'video'}
                    onMouseEnterMediaControls={this.props.onMouseEnterMediaControls}
                    onMouseLeaveMediaControls={this.props.onMouseLeaveMediaControls}
                    stopAllMedia={this.props.stopAllMedia}
                />
                <p><span>{value.artistName}</span> - {value.categoryName}</p>
            </div>
        }
        return <div className="modal-slider-item">
            <div className="slider-file-error">
                <img src={`${CDN_URL}assets/icon-slider-file-missing.svg`} alt="File Missing"/>
                <p>We're having trouble finding that file. Please use the left and right arrows to view more examples.</p>
            </div>
        </div>
    }
}

export default connect(null, null)(ToursModalItem)
