import React from "react"
import {connect} from "react-redux"
import ShoppingCartListItems from "../../components/ShoppingCartListItems";
import ShoppingCartTotals from "../../components/shoppingCartTotals";
import {Link} from "react-router-dom";
import LoadingBlack from "../../components/LoadingBlack";
import {CDN_URL} from "../../actions/Constants";
import {shoppingCartCleanUpRequest} from "../../components/ShoppingCartListItems/actions";

const mapStateToProps = state => ({
    ...state.cart,
    ...state.customerInformation,
});

const mapDispatchToProps = {
    shoppingCartCleanUpRequest,
};


class ShoppingCart extends React.Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        let {itemsAdded, itemUpdated} = this.props.location;
        this.state = {
            itemsAdded: itemsAdded,
            itemUpdated: itemUpdated,
            itemDeleted: false
        }
        this.updateItemDeleted = this.updateItemDeleted.bind(this);
    }
    updateItemDeleted()
    {
        this.setState({
            itemDeleted: !this.state.itemDeleted
        })
    }
    componentDidMount() {
        this._isMounted = true;
        let {itemsAdded, itemUpdated, itemDeleted} = this.state;
        if(itemsAdded || itemUpdated || itemDeleted) {
            setTimeout(() => {
                if(this._isMounted) {
                    this.setState({
                        itemsAdded: false,
                        itemUpdated: false,
                        itemDeleted: false
                    })
                }
            }, 5000)
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    cleanCart() {
        this.props.shoppingCartCleanUpRequest(this.props.customerInfo.id);
    }

    render(){
        let {cart, customerInfo, isFetchingCartItemsList, removeItemIsFetching} = this.props;
        let {artistName} = this.props.location;
        let {itemsAdded, itemUpdated, itemDeleted} = this.state;
        if(removeItemIsFetching) {
            return (
                <main role="main" className="shopping-cart-main" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-cart.png')`}}>
                    <section id="content" className="container fullscreen-cart loading-wrapper">
                        <div className="row">
                            <div id="cart-items" className="col-xs-12">
                                <LoadingBlack
                                    label={'Updating Cart'}
                                    />
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        if(isFetchingCartItemsList) {
            return (
                <main role="main" className="shopping-cart-main" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-cart.png')`}}>
                    <section id="content" className="container fullscreen-cart loading-wrapper">
                        <div className="row">
                            <div id="cart-items" className="col-xs-12">
                                <div className="loading-black">
                                    <i className="icon-loading"></i>
                                    <span>Loading Cart</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        if(cart && !isFetchingCartItemsList) {
            return (
                <main role="main" className="shopping-cart-main"
                      // style={{backgroundImage: "url('/assets/backgrounds/bg-cart.png')"}}
                >
                    <div id="content" className="container fullscreen-cart">
                        <div className="row">
                            <div id="cart-empty" className="col-lg-7" style={{display: cart.items_qty > 0 ? "none": ''}}>
                                <h1 className="h-one">Shopping Cart</h1>
                                <p className="p-two">
                                    Hello {customerInfo.firstname}! Your shopping cart is empty.
                                    <br/>You can add items to your cart from the tour roster.</p>
                                <Link to={"/artists"} className="button blue white large">Browse Tour Roster</Link>
                            </div>
                            <div id="cart-items" className="col-xs-12" style={{display: cart.items_qty == 0 ? "none": ''}}>
                                {itemsAdded && <div className="alert success fade-5s">
                                    <p><strong>{artistName}</strong> was added to your cart successfully.</p>
                                </div>}
                                {itemUpdated && <div className="alert success">
                                    <p><strong>{artistName}</strong> was updated successfully.</p>
                                </div>}
                                {itemDeleted && <div className="alert success">
                                    <p><strong>The item</strong> was deleted successfully.</p>
                                </div>}

                                <h1 className="h-one white">Shopping Cart</h1>
                                {cart.items_qty && <ShoppingCartListItems cart={cart} customerInfo={customerInfo}/>}
                            </div>
                            <div className="cart-options col-xs-12" style={{display: cart.items_qty == 0 ? "none": ''}}>
                                {cart.items_qty && <ShoppingCartTotals cartTotals={cart.totals}/>}
                                <div className="row">
                                    <div className="col-md-4 col-sm-4">
                                        <Link to="/artists" className="button large white-white">Continue Shopping</Link>
                                    </div>
                                    <div className="col-md-4 col-md-offset-4 col-sm-4 col-sm-offset-4">
                                        <Link to="/checkout" className="button large blue-white">Checkout</Link>
                                        <button onClick={this.cleanCart.bind(this)} className="button large white-white" style={{marginTop: 15}}>
                                            Clear cart
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )
        }
        else {
            return (
                <main role="main" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-cart.png')`, backgroundSize: "100% auto"}}>
                    <section id="content" className="container fullscreen-cart loading-wrapper">
                        <div className="row">
                            <div id="cart-items" className="col-xs-12">
                                <div className="loading-black">
                                    <i className="icon-loading"></i>
                                    <span>Loading Cart</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)
