import {request} from "../../axiosRequest";
import Axios from "axios";
export const SEARCH_TOURS_REQUEST = 'SEARCH_TOURS_REQUEST';
export const SEARCH_TOURS_SUCCESS = 'SEARCH_TOURS_SUCCESS';
export const SEARCH_TOURS_FAILURE = 'SEARCH_TOURS_FAILURE';

export const searchTourRequest = () => ({
    type: SEARCH_TOURS_REQUEST
});
export const searchTourSuccess = (data) => ({
    type: SEARCH_TOURS_SUCCESS,
    data: data
});
export const searchTourFailure = (error) => ({
    type: SEARCH_TOURS_FAILURE,
    error: error
});
var tourRequest = null;
export function getToursByCriteria(searchValue, callBack) {
    return (dispatch) => {
        if(tourRequest) {
            tourRequest.cancel();
        }
        tourRequest = request.CancelToken();
        dispatch(searchTourRequest());
        return request.get(`categories/partial/${searchValue}`, true, null, tourRequest.token)
            .then(response => {
                return response
            })
            .then(result => {
                let data = result.data;
                if (data.success && data.code == 200) {
                    dispatch(searchTourSuccess(data.data));
                    if (typeof callBack == "function") {
                        callBack(data.data);
                    }
                } else {
                    dispatch(searchTourFailure(data.data))
                }
            })
            .catch(error => {
                if(error instanceof Axios.Cancel) {
                }
                else {
                    dispatch(searchTourFailure(error))
                }
            })
    }
}
