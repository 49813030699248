import {
    GET_PRODUCTS_BY_CATEGORY_FAILURE,
    GET_PRODUCTS_BY_CATEGORY_SUCCESS,
    GET_PRODUCTS_BY_CATEGORY_REQUEST, PRODUCTS_TO_CART_SHOWS, PRODUCTS_TO_CART_LENGTHS, PRODUCTS_TO_CART_DETAILS,
    ORDER_PROCESS_TOTAL_PRICE, SET_CATEGORY_INFO, PRODUCTS_TO_CART_CUSTOM_OPTIONS, PRODUCTS_TO_CART_SHIPPING,
    CLEANUP_PRODUCTS_TO_CART, SET_ITEMS_TO_DELETE, SET_ADDITIONAL_CUT_PRODUCT, SET_FIRST_CUT_PRODUCT, SET_CUT_PRODUCTS,
    UPDATE_ORDER_PROCESS, UPDATE_ADDITIONAL_PKG_PRODUCTS
} from "./actions";
import {SET_SELECTED_PRODUCTS, PRODUCTS_TO_CART} from "../../components/OrderFormStep1ProductList/actions";

export default (state = {
    productsTypes: null,
    selectedProducts: {},
    cutProducts: null,
    categoryId: null,
    categoryInfo: null,
    isFetchingProductByCategory: false,
    error: null,
    productsToCart: null,
    productsToCartShows: null,
    productsToCartLengths: null,
    productsToCartSharedDetails: null,
    productsToCartCustomOptions: null,
    additionalPkgProducts: null,
    productsToCartShipping: null,
    orderTotalPrice: 0,
    itemsToDelete: [],
}, action) => {
    switch (action.type) {
        case UPDATE_ORDER_PROCESS:
            return {
                ...state,
                productsToCart: action.data.productsToCart,
                productsToCartLengths: action.data.productsToCartLengths,
                productsToCartShipping: action.data.productsToCartShipping,
                productsToCartShows: action.data.productsToCartShows,
                productsToCartCustomOptions: action.data.productsCustomOptions,
                selectedProducts: action.data.selectedProducts,
            }
        case SET_CUT_PRODUCTS:
            return {
                ...state,
                cutProducts: action.data,
            }
        case SET_FIRST_CUT_PRODUCT:
            return {
                ...state,
                firstCutProducts: action.data,
            }
        case SET_ADDITIONAL_CUT_PRODUCT:
            return {
                ...state,
                additionalCutProducts: action.data,
            }
        case GET_PRODUCTS_BY_CATEGORY_REQUEST:
            return {
                ...state,
                isFetchingProductByCategory: true,
            }
        case  GET_PRODUCTS_BY_CATEGORY_FAILURE:
            return {
                ...state,
                isFetchingProductByCategory: false,
                error: action.error
            }
        case SET_CATEGORY_INFO:
            return {
                ...state,
                categoryInfo: action.data,
                categoryId: action.data.category_id
            }
        case GET_PRODUCTS_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetchingProductByCategory: false,
                productsTypes: action.data.items,
                categoryId: action.data.categoryId,
                categoryInfo: action.data.categoryInfo
            }
        case SET_SELECTED_PRODUCTS:
            return {
                ...state,
                selectedProducts: action.data
            }
        case PRODUCTS_TO_CART:
            return {
                ...state,
                productsToCart: action.data
            };
        case PRODUCTS_TO_CART_SHOWS:
            return {
                ...state,
                productsToCartShows: action.data
            };
        case PRODUCTS_TO_CART_DETAILS:
            return {
                ...state,
                productsToCartSharedDetails: action.data
            };
        case PRODUCTS_TO_CART_LENGTHS:
            return {
                ...state,
                productsToCartLengths: action.data
            };
        case PRODUCTS_TO_CART_CUSTOM_OPTIONS:
            return {
                ...state,
                productsToCartCustomOptions: action.data
            };
        case ORDER_PROCESS_TOTAL_PRICE:
            return {
                ...state,
                orderTotalPrice: action.data
            };
        case PRODUCTS_TO_CART_SHIPPING:
            return {
                ...state,
                productsToCartShipping: action.data
            };
        case SET_ITEMS_TO_DELETE:
            return {
                ...state,
                itemsToDelete: action.data
            }
        case UPDATE_ADDITIONAL_PKG_PRODUCTS:
            let additionalPkgProducts = state.additionalPkgProducts;
            if(!additionalPkgProducts) {
                additionalPkgProducts = {};
            }
            additionalPkgProducts = {
                ...additionalPkgProducts,
                ...action.data
            }
            return {
                ...state,
                additionalPkgProducts: additionalPkgProducts
            }
        case CLEANUP_PRODUCTS_TO_CART:
            return {
                ...state,
                productsTypes: null,
                selectedProducts: {},
                categoryId: null,
                isFetching: false,
                error: null,
                productsToCart: null,
                productsToCartShows: null,
                productsToCartLengths: null,
                productsToCartSharedDetails: null,
                productsToCartCustomOptions: null,
                productsToCartShipping: null,
                itemsToDelete: [],
                additionalPkgProducts: null
            }
        default:
            return state;
    }
}
