import React from "react"
import {connect} from "react-redux"
import {getArtists} from "./actions";
import {Link} from "react-router-dom";
import {CDN_URL} from "../../actions/Constants";

const mapDispatchToProps = {
    getArtists,
};

const mapStateToProps = state => ({
    ...state.roster
});

class ArtistRoster extends React.Component{

    constructor(props) {
        super(props);
        this.letters = '#ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        this.state = {
            artistsByLetter: {},
            selectedLetter: '#',
            ref: {},
            topRef: React.createRef()
        }
        this.setSelectedLetter = this.setSelectedLetter.bind(this);
        this.goTop = this.goTop.bind(this);
    }

    componentDidMount() {
        if(!this.props.artistRoster) {
            this.props.getArtists()
        }
        else {
            this.fillArtistByLetter()
        }
    }
    fillArtistByLetter() {
        let tmp = {};
        let ref = {};
        let artistRoster = this.props.artistRoster;
        for(let letterIndex in this.letters) {
            let letter = this.letters[letterIndex];
            tmp[letter] = [];
            const refTemp = React.createRef();
            ref[letter] = refTemp;
        }

        for (let aIndex in artistRoster) {
            let currentLetter = artistRoster[aIndex].name.charAt(0);
            if(!/^[a-zA-Z]/.test(currentLetter)) {
                currentLetter = "#";
            }
            currentLetter = currentLetter.toUpperCase();
            tmp[currentLetter].push(artistRoster[aIndex]);
        }

        this.setState({
            artistsByLetter: tmp,
            ref: ref
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.artistRoster != this.props.artistRoster) {
            this.fillArtistByLetter();
        }
    }
    setSelectedLetter(event) {
        let letter = event.target.getAttribute('letter');
        let ref = this.state.ref[letter];
        if(ref && ref.current){
            let position = ref.current.getBoundingClientRect().top;
            window.scrollTo({top: position - 100, behavior: "smooth", block: 'start'})
            this.setState({
                selectedLetter: letter
            })
        }
    }
    goTop() {
        let topRef = this.state.topRef;
        if(topRef) {
            let position = topRef.current.getBoundingClientRect().top;
            window.scrollTo({top: position - 100, behavior: "smooth", block: 'start'})
        }
    }
    render(){
        let {isFetchingArtistRoster, artistRosterRequestError} = this.props;
        let {artistsByLetter, selectedLetter, ref, topRef} = this.state;
        if(isFetchingArtistRoster)
        {
            return (
                <main id="main" role="main">
                    <section id="content" className="container content-wrapper loading-wrapper">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="loading-white">
                                    <i className="icon-loading"></i>
                                    <span>Loading Tour Roster</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        if(artistRosterRequestError) {
            return (
                <main id="main" role="main">
                    <section id="content" className="checkout-wrapper container">
                        <div className="row section-header">
                            <div className="col-xs-12">
                                <ul className="breadcrumb" ref={topRef}>
                                    <li>
                                        <Link to="#" onClick={this.props.history.goBack}>
                                            Account Dashboard
                                        </Link>
                                    </li>
                                </ul>
                                <h1 className="h-two blue">Edit Account Information</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="alert error">
                                    <p>{artistRosterRequestError}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        return (
            <main id="main" role="main">
                <section id="content" className="container content-wrapper">
                    <div className="row section-header">
                        <div className="col-xs-12">
                            <ul className="breadcrumb" ref={topRef}>
                                <li>
                                    Tour Roster
                                </li>
                            </ul>
                            <h1 className="h-two blue">Tour Roster</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <ol id="tour-roster-alphabet">
                                {Object.keys(artistsByLetter).map((letter, index) => {
                                    let classes = []
                                    if(artistsByLetter[letter].length == 0) {
                                        classes.push('disabled');
                                    }
                                    if(selectedLetter == letter && artistsByLetter[letter].length != 0) {
                                        classes.push('active')
                                    }
                                    return <li key={index}
                                        className={classes.join(' ')}
                                    >
                                        {artistsByLetter[letter].length > 0 && <Link to={`#${letter}`}
                                            letter={letter}
                                              onClick={this.setSelectedLetter}
                                        >
                                            {letter}
                                        </Link>}
                                        {artistsByLetter[letter].length == 0 &&
                                            <span to={`#${letter}`}
                                                  letter={letter}
                                            >
                                                {letter}
                                            </span>
                                        }
                                    </li>
                                })}

                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <ol id="tour-roster-list">
                                {Object.keys(artistsByLetter).map((letter) => {
                                    let artistArray = artistsByLetter[letter];
                                    if(artistArray.length > 0) {
                                        return (
                                            <li key={letter} name={letter} ref={ref[letter]}>
                                                <dl>
                                                    <dt className="h-five mb-30">{letter}</dt>
                                                    <dd>
                                                        <ol>
                                                            {artistArray.map((artist) => {
                                                                return <li key={artist.id}>
                                                                    <Link to={`/artists/${artist.id}`}>
                                                                        {artist.name}
                                                                    </Link>
                                                                </li>
                                                            })}
                                                        </ol>
                                                    </dd>
                                                </dl>
                                            </li>
                                        )
                                    }
                                })}
                            </ol>
                        </div>
                    </div>
                    <div id="tour-roster-top">
                        <Link to='#root' onClick={this.goTop}>
                            <img src={`${CDN_URL}assets/icon-page-top.svg`} alt="Select Date"/>
                        </Link>
                    </div>
                </section>
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistRoster)
