import React from "react"
import {connect} from "react-redux"
import Slider from "react-slick";
import Item from "./Item";

class OrderProcessTourModal extends React.Component{
    constructor(props) {
        super(props);
        this.slickSettings = {
            dots: true,
            infinite: true,
            speed: 500,
            swipe: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        this.state = {
            stopAllMedia: false
        }
        this.stopAllMedia = this.stopAllMedia.bind(this);
    }

    componentDidMount() {
        // document.addEventListener('click', this.props.closeDemosModal);
    }
    componentWillUnmount() {
        // document.removeEventListener('click', this.props.closeDemosModal);
    }

    stopAllMedia() {
        console.log('stopping')
        this.setState({
            stopAllMedia: true
        }, () => {
            this.setState({
                stopAllMedia: false
            });
        })
    }
    render(){
        let data = this.props.media;
        let {stopAllMedia} = this.state;
        return (
            <div id="modal-tour" className="modal-wrapper">
                <div id="modal-slider-wrapper" className="row">
                    <div id="modal-slider">
                        <Slider {...this.slickSettings} beforeChange={this.stopAllMedia}>
                            { data.map((value, index) => {
                                    return <div id={`item_` + index}>
                                <Item
                                        key={index}
                                        data={value}
                                        stopAllMedia={stopAllMedia}
                                    />
                                    </div>
                                }
                            )}
                        </Slider>
                    </div>
                </div>
                <span id="close-modal-account" className="close-modal" onClick={this.props.closeDemosModal}>Close Account Window</span>
            </div>
        )
    }
}

export default connect(null, null)(OrderProcessTourModal)
