export const field_codes = {
    delivery_options: {
        code: 'delivery_options',
        sku: {
            extreme_reach: 'extreme_reach',
            beta_dvd: 'beta_dvd',
            digital_file: 'digital_file'
        },
    },
    extreme_reach: {
        code: 'extreme_reach',
        sku: {
            over_night: 'over_night',
            one_hour: 'one_hour'
        }
    },
    call_letters_sys_codes: 'call_letters_sys_codes',
    beta_dvd: {
        code: 'beta_dvd',
        sku: {
            beta_sp: 'beta_sp',
            pal_beta: 'pal_beta',
            digital_beta: 'digital_beta',
            pal_digital_beta: 'pal_digital_beta',
            dvd: 'dvd',
            pal_dvd: 'pal_dvd'
        }
    },
    digital_file: {
        code: 'digital_file',
        sku: {
            broadcast_tv: 'broadcast_tv',
            non_broadcast_tv: 'non_broadcast_tv'
        }
    },
    delivery_method: {
        code: 'delivery_method',
        sku: {
            ftp_upload: 'ftp_upload',
            td_express_email_delivery: 'td_express_email_delivery'
        }
    },
    shipping_address: 'shipping_address',
    email_address: 'email_address',
    digital_file_specs: 'digital_file_specs',
    digital_file_ftp_info: 'digital_file_ftp_info',
    td_express_delivery: 'td_express_delivery',


};

export const shipping_fields = {
    [field_codes.delivery_options.code]: '',
    [field_codes.extreme_reach.code]: '',
    [field_codes.call_letters_sys_codes]: '',
    [field_codes.beta_dvd.code]: '',
    [field_codes.digital_file.code]: '',
    [field_codes.delivery_method.code]: '',
    [field_codes.shipping_address]: '',
    [field_codes.email_address]: '',
    [field_codes.digital_file_specs]: '',
    [field_codes.digital_file_ftp_info]: '',
    [field_codes.td_express_delivery]: ''

}
;
