import {request} from "../../axiosRequest";

export const SHIPPING_ORDER_REQUEST = 'SHIPPING_ORDER_REQUEST';
export const SHIPPING_ORDER_SUCCESS = 'SHIPPING_ORDER_SUCCESS';
export const SHIPPING_ORDER_FAILURE = 'SHIPPING_ORDER_FAILURE';

export const shippingOrderRequest = () =>({
    type: SHIPPING_ORDER_REQUEST
});
export const shippingOrderSuccess = (data) =>({
    type: SHIPPING_ORDER_SUCCESS,
    data: data
});
export const shippingOrderFailure = (error) =>({
    type: SHIPPING_ORDER_FAILURE,
    error: error
});


export const getShippingOrderAttempt = (orderId) => {
    return (dispatch) => {
        dispatch(shippingOrderRequest());
        return request.get(`orders/${orderId}`,true)
            .then(response => {return response})
            .then(result => {
                let data = result.data
                if(data.success && data.code == 200){
                    dispatch(shippingOrderSuccess(data.data));
                    console.log(data.data)
                }
                else{
                    dispatch(shippingOrderFailure(data.data))
                }
                console.log(result);
            })
            .catch(error => {
                console.log(error);
                dispatch(shippingOrderFailure(error));
            })
    }
}
