import React from "react"
import {connect} from "react-redux"
import {removeItemFromCart} from "./actions";
import {withRouter} from 'react-router-dom';
import {CATEGORY_IMAGES_SERVICES, CDN_URL, FIELDS_CODE} from "../../actions/Constants";
import ShoppingCartItemShipping from "../ShoppingCartItemShipping"
import {getAdmatInfo, numberFormat} from "../../helpers";
import Confirm from "react-confirm-bootstrap"

const mapDispatchToProps = {
    removeItemFromCart,
}

class ShoppingCartItem extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            admatInfo: undefined
        }
        this.removeItemFromCart = this.removeItemFromCart.bind(this);
        this.editItem = this.editItem.bind(this);
        this.getCategoryInfo = this.getCategoryInfo.bind(this);
        this.getTotalPrice = this.getTotalPrice.bind(this);
        this.getAdmatInfo = this.getAdmatInfo.bind(this);
    }
    componentDidMount() {
        this.setState({
            admatInfo: this.getAdmatInfo()
        })
    }

    removeItemFromCart()
    {
        let item_id = this.props.product.item_id;
        let customerId = this.props.customerId;
        let cartId = this.props.cartId;
        this.props.removeItemFromCart(item_id, customerId, cartId);
    }
    editItem()
    {
        let item_id = this.props.product.item_id;
        if (item_id) {
            this.props.history.push({
                pathname: `/cart/edit/item/`+item_id,
                state: {
                    product: this.props.product,
                    cartItems: this.props.cartItems
                }
            })
        }
    }

    getInfoToShow(){
        let {product} = this.props;
        let info = {
            show_time: '',
            show_date: '',
            venue_name: '',
            city_market_name: '',
            special_guests: '',
            logo_file: []
        };
        let showInformation = product.extension_attributes.show_information;
        let additionalShowVenue = [];
        if(showInformation.length > 0)
        {
            additionalShowVenue = showInformation.slice(1, showInformation.length);
            console.log('additionalShowVenue', additionalShowVenue)
            return showInformation[0];
        }
        else {
            return info;
        }
    }

    getCategoryInfo() {
        let {product} = this.props;
        let categoryInfo = {};
        if(product.product_option) {
            let customOptions = product.product_option.extension_attributes.custom_options;
            if(customOptions) {
                customOptions.map((option) => {
                    if(option.extension_attributes) {
                        let optionCode = option.extension_attributes.option_code;
                        if(optionCode == FIELDS_CODE.category_id && option.extension_attributes.category_info) {
                            console.log('getCategoryInfo', option.extension_attributes.category_info);
                            categoryInfo = option.extension_attributes.category_info;
                            if(categoryInfo) {
                                categoryInfo = JSON.parse(categoryInfo);
                            }
                        }
                    }
                })
            }
        }
        console.log('getCategoryInfo', categoryInfo)
        return categoryInfo;
    }

    getTotalPrice() {
        let {product, cartItems} = this.props;
        let totalItems = this.props.totals.items;
        let totalPrice = 0;

        let productType = undefined;
        if(product && product.extension_attributes && product.extension_attributes.original_product
            && product.extension_attributes.original_product.extension_attributes
            && product.extension_attributes.original_product.extension_attributes.product_type
        )
        {
            productType = product.extension_attributes.original_product.extension_attributes.product_type.id;
        }
        if(productType === 'shipping' || productType === 'revision_product') {
            return product.price;
        }
        for(let cartItemIndex in cartItems) {
            let cartItem = cartItems[cartItemIndex];
            if(cartItem.extension_attributes &&
                cartItem.extension_attributes.group_item == product.extension_attributes.group_item
            ) {

                for(let totalItemIndex in totalItems) {
                    let totalItem = totalItems[totalItemIndex];
                    if(totalItem.item_id == cartItem.item_id) {
                        totalPrice += totalItem.row_total
                    }
                }

            }
        }
        return totalPrice;
    }
    getAdmatInfo()
    {
        let product = this.props.product;
        return getAdmatInfo(product);
    }
    addDefaultSrc(ev){
        ev.target.src = `${CDN_URL}assets/default-thumbnail.png`;
    }
    render(){
        let {product, totals, shippingRelated, cutProductsRelated} = this.props;
        let admatInfo = this.state.admatInfo;
        let categoryInfo = this.getCategoryInfo();
        let totalPrice = this.getTotalPrice();

        if(product) {
            let info = this.getInfoToShow();
            return (
                <li>
                    <div className="cart-item row">
                        <div className="cart-item-thumb col-md-2 col-sm-3">
                            {categoryInfo.category_image != "" ? (
                                <img src={categoryInfo.category_image && CATEGORY_IMAGES_SERVICES + categoryInfo.category_image} onError={this.addDefaultSrc} alt=""/>
                            ) : (
                                <img src={`${CDN_URL}assets/default-thumbnail.png`} alt=""/>
                            )}
                            <h2>{categoryInfo.category_artist_name}</h2>
                            <p>{categoryInfo.category_name}</p>
                        </div>
                        <div className="cart-item-details col-md-7 col-sm-6">
                            <h2>{this.props.product.name} {product.qty > 1 && `x ${product.qty}` }</h2>
                            <ul className="cart-item-specs">
                                {info.show_date && <li>
                                    <dl>
                                        <dt>Show Date</dt>
                                        <dd>{info.show_date}</dd>
                                    </dl>
                                </li>}
                                {info.show_time && <li>
                                    <dl>
                                        <dt>Show Time(s)</dt>
                                        <dd>{info.show_time}</dd>
                                    </dl>
                                </li>}
                                {info.venue_name && <li>
                                    <dl>
                                        <dt>Venue Name</dt>
                                        <dd>{info.venue_name}</dd>
                                    </dl>
                                </li>}
                                {info.city_market_name && <li>
                                    <dl>
                                        <dt>City / Market</dt>
                                        <dd>{info.city_market_name}</dd>
                                    </dl>
                                </li>}
                                {info.special_guests && <li>
                                    <dl>
                                        <dt>Special Guest / Instructions</dt>
                                        <dd>{info.special_guests}</dd>
                                    </dl>
                                </li>}
                                {info.special_instructions && info.special_instructions != ""  && <li>
                                    <dl>
                                        <dt>Special Guest / Instructions</dt>
                                        <dd>{info.special_instructions}</dd>
                                    </dl>
                                </li>}
                                <li>
                                    <dl>
                                    {
                                        admatInfo && admatInfo.length > 0 &&
                                        <dt>Admat</dt>
                                    }
                                    {admatInfo && admatInfo.length > 0 &&
                                    admatInfo.map((value, index) => {
                                            return <dd className="filename" key={index}>{value.admat_file}</dd>
                                        }
                                    )}
                                    </dl>
                                </li>
                                <li>
                                    {shippingRelated &&
                                        shippingRelated.map((shippingProduct) =>
                                            <shipping-info key={shippingProduct.item_id}>
                                                <dl>
                                                    {product.extension_attributes.product_type != 'shipping' && <dt>{shippingProduct.name} {shippingProduct.qty > 1 && `x ${shippingProduct.qty}` }</dt>}
                                                    {shippingProduct && <ShoppingCartItemShipping
                                                        cartItem={shippingProduct}
                                                    />}
                                                </dl>
                                            </shipping-info>
                                        )
                                    }
                                </li>
                                {cutProductsRelated && <li>
                                    {
                                        cutProductsRelated.map((cutProduct) =>
                                            <shipping-info key={cutProduct.item_id}>
                                                <dl>
                                                    {product.extension_attributes.product_type != 'shipping' && <dt>{cutProduct.name} {cutProduct.qty > 1 && `x ${cutProduct.qty}`}</dt>}
                                                    {cutProduct && <ShoppingCartItemShipping
                                                        cartItem={cutProduct}
                                                    />}
                                                </dl>
                                            </shipping-info>
                                        )
                                    }
                                </li>}

                                {product && product.extension_attributes.product_type == 'revision_product' &&
                                <li>
                                    <shipping-info key={product.item_id}>
                                        <dl>
                                            <ShoppingCartItemShipping
                                                cartItem={product}
                                            />
                                        </dl>
                                    </shipping-info>

                                </li> }
                            </ul>
                        </div>
                        <div className="cart-item-options col-md-3 col-sm-3">
                            <span className="price">{numberFormat(totalPrice)}</span>
                            <ul>
                                <li><button onClick={this.editItem} className="button xxsmall white">Edit</button></li>
                                <li>
                                    <Confirm
                                        onConfirm={this.removeItemFromCart}
                                        body="Are you sure you want to remove this item from your cart? This action can't be undone."
                                        confirmText="Confirm"
                                        title="Remove Item From Cart">
                                        <button className="button xxsmall white" onClick={this.removeItemFromCart}>
                                            Remove
                                        </button>
                                    </Confirm>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            )
        }
        else {
            return (
                <div>

                </div>
            )
        }
    }
}

export default withRouter(connect(null, mapDispatchToProps)(ShoppingCartItem))
