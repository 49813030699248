export const PRODUCT = {
    sku: "Digital",
}
export const custom_options = {
    on_sale_date_time_text: 'on_sale_date_time_text',
    ordered_by: 'ordered_by',
    due_date: 'due_date',
    include_citi: {
        code: 'include_citi',
        sku: {
            'include_citi_yes': 'yes_citi',
            'include_citi_no': 'no_citi'
        }
    },
    ln_banners_instructions: 'ln_banners_instructions',
    ln_banners: {
        code: 'ln_banners',
        sku: {
            ln_banners_dco:'ln_banners_dco',
            ln_banners_non_dco:'ln_banners_non_dco',
            ln_banners_revision:'ln_banners_revision',
            ln_banners_additional_units:'ln_banners_additional_units',
        }
    },
    social_banners_instructions: 'social_banners_instructions',
    social_banners: {
        code: 'social_banners',
        sku: {
            social_banners_dco:'social_banners_dco',
            social_banners_non_dco:'social_banners_non_dco',
            social_banners_revision:'social_banners_revision',
            social_banners_additional_units:'social_banners_additional_units',
            tm_banner:'tm_banner',
        }
    },
    national_banners_instructions: 'national_banners_instructions',
    national_banners: {
        code: 'national_banners',
        sku: {
            dco_presale_onsale: 'national_dco_presale_onsale',
            non_dco_presale_onsale: 'national_non_dco_presale_onsale',
            revision: 'national_revision',
            additional_units: 'national_additional_units'
        }
    },
    local_banners_instructions: 'local_banners_instructions',
    local_banners: {
        code: 'local_banners',
        sku: {
            dco_presale_onsale: 'local_dco_presale_onsale',
            non_dco_presale_onsale: 'local_non_dco_presale_onsale',
            revision: 'local_revision',
            additional_units: 'local_additional_units'
        }
    },
    email_banner_instructions: 'email_banner_instructions',
    email_banner: {
        code: 'email_banner',
        sku: {
            set: 'email_set',
            revision: 'email_revision'
        }
    },
    single_album_banners_instructions: 'single_album_banners_instructions',
    single_album_banners: {
        code: 'single_album_banners',
        sku: {
            less_than_ten: 'less_than_ten',
            more_than_ten: 'more_than_ten',
        }
    },
    ecard_instructions: 'ecard_instructions',
    ecard: {
        code: 'ecard',
        sku: {
            unit_with_tour_dates: 'unit_with_tour_dates'
        }
    },
    webskins_instructions: 'webskins_instructions',
    webskins: {
        code: 'webskins',
        sku: {
            pushdowns: 'webskin_pushdowns',
            additional_resizes: 'webskin_additional_resizes',
            revision: 'webskin_revision'
        }
    },
    billboards_instructions: 'billboard_instructions',
    billboards: {
        code: 'billboards',
        sku: {
            static_art_creative: 'billboard_static_art_creative',
            w_video: 'billboard_w_video'
        }
    },
    poster_art_boards_instructions: 'poster_art_board_instructions',
    poster_art_boards: {
        code: 'poster_art_boards',
        sku: {
            creative: 'poster_art_creative'
        }
    },
    snapchat_instructions: 'snapchat_instructions',
    snapchat_static: {
        code: 'snapchat_static',
        sku: {
            geo_filter: 'snapchat_geo_filter',
            geo_filter_revision: 'snapchat_geo_filter_revision'
        }
    },
    instagram_video_instructions: 'instagram_video_instructions',
    instagram_video: {
        code: 'instagram_video',
        sku: {
            story_ad: 'instagram_story_ad',
            video: 'instagram_video_option',
            story_ad_edit: 'instagram_story_ad_edit',
            video_edit: 'instagram_video_edit'
        }
    },
    snapchat_video_instructions: 'snapchat_video_instructions',
    snapchat_video: {
        code: 'snapchat_video',
        sku: {
            story_ad: 'snapchat_video_story_ad',
            story_ad_edit: 'snapchat_video_story_ad_edit',
        }
    },
    video_instructions: 'video_instructions',
    video: {
        code: 'video',
        sku: {
            presale_onsale_1_1: 'video_presale_onsale_1_1',
            second_cut_1_1: 'video_second_cut_1_1',
            presale_onsale_16_9: 'video_presale_onsale_16_9',
            second_cut_16_9: 'video_second_cut_16_9',
            one_off_video: 'video_one_off_video'
        }
    },
    video_liners_instructions: 'video_liners_instructions',
    video_liners: {
        code: 'video_liners',
        sku: {
            tour_liners: 'video_tour_liners',
            no_footage: 'video_no_footage',
            with_footage: 'video_with_footage'
        }
    },
    ppt_instructions: 'ppt_instructions',
    ppt: {
        code: 'ppt',
        sku: {
            ppt_decks: 'ppt_decks',
            ppt_updates: 'ppt_updates'
        }
    },
    radio_instructions: 'radio_instructions',
    national_radio: {
        code: 'national_radio',
        sku: {
            national_spots: 'national_spots'
        }
    },
    today_day: 'today_day',
    include_onsale_date: 'include_onsale_date',
    include_ticket_outlets: 'include_ticket_outlets',
    additional_details: 'additional_details',
    upload_build_sheet: 'upload_build_sheet',
    upload_routing: 'upload_routing',
    upload_template: 'upload_template'
}


export const fieldCopy = {
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.due_date]: '',
    [custom_options.ordered_by]: '',
    [custom_options.include_citi.code]: null,
    [custom_options.ln_banners_instructions]: '',
    [custom_options.ln_banners.code]: null,
    [custom_options.social_banners_instructions]: '',
    [custom_options.social_banners.code]: null,
    [custom_options.national_banners_instructions]:'',
    [custom_options.national_banners.code]: null,
    [custom_options.local_banners_instructions]: '',
    [custom_options.local_banners.code]: null,
    [custom_options.email_banner_instructions]: '',
    [custom_options.email_banner.code]: null,
    [custom_options.single_album_banners_instructions]: '',
    [custom_options.single_album_banners.code]: null,
    [custom_options.ecard_instructions]: '',
    [custom_options.ecard.code]: null,
    [custom_options.webskins_instructions]: '',
    [custom_options.webskins.code]: null,
    [custom_options.billboards_instructions]: '',
    [custom_options.billboards.code]: null,
    [custom_options.poster_art_boards_instructions]: '',
    [custom_options.poster_art_boards.code]: null,
    [custom_options.snapchat_instructions]: '',
    [custom_options.snapchat_static.code]: null,
    [custom_options.instagram_video_instructions]: '',
    [custom_options.instagram_video.code]: null,
    [custom_options.snapchat_video_instructions]: '',
    [custom_options.snapchat_video.code]: null,
    [custom_options.video_instructions]: '',
    [custom_options.video.code]: null,
    [custom_options.video_liners_instructions]: '',
    [custom_options.video_liners.code]: null,
    [custom_options.ppt_instructions]: '',
    [custom_options.ppt.code]: null,
    [custom_options.radio_instructions]: '',
    [custom_options.national_radio.code]: null,
    [custom_options.today_day]: '',
    [custom_options.include_onsale_date]: '',
    [custom_options.include_ticket_outlets]: '',
    [custom_options.additional_details]: '',
    [custom_options.upload_build_sheet]: '',
    [custom_options.upload_routing]: '',
    [custom_options.upload_template]: '',

    //Quantity
    [custom_options.ln_banners.sku.ln_banners_dco + "_qty"]: 0,
    [custom_options.ln_banners.sku.ln_banners_non_dco + "_qty"]: 0,
    [custom_options.ln_banners.sku.ln_banners_revision + "_qty"]: 0,
    [custom_options.ln_banners.sku.ln_banners_additional_units + "_qty"]: 0,

    [custom_options.social_banners.sku.social_banners_dco + "_qty"]: 0,
    [custom_options.social_banners.sku.social_banners_non_dco + "_qty"]: 0,
    [custom_options.social_banners.sku.social_banners_revision + "_qty"]: 0,
    [custom_options.social_banners.sku.social_banners_additional_units + "_qty"]: 0,
    [custom_options.social_banners.sku.tm_banner + "_qty"]: 0,

    [custom_options.national_banners.sku.dco_presale_onsale + "_qty"]: 0,
    [custom_options.national_banners.sku.non_dco_presale_onsale + "_qty"]: 0,
    [custom_options.national_banners.sku.revision + "_qty"]: 0,
    [custom_options.national_banners.sku.additional_units + "_qty"]: 0,

    [custom_options.local_banners.sku.dco_presale_onsale + "_qty"]: 0,
    [custom_options.local_banners.sku.non_dco_presale_onsale + "_qty"]: 0,
    [custom_options.local_banners.sku.revision + "_qty"]: 0,
    [custom_options.local_banners.sku.additional_units + "_qty"]: 0,

    [custom_options.email_banner.sku.set + "_qty"]: 0,
    [custom_options.email_banner.sku.revision + "_qty"]: 0,

    [custom_options.single_album_banners.sku.less_than_ten + "_qty"]: 0,
    [custom_options.single_album_banners.sku.more_than_ten + "_qty"]: 0,

    [custom_options.ecard.sku.unit_with_tour_dates + "_qty"]: 0,

    [custom_options.webskins.sku.pushdowns + "_qty"]: 0,
    [custom_options.webskins.sku.additional_resizes + "_qty"]: 0,
    [custom_options.webskins.sku.revision + "_qty"]: 0,

    [custom_options.billboards.sku.static_art_creative + "_qty"]: 0,
    [custom_options.billboards.sku.w_video + "_qty"]: 0,

    [custom_options.poster_art_boards.sku.creative + "_qty"]: 0,

    [custom_options.snapchat_static.sku.geo_filter + "_qty"]: 0,
    [custom_options.snapchat_static.sku.geo_filter_revision + "_qty"]: 0,

    [custom_options.snapchat_video.sku.story_ad + "_qty"]: 0,
    [custom_options.snapchat_video.sku.story_ad_edit + "_qty"]: 0,

    [custom_options.instagram_video.sku.story_ad + "_qty"]: 0,
    [custom_options.instagram_video.sku.video + "_qty"]: 0,
    [custom_options.instagram_video.sku.story_ad_edit + "_qty"]: 0,
    [custom_options.instagram_video.sku.video_edit + "_qty"]: 0,

    [custom_options.video.sku.presale_onsale_1_1 + "_qty"]: 0,
    [custom_options.video.sku.second_cut_1_1 + "_qty"]: 0,
    [custom_options.video.sku.presale_onsale_16_9 + "_qty"]: 0,
    [custom_options.video.sku.second_cut_16_9 + "_qty"]: 0,
    [custom_options.video.sku.one_off_video + "_qty"]: 0,


    [custom_options.video_liners.sku.tour_liners + "_qty"]: 0,
    [custom_options.video_liners.sku.no_footage + "_qty"]: 0,
    [custom_options.video_liners.sku.with_footage + "_qty"]: 0,

    [custom_options.ppt.sku.ppt_decks + "_qty"]: 0,
    [custom_options.ppt.sku.ppt_updates + "_qty"]: 0,

    [custom_options.national_radio.sku.national_spots + "_qty"]: 0,

};

export const fields = JSON.parse(JSON.stringify(fieldCopy));
