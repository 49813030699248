import {
    CATEGORIES_FAILURE,
    CATEGORIES_SUCCESS,
    CATEGORIES_REQUEST
} from "./actions";

export default (state = {
    categories: null,
    isFetchingTours: false
}, action) => {
    switch (action.type) {
        case CATEGORIES_REQUEST:
            return {
                ...state,
                isFetchingTours: true,
            }
        case  CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetchingTours: false,
                categories: action.data
            }
        case  CATEGORIES_FAILURE:
            return {
                ...state,
                isFetchingTours: false,
                error: action.error
            }
        default:
            return state;
    }
}