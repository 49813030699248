import React from "react";
import { Route, Redirect } from "react-router-dom";
import {connect} from "react-redux";


const mapStateToProps = state =>({
    ...state.auth
});
const ProtectedRoute = ({
       component: Component,
       ...rest
   }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (rest.isAuthenticated) {
                    return <Component {...props} />;
                } else {
                    return (

                        <Redirect
                            to={{
                                pathname: `/account/login`,
                                state: {
                                    from: props.location
                                }

                            }}
                            push
                        />
                    );
                }
            }}
        />
    );
};

export default connect(mapStateToProps, null)(ProtectedRoute)