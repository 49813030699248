import React from 'react'
import {RecentOrder} from "../RecentOrder/index";
import {Link} from "react-router-dom";

export class RecentOrdersList extends React.Component{
    constructor(props){
        super(props)
    }

    render() {
        if(this.props.orders){
            return (
                <ul id="list-recent-orders" className="row">
                    {
                        this.props.orders.map((item) =>
                            <RecentOrder item={item} key={item.order}/>
                        )
                    }
                </ul>
            )}
        else
        {
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="table-no-count">
                            <i aria-hidden="true">
                                <img src="assets/icon-no-count.svg" alt="No Items Found"/>
                            </i>
                            <h2>No Orders Found</h2>
                            <p className="p-three">You haven't placed any orders. Please get started by search the <Link to="/artists" className="blue">tour roster</Link>.</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

}
