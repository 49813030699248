import React from "react"
import {connect} from "react-redux"
import Loadable from 'react-loadable';
import {setProductsCustomOptions} from "../../containers/OrderFormStep1/actions";
import {CDN_URL} from "../../actions/Constants";

const mapDispatchToProps = {
    setProductsCustomOptions,
}

class Product extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ProductModule : null,
            customOptions: null,
            requiredFields: null,
        }
        this.updateCustomOptions = this.updateCustomOptions.bind(this);
        this.loading = this.loading.bind(this);
    }

    componentDidMount() {
        let {product} = this.props;
        if(product) {
            let name = product.sku.replace(/ /g, "_");
            name = name.toUpperCase();
            console.log(name);
            let module = Loadable({
                loader: () => import('./'+ name),
                loading: this.loading
                });
            console.log('module loadable', module)
            this.setState({
                ProductModule: module,
            });
        }
    }
    loading(props) {
        console.log('Loadin....', props)
        let {categoryInfo} = this.props;
        if(props.error) {
            return  <div className="row">
                <div className="col-xs-12">
                    <div className="table-no-count">
                        <i aria-hidden="true">
                            <img src={`${CDN_URL}assets/icon-no-count.svg`} alt="No Items Found"/>
                        </i>
                        <h2>Product Not Found</h2>
                        <p className="p-three">We're sorry. This product can't be found. Please
                            <a href={`/cart/selectProducts/${categoryInfo.category_id}` }> select a different product</a> or browse the <a href="/artists">tour roster</a>.</p>
                    </div>
                </div>
            </div>
        }
        else {
            return <div className="loading-black">
                <i className="icon-loading"></i>
                <span>Loading</span>
            </div>
        }
    }
    updateCustomOptions(customOptions) {
        this.setState({
            customOptions: customOptions
        })
        let globalCustomOptions = {...this.props.productCustomOptions};
        globalCustomOptions[this.props.product.sku] = customOptions;
        console.log("globalCustomOptions", globalCustomOptions)
        this.props.setProductsCustomOptions(globalCustomOptions);
    }
    render(){
        let {ProductModule} = this.state;
        let {optionsDescription, optionsRequired} = this.props;
        let {productCustomOptions, product, customErrors, categoryInfo} = this.props;
        let name = product.name.replace(/ /g, "_");
        if(ProductModule){
            return (
                <div>
                    <div className="form-section repeat">
                        <ProductModule
                            {...this.props}
                            updateCustomOptions={this.updateCustomOptions}
                            productCustomOptions={ productCustomOptions}
                            product={product}
                            customErrors={customErrors}
                            productName={name}
                            categoryInfo={categoryInfo}
                            optionsDescription={optionsDescription}
                            optionsRequired={optionsRequired}
                        />
                    </div>
                    <div className="form-section repeat repeat-control">
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="table-no-count">
                            <i aria-hidden="true">
                                <img src={`${CDN_URL}assets/icon-no-count.svg`} alt="No Items Found"/>
                            </i>
                            <h2>Product Not Found</h2>
                            <p className="p-three">We're sorry. This product can't be found. Please
                                <a href={`/cart/selectProducts/${categoryInfo.category_id}` }>select a different product</a> or browse the <a href="/artists">tour roster</a>.</p>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default connect(null, mapDispatchToProps)(Product)

