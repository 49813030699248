import {SHOPPING_CART_TOTALS_FAILURE, SHOPPING_CART_TOTALS_REQUEST, SHOPPING_CART_TOTALS_SUCCESS} from "./actions";

export default (state = {
    cartTotals: null,
    isFetching: false
}, action) => {
    switch (action.type) {
        case SHOPPING_CART_TOTALS_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case SHOPPING_CART_TOTALS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case SHOPPING_CART_TOTALS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                cartTotals: action.data
            }
        default:
            return state;
    }
}