export const PRODUCT = {
    sku: "INTERNATIONAL TV",
}
export const custom_options = {
    due_date: 'due_date',
    on_sale_date_time_text: 'on_sale_date_time_text',
    send_tv_to: 'send_tv_to',
    format: {
        code: "format",
        sku: {
            ntsc: 'ntsc',
            pal: 'pal',
        }
    },
    file_specifications: 'file_specifications',
}

export const fields = {
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.due_date]: '',
    [custom_options.format.code]: custom_options.format.sku.ntsc,
    [custom_options.send_tv_to]: '',
    [custom_options.file_specifications]: '',
}
