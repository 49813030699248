import {
    CATEGORY_MEDIA_FAILURE,
    CATEGORY_MEDIA_SUCCESS,
    CATEGORY_MEDIA_REQUEST, CATEGORY_NOT_FOUND_ERROR
} from "./actions";

export default (state = {
    categoryMediaInfo: null,
    isFetchingCategoryMedia: false,
    fetchCategoryMediaError: null
}, action) => {
    switch (action.type) {
        case CATEGORY_MEDIA_REQUEST:
            return {
                ...state,
                isFetchingCategoryMedia: true,
            }
        case  CATEGORY_MEDIA_FAILURE:
            return {
                ...state,
                isFetchingCategoryMedia: false,
                fetchCategoryMediaError: action.error
            }
        case  CATEGORY_NOT_FOUND_ERROR:
            return {
                ...state,
                isFetchingCategoryMedia: false,
                fetchCategoryMediaError: 404
            }
        case CATEGORY_MEDIA_SUCCESS:
            return {
                ...state,
                isFetchingCategoryMedia: false,
                categoryMediaInfo: action.data,
                fetchCategoryMediaError: null
            }
        default:
            return state;
    }
}
