import React from "react"
import {connect} from "react-redux"
import {shoppingCartTotalsAttempt} from "./actions";
import {numberFormat} from "../../helpers";


class ShoppingCartTotals extends React.Component{

    render(){
        let {cartTotals} = this.props
        console.log(this.props)
        if(cartTotals){
            return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="cart-total">
                        <table width="100%">
                            <tbody>
                            <tr className="subtotal">
                                <td>Subtotal:</td>
                                <td width="10">{numberFormat(cartTotals.subtotal)}</td>
                            </tr>
                            <tr className="total">
                                <td>Estimated Grand Total:</td>
                                <td width="10">{numberFormat(cartTotals.grand_total)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            )
        }
        else{
            return (
                <div>
                    Total: $0
                </div>
            )
        }
    }
}

export default connect(null, null)(ShoppingCartTotals)
