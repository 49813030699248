import React from "react"
import {connect} from "react-redux"

class LoadingWhite extends React.Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        let label = 'Loading...';
        if(this.props.label) {
            label = this.props.label;
        }
        return (
            <div className="loading-white">
                <i className="icon-loading"></i>
                <span>{label}</span>
            </div>
        )
    }
}

export default connect(null, null)(LoadingWhite)