export const PRODUCT = {
    sku: "Radio Spot",
}
export const custom_options = {
    due_date: 'due_date',
    on_sale_date_time_text: 'on_sale_date_time_text',
    additional_venue_mention_check: {
        code: 'additional',
        sku: {
            additional_venue_mention: 'additional_venue_mention'
        },
    },
    additional_venue_mention_text: "additional_venue_mention_text",
    produced_by: 'produced_by',
    sponsored_by: 'sponsored_by',
    presented_by: 'presented_by',
    welcomed_by: 'welcomed_by',

    generic_radio_check: {
        code: 'generic_radio',
        sku: {
            additional_generic_radio: 'additional_generic_radio'
        },
    },

};

export const fields = {
    [custom_options.due_date]: '',
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.produced_by]: '',
    [custom_options.sponsored_by]: '',
    [custom_options.presented_by]: '',
    [custom_options.welcomed_by]: '',
    [custom_options.additional_venue_mention_text]: '',
    [custom_options.additional_venue_mention_check.code]: null,
    [custom_options.additional_venue_mention_check.sku.additional_venue_mention]: false,
    [custom_options.generic_radio_check.code]: null,
    [custom_options.generic_radio_check.sku.additional_generic_radio]: false,
}
