import React from "react"
import {connect} from "react-redux"

class ShoppingCartItemShipping extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            dataToShow: {},
            readMore: [],
        }
        this.readMore = this.readMore.bind(this);
    }

    componentDidMount() {
        if(this.props.cartItem) {
            let dataToShow = {};
            let cartItem = this.props.cartItem;
            let originalProduct = cartItem.extension_attributes.original_product;
            let product_option = cartItem.product_option;
            let readMore = [];
            if(product_option) {
                let cartItemOptions = product_option.extension_attributes.custom_options;
                for (let cartItemOptionIndex in cartItemOptions) {
                    let cartItemOption = cartItemOptions[cartItemOptionIndex];
                    let option = this.getProductOptionById(originalProduct, cartItemOption.option_id);
                    if(option && (
                        cartItemOption.extension_attributes.visibility === 'frontend'
                        || cartItemOption.extension_attributes.visibility === 'both'
                    )) {
                        switch (option.type) {
                            case 'field':
                            case 'area':
                                if(cartItemOption.option_value){
                                    dataToShow[option.title] = cartItemOption.option_value;
                                }
                                break;
                            case 'radio':
                            case 'select':
                            case 'drop_down':
                            case 'checkbox':
                                let value = [];
                                let cartItemOptionValues = cartItemOption.option_value.split(',');
                                for(let valueIndex in option.values) {
                                    let optionValue = option.values[valueIndex];
                                    if(cartItemOptionValues.indexOf(optionValue.option_type_id.toString()) !== -1) {
                                        value.push(optionValue.title);
                                    }
                                }
                                dataToShow[option.title] = value.join(', ');
                                break;
                        }
                        readMore.push(true);
                    }
                }
                this.setState({
                    dataToShow: dataToShow,
                    readMore: readMore
                })
            }

        }
    }
    getProductOptionById(product, optionId) {

        let options = product.options;
        for(let optionIndex in options) {
            let option = options[optionIndex];
            if(option.option_id == optionId) {
                console.log('getProductOptionById', option)
                return option;
            }
        }
        return null;
    }
    readMore(event) {
        let readMore = [...this.state.readMore];
        let index = event.target.getAttribute('index');
        readMore[index] = !readMore[index];
        console.log('index', index);
        console.log('index', readMore[index]);
        this.setState({
            readMore: readMore
        })
    }
    render(){
        let {dataToShow, readMore} = this.state;
        return (
            <dd>
                <cart-item-shipping>
                    <ul>
                    {Object.keys(dataToShow).map((index, arrayIndex) => {
                        let data = dataToShow[index];
                        return <li key={index}>
                            <dl>
                                <dt>{index}:</dt>
                                {readMore[arrayIndex] &&
                                <dd>
                                    <div dangerouslySetInnerHTML={{__html: data.substring(0, 140)}}></div>

                                {data.length > 140 &&
                                <span className="read-more" index={arrayIndex} onClick={this.readMore}>...read more</span>}</dd>
                                }
                                {!readMore[arrayIndex] &&
                                    <dd>
                                        <div dangerouslySetInnerHTML={{__html: data}}></div>
                                        {data.length > 140 &&
                                    <span className="read-more" index={arrayIndex} onClick={this.readMore}>...read less</span>}</dd>
                                }
                            </dl>
                        </li>}
                    )}
                    </ul>
                </cart-item-shipping>
            </dd>
        )
    }
}

export default connect(null, null)(ShoppingCartItemShipping)
