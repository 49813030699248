import {request} from "../../axiosRequest";

export const REVISE_ORDER_REQUEST = 'REVISE_ORDER_REQUEST';
export const REVISE_ORDER_SUCCESS = 'REVISE_ORDER_SUCCESS';
export const REVISE_ORDER_FAILURE = 'REVISE_ORDER_FAILURE';

export const reviseOrderRequest = () =>({
    type: REVISE_ORDER_REQUEST
});
export const reviseOrderSuccess = (data) =>({
    type: REVISE_ORDER_SUCCESS,
    data: data
});
export const reviseOrderFailure = (error) =>({
    type: REVISE_ORDER_FAILURE,
    error: error
});


export const getReviseOrderAttempt = (orderId) => {
    return (dispatch) => {
        dispatch(reviseOrderRequest());
        return request.get(`reviseOrder/${orderId}`,true)
            .then(response => {return response})
            .then(result => {
                let data = result.data
                if(data.success && data.code == 200){
                    dispatch(reviseOrderSuccess(data.data));
                    console.log(data.data)
                }
                else{
                    dispatch(reviseOrderFailure(data.data))
                }
                console.log(result);
            })
            .catch(error => {
                console.log(error);
                dispatch(reviseOrderFailure(error));
            })
    }
}
