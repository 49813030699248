import {
    HOLIDAY_MESSAGE_FAILURE,
    HOLIDAY_MESSAGE_REQUEST,
    HOLIDAY_MESSAGE_SUCCESS
} from "./actions";

export default (state = {
    holidayMessageHtml: null,
    isFetchingHolidayMessage: false,
    holidayMessageError: null
}, action) => {
    switch (action.type) {
        case HOLIDAY_MESSAGE_REQUEST:
            return {
                ...state,
                isFetchingHolidayMessage: true,
            }
        case  HOLIDAY_MESSAGE_FAILURE:
            return {
                ...state,
                isFetchingHolidayMessage: false,
                holidayMessageError: action.error
            }
        case HOLIDAY_MESSAGE_SUCCESS:
            return {
                ...state,
                isFetchingHolidayMessage: false,
                holidayMessageHtml: action.data,
                holidayMessageError: null
            }
        default:
            return state;
    }
}
