import {request} from "../../axiosRequest";

export const GET_PRODUCT_OPTIONS_REQUEST = 'GET_PRODUCT_OPTIONS_REQUEST';
export const GET_PRODUCT_OPTIONS_SUCCESS = 'GET_PRODUCT_OPTIONS_SUCCESS';
export const GET_PRODUCT_OPTIONS_FAILURE = 'GET_PRODUCT_OPTIONS_FAILURE';
export const CLEAN_EDIT_CART_ITEM = 'CLEAN_EDIT_CART_ITEM';

export const getProductOptionsSuccess = (data) =>({
    type: GET_PRODUCT_OPTIONS_SUCCESS,
    data: data
})

export const getProductOptionsRequest = () =>({
    type: GET_PRODUCT_OPTIONS_REQUEST,
})

export const getProductOptionsFailure = (error) =>({
    type: GET_PRODUCT_OPTIONS_FAILURE,
    error: error
})

export const cleanEditCartItem = () =>({
    type: CLEAN_EDIT_CART_ITEM,
})


export const getProductBySku = (productSku) => {
    return (dispatch) => {
        dispatch(getProductOptionsRequest());
        request.get('products/' + productSku,true)
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result.data;
                if(data.success && data.code == 200)
                {
                    dispatch(getProductOptionsSuccess(data.data));
                }
                else {
                    data.data.code = data.code;
                    dispatch(getProductOptionsFailure(data.data))
                }
            })
            .catch(error => {
                dispatch(getProductOptionsFailure(error))
                }
            )

    }
};
