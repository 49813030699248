import React from "react"
import {connect} from "react-redux"
import {getCategoryMedia} from "./actions";
import {Link, withRouter} from "react-router-dom";
import OrderProcessTourModal from "../OrderProcessTourModal";
import {CATEGORY_DEMO_FILE_SERVICES, CATEGORY_IMAGES_SERVICES, CDN_URL} from "../../actions/Constants";
import ErrorMessage from "../ErrorMessage";
import NotFound from "../../containers/NotFound";

const mapStateToProps = state => ({
    ...state.orderProcessTour
})

const mapDispatchToProps = {
    getCategoryMedia,
}

class OrderProcessTour extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            visibleModal: false,
            itemSelected: '',
            backgroundImage: undefined,
            categoryDescription: ''
        }
        this.getAllMedia = this.getAllMedia.bind(this);
        this.openModal = this.openModal.bind(this);
        this.getCategoryDescription = this.getCategoryDescription.bind(this);
        this.closeDemosModal = this.closeDemosModal.bind(this);
    }

    componentDidMount() {
        let categoryId = this.props.categoryId;
        if(categoryId) {
            this.props.getCategoryMedia(categoryId);
        }
        document.addEventListener('keydown', this.closeDemosModal);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.categoryMediaInfo != this.props.categoryMediaInfo) {
            this.setState({
                categoryDescription: this.getCategoryDescription()
            });
        }
        /*if(this.props.categoryMediaInfo) {
            if(prevProps.categoryMediaInfo != this.props.categoryMediaInfo){
                let mediaInfo = this.props.categoryMediaInfo.mediaInfo;
                let categoryId = this.props.categoryId;
                if(mediaInfo.length == 0) {
                    this.props.history.push(`/cart/selectProducts/${categoryId}`)
                }
            }
        }*/
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.closeDemosModal);
    }

    getUrl(media) {
        console.log(media)
        let cut_media = JSON.parse(media);
        let data = {
            media_name: '',
            url: '',
            ext: ''
        };
        if(cut_media && cut_media.length > 0) {
            cut_media = cut_media[0];
            if(cut_media) {
                data.media_name = cut_media.name;
                data.url =  CATEGORY_DEMO_FILE_SERVICES + cut_media.name;
                console.log('data-----', data)
                let splitName = cut_media.name.split('.') ;
                if(splitName.length > 0){
                    data.ext = splitName[splitName.length - 1];
                }
            }
        }
        return data;
    }
    getAllMedia() {
        let categoryMediaInfo = this.props.categoryMediaInfo;
        let data = [];
        if(categoryMediaInfo) {
            let categoryInfo = categoryMediaInfo.categoryInfo;
            let artistPhotoUrl = '';
            if(categoryInfo) {
                if(categoryInfo.extension_attributes && categoryInfo.extension_attributes.artist) {
                    let artistPhoto = this._extractArtistPhoto(categoryInfo.extension_attributes.artist);
                    if(artistPhoto) {
                        artistPhotoUrl = artistPhoto.url;
                    }
                }
            }
            let mediaInfo = categoryMediaInfo.mediaInfo;
            Object.keys(mediaInfo).map((value) => {
                    let cutsMediaInfo = mediaInfo[value];
                    let dataTemp = cutsMediaInfo.map((info) => {
                        let cut = this.getUrl(info.cut_media);
                        console.log('testing...', cut.url)
                        if(cut.url != ""){
                            return {
                                fileUrl: cut.url,
                                artistPhotoUrl: artistPhotoUrl,
                                productType: value,
                                cutName: info.cut_name,
                                id: info.id,
                                ext: cut.ext
                            }
                        }
                })
                dataTemp = dataTemp.filter((item) => {
                    if(item){
                        return item;
                    }
                })
                data = [...data, ...dataTemp]
            })
        }
        console.log('data', data);
        let itemSelected = this.state.itemSelected;
        let indexTemp = 0
        for(let indexData in data) {
            let dataTemp = data[indexData];
            if(dataTemp.id == itemSelected) {
                indexTemp = indexData
                break
            }
        }

        let tempData = data.slice(indexTemp , data.length);
        tempData = tempData.concat(data.slice(0, indexTemp));

        return tempData
    }
    _extractArtistPhoto(artist) {
        let photoProperty = artist.photo;
        if(photoProperty) {
            photoProperty = JSON.parse(photoProperty);
            if(photoProperty && photoProperty.length > 0) {
                photoProperty = photoProperty[0];
                return photoProperty
            }
        }
        return {}
    }
    closeDemosModal(event) {
        this.setState({
            visibleModal: false,
        })
    }
    openModal(event) {
        let id = event.target.getAttribute('media_id');
        this.setState({
            visibleModal: true,
            itemSelected: id
        })
    }
    getCategoryDescription() {
        let categoryMediaInfo = this.props.categoryMediaInfo;
        let description = "";
        if(categoryMediaInfo){
            let categoryInfo = categoryMediaInfo.categoryInfo;
            categoryInfo.custom_attributes.map((value) => {
                if(value['attribute_code'] ==='description') {
                    description = value['value'];
                }
                if(value['attribute_code'] === 'background_image') {
                    this.setState({
                        backgroundImage: CATEGORY_IMAGES_SERVICES + "backgroundImage/" + value['value']
                    })
                }
            })
        }
        return description;
    }
    render(){
        let categoryMediaInfo = this.props.categoryMediaInfo;
        let categoryId = this.props.categoryId;
        let isFetchingCategoryMediaInfo = this.props.isFetchingCategoryMedia;
        let fetchCategoryMediaError = this.props.fetchCategoryMediaError;
        console.log('this.props.fetchCategoryMediaError', this.props.fetchCategoryMediaError, isFetchingCategoryMediaInfo)
        if(isFetchingCategoryMediaInfo) {
            return (<main id="main" role="main"
                          style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-order.jpg')`, backgroundColor: 'black'}}>
                <div id="content" className="container container fullscreen-checkout">
                    <div id="order-form" className="step-one row">
                        <div className="col-xs-12">
                            <div className="loading-black">
                                <i className="icon-loading"></i>
                                <span>Loading Items</span>
                            </div>
                        </div>
                    </div>
                </div>
            </main>)
        }
        if(fetchCategoryMediaError && fetchCategoryMediaError === 404) {
            return <NotFound/>
        }
        let categoryInfo = {};
        let mediaInfo = {};
        let artistName = '';
        let modalInfo = [];
        if(categoryMediaInfo) {
            mediaInfo = categoryMediaInfo.mediaInfo;
            categoryInfo = categoryMediaInfo.categoryInfo;
            if(categoryInfo.extension_attributes && categoryInfo.extension_attributes.artist) {
                let artist = categoryInfo.extension_attributes.artist;
                artistName = artist.name;
            }
            modalInfo = this.getAllMedia();
        }
        let visibleModal = this.state.visibleModal;
        let itemSelected = this.state.itemSelected;
        let backgroundImage = this.state.backgroundImage;
        let categoryDescription = this.state.categoryDescription;
        return (
            <main id="main" role="main"
                  className="fullscreen-responsive"
                  style={{backgroundImage: backgroundImage?`url('${backgroundImage}')`:`url('${CDN_URL}assets/backgrounds/bg-order.jpg')`,
                      backgroundColor: 'black'}}>
                <div id="content" className="container container fullscreen-checkout">
                    <div id="order-form" className="step-zero row">
                        <div className="col-xs-12">
                            <div className="row section-header">
                                <div className="col-sm-9 col-xs-7">
                                    <h1 className="h-two white mb-20">
                                        {artistName}
                                    </h1>
                                </div>
                                <div className="col-sm-3 col-xs-5">
                                    <Link to={{
                                        pathname: `/cart/selectProducts/${categoryId}`,
                                        backgroundImage: backgroundImage
                                    }}
                                          className="button medium blue-white order"
                                            onClick={this.onClick}
                                    >
                                        Order
                                    </Link>

                                </div>
                            </div>
                            <div id="order-option-preview-list" className="row">
                                {Object.keys(mediaInfo).length == 0 &&
                                <div className="order-option-preview col-sm-4 col-xs-6">
                                    <h2 className="h-three white"></h2>
                                </div>
                                }
                                {Object.keys(mediaInfo).map((categoryType, index) => {
                                    let cutsInfo = mediaInfo[categoryType];
                                    return (
                                        <div className="order-option-preview col-sm-4 col-xs-6" key={index}>
                                            <h2 className="h-three white">{categoryType}</h2>
                                            <ul>
                                                {Object.keys(cutsInfo).map((value, index) => {
                                                    let cutInfo = cutsInfo[value];
                                                    if(!cutInfo.cut_media) {
                                                        return <li key={index}>
                                                            {cutInfo.cut_name}
                                                        </li>
                                                    }
                                                    return (
                                                        <li key={index}>
                                                            <span to='#'
                                                                  title="Click for sample"
                                                                  onClick={this.openModal}
                                                                  media_id={cutInfo.id}
                                                            >
                                                                {cutInfo.cut_name}
                                                            </span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    )
                                })}
                            </div>
                            {categoryInfo &&
                                <div className="col-xs-12">
                                    <div className={'row order-message order-message-border-top'} dangerouslySetInnerHTML={{__html: categoryDescription}}>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                {visibleModal && <OrderProcessTourModal
                    media={modalInfo}
                    itemSelected={itemSelected}
                    closeDemosModal={this.closeDemosModal}
                />}

            </main>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderProcessTour))
