import React from "react"
import {connect} from "react-redux"
import {validatePasswordToken} from "./actions";
import {validEmailRegex} from "../../helpers";
import {Link} from "react-router-dom";
import {CDN_URL} from "../../actions/Constants";

const mapStateToProps = state => ({
    ...state.validatePasswordToken
})

const mapDispatchToProps = {
    validatePasswordToken,
}

class AccountValidateToken extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            newPassword: '',
            confirmNewPassword: '',
            errors: []
        }
        this.validateForm =this.validateForm.bind(this);
        this.resetPassword =this.resetPassword.bind(this);
        this.handleChange =this.handleChange.bind(this);
        this.goToLogin =this.goToLogin.bind(this);
        this.goToResetPassword =this.goToResetPassword.bind(this);
    }

    componentDidMount() {


    }
    validateForm() {
        let email = this.state.email;
        let newPassword = this.state.newPassword;
        let confirmNewPassword = this.state.confirmNewPassword;
        let errors = {};
        if(email == '') {
            errors['email'] = 'Please enter the email address associated with your account.';
        }
        else {
            if(email != '' && !validEmailRegex.test(email)) {
                errors['email'] = 'Please enter a valid email address.';
            }
            else{
                if(newPassword == '') {
                    errors['newPassword'] = "Please enter your password.";
                }
                else {
                    if(newPassword != confirmNewPassword) {
                        errors['confirmNewPassword'] = "Password doesn't match.";
                    }
                }
            }
        }
        this.setState({
            errors: errors
        })
        if(Object.keys(errors).length > 0) {
            return false
        }
        return true;
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    resetPassword(event) {
        event.preventDefault();
        if(this.props.match.params && this.props.match.params.token && this.validateForm()) {
            let token = this.props.match.params.token;
            let email = this.state.email;
            let password = this.state.newPassword;
            this.props.validatePasswordToken(token, email, password)
        }
    }
    goToLogin()
    {
        this.props.history.push('/account/login');
    }
    goToResetPassword() {
        this.props.history.push('/account/resetPassword');
    }
    render() {
        let errors = this.state.errors;
        let {email, newPassword, confirmNewPassword} = this.state;
        let {isFetchingValidatePasswordToken, validatePasswordTokenError, validatePasswordTokenData} = this.props;
        if(validatePasswordTokenData) {
            return (
                <main role="main" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-two.jpg')`}}>
                    <div id="modal-account" className="container fullscreen-modal">
                        <div className="row">
                            <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 center">
                                <h2>Reset Password</h2>
                                {validatePasswordTokenData &&
                                <div className="alert success">
                                    <p>
                                        Your password was changed successfully. You may <Link to={"/account/login"}>login now</Link> and start browsing tours.
                                    </p>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            )
        }
        if(isFetchingValidatePasswordToken) {
            return (
            <main role="main" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-two.jpg')`}}>
                <div id="modal-account" className="container fullscreen-modal">
                    <div className="loading-small">
                        <i className="icon-loading"></i>
                        <span>We are processing your request. Please wait.</span>
                    </div>
                </div>
            </main>
            )
        }
        return (
            <main role="main" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-two.jpg')`}}>
                <div id="modal-account" className="container fullscreen-modal">

                    <div className="row">
                        <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 center">
                            <h2>Reset Password</h2>
                            {validatePasswordTokenError &&
                            <div className="alert error">
                                <p>
                                    {validatePasswordTokenError}
                                </p>
                            </div>
                            }
                            {
                                errors && Object.keys(errors).length > 0 &&
                                <div className="alert error">
                                    {Object.keys(errors).map((item) => {
                                        return <p>{errors[item]}</p>

                                    })}
                                </div>
                            }
                            <form onSubmit={this.resetPassword}>
                                <input type="email" placeholder="Email Address"
                                       name='email'
                                       value={email}
                                       onChange={this.handleChange}
                                       className={'email' in errors?'error':''}
                                />

                                <input type="password" placeholder="New Password"
                                       name='newPassword'
                                       value={newPassword}
                                       onChange={this.handleChange}
                                       className={'newPassword' in errors?'error':''}
                                />
                                <input type="password" placeholder="Confirm New Password"
                                       name='confirmNewPassword'
                                       value={confirmNewPassword}
                                       onChange={this.handleChange}
                                       className={'confirmNewPassword' in errors?'error':''}
                                />
                                <button type="submit" className="button small blue-white" onClick={this.resetPassword}>
                                    Reset Password
                                </button>
                                <button id="button-cancel-reset-password" type="button" onClick={this.goToLogin}
                                        className="button-text light">I remember my password.
                                </button>
                                <button id="button-reset-password" type="button" onClick={this.goToResetPassword}
                                        className="button-text light"> Send new link.
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountValidateToken)
