import {
    SET_SHOPPING_CART_ITEMS,
    SHOPPING_CART_CLEANUP,
    SHOPPING_CART_FAILURE,
    SHOPPING_CART_REQUEST,
    SHOPPING_CART_SUCCESS
} from "./actions";
import {USER_LOGOUT} from "../Logout/actions";
import {
    REMOVE_ITEM_FROM_CART_FAILURE,
    REMOVE_ITEM_FROM_CART_REQUEST,
    REMOVE_ITEM_FROM_CART_SUCCESS
} from "../ShoppingCartItem/actions";

export default (state = {
    cart: null,
    isFetchingCartItemsList: false,
    removeItemIsFetching: false,
    fetchingCartError: null
}, action) => {
    switch (action.type) {
        case SHOPPING_CART_REQUEST:
            console.log('is fetching',state.isFetchingCartItemsList);
            return {
                ...state,
                isFetchingCartItemsList: true,
            }
        case SHOPPING_CART_FAILURE:
            return {
                ...state,
                isFetchingCartItemsList: false,
                fetchingCartError: action.error
            }
        case SHOPPING_CART_SUCCESS:
            return {
                ...state,
                isFetchingCartItemsList: false,
                removeItemIsFetching: false,
                cart: action.data
            }
        case SET_SHOPPING_CART_ITEMS:
            let cart = {...state.cart};
            cart['items'] = action.data;
            console.log('Shopping.....',cart);
            return {
                ...state,
                isFetchingCartItemsList: false,
                cart: {...cart}
            }
        case SHOPPING_CART_CLEANUP:
            return {
                ...state,
                cart: null,
                isFetchingCartItemsList: false,

            };
        case REMOVE_ITEM_FROM_CART_REQUEST:
            return {
              ...state,
              removeItemIsFetching: true
            };
        case REMOVE_ITEM_FROM_CART_SUCCESS:
            return {
              ...state,
              removeItemIsFetching: false,
            };
        case REMOVE_ITEM_FROM_CART_FAILURE:
            return {
              ...state,
              removeItemIsFetching: false
            };
        case USER_LOGOUT:
            return {
                ...state,
                cart: null,
            }
        default:
            return state;
    }
}