import React from "react"
import {connect} from "react-redux"
import ShowsFields from "../ShowsFields"
import {FIELDS_CODE} from "../../actions/Constants";

class OrderFormStep2ShowData extends React.Component{

    constructor(props)
    {
        super(props)
        this.state = {
            showInformation: props.show
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheckAndRadio = this.handleChangeCheckAndRadio.bind(this);
        this.handleShowDate = this.handleShowDate.bind(this);
    }
    componentDidMount() {
        this.setState( {
            showInformation: this.props.show,
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.show != this.props.show && this.props.show){
            this.setState({
                showInformation: this.props.show
            })
        }
    }
    handleChangeCheckAndRadio(info) {
        let showInformation = {...this.state.showInformation};
        if(!info.type || info.type == 'checkbox') {
            showInformation[info.code] = !showInformation[info.code];
            // let fieldCode = info['field-code'];
            // showInformation[fieldCode][info.code] = showInformation[info.code];
        }
        else {
            showInformation[info.code] = info.value;
        }
        this.setState({
            showInformation: showInformation
        });
        let showindex = this.props.showindex;
        this.props.updateShow(showindex, showInformation)
    }
    handleChange(event) {

        let code = event.target.getAttribute("code");
        let showInformation = this.state.showInformation;
        showInformation[code] = event.target.value;
        this.setState({
            showInformation: showInformation
        });
        let showindex = this.props.showindex;
        this.props.updateShow(showindex, showInformation)
    }
    handleShowDate(dates) {
        let showInformation = this.state.showInformation;
        showInformation[FIELDS_CODE.show_date] = dates;
        this.setState({
            showInformation: showInformation
        });
        let showindex = this.props.showindex;
        this.props.updateShow(showindex, showInformation)
    }
    render(){
        let {showInformation} = this.state;
        let {showindex, pkgArtForm, fieldsToShow} = this.props;
        let errors = [];
        if(this.props.errors) {
            errors = this.props.errors
        }

        return (
            <div className="form-section repeat">
                {showInformation && <ShowsFields showindex={showindex}
                             showInformation={showInformation}
                             handleChange={this.handleChange}
                             handleChangeCheckAndRadio={this.handleChangeCheckAndRadio}
                             handleShowDate={this.handleShowDate}
                             errors={errors}
                             pkgArtForm={pkgArtForm}
                             fieldsToShow={fieldsToShow}
                />}
            </div>
        )

    }
}


export default connect(null, null)(OrderFormStep2ShowData)
