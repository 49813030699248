import React from "react"
import {connect} from "react-redux"
import CheckoutBillingAddress from "../../components/CheckoutBillingAddress";
import CheckoutOrderReview from "../../components/CheckoutOrderReview";
import {checkOutFinished, getPaymentInformationAvailable, placeOrderAttempt} from "./actions";
import CheckoutPaymentInformation from "../../components/CheckoutPaymentInformation";
import {withRouter} from 'react-router-dom'
import {getCountries} from "../../components/Countries/actions";
import {Link} from "react-router-dom";
import LoadingWhite from "../../LoadingWhite";
import ErrorMessage from "../../components/ErrorMessage";

const mapStateToProps = state => ({
    ...state.cart,
    ...state.customerInformation,
    ...state.checkout,
    ...state.accountEditAddressForm,
    ...state.countriesData
})

const mapDispatchToProps = {
    getPaymentInformationAvailable,
    placeOrderAttempt,
    getCountries,
    checkOutFinished,
}

class Checkout extends React.Component{
    constructor(props)
    {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setBillingErrors = this.setBillingErrors.bind(this);
        this.updateSelectedPaymentMethod = this.updateSelectedPaymentMethod.bind(this);

        this.state = {
            billingAddress: null,
            billingErrors: null,
            paymentMethod: {
                payment: 'checkmo'
            },
            isValid: true
        }
    }
    updateSelectedPaymentMethod(data){
        console.log('updateselectedpayment', data)
        this.setState({
            paymentMethod: {...data}
        })
    }

    componentDidMount() {
        // this.props.getPaymentInformationAvailable();
        if(!this.props.countries) {
            this.props.getCountries();
        }
        let billingAddress = this.getBillingInfo();
        let billingAddressError = false;
        if(!billingAddress) {
            billingAddressError = true;
        }
        if(this.props.customerInfo) {
            this.setState({
                billingAddress:billingAddress? billingAddress:null,
                billingErrors: billingAddressError
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.customerInfo != prevProps.customerInfo) {
            let billingAddress = this.getBillingInfo();
            let billingAddressError = false;
            if(!billingAddress) {
                billingAddressError = true;
            }
            this.setState({
                billingAddress:billingAddress? billingAddress:null,
                billingErrors: billingAddressError
            })
        }
        if(prevProps.placeOrderError != this.props.placeOrderError && this.props.placeOrderError)
        {
            this.setState({
                isValid: true
            })
        }
    }
    getBillingInfo() {
        let customerInfo = this.props.customerInfo;
        if(customerInfo){
            for (let index in customerInfo.addresses) {
                let address = customerInfo.addresses[index];
                if(address.id == customerInfo.default_billing) {
                    console.log('address', address);
                    return {
                        ...address,
                        email: customerInfo.email,
                        fax: customerInfo.fax,
                    };
                }
            }
        }
        return  false;
    }
    componentWillUnmount() {
        this.props.checkOutFinished()
    }
    validatePayment() {
        console.log('Validating....');
        let errors = {};
        let {payment, credit_card, purchase_order} = {...this.state.paymentMethod};

        switch (payment) {
            case '':
            case null:
            case undefined:
                errors['payment'] = 'You should choose a payment method.';
                break;
            case 'credit_card':
                for(let ccValue in credit_card) {
                    if(credit_card[ccValue] === '') {
                        errors[ccValue] = "This field can't be blank.";
                    }
                }
                break;
            case "purchase_order":
                for(let ccValue in purchase_order) {
                    if(purchase_order[ccValue] === '') {
                        errors[ccValue] = "This field can't be blank.";
                    }
                }
                break;
            case "invoice":
            case "checkmo":
                errors = {};
                break;
            default:
                if(payment !== "")
                {
                    delete errors['payment'];
                }
        }
        let paymentMethod = {...this.state.paymentMethod};
        console.log('paymentMethod', errors);
        paymentMethod['errors'] = errors;
        this.setState({
            paymentMethod: paymentMethod
        });
        return errors;
    }

    setBillingErrors(errors) {
        if(errors && Object.keys(errors).length > 0){
            this.setState({billingErrors: true})
        }
    }
    isValidForm(form)
    {
        let paymentErrors = this.validatePayment();
        console.log('paymentMethod', paymentErrors)
        let addressBillingErrors = this.state.billingErrors?this.state.billingErrors:{};
        let inputs = form.getElementsByTagName('input');
        let selects = form.getElementsByTagName('select');
        let all = {...inputs, ...selects};
        let isValid = true;
        if(paymentErrors && Object.keys(paymentErrors).length !== 0 ||
            addressBillingErrors && Object.keys(addressBillingErrors).length !== 0 ) {
            console.log("paymentErrors", paymentErrors);
            console.log("addressBillingErrors", addressBillingErrors);
            isValid = false;
        }
        for(let index in all) {
            let input = all[index];
            if (input.name in paymentErrors || input.name in addressBillingErrors) {
                input.classList.add('error');
                isValid = false;
            }
            else {
                if(input.classList && input.classList.contains('error')) {
                    input.classList.remove('error');
                }
            }
        }
        this.setState({isValid: isValid});
        console.log('isValid', isValid)
        return isValid;
    }

    handleSubmit(event)
    {
        event.preventDefault();
        let paymentMethod = this.state.paymentMethod;
        let paymentSetup = {};
        if(this.isValidForm(event.target)) {
            if(paymentMethod.payment === 'credit_card') {
                let credit_card = paymentMethod.credit_card;
                let date = credit_card.cc_exp_date.split('/')
                paymentSetup = {
                    method: 'authorizenet_acceptjs',
                    additional_data: {
                        cc_cid: credit_card.cc_cid,
                        // cc_type:'',
                        cc_exp_year: date[1],
                        cc_exp_month: date[0],
                        cc_number: credit_card.cc_number,
                    }
                };
            }
            if(paymentMethod.payment === 'purchase_order') {
                let purchase_order = paymentMethod.purchase_order;
                paymentSetup = {
                    method: 'purchaseorder',
                    po_number: purchase_order.po_number
                };
            }
            if(paymentMethod.payment === 'checkmo' || paymentMethod.payment === 'invoice') {
                paymentSetup = {
                    method: 'checkmo',
                };
            }
            let stateBillingAddress = this.state.billingAddress;
            let street = stateBillingAddress.street;
            if(stateBillingAddress.apt_number && stateBillingAddress.apt_number !== '') {
                street.push(stateBillingAddress.apt_number);
            }
            let billingAddress = {
                firstname: stateBillingAddress.firstname,
                lastname: stateBillingAddress.lastname,
                region: stateBillingAddress.region.region,
                region_id: stateBillingAddress.region.region_id,
                region_code: stateBillingAddress.region.region_code,
                street: street,
                postcode: stateBillingAddress.postcode,
                city: stateBillingAddress.city,
                country_id: stateBillingAddress.country_id,
                telephone: stateBillingAddress.telephone,
                fax: stateBillingAddress.fax,
                email: stateBillingAddress.email,
                company: stateBillingAddress.company,
            }
            this.props.placeOrderAttempt({
                billingAddress: billingAddress,
                paymentMethod: paymentSetup,
                customerId: this.props.customerInfo.id
            });
        }
    }
    render(){
        let {cart, customerInfo, paymentInformation, countries, isFetchingPlaceOrder,
            placeOrderError, checkOutSuccess} = this.props;
        let {paymentMethod, billingErrors} = {...this.state};
        let isValid = this.state.isValid;
        if(cart){
            if(checkOutSuccess) {
                let processedOrders = this.props.processedOrders;
                return (
                    <main id="main" role="main">
                        <section id="content" className="checkout-wrapper container">
                            <div className="row section-header">
                                <div className="col-md-10 col-sm-9">
                                    <h1 className="h-two blue mb-20">
                                        Your order has been received.
                                    </h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 mb-30">
                                    <p className="p-three">
                                        Thank you for your purchase!
                                    </p>
                                </div>
                                {processedOrders.map((processedOrder, index) => {
                                    return (
                                        <div className="col-xs-12" key={index}>
                                            <h2 className="h-four blue mb-10">Order: {processedOrder["orderNumber"]}</h2>
            {/*                                <p className="p-three mb-20">
                                                Order Total: <strong>${processedOrder['orderTotal']}</strong>
                                            </p>*/}
                                            <p className="p-four mb-50">
                                                <Link
                                                    to={`/orderDetails/${processedOrder['orderId']}`}
                                                    className="blue">
                                                    View Order Details
                                                </Link> |&nbsp;
                                                <Link
                                                    to={`/orders/print/${processedOrder['orderId']}`}
                                                    target="_blank"
                                                    className="blue">
                                                    Print Order Details
                                                </Link>
                                            </p>
                                        </div>
                                    )
                                })}
                                <div className="col-xs-12">
                                    <p className="p-three">
                                        You may also <Link to="/orders" className="blue">review all orders</Link>,&nbsp;
                                        <Link to="/artists" className="blue">browse the tour roster</Link>
                                        &nbsp;to order more materials, or&nbsp;
                                        <Link to="/" className="blue">
                                            return to your dashboard
                                        </Link>.</p>
                                </div>
                            </div>
                        </section>
                    </main>
                )
            }
            if(isFetchingPlaceOrder) {
                return (
                    <main id="main" role="main">
                        <section id="content" className="checkout-wrapper container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="loading-white">
                                        <i className="icon-loading"></i>
                                        <span>Processing Order</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                )
            }
        if(cart && cart.items_qty === 0) {
            return (
                <main id="main" role="main">
                    <section id="content" className="checkout-wrapper container">
                        <div className="row section-header">
                            <div className="col-md-10 col-sm-9">
                                <h1 className="h-two blue mb-20">Checkout</h1>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-xs-12">
                                <p className="p-two">Your cart is empty. Please browse the <Link to="/artists" className="blue">tour roster</Link> to order promo materials.</p>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        return (
            <main id="main" role="main">
                <section id="content" className="checkout-wrapper container">
                    <div className="row section-header">
                        <div className="col-md-10 col-sm-9">
                            <h1 className="h-two blue mb-10">Checkout</h1>
                            <small className="required">Required</small>
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-8">
                                <h2 className="payment-information h-five">Payment Information</h2>
                                {billingErrors && <div className="row ">
                                    <div className="col-xs-12">
                                        <div className="alert error">
                                            <p>
                                                There is no billing address associated with this account.
                                                Please contact us at &nbsp;
                                                <a href='mailto:accounting@tourdesign.com'>
                                                    accounting@tourdesign.com
                                                </a> or &nbsp;
                                                <a href='tel:323-217-1100'>
                                                    323-217-1100.
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>}
                                <CheckoutPaymentInformation
                                    paymentSelected={paymentMethod}
                                    updateSelectedPaymentMethod={this.updateSelectedPaymentMethod}
                                />

                                {cart && this.state.billingAddress && <CheckoutBillingAddress
                                    cart={cart}
                                    customerInfo={customerInfo}
                                    setBillingErrors={this.setBillingErrors}
                                    countries={countries}
                                    isValid={isValid}
                                    billingAddress={this.state.billingAddress}
                                    placeOrderError={placeOrderError}
                                />
                                }
                            </div>

                            {cart && customerInfo && <CheckoutOrderReview cart={cart}/>}

                        </div>
                    </form>
                </section>
            </main>
        )}
        else {
            return (
                <main id="main" role="main">
                    <section id="content" className="checkout-wrapper container">
                        <LoadingWhite message="Loading.."/>
                    </section>
                </main>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout))
