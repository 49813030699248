import {request} from "../../axiosRequest";

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_CLEAN_UP = 'RESET_PASSWORD_CLEAN_UP';

export const resetPasswordSuccess = (data) =>({
    type: RESET_PASSWORD_SUCCESS,
    data: data
})

export const resetPasswordRequest = () =>({
    type: RESET_PASSWORD_REQUEST,
})

export const resetPasswordFailure = (error) =>({
    type: RESET_PASSWORD_FAILURE,
    error: error
})
export const resetPasswordCleanUp = () =>({
    type: RESET_PASSWORD_CLEAN_UP,
})


export const resetPassword = (email) => {
    return (dispatch) => {
        dispatch(resetPasswordRequest());
        request.post(`customers/resetPassword/${email}`)
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result.data;
                if(data.success && data.code == 200) {
                    dispatch(resetPasswordSuccess(data.data));
                    return;
                }
                else {
                    if(data.code == 404) {
                        dispatch(resetPasswordSuccess(data.data));
                    }
                    else {
                        dispatch(resetPasswordFailure(data.data));
                    }
                }
                console.log(data.data);
            })
            .catch(error => {
                    console.log(error);
                    dispatch(resetPasswordFailure(error));
                }
            )

    }
};