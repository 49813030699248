export const PRODUCT = {
    sku: "AMEX RADIO SPOT",
}
export const custom_options = {
    due_date: 'due_date',
    on_sale_date_time_text: 'on_sale_date_time_text',
    additional_venue_mention_check: {
        code: 'additional',
        sku: {
            additional_venue_mention: 'additional_venue_mention'
        },
    },
    additional_venue_mention_text: "additional_venue_mention_text",
    on_screen_copy: 'on_screen_copy',
}

export const fields = {
    [custom_options.due_date]: '',
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.additional_venue_mention_text]: '',
    [custom_options.additional_venue_mention_check.code]: null,
    [custom_options.additional_venue_mention_check.sku.additional_venue_mention]: false,
}
