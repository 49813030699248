import React from 'react'
import {connect} from "react-redux"
import OrderItem from "../OrderDetailsItem";

class OrderItemsList extends React.Component{

    render() {
        let {ordersItems} = this.props
        return (
            <section className="row section-account">
                <div className="col-xs-12">
                    <header className="mb-10">
                        <h3 className="h-five">Items Ordered</h3>
                    </header>
                    <div className="row">
                        <div className="col-xs-12">
                            <table className="item-details" width="100%">
                                <thead>
                                <tr>
                                    <th className="left">Item(s)</th>
                                    <th className="right">Price</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {ordersItems.map((item) =>
                                    <OrderItem orderItem={item} key={item.item_id}/>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default connect(null, null)(OrderItemsList)
