import {request} from "../../axiosRequest";

import {DEFAULT_CATEGORY_PAGE_SIZE, DEFAULT_PAGE_SIZE} from "../../actions/Constants"
import {recentOrderListError, recentOrderListReceived, recentOrderListRequest} from "../Orders/actions";

export const CATEGORIES_REQUEST = "CATEGORIES_REQUEST"
export const CATEGORIES_SUCCESS = "CATEGORIES_SUCCESS"
export const CATEGORIES_FAILURE = "CATEGORIES_FAILURE"


export const categoriesRequest = () => ({
    type: CATEGORIES_REQUEST,

})
export const categoriesSuccess = (data) => ({
    type: CATEGORIES_SUCCESS,
    data: data
})
export const categoriesFailure = (error) => ({
    type: CATEGORIES_FAILURE,
    error: error
})


export const getToursAttempt = (currentPage = 1, pageSize = DEFAULT_CATEGORY_PAGE_SIZE,
                                filter = {}) => {
    return (dispatch) => {
        dispatch(categoriesRequest());
        let data = {currentPage: currentPage, pageSize: pageSize};
        data["filterBy"] = JSON.stringify({parent_id: {value: 2, condition: "eq"}});
        return request.get("categories", true, data)
            .then(response => {return response})
            .then(result => {
                console.log(result);
                let data = result.data
                if(data.success && data.code == 200){
                    console.log(data.data);
                    dispatch(categoriesSuccess(data.data.items));
                }
                else{
                    console.log(data.data);
                    let errorData = data.data
                    errorData["code"] = data.code
                    dispatch(categoriesFailure(errorData));
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(categoriesFailure(error));
            })

    }
}