import {request} from "../../axiosRequest";

export const GET_ARTISTS_REQUEST = 'GET_ARTISTS_REQUEST';
export const GET_ARTISTS_SUCCESS = 'GET_ARTISTS_SUCCESS';
export const GET_ARTISTS_FAILURE = 'GET_ARTISTS_FAILURE';

export const getArtistsSuccess = (data) =>({
    type: GET_ARTISTS_SUCCESS,
    data: data
})

export const getArtistsRequest = () =>({
    type: GET_ARTISTS_REQUEST,
})

export const getArtistsFailure = (error) =>({
    type: GET_ARTISTS_FAILURE,
    error: error
})


export const getArtists = (currentPage = 1) => {
    return (dispatch) => {
        dispatch(getArtistsRequest());
        let data = {currentPage: currentPage};
        let sortBy = {"name": "asc"};
        data["sortBy"] = JSON.stringify(sortBy);
        request.get(`artists/`, true, data)
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result.data;
                if(data.success && data.code === 200)
                {
                    dispatch(getArtistsSuccess(data.data));
                }
                else {
                    if(data.code === 400)
                    {
                        dispatch(getArtistsFailure(data.data.message))
                    }
                    else {
                        data.data.code = data.code;
                        dispatch(getArtistsFailure(data.data.message))
                    }
                }
                console.log(data)
            })
            .catch(error => {
                dispatch(getArtistsFailure(error))
                }
            )

    }
};