import React from "react"
import {connect} from "react-redux"
import AccountEditAddressForm from "../../components/AccountEditAddressForm";
import {Link} from "react-router-dom";

class AccountEditAddress extends React.Component{
    render(){
        let param = this.props.match.params.addressId;
        return (
            <main id="main" role="main">
                <section id="content" className="container content-wrapper">
                    <div className="row section-header">
                        <div className="col-xs-12">
                            <ul className="breadcrumb">
                                <li><Link to={'/dashboard'}>Account Dashboard</Link></li>
                                <li><Link to={"/customer/addressBook"}>Address Book</Link></li>
                            </ul>
                            <h1 className="h-two blue">
                                {param === 'new'? 'Add New Address' : 'Edit Address'}
                            </h1>
                        </div>
                    </div>
                    <AccountEditAddressForm/>
                </section>
            </main>
        )
    }
}

export default connect(null, null)(AccountEditAddress)
