import React from "react"
import {connect} from "react-redux"
import {setItemsToDelete, setShippingProducts} from "../../containers/OrderFormStep1/actions";
import Loadable from "react-loadable";

const mapDispatchToProps = {
    setShippingProducts,
    setItemsToDelete
}

class ProductShipping extends React.Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        console.log(this.props.shippingProduct)
        this.state = {
            shippingMethods: [...this.props.shippingProduct],
            ShippingModule: null,
            optionsDescription: {},
            shipping_definition: null,
            updateChildInfo: false
        };
        this.addShippingMethod = this.addShippingMethod.bind(this);
        this.setShippingMethod = this.setShippingMethod.bind(this);
        this.updateShippingMethod = this.updateShippingMethod.bind(this);
        this.deleteShippingMethod = this.deleteShippingMethod.bind(this);
        this.optionsDescription = this.optionsDescription.bind(this);
        this.loadShippingMethods = this.loadShippingMethods.bind(this);
    }
    addShippingMethod() {
        let shippingMethods = [...this.state.shippingMethods];
        let product = this.props.selectedProduct;
        if (product.extension_attributes.shipping) {
            let shippingProduct = product.extension_attributes.shipping;
            let shippingName = shippingProduct.name;
            if(shippingName) {
                let name = shippingName.replace(/ /g, "_");
                name = name.toUpperCase();
                let definition = import('./' + name + '/definition');
                definition.then((response) => {
                    shippingMethods.push({...response.shipping_fields});
                    this.setState({
                        shippingMethods: shippingMethods
                    }, () => {
                        this.setShippingMethod();
                    })
                }).catch((error) => {
                    console.log('error loading shipping definition...')
                })
            }
        }
    }
    componentDidMount() {
        this._isMounted = true;
        if(this.props.shippingProduct) {
            this.uploadShippingProductModule();
        }
        let product = this.props.selectedProduct;
        if (product.extension_attributes.shipping) {
            let shippingProduct = product.extension_attributes.shipping;
            let shippingName = shippingProduct.name;
            if (shippingName) {
                let name = shippingName.replace(/ /g, "_");
                name = name.toUpperCase();
                let definition = import('./' + name + '/definition');
                definition.then((response) => {
                    if(this._isMounted){
                        this.setState({
                            shipping_definition: {
                                field_codes: response.field_codes,
                                shipping_fields: response.shipping_fields
                            }
                        })
                    }
                }).catch((error) => {
                    console.log('error loading shipping definition...')
                })
            }
        }

    }

    setShippingMethod() {
        let product = this.props.selectedProduct;
        let productsToCartShipping = {...this.props.productsToCartShipping};
        console.log('productsToCartShipping', productsToCartShipping)
        productsToCartShipping[product.id] = [...this.state.shippingMethods];
        this.props.setShippingProducts(productsToCartShipping);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.shippingMethods != this.state.shippingMethods) {
            this.setShippingMethod();
        }
    }
    updateShippingMethod(index, info) {
        let {shippingMethods} = {...this.state};
        let groupItem = undefined;
        if(shippingMethods[index] && shippingMethods[index]['group_item']) {
            info['group_item'] = shippingMethods[index]['group_item'];
        }
        shippingMethods[index] = info;
        this.setState({
            shippingMethods: shippingMethods
        }, () => {
            this.setShippingMethod();
        })
    }

    uploadShippingProductModule() {
        let product = this.props.selectedProduct;
        if (product.extension_attributes.shipping) {
            let shippingProduct = product.extension_attributes.shipping;
            let shippingName = product.extension_attributes.shipping.name;
            console.log('shippingName', shippingName);
            if(shippingName) {
                let name = shippingName.replace(/ /g, "_");
                name = name.toUpperCase();
                console.log(name);
                let module = Loadable({
                    loader: () => import('./'+ name),
                    loading: () => <div>Loading { name }...</div>,
                });
                this.loadShippingMethods(name);
                let optionsDescription = this.optionsDescription();
                this.setState({
                    ShippingModule: module,
                    optionsDescription: optionsDescription
                });
            }
        }
    }
    loadShippingMethods(productName) {
        let shippingProducts = this.props.shippingProduct;
        let update = false;
        for(let productIndex in shippingProducts) {
            let shippingProduct = shippingProducts[productIndex];
            if(!shippingProduct) {
                update = true;
            }
        }

        if(update) {
            let definition = import('./' + productName + '/definition');
            definition.then((response) => {
                let shippingMethods = [{...response.shipping_fields}]
                this.setState({
                    shippingMethods: shippingMethods
                }, () =>  {this.setShippingMethod();})
            })
        }
    }
    deleteShippingMethod(event) {
        let index = event.target.getAttribute('index');
        let {shippingMethods} = {...this.state};
        let methodToDelete = shippingMethods[index];
        shippingMethods.splice(index, 1);
        console.log('shippingMethods', shippingMethods)
        this.setState({
            shippingMethods: shippingMethods,
            updateChildInfo: true
        }, () => {
            this.setShippingMethod();
            this.setState({
                updateChildInfo: false
            })
        })
        let idsToDelete = this.props.itemsToDelete;
        if(methodToDelete && methodToDelete.item_id) {
            idsToDelete.push(methodToDelete.item_id)
        }
        this.props.setItemsToDelete(idsToDelete);
    }
    optionsDescription() {
        let product = this.props.shippingProductOriginal;
        if(product) {
            let productOptions = product.options;
            if(productOptions) {
                let allDescriptions = {};
                productOptions.map((option) => {
                    if(option.extension_attributes && option.extension_attributes.description) {
                        allDescriptions[option.extension_attributes.option_code] = option.extension_attributes.description;
                    }
                })
                return allDescriptions;
            }
        }
        return {};

    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render(){
        let shippingMethods = [...this.state.shippingMethods];
        let ShippingModule = this.state.ShippingModule;
        let selectedProduct = this.props.selectedProduct;
        let customerEmail = this.props.customerEmail;
        let optionsDescription = this.state.optionsDescription;
        let shipping_definition = this.state.shipping_definition;
        let updateChildInfo = this.state.updateChildInfo;
        let formErrors = {};
        if(this.props.formErrors) {
            formErrors = this.props.formErrors;
        }
        return (
            <div className="repeat-control-parent">
                <div>
                    <h3>

                    </h3>
                </div>
                {ShippingModule && shippingMethods.map((value, index, array) => {
                    console.log('shipping methods value', value)
                    return <shipping-module-section key={index}>
                        <ShippingModule
                            index={index}
                            shippingInfo={{...value}}
                            updateShippingMethod={this.updateShippingMethod}
                            selectedProduct={selectedProduct}
                            formErrors={formErrors[index]}
                            optionsDescription={optionsDescription}
                            customerEmail={customerEmail}
                            updateChildInfo={updateChildInfo}
                        />
                         {shippingMethods && shippingMethods.length > 1 && <div className="remove-shipping-wrapper mt-20">
                            <button className="remove-shipping button xsmall red-white"
                                    type={'button'}
                                    onClick={this.deleteShippingMethod}
                                    index={index}
                            >
                                Remove Encoding Above
                            </button>
                        </div>}
                    </shipping-module-section>
                })}
                {
                    shipping_definition
                    && shipping_definition.field_codes
                    && shipping_definition.field_codes.delivery_options
                    && Object.keys(shipping_definition.field_codes.delivery_options.sku).length > 1
                    && <div className={"repeat-control repeat-control-inner" + (shippingMethods && shippingMethods.length > 1 ? "" : " no-remove")}>
                    <button type="button" onClick={this.addShippingMethod}>
                        Add Another Encoding Method
                    </button>
                </div>}
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(ProductShipping)
