import React from "react"
import {connect} from "react-redux"
import {ordersListFetch, setOrdersSortBy} from "../../containers/Orders/actions";
import OrderPaginator from "../OrdersPaginator";
import {Link} from "react-router-dom";
import {capitalizeFirstLetter} from "../../helpers";
import {ordersStatuses} from "../../actions/Constants";

const mapStateToProps = state => ({
    ...state.customerInformation,
    ...state.ordersList,
})

const mapDispatchToProps = {
    ordersListFetch,
    setOrdersSortBy
}

class OrdersList extends React.Component{
    severalShippingOptions = [
        'HD TV',
        'SD TV',
        'AMEX HD TV',
        'AMEX TV',
    ]
    revisionProducts = [
        'AMEX HD TV',
        'HD TV',
        'Radio Spot',
        'AMEX Radio Spot',
        'SD TV',
        "AMEX TV"
    ];
    constructor(props) {
        super(props);
        this.state = {
            expanded: -1,
        }
        this.expandOptions = this.expandOptions.bind(this);
        this.setSort = this.setSort.bind(this);
        this.getSortByClasses = this.getSortByClasses.bind(this);
    }

    componentDidMount() {
        let {customerInfo} = this.props;
        if(customerInfo && customerInfo.id){
            this.props.ordersListFetch(customerInfo.id, this.props.currentPage, this.props.pageSize);
        }
        let sortBy = {...this.props.sortBy}
        if(sortBy) {
            this.setState({sortBy: sortBy})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {customerInfo} = this.props;
        if(prevProps.customerInfo == null && customerInfo != null){
            this.props.ordersListFetch(customerInfo.id);
        }
        if(customerInfo != null && prevProps.currentPage !== this.props.currentPage) {
            this.props.ordersListFetch(customerInfo.id,
                this.props.currentPage,
                this.props.pageSize,
                this.props.filter,
                this.props.sortBy
            );
        }
        if(customerInfo != null && prevProps.pageSize !== this.props.pageSize && this.props.pageSize){
            this.props.ordersListFetch(customerInfo.id, 1,
                this.props.pageSize,
                this.props.filter
            );
        }

        if(customerInfo != null && prevProps.filter !== this.props.filter){
            this.props.ordersListFetch(
                customerInfo.id, 1, this.props.pageSize,
                this.props.filter, this.props.sortBy
            );
        }
        if(customerInfo != null && prevProps.sortBy != this.props.sortBy) {
            this.props.ordersListFetch(
                customerInfo.id, 1, this.props.pageSize,
                this.props.filter, this.props.sortBy
            );
        }

        this.props.updateOrdersTotal(this.props.ordersTotal);

    }
    canAddShipping(items) {
        let hasShipping = false;
        console.log('item.name', items)
        for(let itemIndex in items) {
            let item = items[itemIndex]
            console.log('item.name', item['name'])
            if( this.severalShippingOptions.indexOf(item['name']) !== -1 ) {
                hasShipping =  true
            }
        }
        return hasShipping;
    }

    hasReviseOption(items) {
        for(let itemIndex in items) {
            let item = items[itemIndex]
            if(item && this.revisionProducts.indexOf(item['name']) !== -1) {
                return true;
            }
        }
        return false
    }


    expandOptions(event) {
        let index = event.target.getAttribute('index');
        let expanded = -1;
        if(this.state.expanded != index) {
            expanded = index
        }
        this.setState({
            expanded: expanded
        })
    }
    setSort(event) {
        let name = event.target.getAttribute('name');
        let sortBy = {...this.props.sortBy};
        if(name in sortBy) {
            if(sortBy[name] == 'asc') {
                sortBy[name] = 'desc'
            }
            else  {
                sortBy[name] = 'asc'
            }
        }
        else {
            sortBy = {[name]: 'desc'}
        }
        this.props.setOrdersSortBy(sortBy);
    }
    getSortByClasses(){
        let classes = [
            'left', 'sort'
        ]
        let sortBy = this.props.sortBy;
        let sortByParameters = [
            'entity_id',
            'show_date',
            'category_id',
            'city_market_name',
            'grand_total',
            'status'
        ];
        let sortByClasses = {};
        for(let paramIndex in sortByParameters) {
            let param = sortByParameters[paramIndex];
            if(param in sortBy) {
                if(sortBy[param] == 'asc') {
                    sortByClasses = {
                        ...sortBy,
                        [param]: [...classes, 'ascend']
                    }
                }
                else {
                    sortByClasses = {
                        ...sortByClasses,
                        [param]: [...classes, 'descend']
                    }
                }
            }
            else {
                sortByClasses = {
                    ...sortByClasses,
                    [param]: [...classes]
                }
            }
        }
        return sortByClasses;
    }

    render(){
        let {isFetchingOrders} = this.props;
        if(isFetchingOrders) {
            return (
                <div className="loading-white">
                    <i className="icon-loading"></i>
                    <span>Loading Orders</span>
                </div>
            )
        }
        let {orders, totalCount, currentPage, pageSize, totalOrdersAmount, sortBy} = this.props;
        let expanded = this.state.expanded;
        let sortByClasses = this.getSortByClasses();
        console.log('getSortByClasses', sortByClasses);
        if(orders.length == 0) {
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="table-no-count">
                            <i aria-hidden="true">
                                <img src="assets/icon-no-count.svg" alt="No Items Found"/>
                            </i>
                            <h2>No Orders Found</h2>
                            <p className="p-three">Try changing the filter criteria and try again or search the <Link to="/artists">tour roster</Link>.</p>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="responsive-table">
                        <table className="item-details" style={{width: '100%'}}>
                            <thead>
                                <tr>
                                    <th
                                        className={sortByClasses && sortByClasses['entity_id']?sortByClasses['entity_id'].join(' '):''}
                                        name='entity_id' onClick={this.setSort}>
                                        Order #
                                    </th>
                                    <th
                                        className={sortByClasses && sortByClasses['show_date']?sortByClasses['show_date'].join(' '):''}
                                        name='show_date' onClick={this.setSort}>
                                        Tour Date
                                    </th>
                                    <th
                                        className={sortByClasses && sortByClasses['category_id']?sortByClasses['category_id'].join(' '):''}
                                        name='category_id' onClick={this.setSort}>
                                        Tour
                                    </th>
                                    <th className={sortByClasses && sortByClasses['city_market_name']?sortByClasses['city_market_name'].join(' '):''}
                                        name='city_market_name' onClick={this.setSort}>
                                        Market
                                    </th>
                                    <th className={sortByClasses && sortByClasses['grand_total']?sortByClasses['grand_total'].join(' '):''}
                                        name='grand_total' onClick={this.setSort}>
                                        Order Estimate
                                    </th>
                                    <th className={sortByClasses && sortByClasses['status']?sortByClasses['status'].join(' '):''}
                                        name='status' onClick={this.setSort}>
                                        Order Status
                                    </th>
                                    <th className="left">
                                        Options
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {orders && orders.length != 0 && orders.map((item, index) =>
                                <tr key={index}>
                                    <td>
                                        {item.increment_id}
                                    </td>
                                    <td>
                                        <ul>
                                            {item.show_date &&
                                            item.show_date.map((show_date, index) =>
                                                <li key={index}>
                                                    {show_date}
                                                </li>
                                            )
                                            }
                                        </ul>
                                    </td>
                                    <td>
                                        {item.category_name}
                                    </td>
                                    <td>
                                        <ul>
                                            {item.city_market_name &&
                                            item.city_market_name.map((item, index) =>
                                                <li key={index}>
                                                    {item}
                                                </li>
                                            )
                                            }
                                        </ul>
                                    </td>
                                    <td>
                                        {item.grand_total.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',})}
                                    </td>
                                    <td>
                                        {ordersStatuses[item.status]}
                                    </td>
                                    <td>
                                        <dl className="dropdown">
                                            <dt onClick={this.expandOptions}
                                            index={index}
                                                className={expanded==index?'active':''}
                                            >Select</dt>
                                            {expanded==index && <dd className={expanded==index?'active':''}>
                                                <ul >
                                                    <li>
                                                        <Link to={{
                                                            pathname: `/orderDetails/${item.order}`,
                                                        }}>
                                                            View Order
                                                        </Link>
                                                    </li>
                                                    {this.canAddShipping(item.items) &&
                                                    <li>
                                                        <Link to={{
                                                            pathname: `/order/addShipping/${item.order}`,
                                                        }}>
                                                            Add Shipping
                                                        </Link>
                                                    </li>}
                                                    {this.hasReviseOption(item.items) &&
                                                    <li>
                                                        <Link to={{
                                                            pathname: `/order/reviseOrder/${item.order}`,
                                                        }}>
                                                           Revise Order
                                                        </Link>
                                                    </li>}
                                                </ul>
                                            </dd>}
                                        </dl>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>


                <OrderPaginator
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    totalOrdersAmount={totalOrdersAmount}
                />

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList)
