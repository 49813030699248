import {
    GET_COUNTRIES_FAILURE,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_REQUEST,
} from "./actions";

export default (state = {
    countries: null,
    isFetchingCountries: false,
    fetchingCountriesError: null
}, action) => {
    switch (action.type) {
        case GET_COUNTRIES_REQUEST:
            return {
                ...state,
                isFetching: true,
                countries: null
            }
        case  GET_COUNTRIES_FAILURE:
            return {
                ...state,
                isFetching: false,
                countries: null,
                fetchingCountriesError: action.error
            }
        case GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                countries: action.data
            };
        default:
            return state;
    }
}