import {custom_options as common_options} from "../AlbumCommonOptions/definition"
import {fields as common_fields} from "../AlbumCommonOptions/definition"


export const custom_options = {
    ...common_options,
    on_sale_date_time_text: 'on_sale_date_time_text',
    video_tags: {
        code: 'video_tags',
        sku: {
            apple: 'apple',
            itunes: 'itunes',
            amazon: 'amazon',
            youtube: 'youtube',
            available_now: 'available_now',
            other: 'other_video_tag'
        }
    },
    ad_type: {
        code: 'ad_type',
        sku: {
            ol_album_ad: 'ol_album_ad',
            hd_tv_album_ad: 'hd_tv_album_ad',
            lyric_video: 'lyric_video',
            sizzle_hype_reel: 'sizzle_hype_reel',
        }
    },
    ol_size_options: {
        code: 'ol_size_options',
        sku: {
            size_16x9: 'size_16x9',
            size_1x1: 'size_1x1',
            size_9x16: 'size_9x16',
        }
    }
};

export const fields = {
    ...common_fields,
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.video_tags.code]: '',
    [custom_options.ad_type.code]: '',
    [custom_options.ol_size_options.code]: null

}
