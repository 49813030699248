import {
    ORDER_REQUEST,
    ORDER_FAILURE,
    ORDER_SUCCESS
} from "./actions";

export default ( state = {
    orderDetails: null,
    isFetchingOrderDetails: false
}, action) => {
    switch (action.type) {
        case ORDER_REQUEST:
            return {
                ...state,
                isFetchingOrderDetails: true,
            }
        case ORDER_SUCCESS:
            return {
                ...state,
                isFetchingOrderDetails: false,
                orderDetails: action.data
            }
        case ORDER_FAILURE:
            return {
                ...state,
                isFetchingOrderDetails: false,
                error: action.error
            }

        default:
            return state;

    }

}