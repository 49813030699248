import React from "react"
import {connect} from "react-redux"
import {setProductsToCart, setSelectedProducts} from "../../components/OrderFormStep1ProductList/actions";
import OrderFormStep3 from "../OrderFormStep3";
import {setCategoryInfo, setProductsCustomOptions, setShippingProducts} from "../OrderFormStep1/actions";
import {getReviseOrderAttempt} from "./actions";
import NotFound from "../NotFound";
import LoadingWhite from "../../LoadingWhite";
import {getCategoryInfoFromItemOptions, getCategoryInfoFromOrder} from "../../helpers";
import {FIELDS_CODE} from "../../actions/Constants";
import {fields, custom_options} from "../../components/Product/REVISION/definition"

const mapStateToProps = state => ({
    ...state.customerInformation,
    ...state.cart,
    ...state.orderProcess,
    ...state.reviseOrder,
})

const mapDispatchToProps = {
    setSelectedProducts,
    setShippingProducts,
    getReviseOrderAttempt,
    setCategoryInfo,
    setProductsToCart,
    setProductsCustomOptions
}
class OrderRevise extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedProducts: null,
            categoryInfo: null,
            shippingProducts: null
        }
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        let {orderId} = this.props.match.params;
        if(orderId) {
            this.props.getReviseOrderAttempt(orderId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.orderId != this.props.match.params.orderId) {
            this.props.getReviseOrderAttempt(this.props.match.params.orderId);
        }
        if(prevProps.reviseOrderDetails != this.props.reviseOrderDetails) {
            let order = this.props.reviseOrderDetails['orderData'];
            let originalProduct = this.getOriginalProduct();
            let revisionProduct = this.props.reviseOrderDetails['productData'];
            let selectedProducts = {};
            if(revisionProduct) {
                selectedProducts ={
                    [revisionProduct.id]: revisionProduct
                }
            }
            let categoryInfo = getCategoryInfoFromOrder(order)
            let group_item = this.getGroupItem(order.items[0]);
            this.setState({
                categoryInfo: categoryInfo,
                selectedProducts: selectedProducts
            })
            this.props.setSelectedProducts(selectedProducts);
            this.props.setCategoryInfo(categoryInfo);
            let venueCapacity = this.getVenueCapacitySelected(order);
            let customOptions = {...fields};
            let sku = originalProduct.sku;
            sku = sku.replace(/ /g, "_");
            sku = sku.toLowerCase();
            customOptions[custom_options.revision_package.code] = sku + '_' + venueCapacity;
            customOptions[custom_options.revision_product] = originalProduct.name;
            this.props.setProductsCustomOptions({
                [revisionProduct.sku]: {
                    group_item: group_item,
                    ...customOptions
            }
            });
        }
        if(this.state.selectedProducts != prevState.selectedProducts) {
            this.setProductsToCart()
        }

    }
    getVenueCapacitySelected(order) {
        let orderItems = order.items;
        for (let itemIndex in orderItems) {
            let item = orderItems[itemIndex];
            if(item.extension_attributes && item.extension_attributes.product_type != 'shipping') {
                let additionalData = item.additional_data;
                if(additionalData) {
                    additionalData = JSON.parse(additionalData);
                    let shows = additionalData['showInformation'];
                    for(let showIndex in shows) {
                        let show = shows[showIndex];
                        if(FIELDS_CODE.venue_capacity.code in show) {
                            return show[FIELDS_CODE.venue_capacity.code]
                        }
                    }

                }
            }
        }
        return '';
    }
    getOriginalProduct() {
        let mainItem = this.getMainItem();
        console.log('mainItem', mainItem);
        if(mainItem && mainItem.extension_attributes) {
            return mainItem.extension_attributes.original_product;
        }
        return undefined;
    }
    getMainItem() {
        let reviseOrderDetails = this.props.reviseOrderDetails;
        let order = reviseOrderDetails['orderData']
        if(order) {
            let orderItems = order.items;
            for (let itemIndex in orderItems) {
                let orderItem = orderItems[itemIndex];
                if(orderItem.extension_attributes.product_type != 'shipping') {
                    return orderItem
                }
            }
        }
        return undefined;
    }
    getOrderFromReviseOrderDetails () {
        let reviseOrderDetails = this.props.reviseOrderDetails;
        if(reviseOrderDetails) {
            return reviseOrderDetails['orderData']
        }
        return undefined;
    }
    setProductsToCart()
    {
        let {selectedProducts, cart} = this.props;
        if(cart) {
            let productsToCart = [];
            let order = this.getOrderFromReviseOrderDetails()
            let group_item = undefined;
            if(order) {
                group_item = this.getGroupItem(order.items[0]);
            }
            Object.keys(selectedProducts).map((index) =>{
                let selectedProduct = selectedProducts[index];
                let product = {
                    sku: selectedProduct.sku,
                    qty: 1,
                    name: selectedProduct.name,
                    price: selectedProduct.price,
                    product_type: selectedProduct.type_id,
                    quote_id: cart.id,
                    group_item: group_item
                };
                productsToCart.push(product)
            })
            this.props.setProductsToCart(productsToCart);
            this.setState({
                productsToCart: productsToCart,
            })
        }
    }

    getGroupItem(item) {
        let additionalData = item.additional_data;
        if(additionalData) {
            additionalData = JSON.parse(additionalData);
            if(additionalData && 'groupItem' in additionalData) {
                return additionalData['groupItem']
            }
        }
        return  null

    }
    goBack() {
        this.props.history.goBack();
    }
    render(){
        let {selectedProducts, categoryInfo} = {...this.state};
        let {isFetchingReviseOrderDetails} = {...this.props};
        console.log('selectedProducts', selectedProducts);
        console.log('customerInfo', categoryInfo);
        let itemsToDelete = [];
        let {orderId} = this.props.match.params;

        if(!orderId) {
            return (
                <NotFound/>
            )
        }
        if(isFetchingReviseOrderDetails) {
            return (
                <main id="main" className="black" role="main"
                      // style={{backgroundImage: `url(${categoryInfo.category_background_image})`, backgroundSize: "auto", backgroundRepeat: "no-repeat", backgroundColor: "black"}}
                >
                    <div id="content" className="container fullscreen-checkout">

                    <LoadingWhite/>
                    </div>
                </main>
            )
        }
        return (
            <section-add-shipping>
               {categoryInfo &&
               <OrderFormStep3
                    categoryInfo={categoryInfo}
                    goBack={this.goBack}
                />}

            </section-add-shipping>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRevise)