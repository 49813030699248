import React from 'react'
import {RecentOrdersList} from "../../components/RecentOrdersList/index";
import {connect} from "react-redux"
import {ordersListFetch} from "../Orders/actions";
import {RECENT_ORDERS_AMOUNT} from "../../actions/Constants";
import {Link} from "react-router-dom";

const mapStateToProps = state => ({
    ...state.ordersList
});

const mapDispatchToProps = {
    ordersListFetch
};

class RecentOrders extends React.Component {

    componentDidMount() {
        let customerInfo = this.props.customerInfo;
        if(customerInfo)
        {
            console.log("CustomerInfo", customerInfo);
            this.props.ordersListFetch(customerInfo.id, 1, RECENT_ORDERS_AMOUNT);
        }
    }

    render() {
        let {isFetchingOrders} = this.props;
        return (
            <section className="row section-account">
                <div className="col-xs-12">
                    <header className="mb-20">
                        <div className="row">
                            <div className="col-sm-9">
                                <h3 className="h-four">Recent Orders</h3>
                            </div>
                            {!isFetchingOrders && (this.props.orders && this.props.orders.length > 0) &&
                            <div className="col-sm-3">
                                <Link to="/orders" className="button xsmall blue">View All Orders</Link>
                            </div>
                            }
                        </div>
                    </header>
                    {isFetchingOrders &&
                        <div className="loading-white">
                            <i className="icon-loading"></i>
                            <span>Finding Recent Orders</span>
                        </div>
                    }
                    {!isFetchingOrders && (!this.props.orders || this.props.orders.length == 0) &&
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="table-no-count">
                                    <i aria-hidden="true">
                                        <img src="assets/icon-no-count.svg" alt="No Items Found"/>
                                    </i>
                                    <h2>No Orders Found</h2>
                                    <p className="p-three">You can order promo materials from the <a href="/artists">tour roster</a> or by using the search feature above.</p></div>
                            </div>
                        </div>
                    }
                    {!isFetchingOrders && (this.props.orders && this.props.orders.length > 0) &&
                        <RecentOrdersList orders={this.props.orders}/>
                    }
                </div>
            </section>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(RecentOrders);
