import {
    EDIT_ADDRESS_REQUEST,
    EDIT_ADDRESS_SUCCESS,
    EDIT_ADDRESS_FAILURE, CLEAN_EDIT_ADDRESS,
} from "./actions";



export default (state = {
    countries: null,
    isFetchingCountries: false,
    updateAddressError: null,
    editAddressSuccess: false,
    isUpdatingAddress: false
}, action) => {
    switch (action.type) {
        case EDIT_ADDRESS_FAILURE:
            return {
                ...state,
                updateAddressError: action.error,
                isUpdatingAddress: false
            }
        case EDIT_ADDRESS_SUCCESS:
            return {
                ...state,
                editAddressSuccess: true,
                isUpdatingAddress: false
            }
        case EDIT_ADDRESS_REQUEST:
            return {
                ...state,
                isUpdatingAddress: true
            }
        case CLEAN_EDIT_ADDRESS:
            return {
                ...state,
                editAddressSuccess: false,
                error: null
            }
        default:
            return state;
    }
}