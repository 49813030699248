import React from "react"
import {connect} from "react-redux"
import {setOrdersFilter} from "../../containers/Orders/actions";
import DayPicker, {DateUtils} from "react-day-picker";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {FIELDS_CODE, ordersStatuses} from "../../actions/Constants"

const mapStateToProps = state => ({
    ...state.ordersList
})

const mapDispatchToProps = {
    setOrdersFilter,
}
class DayPickerComponent extends React.Component{
    constructor(props) {
        super(props);
        this.dayPickerStyles = {
            zIndex: 1000,
            backgroundColor: 'white',
            width: 'auto'
        };
        this.state = {
            selectedDay: undefined,
            selectedDayError: false
        };
        this.handleDayClick = this.handleDayClick.bind(this);
    }
    handleDayClick(day, {selected}){
        console.log('selected', selected);
        let state = {...this.state};
        let selectedDay = state.selectedDay;
        if (selected) {
            selectedDay = undefined;
        } else {
            selectedDay = day
        }
        if(DateUtils.isDate(selectedDay)) {
            this.setState({
                selectedDay: selectedDay,
            });
            if(this.props.handleDayClick != undefined) {
                this.props.handleDayClick(this.props.feedBackInfo, this.convertDate(selectedDay))
                this.setState({
                    selectedDayError: false
                })
            }
        }
        else{
            this.setState({
                selectedDayError: true
            })
        }
    }
    convertDate(dateStr) {
        let dateToReturn = '';
        if(DateUtils.isDate(dateStr)) {
            let date = new Date(dateStr);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            dateToReturn = `${month}/${day}/${year}`;
        }
        return dateToReturn;
    }
    render() {
        let {selectedDay, selectedDayError} = this.state;
        let {label} = this.props;
        let FORMAT = 'MM/dd/yyyy';
        return (
            <day-picker-component>
                <DayPickerInput
                    readOnly={true}
                    placeholder={label}
                    format={FORMAT}
                    value={selectedDay}
                    onDayChange={this.handleDayClick}
                    dayPickerProps={{
                        selectedDays: selectedDay,
                    }}
                />
                {selectedDayError && <label className={'error'}>No valid Date.</label>}
            </day-picker-component>
        )
    }
}

class OrdersFilter extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            filterText: '',
            start_date: '',
            end_date: '',
            statusFilter: '',
            tourFilter: '',
            toActive: ''
        }
        this.timeOut = 0;
        this.handleChange = this.handleChange.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.filterOrders = this.filterOrders.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.filterText != this.state.filterText
        || prevState.start_date != this.state.start_date
        || prevState.end_date != this.state.end_date
        || prevState.statusFilter != this.state.statusFilter
        || prevState.tourFilter != this.state.tourFilter
        ) {
            this.filterOrders()
        }
    }
    filterOrders()
    {
        if(this.timeOut){
            clearTimeout(this.timeOut)
        }
        this.timeOut = setTimeout(() => {
            let data = {}
            let filterText = this.state.filterText;
            if(filterText && filterText != '')
            {
                data['filterText'] = filterText;
            }
            let start_date = this.state.start_date;
            let end_date = this.state.end_date;
            if(start_date && start_date != '') {
                data[FIELDS_CODE.show_date] = {value: start_date, condition: 'lt'}
                if(end_date && end_date != '') {
                    data[FIELDS_CODE.show_date]['value'] = [start_date, end_date];
                    data[FIELDS_CODE.show_date]['condition'] = 'bt';
                }
            }
            else {
                if(end_date && end_date != '') {
                    data[FIELDS_CODE.show_date] = {value: end_date, condition: 'gt'}
                }
            }
            let statusFilter = this.state.statusFilter;
            if(statusFilter && statusFilter != '') {
                data['status'] = {value: statusFilter, condition: 'eq'}
            }
            let tourFilter = this.state.tourFilter;
            if(tourFilter && tourFilter != '' && tourFilter != 'all') {
                data[FIELDS_CODE.category_id] = {value: tourFilter, condition: 'eq'}
            }
            console.log('filter data', data);
            // if(Object.keys(data).length != 0){
            this.props.setOrdersFilter(data);
            // }
        }, 500);
    }
    handleChange(event){
        event.preventDefault();
        let {name, value} = event.target;
        console.log('name', name, value);
        this.setState({[name]: value});

    }
    handleDayClick(info, selectedDay) {
        let state = {...this.state};
        state[info.code] = selectedDay;
        this.setState({...state});
    }

    clearFilter() {
        let state = {...this.state};
        state['filterText'] = '';
        console.log(state)
        this.setState({...state});
    }
    render(){
        let {filterText, statusFilter, tourFilter, toActive} = {...this.state};
        let allTours = [];
        if(this.props.allTours) {
            allTours = this.props.allTours;
        }
        return (
            <div className="filter-details">
                <div className="row">
                    <div className="filter-options col-lg-7 col-md-12">
                        <DayPickerComponent
                            label={'Start Date'}
                            feedBackInfo={{
                                code: 'start_date'
                            }}
                            handleDayClick={this.handleDayClick}
                        />
                        <DayPickerComponent
                            label={'End Date'}
                            feedBackInfo={{
                                code: 'end_date'
                            }}
                            handleDayClick={this.handleDayClick}
                        />
                        <select
                            name={'statusFilter'}
                            value={statusFilter}
                            onChange={this.handleChange}
                        >
                            <option value='all'>Status</option>
                            {Object.keys(ordersStatuses).map((index) => {
                                let status = ordersStatuses[index]
                                return <option key={index} value={index}>{status}</option>
                            })}
                        </select>
                        <select
                            name="tourFilter"
                            value={tourFilter}
                            onChange={this.handleChange}
                        >
                            <option value="all">Tours</option>
                            {allTours.map((tour, index) =>
                                <option key={index} value={tour.id}>{tour.name}</option>
                            )}
                        </select>
                    </div>
                    <div className="filter-search col-lg-4 col-lg-offset-1 col-md-12">
                        <div className="filter-search-inner">
                            <input placeholder="Search Orders" value={filterText} name="filterText" onChange={this.handleChange}/>
                            <button type={"button"} onClick={this.clearFilter}>
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(OrdersFilter)
