import React from "react"
import {connect} from "react-redux"
import Login from "../Login";
import {CreateAccount} from "../CreateAccount";
import AccountResetPassword from "../../components/AccountResetPassword";
import {withRouter} from "react-router-dom";
import {CDN_URL} from "../../actions/Constants";

const mapStateToProps = state => ({
    ...state.auth
})


class Account extends React.Component{

    constructor(props) {
        super(props);
        this.goToHome = this.goToHome.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0,0)
        if(this.props.isAuthenticated) {
            this.props.history.push('/');
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params != prevProps.match.params) {
            window.scrollTo(0,0)
        }
    }

    goToHome()
    {
        this.props.history.push("/");
    }
    render(){
        console.log(this.props);
        let params = this.props.match.params;
        return (
            <main role="main" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-two.jpg')`, backgroundSize: 'cover'}}>
                <div id="modal-account" className="container fullscreen-modal">
                    {params.action === 'login' &&<Login/>}
                    {params.action === 'createAccount' &&<CreateAccount/>}
                    {params.action === 'resetPassword' &&<AccountResetPassword/>}
                </div>
                <span id="close-modal-account" className="close-modal" onClick={this.goToHome}
                      style={{display: "block"}}>Close Account Window</span>
            </main>
        )
    }
}

export default withRouter(connect(mapStateToProps, null)(Account))
