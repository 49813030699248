import {request} from "../../axiosRequest";

export const CATEGORY_MEDIA_REQUEST = 'CATEGORY_MEDIA_REQUEST';
export const CATEGORY_MEDIA_SUCCESS = 'CATEGORY_MEDIA_SUCCESS';
export const CATEGORY_MEDIA_FAILURE = 'CATEGORY_MEDIA_FAILURE';
export const CATEGORY_NOT_FOUND_ERROR = 'CATEGORY_NOT_FOUND_ERROR';

export const getCategoryMediaSuccess = (data) =>({
    type: CATEGORY_MEDIA_SUCCESS,
    data: data
})

export const getCategoryMediaRequest = () =>({
    type: CATEGORY_MEDIA_REQUEST,
})

export const getCategoryMediaFailure = (error) =>({
    type: CATEGORY_MEDIA_FAILURE,
    error: error
})
export const getCategoryNotFoundError = (error) =>({
    type: CATEGORY_NOT_FOUND_ERROR,
    error: error
})


export const getCategoryMedia = (categoryId) => {
    return (dispatch) => {
        dispatch(getCategoryMediaRequest());
        request.get(`categories/media/${categoryId}`, true)
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result['data'];
                if(data['success'] && data['code'] === 200) {
                    dispatch(getCategoryMediaSuccess(data.data));
                }
                else {
                    if(data.code === 404){
                        dispatch(getCategoryNotFoundError(data.data));
                        return;
                    }
                    dispatch(getCategoryMediaFailure(data.data));
                }
            })
            .catch(error => {

                if(error && ((error.response && error.response.status === 404))
                  || error.code === 404
                ) {
                    dispatch(getCategoryNotFoundError(error));
                    return;
                }
                dispatch(getCategoryMediaFailure(error));
                }
            )

    }
};
