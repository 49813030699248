import React from "react"
import {connect} from "react-redux"
import {_getAvailableRegions} from "../../helpers";
import triggerBrowserReflow from "react-bootstrap/cjs/triggerBrowserReflow";
import {Link} from "react-router-dom";

class CheckoutBillingAddress extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            availableRegions: null,
            errors: {},
            notRequired: ['fax', 'apt_number'],
            requiredFields: ["firstname", "lastname", "company", "email",
                "street", "city", "region_id", "postcode", "telephone", "country_id"]
        };
        this.validate = this.validate.bind(this)
    }

    componentDidMount() {
        this.validate();
    }

    validate() {
        let billingAddress = this.props.billingAddress;
        let notRequired = this.state.notRequired;
        let required = this.state.requiredFields;
        let errors = {};
        for(let addressFieldIndex in required) {
            console.log('addressField', addressFieldIndex);
            let addressField = required[addressFieldIndex];
            let field = document.getElementsByName(addressField);
            if(!(addressField in billingAddress) ||
                (billingAddress[addressField] === '' || !billingAddress[addressField])) {
                if(addressField === 'region_id' && billingAddress.country_id !== 'US' && billingAddress[addressField] === 0) {
                    continue;
                }
                errors[addressField] = "This field can't be blank.";
                for(let i = 0; i < field.length; i++ ) {
                    let fieldValue = field[i];
                    fieldValue.classList.add('error')
                }
            }
            else {
                delete errors[addressField];
                for(let i = 0; i < field.length; i++ ) {
                    let fieldValue = field[i];
                    fieldValue.classList.remove('error')
                }
            }
        }
        this.setState({errors: errors});
        this.props.setBillingErrors(errors);
    }
    getBillingAddress(){
        let {customerInfo} = this.props;
        let billingAddress = {};
        let street = '';
        let apt_number = '';
        let customerAddresses = customerInfo.addresses;
        let customerAddress = {};

        customerAddresses.map((address) => {
            if(address.id == customerInfo.default_billing){
                customerAddress = address;
            }
        });
        if(customerAddress.street){
            if(customerAddress.street.length == 0) {
                street = '';
            }
            if(customerAddress.street.length == 2) {
                street = customerAddress.street[0];
                apt_number = customerAddress.street[1]
            }
            if(customerAddress.street.length == 1) {
                street = customerAddress.street[0];
            }
        }
        billingAddress = {
            firstname: customerAddress.firstname?customerAddress.firstname:'',
            lastname: customerAddress.lastname? customerAddress.lastname:"",
            region: customerAddress.region?customerAddress.region.region:{},
            region_id: customerAddress.region_id?customerAddress.region_id:'',
            region_code: customerAddress.region?customerAddress.region.region_code: '',
            street: street,
            apt_number: apt_number,
            postcode: customerAddress.postcode?customerAddress.postcode:'',
            city: customerAddress.city?customerAddress.city: "",
            country_id: customerAddress.country_id?customerAddress.country_id: "",
            telephone: customerAddress.telephone?customerAddress.telephone:"",
            fax: customerAddress.fax? customerAddress.fax: '',
            email: customerInfo.email,
            company: customerAddress.company ? customerAddress.company: '',
        };
        let availableRegions = [];
        if(this.props.countries) {
            availableRegions = _getAvailableRegions(billingAddress.country_id, this.props.countries);
        }
        this.setState({
            billingAddress: billingAddress,
            availableRegions: availableRegions
        }, () => {this.validate()});
    }

    render(){
        if(this.state.billingAddress)
        console.log(this.state.billingAddress, this.state.billingAddress.country_id)
        let {firstname, lastname, company, email, street,
            region_code, city, country_id, postcode, telephone, fax,
            apt_number, region} = this.props.billingAddress;
        let availableRegions = this.state.availableRegions;
        let errors = this.state.errors;
        let {countries, isValid, placeOrderError} = this.props;
        return (
            <div-billingaddress>
                {!isValid && Object.keys(errors).length > 0 && <div className="alert error">
                    <p>Your billing information is not completed yet. Please contact to TDC</p>
                </div>}
                {!isValid && Object.keys(errors).length === 0 && <div className="alert error">
                    <p>Please complete the fields outlined in red below before proceeding.</p>
                </div>}
                <div className="alert error" style={{display: placeOrderError==null?'none':'block'}}>
                    <p>{placeOrderError}</p>
                    <p>If you continue to receive this error, please  <Link to={'contact-us'}>contact us</Link></p>
                </div>
                <h2 className="billing-information h-five">Billing Information</h2>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label className="required">First Name</label>
                        <input type="text"
                               name="firstname"
                               value={firstname}
                               readOnly={true}
                        />
                    </div>
                    <div className="form-group col-sm-6">
                        <label className="required">Last Name</label>
                        <input type="text"
                               name="lastname"
                               value={lastname}
                               readOnly={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label className="required">Company Name</label>
                        <input type="text"
                               name="company"
                               value={company}
                               readOnly={true}
                        />
                    </div>
                    <div className="form-group col-sm-6">
                        <label className="required">Email Address</label>
                        <input type="text"
                               name="email"
                               value={email}
                               readOnly={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-9 col-md-8 col-sm-8">
                        <label className="required">Street Address</label>
                        <input type="text"
                               name="street"
                               value={street}
                               readOnly={true}
                        />
                    </div>
                    <div className="form-group col-lg-3 col-md-4 col-sm-4">
                        <label>Apartment, Suite, etc.</label>
                        <input type="text"
                               name="apt_number"
                               value={apt_number}
                               readOnly={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label className="required">City</label>
                        <input type="text"
                               name="city"
                               value={city}
                               readOnly={true}
                        />
                    </div>
                    <div className="form-group col-sm-6">
                        <label className="required">State / Province</label>
                        <select
                            name="region"
                            value={region.code}
                        >
                            <option
                                key={region.id}
                                value={region.code}
                                region-id={region.id}
                                region-name={region.name}
                            >
                                {region.region}
                            </option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label className="required">Zip / Postal Code</label>
                        <input type="text"
                               name="postcode"
                               value={postcode}
                               readOnly={true}
                        />
                    </div>
                    <div className="form-group col-sm-6">
                        <label className="required">Country</label>
                        <select
                            name="country_id"
                        >
                            {countries &&
                            countries.map((country) => {
                                if(country.id === country_id) {
                                    return <option value={country.id} key={country.id}>
                                        {country.full_name_english}
                                    </option>
                                }
                            })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label className="required">Phone</label>
                        <input type="text"
                               name="telephone"
                               value={telephone}
                               readOnly={true}
                        />
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Fax</label>
                        <input type="text"
                               name="fax"
                               value={fax}
                               readOnly={true}
                        />
                    </div>
                </div>

            </div-billingaddress>
        )
    }
}

export default connect(null, null)(CheckoutBillingAddress)
