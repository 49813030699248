export const custom_options = {
    art_product_config_id: "art_product_config_id",
    art_product_config_description: "art_product_config_description"

};

export const fields = {
    [custom_options.art_product_config_description]: '',
    [custom_options.art_product_config_id]: '',
}
