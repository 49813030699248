import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";
import {FishEye} from 'react-fisheye';
import {categoriesToDisplay} from "./actions";
import {CATEGORY_IMAGES_SERVICES, CDN_URL, HOME_TOUR_DEMO_DEMO, HOME_TOUR_DEMO_IMAGE} from "../../actions/Constants";
import ToursModal from "../../containers/ToursModal"
import HolidayMessage from "../HolidayMessage";

const mapStateToProps = state =>({
    ...state.auth,
    ...state.categoriesHome
});

const mapDispatchToProps = {
    categoriesToDisplay,
};

class Home extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            slideList: [],
            itemSelected: '',
            showModal: false,
            hideFishEye: false,
            divExploreClasses: []
        }
        this.fillSlideList = this.fillSlideList.bind(this);
        this.closeDemosModal = this.closeDemosModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.fishEyeEnter = this.fishEyeEnter.bind(this);
        this.fishEyeLeave = this.fishEyeLeave.bind(this);
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        if(this.props.isAuthenticated) {
            this.props.history.push('/dashboard');
        }
        if(!this.props.homeDemos) {
            this.props.categoriesToDisplay();
        }
        else {
            this.setState({
                slideList: this.fillSlideList()
            })
        }
        document.body.style.background = '#000000';
    }
    componentWillUnmount() {
        document.body.style.background = null;
    }

    resize() {
        let hideFishEye = (window.innerWidth <= 768);
        if (hideFishEye !== this.state.hideFishEye) {
            this.setState({hideFishEye: hideFishEye});
        }
    }
    fillSlideList()
    {
        let homeDemos = this.props.homeDemos;
        console.log('categories Testing', homeDemos)
        let toReturn = homeDemos.slice(0, 10).map((demo, index) => {
            let name = demo.tour_name;
            let id = demo.id;
            let fileName = demo.tour_demo;
            let artistName = demo.artist_name;
            let imageUrl = demo.tour_demo?HOME_TOUR_DEMO_IMAGE + demo.tour_image:'';
            let demoFileUrl = demo.tour_demo?HOME_TOUR_DEMO_DEMO + demo.tour_demo:'';

            return {
                name: name,
                id: id,
                fileName: fileName,
                url: imageUrl,
                artistName: artistName,
                artistImage: imageUrl,
                demoFileUrl: demoFileUrl,
                key: id
            }
        })
        return toReturn;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.isAuthenticated != this.props.isAuthenticated) {
            if(this.props.isAuthenticated) {
                this.props.history.push('/dashboard');
            }
        }
        if(prevProps.homeDemos !== this.props.homeDemos) {
            this.setState({
                slideList: this.fillSlideList()
            })
        }
    }
    closeDemosModal() {
        this.setState({
            showModal: false
        })
    }
    openModal(event) {
        event.preventDefault();
        let index = event.currentTarget.getAttribute('index');
        this.setState({
            showModal: true,
            itemSelected: index
        })
    }
    fishEyeEnter() {
        let divExploreClasses = [...this.state.divExploreClasses]
        if(divExploreClasses.indexOf('active') == -1) {
            divExploreClasses.push('active');
            this.setState({
                divExploreClasses: divExploreClasses
            })
        }
    }
    fishEyeLeave() {
        let divExploreClasses = [...this.state.divExploreClasses]
        if(divExploreClasses.indexOf('active') != -1){
            divExploreClasses.splice(divExploreClasses.indexOf('active'), 1);
            this.setState({
                divExploreClasses: divExploreClasses
            })
        }
    }
    render(){
        let {isFetchingHomeDemos} = this.props;
        let {slideList, itemSelected, showModal, hideFishEye, divExploreClasses} = this.state;
        return (
            <main id="fullscreen-video-wrapper" role="main">
                <div id="fullscreen-video" className="fullscreen-responsive">
                    <div className="container">
                        <div id="hero-content" className="row">
                            <div className="col-xs-12">
                                <div className="center">
                                    <HolidayMessage/>
                                    <h1 className="h-one white">Engage Your Audience</h1>
                                    <p className="p-one">Have something to promote? We can help!</p>
                                    <Link to={"/tours"} className="button large blue-white take-tour-button">Take the Tour</Link>
                                </div>
                            </div>
                        </div>
                        <video id="hero-video" poster={`${CDN_URL}assets/backgrounds/tdc-hero-video.jpg`} autoPlay playsInline muted loop>
                            <source src={`${CDN_URL}assets/backgrounds/tdc-hero-video.mp4`} type="video/mp4" />
                        </video>
                        <div id="hero-video-overlay"></div>
                    </div>
                </div>
                <div id="carousel">
                    {!isFetchingHomeDemos && <div className={'explore ' + divExploreClasses.join(' ')}>
                        <h2>Explore</h2>
                        <p>View some of our recent work.</p>
                    </div>}
                    {isFetchingHomeDemos &&
                        <div className="loading-carousel">
                            <i className="icon-loading"></i>
                        </div>
                    }
                    <div className={divExploreClasses.join(' ')}
                         onMouseEnter={this.fishEyeEnter}
                         onMouseLeave={this.fishEyeLeave}
                    >
                    {!isFetchingHomeDemos && !hideFishEye && <FishEye
                        multiple={0}
                        data={slideList.slice(0,10)}
                        mode="horizontal"
                        render={(item, index) => {
                            return <div
                                key={item.id}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                                onClick={this.openModal} index={index}
                            >
                                <Link to="#" onClick={this.openModal} index={index}>
                                    <img
                                        src={item.url}
                                        alt={item.name}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            display: 'block'
                                        }}
                                    />
                                </Link>
                            </div>
                                ;
                        }}
                    />}
                    </div>
                    {!isFetchingHomeDemos && hideFishEye &&
                    <ul id="static-carousel">
                        {slideList.slice(0,10).map((item, index) => {
                        return <li key={index}>
                            <Link  to="#" onClick={this.openModal} index={index}>
                                <img
                                    src={item.url}
                                    alt={item.name}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        display: 'block'
                                    }}
                                />
                            </Link>
                        </li>
                    })}
                    </ul>
                    }
                </div>
                {showModal && <ToursModal
                    type={'home_demo'}
                    categories={this.props.homeDemos.slice(0,10)}
                    closeDemosModal={this.closeDemosModal}
                    itemSelected={itemSelected}
                />}
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
