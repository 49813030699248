import React from "react"
import {connect} from "react-redux"
import ShoppingCartItem from "../ShoppingCartItem";

class ShoppingCartListItems extends React.Component{
    constructor(props) {
        super(props);
        this.getShippingRelated = this.getShippingRelated.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.customerInfo != this.props.customerInfo && this.props.customerInfo)
        {
            this.props.shoppingCartAttempt(this.props.customerInfo.id)
        }
    }
    getShippingRelated(group_item, cartItem) {
        let shippingRelated = [];
        let cartItems = this.props.cart.items;
        if(cartItem.extension_attributes.product_type == 'revision_product') {
            return []
        }
        if(cartItem.extension_attributes.product_type == 'shipping') {
            return [cartItem]
        }
        shippingRelated = cartItems.map((item) => {
            console.log('getShippingRelated', cartItems)
                if(item.extension_attributes.group_item == group_item
                && item.extension_attributes.product_type == 'shipping'
                &&  item.item_id != cartItem.item_id
                ) {
                    return item
                }
            }
        )
        shippingRelated = shippingRelated.filter((item) =>{
            if(item)
            {
                return item;
            }
        })
        return shippingRelated;
    }
    existsMainProductForShippingItem(item) {
        let cartItems = this.props.cart.items;
        if(item && item.extension_attributes.product_type == 'shipping') {
            for(let index in cartItems) {
                let cartItem = cartItems[index];
                if (
                    cartItem.extension_attributes.group_item == item.extension_attributes.group_item
                    && cartItem.extension_attributes.product_type != 'shipping'
                    && cartItem.extension_attributes.product_type != 'revision_product'
                ) {
                    return true;
                }
            }
            return false
        }
        return true;

    }
    getCutCartItems(group_item, cartItem) {
        let cartItems = this.props.cart.items;
        if(cartItem.extension_attributes.product_type == 'revision_product') {
            return []
        }
        let cutRelates = cartItems.map((item) => {
                console.log('getShippingRelated', cartItems)
                if(item.extension_attributes.group_item == group_item
                    && item.extension_attributes.product_type == 'cut'
                    &&  item.item_id != cartItem.item_id
                ) {
                    return item
                }
            }
        )
        cutRelates = cutRelates.filter((item) =>{
            if(item)
            {
                return item;
            }
        })
        return cutRelates;
    }
    render(){
        console.log("customer cart list")
        console.log(this.props.cart);
        let {cart, customerInfo} = this.props;
        if(!(cart && customerInfo)) {
            return (
                <div>
                    Loading...
                </div>
            )
        }
        else {
            return (
                <ul id="cart-items-list">
                    {cart.items.map((item, index) =>{
                        let shippingRelated = this.getShippingRelated(item.extension_attributes.group_item, item);
                        if((item.extension_attributes.product_type !== 'shipping' && item.extension_attributes.product_type !=='cut') || !this.existsMainProductForShippingItem(item)) {
                            return <ShoppingCartItem
                                product={item}
                                key={item.item_id }
                                totals={cart.totals}
                                customerId={customerInfo.id}
                                cartItems={cart.items}
                                cartId={cart.id}
                                shippingRelated={shippingRelated}
                                cutProductsRelated={this.getCutCartItems(item.extension_attributes.group_item, item)}
                            />
                        }
                    }
                    )}
                    <br/>
                </ul>
            )
        }
    }
}

export default connect(null, null)(ShoppingCartListItems)