import React from "react"
import {connect} from "react-redux"
import "./modal_styles.css"
import {Link} from "react-router-dom";

class ModalConfirmation extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            hidden: this.props.hidden != undefined?this.props.hidden:true
        }
        this.confirmAction = this.confirmAction.bind(this);
        this.cancelAction = this.cancelAction.bind(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('prevProps.hidden', prevProps.hidden)
        console.log('this.props.hidden', this.props.hidden)

        if(prevProps.hidden != this.props.hidden || this.props.hidden != this.state.hidden) {
            this.setState({
                hidden: this.props.hidden
            })
        }
    }

    confirmAction() {
        this.setState({
            hidden: true
        });
        if(this.props.onConfirm != undefined) {
            this.props.onConfirm();
        }
    }
    cancelAction() {
        this.setState({
            hidden: true
        })
        if(this.props.onCancel != undefined) {
            this.props.onCancel();
        }
    }
    render(){
        let hidden = this.state.hidden;
        let type = this.props.type
        if(type == 'large') {
            return (
                <button-section id={'modal-confirm'} style={{display: hidden?'none':"block"}}>
                    <div className="row">
                        <div className="col-md-3 col-sm-4 col-xs-12">
                            <button type="button" onClick={this.cancelAction} className="button medium grey">Cancel</button>
                        </div>
                        <div className="col-md-3 col-md-offset-6 col-sm-4 col-sm-offset-4 col-xs-12">
                            <button type="button" className="button medium red" onClick={this.confirmAction}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </button-section>
            )
        }
        return (
            <div className="address-options" id={'modal-confirm'} style={{display: hidden?'none':"block"}}>
                <Link to={"#"} onClick={this.confirmAction} className="button xxxsmall red">Confirm</Link>
                <Link to={"#"} onClick={this.cancelAction} className="button xxxsmall grey ml-5">Cancel</Link>
            </div>
        )
    }
}

export default connect(null, null)(ModalConfirmation)
