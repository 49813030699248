export const PRODUCT = {
    sku: "VIDEO CAPTURE",
}
export const custom_options = {
    today_day: 'today_day',
    details: 'details',
};

export const fields = {
    [custom_options.today_day]: '',
    [custom_options.details]: '',
}