import React from 'react'
import {Media, Player, withMediaProps, controls} from 'react-media-player'
import PlayPause from './PlayPause'
import MuteUnmute from './MuteUnmute'
import Fullscreen from './Fullscreen'
import {connect} from "react-redux"

const {CurrentTime, Progress, SeekBar, Duration, Volume} = controls


class VideoPlayer extends React.Component{
    constructor(props) {
        super(props);
        this.mediaRef = React.createRef();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.stopAllMedia != this.props.stopAllMedia) {
            console.log('stop', this.props.stopAllMedia)
            if(this.props.stopAllMedia) {
                this.stopMedia();
            }
        }
    }
    stopMedia()
    {
        if(this.mediaRef.current) {
            console.log('Current', this.mediaRef.current )
            if(this.mediaRef.current.state.isPlaying) {
                this.mediaRef.current.playPause();
            }
        }
    }

    render(){
        let {src, onMouseEnterMediaControls, onMouseLeaveMediaControls} = this.props;
        return (
            <Media ref={this.mediaRef}>
                {({isFullscreen, playPause}) =>
                    <div
                        className={'media-player' + (isFullscreen ? ' media-player--fullscreen' : '')}
                        tabIndex="0"
                    >
                        <Player
                            src={src}
                            onClick={() => playPause()}
                        />
                        <div className="media-controls"
                             onMouseEnter={onMouseEnterMediaControls}
                             onMouseLeave={onMouseLeaveMediaControls}
                        >
                            <PlayPause className="media-control media-control--play-pause"/>
                            <CurrentTime className="media-control media-control--current-time"/>
                            <div className="media-control-group media-control-group--seek">
                                <Progress className="media-control media-control--progress"/>
                                <SeekBar className="media-control media-control--seekbar"/>
                            </div>
                            <Duration className="media-control media-control--duration"/>
                            <MuteUnmute className="media-control media-control--mute-unmute"/>
                            <Volume className="media-control media-control--volume"/>
                            <Fullscreen className="media-control media-control--fullscreen"/>
                        </div>
                    </div>
                }
            </Media>
        )
    }
}

export default connect(null, null)(VideoPlayer)
