export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS';
export const PRODUCTS_TO_CART = 'PRODUCTS_TO_CART';


export const setSelectedProducts = (data) => ({
    type: SET_SELECTED_PRODUCTS,
    data: data
})
export const setProductsToCart = (data) => ({
    type: PRODUCTS_TO_CART,
    data: data
})