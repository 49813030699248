import React from "react"
import {Link} from "react-router-dom";

export class CustomerInformation extends React.Component{
    constructor(props) {
        super(props);
        this.getFullName = this.getFullName.bind(this);
    }

    getFullName() {
        let {customerInfo} = this.props;
        return `${customerInfo.firstname} ${customerInfo.lastname}`;
    }
    render() {
        let {customerInfo} = this.props;
        return(
            <section className="row section-account">
                <div className="col-xs-12">
                    <header className="mb-20">
                        <div className="row">
                            <div className="col-sm-9">
                                <h3 className="h-four">Contact Information</h3>
                            </div>
                            <div className="col-sm-3">
                                <Link to="/customer/information" className="button xsmall blue">Edit</Link>
                            </div>
                        </div>
                    </header>
                    <ul id="list-accounts" className="row">
                        <li className="col-md-3 col-sm-6">
                            <span>{this.getFullName()}</span>
                            <span>{customerInfo.email}</span>
                            <Link to="/customer/information" className="button xsmall blue">Change Password</Link>
                        </li>
                    </ul>
                </div>
            </section>
        )
    }

}
