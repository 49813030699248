import React from "react";
import {connect} from "react-redux";
import LengthFields from "../LengthFields";
import {setItemsToDelete} from "../../containers/OrderFormStep1/actions";
import Confirm from "react-confirm-bootstrap"

const mapDispatchToProps = {
    setItemsToDelete
}

class OrderFormStep3Cuts extends React.Component{
    constructor(props) {
        super(props);
        this.removeLength = this.removeLength.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }
    removeLength()
    {
        let lengthInformation = {...this.props.lengthInformation};
        let idsToDelete = [...this.props.itemsToDelete];
        let updateItemsToDelete = false;
        for(let index in lengthInformation) {
            let length = lengthInformation[index];
            console.log('length', length)
            if(length.item_id && idsToDelete.indexOf(length.item_id) === -1) {
                console.log('length', length)
                idsToDelete.push(length.item_id);
                updateItemsToDelete = true;
            }
        }
        if(updateItemsToDelete) {
            this.props.setItemsToDelete(idsToDelete);
        }
        let lengthIndex = this.props.lengthindex;
        this.props.removeLength(lengthIndex);
    }
    render(){
        let {lengthindex, productsku, lengthErrors, amountOfCuts, product,
            categoryInfo, itemsToDelete, cutProducts} = {...this.props};
        let lengthInformation = {...this.props.lengthInformation}
        return (
            <div>
                <LengthFields
                    lengthindex={lengthindex}
                    updateLength={this.props.updateLength}
                    lengthInformation={{...lengthInformation}}
                    productsku={productsku}
                    lengthConfig = {this.props.lengthConfig}
                    lengthErrors = {lengthErrors}
                    product={product}
                    categoryInfo={categoryInfo}
                    itemsToDelete={itemsToDelete}
                    cutProducts={cutProducts}
                    resetLengths={this.props.resetLengths}

                />


               {amountOfCuts > 1 && <div className="remove-length-wrapper">
                   {lengthindex === 0 && <Confirm
                           onConfirm={this.removeLength}
                           body="Deleting this cut will also delete all the cuts below it. Are you sure you want to do this?"
                           confirmText="Confirm"
                           title="Delete Cut">
                           <button className="remove-length button xsmall red-white" type="button" onClick={this.removeLength} disabled={amountOfCuts <= 1}>
                               Remove Length Above
                           </button>
                       </Confirm>
                   }
                   {lengthindex > 0 &&
                       <button className="remove-length button xsmall red-white" type="button" onClick={this.removeLength} disabled={amountOfCuts <= 1}>
                           Remove Length Above
                       </button>
                   }


               </div>}

            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(OrderFormStep3Cuts)
