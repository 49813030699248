import React from "react"
import {connect} from "react-redux"
import {SlideToggleContent} from "../SlideToggleContent";
import ProductShipping from "../ProductShipping";
import {setShippingProducts} from "../../containers/OrderFormStep1/actions";

const mapDispatchToProps = {
    setShippingProducts,
}
const mapStateToProps = state => ({
    // ...state.orderProcess,
})
class OrderFormStep3Shipping extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            accordionExpanded: true,
            accordionContentClasses: ['accordion-content'],
            accordionGroupClasses: ['accordion-group', 'expanded']
        };
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.showShipping = this.showShipping.bind(this);
    }
    componentDidMount() {
        let selectedProducts = this.props.selectedProducts;
        let shippingProducts = {...this.props.shippingProducts};
        if(!this.props.shippingProducts || Object.keys(this.props.shippingProducts).length == 0) {
            let shippingProduct = {};
            Object.keys(selectedProducts).map((value, index, array) => {
                let selectedProduct = selectedProducts[value];
                shippingProduct[selectedProduct.id]= [null]
            })
            this.props.setShippingProducts(shippingProduct);
        }
        else {
            Object.keys(selectedProducts).map((value, index, array) => {
                let selectedProduct = selectedProducts[value];
                if(!shippingProducts[value]) {
                    shippingProducts[selectedProduct.id] = [null];
                }
            })
            this.props.setShippingProducts(shippingProducts);
        }
    }

    toggleAccordion()
    {
        let {accordionExpanded, accordionGroupClasses} = this.state;
        if(accordionExpanded) {
            accordionGroupClasses.splice(accordionGroupClasses.indexOf('expanded'));
        }
        else {
            accordionGroupClasses.push('expanded');
        }
        this.setState({
            accordionExpanded: !this.state.accordionExpanded,
            accordionGroupClasses: accordionGroupClasses,
        })
    }
    showShipping() {
        let selectedProducts = this.props.selectedProducts;
        for (let index in selectedProducts) {
            let selectedProduct = selectedProducts[index];
            if(selectedProduct.extension_attributes && selectedProduct.extension_attributes.shipping) {
                return true;
            }
            if(selectedProduct.extension_attributes && selectedProduct.extension_attributes.product_type.id == 'shipping') {
                return  true;
            }
        }
        return  false;
    }
    render(){
        let {accordionExpanded, accordionGroupClasses, accordionContentClasses} = this.state;
        let {selectedProducts, shippingProducts, customerId, customerEmail,itemsToDelete} = {...this.props};
        let showShipping = this.showShipping();
        let formErrors = {};
        if(this.props.formErrors && this.props.formErrors.shipping) {
            formErrors = this.props.formErrors.shipping;
        }
        console.log("shippingProducts", shippingProducts);
        return (
            <shipping-section>
                {showShipping && <div className={accordionGroupClasses.join(' ')}>
                    <div className="accordion-header" onClick={this.toggleAccordion} >
                        <h2>Shipping</h2>
                    </div>
                    <SlideToggleContent isVisible={accordionExpanded}>
                        <div className={accordionContentClasses.join(' ')}>
                            {shippingProducts &&
                            Object.keys(shippingProducts).map((value, index, array) => {

                                let selectedProduct = selectedProducts[value];
                                let shippingProduct = shippingProducts[value];
                                let shippingProductOriginal = selectedProduct.extension_attributes.shipping;
                                if(selectedProduct.extension_attributes.product_type.id == 'shipping') {
                                    shippingProductOriginal = selectedProduct;
                                    selectedProduct.extension_attributes = {
                                        ...selectedProduct.extension_attributes,
                                        shipping: shippingProductOriginal
                                    }
                                }
                                console.log('shippingProductOriginal', shippingProductOriginal)
                                if(shippingProductOriginal) {
                                       return <div className="form-section repeat" key={index}>
                                            <div className="row">
                                                <div className="form-group col-xs-12">
                                                    <h3 className="h-five blue mb-10">{selectedProduct.name}</h3>
                                                </div>
                                            </div>
                                            <ProductShipping
                                                shippingProduct={shippingProduct}
                                                shippingProductOriginal={shippingProductOriginal}
                                                selectedProduct={selectedProduct}
                                                productsToCartShipping={this.props.shippingProducts}
                                                customerId={customerId}
                                                customerEmail={customerEmail}
                                                itemsToDelete={itemsToDelete}
                                                formErrors={formErrors[selectedProduct.id]}
                                            />
                                        </div>
                                }
                            }
                            )}
                        </div>
                    </SlideToggleContent>
                </div>}
            </shipping-section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFormStep3Shipping)
