import React from "react"
import {connect} from "react-redux"
import CustomerAddressItem from "../CustomerAddressItem";
import {Link} from "react-router-dom";
import {customerInformationReceived} from "../../containers/CustomerInformation/actions";

const mapDispatchToProps = {
    customerInformationReceived,
}

const mapStateToProps = state => ({

})

class CustomerAddressList extends React.Component{
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    componentWillUnmount() {
    }

    getDefaultBillingAddress() {
        let addresses = this.props.addresses;
        let defaultBillingAddressId = this.props.defaultBillingAddressId;

        for(let index in addresses)
        {
            let item = addresses[index];
            if(item && item.id && defaultBillingAddressId){
                if(item.id.toString() === defaultBillingAddressId.toString()) {
                    return <CustomerAddressItem address={item} key={item.id}
                                         defaultBillingAddressId={defaultBillingAddressId}
                                         customerInfo={this.props.customerInfo}
                    />
                }
            }
        }
    }

    render(){
        let addresses = this.props.addresses;
        let defaultBillingAddressId = this.props.defaultBillingAddressId;
        return (
            <section className="row section-account">
                <div className="col-xs-12">
                    <header className="mb-20">
                        <div className="row">
                            <div className="col-sm-9">
                                <h3 className="h-four">Addresses</h3>
                            </div>
                            <div className="col-sm-3">
                                <Link to="/customer/addressBook" className="button xsmall blue">Manage Addresses</Link>
                            </div>
                        </div>
                    </header>
                    <ul id="list-addresses" className="row">
                        {this.getDefaultBillingAddress()}
                        {addresses.map((item) => {
                            if(!defaultBillingAddressId || (item.id.toString() !== defaultBillingAddressId.toString())){
                                return <CustomerAddressItem address={item} key={item.id}
                                                     defaultBillingAddressId={defaultBillingAddressId}
                                                     customerInfo={this.props.customerInfo}
                                />
                            }
                        })}
                    </ul>
                </div>
            </section>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddressList)
