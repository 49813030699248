import {request} from "../../axiosRequest";

export const CUSTOMER_UPDATE_REQUEST = "CUSTOMER_UPDATE_REQUEST";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAILURE = "CUSTOMER_UPDATE_FAILURE";

export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";



export const customerUpdateRequest = (data = []) => ({
    type: CUSTOMER_UPDATE_REQUEST,
    data: data
})

export const customerUpdateSuccess = (data) => ({
    type: CUSTOMER_UPDATE_SUCCESS,
    data: data
})

export const customerUpdateError = (error) => ({
    type: CUSTOMER_UPDATE_FAILURE,
    error: error
})


export const updatePasswordSuccess = (data) => ({
    type: UPDATE_PASSWORD_SUCCESS,
    error: data
})

export const updatePasswordError = (error) => ({
    type: UPDATE_PASSWORD_FAILURE,
    error: error
})

export const customerUpdateAttempt = (data) => {
    let postData = {};
    if(data['currentPassword'] && data['currentPassword'] !== '' &&
        data['newPassword'] === data['newPasswordConfirm'])
    {
        postData['password'] = {newPassword: data['newPassword'], currentPassword: data['currentPassword']}
    }
    if(!data['tdc_additional_emails'] || data['tdc_additional_emails'] == '') {
        data['tdc_additional_emails'] = [];
    }
    postData['customer'] = {
        customer: {
            "id": data["id"],
            "website_id": data["websiteId"],
            "email": data['email'],
            "firstname": data['firstName'],
            "lastname": data['lastName'],
            'custom_attributes': [
                {
                    attribute_code:  'tdc_additional_emails',
                    value: JSON.stringify(data['tdc_additional_emails']),

                },
                {
                    attribute_code: 'is_approved',
                    value: 1
                }

            ]
        }
    };
    console.log(postData);
    return (dispatch) => {
        dispatch(customerUpdateRequest());
        request.post(`customers/${data['id']}/update`,postData, true)
            .then(response => {
                return response;
            })
            .then(result => {
                try{
                    let infoResult = result.data.data.customer;
                    // console.log('customer')
                    // console.log(infoResult);
                    if(infoResult.code == 200){
                        dispatch(customerUpdateSuccess(infoResult.data));
                    }
                    else{
                        dispatch(customerUpdateError(infoResult.data.message));
                    }
                }catch (e) {
                    console.log(e);
                }
                let passwordData = result.data.data.password;
                if(passwordData){
                    if(passwordData.code == 200){
                        dispatch(updatePasswordSuccess());
                        console.log('Password updated');
                    }
                    else{
                        dispatch(updatePasswordError(passwordData));
                        console.log(passwordData);
                    }
                }
            })
            .catch(error => {
                dispatch(updatePasswordError(error));
                console.log(error.statusCode);
            })
    }
}

