import {request} from "../../axiosRequest";

export const GET_CATEGORY_BY_ARTIST_REQUEST = 'GET_CATEGORY_BY_ARTIST_REQUEST';
export const GET_CATEGORY_BY_ARTIST_SUCCESS = 'GET_CATEGORY_BY_ARTIST_SUCCESS';
export const GET_CATEGORY_BY_ARTIST_FAILURE = 'GET_CATEGORY_BY_ARTIST_FAILURE';

export const getCategoryByArtistSuccess = (data) =>({
    type: GET_CATEGORY_BY_ARTIST_SUCCESS,
    data: data
})

export const getCategoryByArtistRequest = () =>({
    type: GET_CATEGORY_BY_ARTIST_REQUEST,
})

export const getCategoryByArtistFailure = (error) =>({
    type: GET_CATEGORY_BY_ARTIST_FAILURE,
    error: error
})


export const getCategoryByArtist = (artistId) => {
    return (dispatch) => {
        dispatch(getCategoryByArtistRequest());
        request.get(`categories/artist/${artistId}`, true)
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result.data;
                if(data.success && data.code == 200)
                {
                    dispatch(getCategoryByArtistSuccess(data.data));
                }
                else {
                    if(data.code == 404) {
                        dispatch(getCategoryByArtistFailure(data.data.message));
                    }
                    else {
                        dispatch(getCategoryByArtistFailure(data.data.message))
                    }
                }
                console.log(data)
            })
            .catch(error => {
                dispatch(getCategoryByArtistFailure(error))
                }
            )

    }
};