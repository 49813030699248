import React from "react"
import {connect} from "react-redux"
import {
    checkIfProductHasSharedFields,
    checkIsMainProduct, getAllCutsFromSelectedProducts,
    getCategoryCustomOption, getFieldsToShowOnVenueSection,
    splitItemCartIntoOrderProcessInformation
} from "../../helpers";
import {
    cleanUpOrderProcessState,
    setCategoryInfo, setCutProducts,
    setProductsCustomOptions, setProductsLengths,
    setProductsShows,
    setProductsToCartDetails,
    setShippingProducts
} from "../OrderFormStep1/actions";
import {setProductsToCart, setSelectedProducts} from "../../components/OrderFormStep1ProductList/actions";
import OrderFormStep2 from "../OrderFormStep2";
import OrderFormStep3 from "../OrderFormStep3";
import {CDN_URL, FIELDS_CODE} from "../../actions/Constants";

const mapDispatchToProps = {
    setCategoryInfo,
    setProductsLengths,
    setProductsShows,
    setProductsToCartDetails,
    setProductsCustomOptions,
    setShippingProducts,
    setProductsToCart,
    setSelectedProducts,
    cleanUpOrderProcessState,
    setCutProducts
};

const mapStateToProps = state => ({
    ...state.cart
})


class ReOrder extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showsFields: null,
            sharedFields: null,
            lengthFields: null,
            customOptions: null,
            productToCart: null,
            shippingInfo: null,
            currentStep: 'step2'

        };
        this.getCategoryInfo = this.getCategoryInfo.bind(this);
        this.updateOrderProcess = this.updateOrderProcess.bind(this);

        this.updateLength = this.updateLength.bind(this);
        this.updateShow = this.updateShow.bind(this);
        this.updateSharedInformation = this.updateSharedInformation.bind(this);
        this.nextStepUpdate = this.nextStepUpdate.bind(this);
        this.goBackUpdate = this.goBackUpdate.bind(this);

    }
    componentDidMount() {
        if(this.props.history.location.state && this.props.history.location.state['order']) {
            let order = this.props.history.location.state['order'];
            if(order) {
                let originalProduct = order.extension_attributes.main_product;
                if(originalProduct) {
                    let itemOrdered = null;
                    let optionsInformation = [];

                    let customOptions = {};
                    let lengthFields = {};
                    let sharedDetails = {};
                    let shippingInfo = {};
                    let showsFields = [];
                    let stateInfo = {};
                    let categoryInfo = this.getCategoryInfo();
                    for (let orderItemIndex in order.items) {
                        let orderItem = order.items[orderItemIndex];
                        let productSku = orderItem.extension_attributes.product_sku;
                        let productId = orderItem.extension_attributes.product_id;
                        if (checkIsMainProduct(orderItem.extension_attributes.product_type)) {
                            itemOrdered = orderItem;
                            optionsInformation = splitItemCartIntoOrderProcessInformation(itemOrdered, order.items);
                            customOptions[productSku] = optionsInformation.customOptionsInformation;
                            sharedDetails = optionsInformation.sharedInformation;
                            shippingInfo[productId] = this.removeItemId(optionsInformation.shippingInformation);
                            showsFields = optionsInformation.showInformation;
                            lengthFields[productId] = this.removeItemIdFromCut(optionsInformation.lengthInformation);
                            stateInfo[productId] = {
                                showsFields: showsFields,
                                lengthFields: optionsInformation.lengthInformation,
                                sharedFields: optionsInformation.sharedInformation,
                                customOptions: optionsInformation.customOptionsInformation,
                                shippingInfo: optionsInformation.shippingInformation,
                                categoryInfo: categoryInfo
                            }
                        }
                    }
                    console.log('customOptions', customOptions);
                    console.log('customOptions', lengthFields);

                    let currentStep = 'step2';
                    if(showsFields.length === 0) {
                        currentStep = 'step3';
                    }

                    this.setState({
                        productsInfo : {...stateInfo},
                        showsFields: showsFields,
                        lengthFields: lengthFields,
                        sharedFields: sharedDetails,
                        customOptions: customOptions,
                        shippingInfo: shippingInfo,
                        categoryInfo: categoryInfo,
                        currentStep: currentStep,
                    }, () => {
                        this.updateOrderProcess()
                    })
                }
            }
            else {
                let orderId = this.props.match.params.orderId;
                this.props.history.push(`/orderDetails/${orderId}`);
            }
        }
        else {
            let orderId = this.props.match.params.orderId;
            this.props.history.push(`/orderDetails/${orderId}`);
        }
    }
    removeItemIdFromCut(itemInfo) {
        itemInfo.map((value, index) => {
            Object.keys(value).map((cutKey) => {
                let info = value[cutKey];
                if(info.item_id) {
                    delete info.item_id;
                    value[cutKey] = info;
                    itemInfo[index] = value;
                }
            })
        })
        return itemInfo;
    }
    removeItemId(itemInfo) {
        if(itemInfo){
            itemInfo = itemInfo.map((value) => {
                if(value && value.item_id) {
                    delete value.item_id;
                }
                return value;
            })
        }
        return itemInfo;
    }

    getMainProduct() {
        let order = this.props.history.location.state['order'];
        let itemsOrdered = [];
        for (let orderItemIndex in order.items) {
            let orderItem = order.items[orderItemIndex];
            if (checkIsMainProduct(orderItem.extension_attributes.product_type)) {
                itemsOrdered.push(orderItem);
            }
        }
        return itemsOrdered;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.cart != this.props.cart)
        {
            console.log('prevProps.cart', prevProps.cart)
            console.log('this.props.cart', this.props.cart)
            if (this.state.productsToCart) {
                let productsToCart = [...this.state.productsToCart];
                let productsToCartTemp = [];
                for (let productIndex in productsToCart) {
                    let product = {...productsToCart[productIndex]};
                    if(product.quote_id == null) {
                        product.quote_id = this.props.cart.id
                    }
                    productsToCartTemp.push(product)
                }
                let state = {...this.state};
                state['productsToCart'] = productsToCartTemp;
                this.setState(state);
                this.props.setProductsToCart(productsToCartTemp);
                if(getFieldsToShowOnVenueSection(productsToCart).length === 0) {
                    this.setState({
                        currentStep: 'step3'
                    })
                }
            }
        }
    }

    getCategoryInfo()
    {
        let products = this.getMainProduct();
        let product = null;
        if(products.length > 0) {
            product = products[0]
        }
        let categoryInfo = {};
        if(product) {
            if(product.product_option) {
                let optionList = product.product_option.extension_attributes.custom_options;
                console.log('categoryInfo optionList', optionList);
                categoryInfo = getCategoryCustomOption(optionList);
                console.log('categoryInfo', optionList);

            }
            return categoryInfo;
        }
        return categoryInfo;
    }
    setCutProducts(showsFields, selectedProducts) {
        if(showsFields.length > 0) {
            let showField = showsFields[0];
            let cutProducts = getAllCutsFromSelectedProducts(showField[FIELDS_CODE.venue_capacity.code], selectedProducts);
            this.props.setCutProducts(cutProducts)
        }
    }

    getOriginalProductFromId(productId) {
        let order = this.props.history.location.state['order'];
        for(let itemIndex in order.items) {
            let item = order.items[itemIndex];
            if(item.product_id == productId) {
                if(item.extension_attributes && item.extension_attributes.original_product) {
                    return item.extension_attributes.original_product;
                }
            }
        }
        return null;
    }

    updateOrderProcess() {
        let {productsInfo} = {...this.state};
        let selectedProducts = {};
        let productsToCart = [];

        let showsFields = [];
        let customOptions = {};
        let shippingInfo = {};
        let lengthFields = {};
        let sharedFields = {};

        for(let productId in productsInfo) {
            let productInfo = productsInfo[productId];
            let originalProduct = this.getOriginalProductFromId(productId);
            selectedProducts[originalProduct.id] = originalProduct;
            let product = {
                sku: originalProduct.sku,
                qty: 1,
                name: originalProduct.name,
                price: originalProduct.price,
                product_type: originalProduct.type_id,
                quote_id: this.props.cart?this.props.cart.id:null
            };
            productsToCart.push(product);
            showsFields = productInfo['showsFields'];
            customOptions[originalProduct.sku] = productInfo['customOptions'];
            shippingInfo[originalProduct.id] = productInfo['shippingInfo'];
            lengthFields[originalProduct.id] = productInfo['lengthFields'];
            sharedFields = productInfo['sharedFields'];

        }
        this.props.setSelectedProducts(selectedProducts);
        this.props.setProductsShows(showsFields);
        this.props.setProductsToCartDetails(sharedFields);
        this.props.setProductsCustomOptions(customOptions);
        this.props.setShippingProducts(shippingInfo);

        this.props.setCategoryInfo(this.getCategoryInfo());
        console.log('this.props.setProductsLengths', lengthFields)
        this.props.setProductsLengths(lengthFields);


        this.props.setProductsToCart(productsToCart);
        this.setState({
            productsToCart: productsToCart
        })
        this.setCutProducts(showsFields, selectedProducts);
    }
/*    updateOrderProcess1() {
        let {
            showsFields,
            lengthFields,
            sharedFields,
            customOptions,
            shippingInfo} = {...this.state};
        let order = this.props.history.location.state['order'];
        let mainProduct = order.extension_attributes.main_product;
        if(showsFields && lengthFields && sharedFields && mainProduct) {
            let selectedProducts = {};
            let productsToCart = [];

            selectedProducts[mainProduct.id] = mainProduct;

            let product = {
                sku: mainProduct.sku,
                qty: 1,
                name: mainProduct.name,
                price: mainProduct.price,
                product_type: mainProduct.type_id,
                quote_id: this.props.cart?this.props.cart.id: null,
            };
            productsToCart.push(product);

            this.props.setSelectedProducts(selectedProducts);
            this.props.setProductsShows(showsFields);
            this.props.setProductsToCartDetails(sharedFields);
            this.props.setProductsCustomOptions(customOptions);
            this.props.setShippingProducts(shippingInfo);

            this.props.setCategoryInfo(this.getCategoryInfo());
            console.log('this.props.setProductsLengths', lengthFields)
            this.props.setProductsLengths(lengthFields);


            this.props.setProductsToCart(productsToCart);
            this.setState({
                productsToCart: productsToCart
            })
            this.setCutProducts(showsFields, selectedProducts);
        }
    }*/
    updateShow(showIndex=0, showInformation = {}) {
        let showsFields = this.state.showsFields;
        showsFields[showIndex] = showInformation;
        this.setState({
            showsFields: showsFields
        })
    }
    updateLength(lengthIndex, lengthFields)
    {
        this.setState({
            lengthFields: lengthFields
        })
    }
    updateSharedInformation(sharedInformation)
    {
        this.setState({
            sharedFields: {...sharedInformation}
        })
    }
    nextStepUpdate(data = null){
        let {currentStep} = this.state;
        if (currentStep == 'step2') {
            if(data.shows) {
                console.log('shows', data.shows);
                this.setState({
                    showsFields: data.shows
                })
                this.props.setProductsShows(data.shows);
            }
            if(data.details) {
                this.setState({
                    sharedFields: data.details
                });
                this.props.setProductsToCartDetails(data.details);
            }
            currentStep = 'step3';
        }
        this.setState({
            currentStep: currentStep
        })
    }

    goBackUpdate() {
        let {currentStep} = this.state;

        if(currentStep == 'step2' || (currentStep == 'step3' && !checkIfProductHasSharedFields(this.state.productsToCart))) {
            this.props.history.push(`/orderDetails/${this.props.match.params.orderId}`);
        }
        if (currentStep == 'step3') {
            this.setState({
                currentStep: 'step2'
            })
        }
    }
    componentWillUnmount() {
        this.props.cleanUpOrderProcessState();
    }
    render(){
        let {isFetchingReOrderProduct, isFetchingAddProductToCart} = {...this.props};
        let {currentStep, showsFields} = {...this.state};
        if(isFetchingReOrderProduct) {
            return (
                <main id="main" className="black" role="main"
                      style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-order.jpg')`, backgroundSize: "auto"}}>
                    <div id="content" className="container">
                        Loading...
                    </div>
                </main>
            )
        }
        if(isFetchingAddProductToCart) {
            return (
                <main id="main" className="black" role="main"
                      style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-order.jpg')`, backgroundSize: "auto"}}>
                    <div id="content" className="container">
                        Updating cart...
                    </div>
                </main>
            )
        }

        return (
            <div>
                {currentStep === 'step2' &&
                <OrderFormStep2
                    nextStep={this.nextStepUpdate}
                    shows={showsFields}
                    goBack={this.goBackUpdate}
                />
                }
                {currentStep === 'step3' &&
                <OrderFormStep3 nextStep={this.nextStepUpdate}
                                goBack={this.goBackUpdate}
                                itemId={null}
                />}

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReOrder)
