export const PRODUCT = {
    sku: "ONLINE AUDIO",
}
export const custom_options = {
    due_date: 'due_date',
    on_sale_date_time_text: 'on_sale_date_time_text',
    ticketing_info: 'ticketing_info',
    platform: 'platform',
    voice_over_tag: 'voice_over_tag',
}

export const fields = {
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.due_date]: '',
    [custom_options.platform]: '',
    [custom_options.ticketing_info]: '',
    [custom_options.voice_over_tag]: '',
}
