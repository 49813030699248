import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";

class NotFound extends React.Component{
    render(){
        return (
            <main id="main" role="main">
                <section id="content" className="container cms-content-wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            <h1>Page Not Found</h1>
                            <p>The page you requested was not found.</p>
                            <ul>
                                <li>If you typed the URL directly, please make sure the spelling is correct. If you clicked on a link to get here, the link is outdated.</li>
                                <li>Need help? Start by visiting our <Link to="/">homepage</Link> or <Link to="/tours">taking the tour</Link>. If you already have an account, please <Link to="/account/login">login</Link>.</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default connect(null, null)(NotFound)
