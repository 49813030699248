import React from "react"
import {connect} from "react-redux"
import VideoPlayer from "./Aux/VideoPlayer";
import AudioPlayer from "./Aux/AudioPlayer";

class MediaPlayer extends React.Component{
    render(){
        let src = this.props.src;
        let vendor = this.props.vendor;
        console.log('src', src);
        if(vendor == "video")
        {
            return (
                <VideoPlayer src={src}
                    onMouseEnterMediaControls={this.props.onMouseEnterMediaControls}
                    onMouseLeaveMediaControls={this.props.onMouseLeaveMediaControls}
                    stopAllMedia={this.props.stopAllMedia}
                />)
        }
        if(vendor == "audio") {
            return (<AudioPlayer src={src}
                                 onMouseEnterMediaControls={this.props.onMouseEnterMediaControls}
                                 onMouseLeaveMediaControls={this.props.onMouseLeaveMediaControls}
                                 stopAllMedia={this.props.stopAllMedia}
            />)
        }
    }
}

export default connect(null, null)(MediaPlayer)