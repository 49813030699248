import React from "react"
import {connect} from "react-redux"
import OrderFormStep1SelectProduct from "../OrderFormStep1SelectProduct"
import {setSelectedProducts} from "./actions";
import {setShippingProducts, setProductsLengths, setCutProducts} from "../../containers/OrderFormStep1/actions";
import {Link, withRouter} from "react-router-dom";
import {getAllCutsFromSelectedProducts, getCutTypeProduct} from "../../helpers";
import {FIELDS_CODE, LENGTH_INFORMATION} from "../../actions/Constants";

const mapDispatchToProps = {
    setSelectedProducts,
    setShippingProducts,
    setProductsLengths,
    setCutProducts,
}

const mapStateToProps = state => ({
    ...state.orderProcess
})


class OrderFormStep1ProductList extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            selectedProducts: props.selectedProducts,
            renderFormSelectedProducts: true,
            isSubmittedSelectedProducts: false,
            isFormValid: true
        }

        this.toggleSelectProduct = this.toggleSelectProduct.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.goOrderTours = this.goOrderTours.bind(this);
    }

    componentDidMount() {
        this.setState( {
            productsTypes: this.props.productsTypes,
            selectedProducts: this.props.selectedProducts
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.categoryId != this.props.categoryId) {
            this.setState({
                productsTypes: this.props.productsTypes,
                selectedProducts: {}
            });
        }
    }
    removeProductFromProductsLength(productId) {
        let productsToCartLength = {...this.props.productsToCartLengths};
        delete productsToCartLength[productId];
        this.props.setProductsLengths(productsToCartLength);
    }
    addProductToProductsLength(selectedProduct) {
        let productCut = getCutTypeProduct(null, selectedProduct.sku)
        if(productCut){
            let productsLengths = this.props.productsToCartLengths;
            productsLengths = {
                ...productsLengths,
                [selectedProduct.id]: [{none: {...LENGTH_INFORMATION, 'first': true}}]
            }

            this.props.setProductsLengths(productsLengths);
        }
    }
    removeProductFromProductsShipping(productId) {
        let productsToCartShipping = {...this.props.productsToCartShipping};
        delete productsToCartShipping[productId];
        this.props.setShippingProducts(productsToCartShipping);
    }
    updateCutProducts(selectedProducts) {
        let shows = this.props.productsToCartShows;
        if(shows && shows.length > 0) {
            let thisShow = shows[0];
            if(thisShow[FIELDS_CODE.venue_capacity.code] && thisShow[FIELDS_CODE.venue_capacity.code] !== "") {
                let allCutProducts = getAllCutsFromSelectedProducts(thisShow[FIELDS_CODE.venue_capacity.code], selectedProducts);
                this.props.setCutProducts(allCutProducts);
            }
        }
    }
    toggleSelectProduct(productId, product)
    {
        console.log(product);
        let {selectedProducts} = {...this.state};
        if(selectedProducts[productId]) {
            delete selectedProducts[productId];
            this.removeProductFromProductsShipping(productId);
            this.removeProductFromProductsLength(productId);
        }
        else{
            selectedProducts[productId] = product;
            this.addProductToProductsLength(product)
        }
        console.log('toggleSelectProduct', selectedProducts, product);
        this.setState({selectedProducts: selectedProducts});
        this.props.setSelectedProducts(selectedProducts);
        this.updateCutProducts(selectedProducts);
    }

    componentWillUnmount() {
        this.setState({
            selectedProducts: {}
        })
    }
    isValid()
    {
        let {selectedProducts} = this.state;
        let isValid = false;
        for(let sp in selectedProducts){
            if(selectedProducts[sp]){
                isValid = true;
            }
        }
        if(!isValid) {
            setTimeout(() => {
                window.scrollTo({top: 0, behavior: "smooth", block: 'start'})
            }, 100)

        }
        return isValid;
    }
    submitForm(event)
    {
        event.preventDefault();
        if (this.isValid()){
            this.props.nextStep();
            console.log("submitting form")
        }
        else {
            this.setState({
                isFormValid: false
            })
        }
    }
    goOrderTours() {
        this.props.history.push(`/order/process/tours/${this.props.categoryId}`)
    }
    render(){

        let {productsTypes, selectedProducts, isFormValid} = this.state;
        let {categoryInfo} = this.props;
        console.log("render select product/...")
        console.log(selectedProducts);
        if(productsTypes && Object.keys(productsTypes).length == 0 )
        {
            return (
                <div className="row section-header">
                    <div className="col-lg-12">
                        <h1 className="h-two white mb-20"> {categoryInfo && categoryInfo.category_artist_name}</h1>
                        <p className="p-three grey">Sorry, we didn't find any product for {categoryInfo && categoryInfo.category_artist_name}. Please return to the full
                            <Link to="/artists" className="blue"> tour roster</Link> list to find another artist.</p>
                    </div>
                </div>
            )
        }
        return (
            <form onSubmit={this.submitForm}>
                <div className="row section-header">
                    <div className="col-lg-7 col-md-6 col-sm-6">
                        <h1 className="h-two white mb-20"> {categoryInfo && categoryInfo.category_artist_name}</h1>
                        <p className="p-three">Tell us what you need to order! Please select at least one option below:</p>
                    </div>
                    <div className="back col-lg-5 col-md-6 col-sm-6">
                        <Link to="#" onClick={this.goOrderTours}>1. Select Products</Link>
                    </div>
                </div>
                {!isFormValid && <div className="alert error">
                    <p>Please select at least one option.</p>
                </div>}
                <div id="order-option-list" className="row">
                    {productsTypes && Object.keys(productsTypes).map((index) =>
                        <OrderFormStep1SelectProduct
                                key={index}
                               index={index} products={productsTypes[index].products}
                               typeName={productsTypes[index].typeName}
                               toggleSelectProduct={this.toggleSelectProduct}
                        />
                    )}
                </div>
                <div className="order-options">
                    <div className="row">
                        <div className="col-sm-3 col-sm-offset-9">
                            <button type="submit"
                                    className="button medium blue-white"
                                    disabled={this.props.isFetchingCartItemsList}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        )

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderFormStep1ProductList))
