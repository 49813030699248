import React from "react"
import {connect} from "react-redux"
import {setOrdersListCurrentPage, setOrdersListPageSize} from "../../containers/Orders/actions"

const mapDispatchToProps = {
    setOrdersListCurrentPage,
    setOrdersListPageSize
}

class OrderPaginator extends React.Component{

    constructor(props)
    {
        super(props)
        this.state = {
            pageRange: [],
            pageSize: 0,
            totalCount: 0
        };
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.setPageSize = this.setPageSize.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.getCountResultData = this.getCountResultData.bind(this);
    }

    componentDidMount() {
        this.setState({
            pageRange: this.getPageRange(),
            pageSize: this.props.pageSize,
            totalCount: this.props.totalCount
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.pageSize != this.props.pageSize){
            this.setState({pageRange: this.getPageRange()})
        }
        if(prevProps.totalCount != this.props.totalCount)
        {
            this.setState({pageRange: this.getPageRange()})
        }
    }

    getPageRange(){
        let {pageSize, totalCount, currentPage} = this.props;
        let firstPage = 1;
        let pageQuantity = Math.ceil(totalCount/pageSize);
        let lastPage =  pageQuantity;
        console.log(this.props);
        currentPage = parseInt(currentPage);

        let range = [...Array(pageQuantity).keys()].slice(1, pageQuantity - 1).map((number) => {return number + 1});
        let rangeTemp = range.filter((item) => {
            let plus = 1;
            let less = 1;
            if(currentPage <= 2) {
                plus += 2
            }
            if(lastPage - currentPage <= 2) {
                less += 2 - (lastPage - currentPage);
            }
            if(item >= parseInt(currentPage) - less && item <= parseInt(currentPage) + plus) {
                return item;
            }

        })
        if(1 == lastPage) {
            return [1];
        }
        return [1, ...rangeTemp, lastPage];
    }

    setPageSize(event)
    {
        let {value} = event.target;
        this.props.setOrdersListPageSize(value);
    }

    setCurrentPage(event)
    {
        let {value} = event.target;
        this.props.setOrdersListCurrentPage(value);

    }
    nextPage(){
        let currentPage = this.props.currentPage;
        let {pageRange} = this.state;
        if(pageRange.length > currentPage) {
            currentPage = parseInt(currentPage) + 1;
            this.props.setOrdersListCurrentPage(currentPage);
        }
    }
    prevPage(){
        let currentPage = this.props.currentPage;
        if(currentPage != 1) {
            currentPage = parseInt(currentPage) - 1;
            this.props.setOrdersListCurrentPage(currentPage);
        }
    }

    getCountResultData() {
        let {pageSize, currentPage, totalCount} = this.props;
        let first = pageSize*(currentPage -1) + 1;
        let last = pageSize*(currentPage -1) + pageSize;
        if(last > totalCount) {
            last = totalCount;
        }
        if(totalCount == 0)
        {
            first = 0;
        }
        return {first: first, last: last};
    }
    render(){
        let {pageRange} = this.state;
        let currentPage = this.props.currentPage;
        let pageSize = this.props.pageSize;
        let totalCount = this.props.totalCount;
        let totalOrdersAmount = this.props.totalOrdersAmount;
        let countResult = this.getCountResultData();
        console.log(pageRange);
        return (
            <div className="col-xs-12">
                <div className="paging-wrapper">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <span className="count-results">
                                Showing {countResult.first} to {countResult.last} of {totalCount} entries (filtered from {totalOrdersAmount} total entries)
                            </span>
                        </div>
                        {totalCount > 5 && <div className="col-md-3 col-sm-12 show-results">
                            Show
                            <select
                            onChange={this.setPageSize}
                            value={pageSize}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                            entries
                        </div>}
                        {pageRange.length > 1 && <div className="col-md-5 col-sm-12 paging">
                           <button type="button" className="prev"
                                    onClick={this.prevPage}
                                    disabled={currentPage==1?true:false}
                            >
                                Previous
                            </button>
                            {pageRange.map((item) =>
                                <button
                                    value={item}
                                    key={item}
                                    onClick={this.setCurrentPage}
                                    className={currentPage==item?'active':''}
                                >{item}</button>
                            )}
                            <button type="button"
                                    className="next"
                                    disabled={currentPage==pageRange.length?true:false}
                                    onClick={this.nextPage}>
                                Next
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(OrderPaginator)
