import React from "react"
import CreateAccountForm from "../../components/CreateAccountForm/index"


export class CreateAccount extends React.Component{

    render() {
        return (
            <div className="row">
                <CreateAccountForm/>
            </div>
        )
    }

}
