export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const adminCustomerSuccess = (data) =>({
    type: LOGIN_SUCCESS,
    data: data
})


export const adminCustomer = (authToken, email) => {
    return (dispatch) => {
        dispatch(adminCustomerSuccess(
            {
                customerInfo: null,
                customerAuthToken: authToken,
                customerEmail: email,
                impersonated: true
            }
        ));
    }
};
