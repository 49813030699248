import React from "react"
import {connect} from "react-redux"
import {customerUpdateAttempt} from "./actions";
import {validEmailRegex} from "../../helpers"
import {validateForm} from "../../helpers"
import AdditionalEmails from "./AdditionalEmails";

const mapDispatchToProps = {
    customerUpdateAttempt,
}

const mapStateToProps = state => ({
    ...state.customerInformation
})

class EditAccountInformationForm extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id: null,
            websiteID: null,
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            firstName: "",
            lastName: "",
            email: "",
            errors : [],
            errorMessage: 'Please complete the fields outlined in red below before proceeding.',
            tdc_additional_emails: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.checkFormIsValid = this.checkFormIsValid.bind(this);
        this.updateAdditionalEmails = this.updateAdditionalEmails.bind(this);
        this.searchTdcAdditionalEmails = this.searchTdcAdditionalEmails.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    componentDidMount() {
        if(this.props.customerInfo){
            let tdcAdditionalEmails = this.searchTdcAdditionalEmails();
            this.setState({
                id: this.props.customerInfo.id,
                websiteId: this.props.customerInfo.website_id,
                firstName: this.props.customerInfo.firstname,
                lastName: this.props.customerInfo.lastname,
                email: this.props.customerInfo.email,
                tdc_additional_emails: tdcAdditionalEmails
            });
        }
    }
    searchTdcAdditionalEmails() {
        let customerInfo = this.props.customerInfo;
        if(customerInfo && customerInfo.custom_attributes) {
            let customAttributes = customerInfo.custom_attributes;
            for(let cAttributesIndex in customAttributes) {
                let customAttribute = customAttributes[cAttributesIndex];
                if(customAttribute.attribute_code == 'tdc_additional_emails') {
                    let value = customAttribute.value;
                    value = JSON.parse(value);
                    if(value) {
                        return value
                    }
                    else{
                        return [];
                    }
                }
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.props.customerInfo);
        console.log(prevProps.customerInfo)
        if(prevProps !== this.props && this.props.customerInfo){
            this.initData(this.props.customerInfo);
        }
        if(prevProps.updatePasswordError != this.props.updatePasswordError && this.props.updatePasswordError) {
            window.scrollTo({top: 0, behavior: "smooth", block: 'start'})
        }
    }
    initData(customer) {
        let tdcAdditionalEmails = this.searchTdcAdditionalEmails();
        this.setState({
            id: customer.id,
            websiteId: customer.website_id,
            firstName: customer.firstname,
            lastName: customer.lastname,
            email: customer.email,
            tdc_additional_emails: tdcAdditionalEmails,
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            errors: []
        });
    }
    resetForm() {
        let customerInfo = this.props.customerInfo;
        this.initData(customerInfo);
    }
    handleChange(event){
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({errors, [name]: value}, ()=> {
            console.log(errors)
        })
    }
    checkFormIsValid() {
        let {currentPassword, newPassword, newPasswordConfirm,
            firstName, lastName, email
        } = this.state;
        let errors = [];
        let errorMessage = null;

        if(firstName == '') {
            errors.push('firstName');
        }
        if(lastName == '') {
            errors.push('lastName');
        }
        if(email == '') {
            errors.push('email');
        }
        if(!validEmailRegex.test(email)) {
            errorMessage = 'Please enter a valid email address.'
        }
        if(currentPassword != '') {
            if(newPassword == '') {
                errors.push('newPassword');
            }
            else {
                if(newPassword != newPasswordConfirm) {
                    if(!errorMessage) {
                        errorMessage = 'We couldn\'t update your password. The new passwords you entered don’t match. ' +
                            'Please check the passwords and try again.';
                    }

                    errors.push('newPassword');
                    errors.push('newPasswordConfirm');
                }
            }
        }
        else {
            if(newPassword != '') {
                errors.push('currentPassword')
                if(newPasswordConfirm != newPassword) {
                    errors.push('newPasswordConfirm');
                }
            }
        }
        this.setState({
            errors : errors,
            errorMessage: errorMessage
        });
        let additionalEmailsErrors = this.checkErrorsOnAdditionalEmails();
        if(errors.length > 0 || !additionalEmailsErrors) {
            window.scrollTo({top: 0, behavior: "smooth", block: 'start'})
            return false;
        }
        return true;
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if(this.checkFormIsValid()) {
            this.props.customerUpdateAttempt(
                this.state
            );
            console.info('Valid Form');
        }else{
            console.error('Invalid Form');
        }

    }
    updateAdditionalEmails(additionalEmail) {
        let state = {...this.state}
        state['tdc_additional_emails'] = additionalEmail;
        this.setState(state);
    }
    checkErrorsOnAdditionalEmails() {
        let additionalEmails = this.state.tdc_additional_emails;
        let additionalEmailsErrors = {};
        for(let aEmailsIndex in additionalEmails) {
            let emails = additionalEmails[aEmailsIndex]['emails'];
            console.log('emails', emails);
            emails = emails.replace(/ /g, "")
            let emailsArray = emails.split(',')
            let invalidEmail = false;
            let emailWithErrors = [];
            console.log('emailsArray', emailsArray)
            for(let emailIndex in emailsArray) {
                let email = emailsArray[emailIndex]
                if(!validEmailRegex.test(email)) {
                    invalidEmail = true;
                    emailWithErrors.push(email);
                }
            }
            if(invalidEmail) {
                if(additionalEmailsErrors[aEmailsIndex]){
                    additionalEmailsErrors[aEmailsIndex]['emails'] = emailWithErrors ;
                }
                else{
                    additionalEmailsErrors[aEmailsIndex] = {
                        'emails': emailWithErrors
                    };
                }
            }
        }
        this.setState({
            additionalEmailsErrors: additionalEmailsErrors
        })
        if(Object.keys(additionalEmailsErrors).length > 0) {
            return false
        }
        return true;
    }
    render(){
        let {errors, errorMessage,additionalEmailsErrors} = {...this.state};
        let tdc_additional_emails = [];
        if(this.state.tdc_additional_emails){
            tdc_additional_emails = [...this.state.tdc_additional_emails];
        }
        let {updatePasswordError, customerUpdateRequest, customerUpdated} = this.props;
        if(customerUpdateRequest) {
            return (
                <updating-customer-info>
                    <div>
                        <div className="loading-white">
                            <i className="icon-loading"></i>
                            <span>Updating Account Information</span>
                        </div>
                    </div>
                </updating-customer-info>
            )
        }
        return (
            <form onSubmit={this.handleSubmit} >

                <small className="required">Required</small>

                <section className="row section-account">
                    <div className="col-xs-12">
                        <header className="mb-10">
                            <h3 className="h-five">Account Information</h3>
                        </header>
                        {customerUpdated && !updatePasswordError &&
                            <div className="alert success">
                                <p>Your account information has been updated.</p>
                            </div>
                        }
                        {!(errors && errors.length > 0) && updatePasswordError &&
                        <div className="alert error">
                            <p>{updatePasswordError}</p>
                        </div>
                        }
                        {errors && errors.length > 0 &&
                        <div className="alert error">
                            <p>{errorMessage}</p>
                        </div>
                        }
                        <div className="row">
                            <div className="form-group col-sm-4">
                                <label className="required">First Name</label>
                                <input type="text"
                                       name="firstName"
                                       name="firstName"
                                       value={this.state.firstName}
                                       onChange={this.handleChange}
                                       className={errors.indexOf('firstName') != -1?'error':''}
                                />
                            </div>
                            <div className="form-group col-sm-4">
                                <label className="required">Last Name</label>
                                <input type="text" placeholder="Last Name"
                                       name="lastName"
                                       value={this.state.lastName}
                                       onChange={this.handleChange}
                                       className={errors.indexOf('lastName') != -1?'error':''}
                                />
                            </div>
                            <div className="form-group col-sm-4">
                                <label className="required">Email Address</label>
                                <input type="text" placeholder="Email"
                                       name="email"
                                       value={this.state.email}
                                       onChange={this.handleChange}
                                       className={errors.indexOf('email') != -1?'error':''}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="row section-account">
                    <div className="col-xs-12">
                        <header className="mb-10">
                            <h3 className="h-five">Update Password</h3>
                        </header>
                        <div className="row">
                            <div className="form-group col-sm-4">
                                <label className="required">Current Password</label>
                                <input type="password" placeholder="Current Password"
                                       name="currentPassword"
                                       value={this.state.currentPassword}
                                       onChange={this.handleChange}
                                       className={errors.indexOf('currentPassword') != -1?'error':''}
                                />
                            </div>
                            <div className="form-group col-sm-4">
                                <label className="required">New Password</label>
                                <input type="password" placeholder="New Password"
                                       name="newPassword"
                                       value={this.state.newPassword}
                                       onChange={this.handleChange}
                                       className={errors.indexOf('newPassword') != -1?'error':''}
                                />
                            </div>
                            <div className="form-group col-sm-4">
                                <label className="required">Confirm New Password</label>
                                <input type="password" placeholder="Confirm New Password"
                                       name="newPasswordConfirm"
                                       value={this.state.newPasswordConfirm}
                                       onChange={this.handleChange}
                                       className={errors.indexOf('newPasswordConfirm') != -1?'error':''}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <AdditionalEmails
                    updateAdditionalEmails={this.updateAdditionalEmails}
                    errors={additionalEmailsErrors}
                    tdc_additional_emails={tdc_additional_emails}
                />

                <div className="form-options">
                    <div className="row">
                        <div className="col-md-3 col-sm-4 col-xs-6">
                            <button type="button" className="button medium grey"
                                    onClick={this.resetForm}
                            >Cancel
                            </button>
                        </div>
                        <div className="col-md-3 col-md-offset-6 col-sm-4 col-sm-offset-4 col-xs-6">
                            <button type="submit" className="button medium blue">Save</button>
                        </div>
                    </div>
                </div>

            </form>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountInformationForm)
