import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";
import {numberFormat} from "../../helpers";

class OrderProcessNavigation extends React.Component{
    numberFormat(num) {
        return num.toFixed(2)
     }
    componentDidMount() {
        this.hideNavigationDetails();
        window.addEventListener('scroll', this.hideNavigationDetails)
    }
    hideNavigationDetails()
    {
        let orderOptionsBottom = document.getElementById("order-options-bottom");
        if(orderOptionsBottom) {
            let divPosition = orderOptionsBottom.getBoundingClientRect();
            let classList = document.getElementById('order-navigation-bottom').classList;
            if(divPosition.top + 100 < window.innerHeight){
                if(!classList.contains('bottom')) {
                    document.getElementById('order-navigation-bottom').classList.add('bottom')
                }
            }
            else {
                if(classList.contains('bottom')) {
                    document.getElementById('order-navigation-bottom').classList.remove('bottom')
                }

            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.hideNavigationDetails);
    }

    onClick()
    {
        document.getElementById('nextStepButton').click();
    }
    getButtonTitle() {
        let {step, showStep3, itemId} = this.props;
        let title = 'Next';
        if(step == 'step2') {
            if(!showStep3) {
                if(itemId) {
                    title = 'Update Cart'
                }
                else {
                    title = 'Add to Cart'
                }
            }
        }
        else {
            if(itemId) {
                title = 'Update Cart'
            }
            else {
                title = 'Add to Cart'
            }
        }
        return title;
    }
    render(){
        let step = this.props.step;
        let {categoryInfo, totalPrice, showStep3} = this.props;

        return (
            <div id="order-navigation">
                <div id="order-navigation-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-8">
                                <h1 className="h-four white">{categoryInfo && categoryInfo.category_artist_name}</h1>
                            </div>
                            <div className="step col-md-3 col-sm-4">
                                {step == 'step2' && <Link to="#">2. Product Details</Link>}
                                {step == 'step3' && <Link to="#">3. Cuts and Shipping</Link>}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="order-navigation-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-8">
                                <dl>
                                    <dt>Order Estimate:</dt>
                                    <dd>{numberFormat(totalPrice)}</dd>
                                </dl>
                            </div>
                            <div className="col-md-3 col-sm-4">
                                <button type="submit" className="button blue-white"
                                     onClick={this.onClick}
                                >
                                    {this.getButtonTitle()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, null)(OrderProcessNavigation)
