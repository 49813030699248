import React from "react"
import {connect} from "react-redux"

class CustomCheckbox extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            className: [],
            checked: this.props.checked,
            label: this.props.label
        }
        this.handleChecked = this.handleChecked.bind(this);
        this.addHoverClass = this.addHoverClass.bind(this);
        this.removeHoverClass = this.removeHoverClass.bind(this);
    }
    componentDidMount() {
        let className = [];
        if(this.props.type == 'radio') {
            className.push("iradio_blue")
        }
        else {
            className.push("icheckbox_blue")
        }
        if(this.props.checked) {
            className.push('checked');
        }
        if(this.props.disabled) {
            className.push('disabled');
        }
        console.log('did mount', className, this.props)
        this.setState({
            className: className
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let className = [...this.state.className];
        if(prevProps.checked != this.props.checked) {
            let checkedIndex = className.indexOf('checked');
            if(this.props.checked) {
                if(checkedIndex == -1) {
                    className.push('checked')
                }
            }
            else {
                if(checkedIndex != -1) {
                    className.splice(checkedIndex, 1)
                }
            }

            if(this.props.checked) {
                if(this.props.setFocus != undefined) {
                    console.log('focus');
                    if(document.getElementById(this.props.setFocus))
                    {
                        document.getElementById(this.props.setFocus).focus();
                    }
                }
            }
        }
        if(prevProps.disabled != this.props.disabled) {
            if(this.props.disabled) {
                if(className.indexOf('disabled')  == -1) {
                    className.push('disabled')
                }
            }
            else {
                if(className.indexOf('disabled')  != -1) {
                    className.splice(className.indexOf('disabled'), 1)
                }
            }
        }
        if(prevProps.disabled != this.props.disabled || prevProps.checked != this.props.checked){

            this.setState({
                className: className,
                checked: this.props.checked,

            })
        }

    }

    handleChecked() {
        let type = this.props.type;
        let checked = this.state.checked;
        let name = this.props.name;
        let className = [...this.state.className];
        if(type === 'radio') {
            if(!checked) {
                checked = true;
                let allRadioOptions = document.getElementsByName(name);
                for(let i = 0; i < allRadioOptions.length; i++) {
                    let radioOption = allRadioOptions[i];
                    radioOption.classList.remove("checked");
                }
                console.log('className.indexOf', className.indexOf('checked'))
                if(className.indexOf('checked') === -1) {
                    className.push('checked')
                }
            }
        }
        else {
            checked = !checked;
            if(checked) {
                className.push('checked')
            }
            else {
                let index = className.indexOf('checked');
                if(index != -1) {
                    className.splice(index, 1)
                }
            }
        }
        this.setState({
            checked: checked,
            className: className
        });
        let feedBackInfo = this.props.feedBackInfo;

        if(this.props.onchange) {
            this.props.onchange({...feedBackInfo, 'type': this.props.type});
        }
    }
    addHoverClass() {
        let className = [...this.state.className];
        if(className.indexOf('hover') === -1) {
            className.push('hover');
            this.setState({
                className: className
            })
        }
    }
    removeHoverClass() {
        let className = [...this.state.className];
        if (className.indexOf('hover') !== -1) {
            className.splice(className.indexOf('hover'), 1);

            this.setState({
                className: className
            })
        }
    }
    render(){
        let className = this.state.className;
        let label = this.state.label;
        let name = this.props.name;
        let disabled = this.props.disabled;
        if(disabled) {
            return (
                <label>
                    <div className={className.join(' ')} name={name} style={{borderColor: "gray"}}>
                    </div>
                    <span>{label}</span>
                </label>
            )
        }
        return (
            <label onClick={this.handleChecked}
                   onMouseEnter={this.addHoverClass}
                   onMouseLeave={this.removeHoverClass}>
                <div className={className.join(' ')} name={name}>
                </div>
                <span dangerouslySetInnerHTML={{__html: label}}></span>
            </label>
        )
    }
}

export default connect(null, null)(CustomCheckbox)
