import {request} from "../../axiosRequest";

export const HOLIDAY_MESSAGE_REQUEST = 'HOLIDAY_MESSAGE_REQUEST';
export const HOLIDAY_MESSAGE_SUCCESS = 'HOLIDAY_MESSAGE_SUCCESS';
export const HOLIDAY_MESSAGE_FAILURE = 'HOLIDAY_MESSAGE_FAILURE';

export const getHolidayMessageSuccess = (data) =>({
    type: HOLIDAY_MESSAGE_SUCCESS,
    data: data
})

export const getHolidayMessageRequest = () =>({
    type: HOLIDAY_MESSAGE_REQUEST,
})

export const getHolidayMessageFailure = (error) =>({
    type: HOLIDAY_MESSAGE_FAILURE,
    error: error
})


export const getHolidayMessage = () => {
    return (dispatch) => {
        dispatch(getHolidayMessageRequest());
        request.get('block/identifier/holiday_message')
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result.data
                if(data['success'] && data['code'] == 200) {
                    let content = '';
                    if(data['data']['items'].length > 0) {
                        content = data['data']['items'][0].content;
                    }
                    dispatch(getHolidayMessageSuccess(content))
                }
                else {
                    dispatch(getHolidayMessageFailure(data['data']))
                }
            })
            .catch(error => {
                    dispatch(getHolidayMessageFailure(error))
                }
            )

    }
};
