import {request} from "../../axiosRequest";

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_STATUS = 'LOGIN_STATUS';

export const userLoginAttemptSuccess = (data) =>({
    type: LOGIN_SUCCESS,
    data: data
})

export const userLoginAttemptRequest = () =>({
    type: LOGIN_REQUEST,
})

export const userLoginAttemptFailure = (data) =>({
    type: LOGIN_FAILURE,
    error: data
})

export const setLoginSuccess = (data) =>({
    type: LOGIN_STATUS,
    data: data
})


export const userLoginAttempt = (username, password) => {
    return (dispatch) => {
        dispatch(userLoginAttemptRequest());
        request.post('customers/login', {user: username, password: password},false)
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result['data'];
                if(data['success'] && data['code'] == 200){
                    dispatch(userLoginAttemptSuccess(
                        {
                                customerInfo: data['customerInfo'],
                                customerAuthToken: data['data'],
                                customerEmail: username
                            }
                        ))
                }
                else{
                    dispatch(userLoginAttemptFailure(data['data']['message']))
                }
                console.log('Login error', data['data']['message']);
            })
            .catch(error => {
                    console.log('Login failed');
                    console.log(error);
                    dispatch(userLoginAttemptFailure(error));
                }
            )

    }
};
