import Axios from "axios";
import Cancel from "axios";
import {API_BASE_URL} from "./actions/Constants";

export const ERROR_401 = 'ERROR_401'

const apiBaseUrl = API_BASE_URL;

let token = null;

const tokenPlugin = secured => {
    if(token && secured)
    {
        return token
    }

}

export const request = {
    get: (url, secured = false, data = null, cancelToken = null) => {
        let authToken = tokenPlugin(secured);
        let query = '';

        if(data){
            query += '?' + Object.keys(data).map(key => key + '=' + data[key]).join('&');
        }
        if(cancelToken){
            return  Axios.get(`${apiBaseUrl}${url}${query}`,{cancelToken: cancelToken, headers: {
                    Authorization: 'Bearer ' + token
                }});
        }
        return  Axios.get(`${apiBaseUrl}${url}${query}`, {headers: {
                Authorization: 'Bearer ' + token
            }});
    },
    post: (url, data = {}, secured = true) =>{
        console.log(data);
        return Axios.post(`${apiBaseUrl}${url}`, data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
    },
    CancelToken: () => {
        return Axios.CancelToken.source();
    },
    setToken: (newToken) =>
        token = newToken

}
