import React from "react"
import {connect} from "react-redux"
import OrderFormStep3Cuts from "../OrderFormStep3Cuts";
import Product from "../Product";
import {FIELDS_CODE, LENGTH_INFORMATION} from "../../actions/Constants";
import ReactSlideToggle from "react-slide-toggle"
import ProductShipping from "../ProductShipping";
import {getCutTypeFromAllLengths, getProductLabel} from "../../helpers";
import {setItemsToDelete} from "../../containers/OrderFormStep1/actions";
const SlideToggle = ReactSlideToggle;


const mapDispatchToProps = {
    setItemsToDelete
}

class OrderFormStep3ProductInfo extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            lengths: [],
            accordionExpanded: true,
            accordionContentClasses: ['accordion-content'],
            accordionGroupClasses: ['accordion-group', 'expanded'],
            optionsDescription: {},
            optionsRequired: {},
            showAddLength: false
        };
        this.addNewLength = this.addNewLength.bind(this);
        this.resetLengths = this.resetLengths.bind(this);
        this.removeLength = this.removeLength.bind(this);
        this.updateLength = this.updateLength.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.optionsDescription = this.optionsDescription.bind(this);
        this.optionsRequired = this.optionsRequired.bind(this);
        this.getScriptValue = this.getScriptValue.bind(this);
    }

    componentDidMount() {
        let {productsLengths, product} = this.props;
        let optionsDescription = this.optionsDescription();
        let optionsRequired = this.optionsRequired();
        this.setState({
            optionsDescription: optionsDescription,
            optionsRequired: optionsRequired,
        })
        if(productsLengths && productsLengths[product.id]) {
            let lengths = [...productsLengths[product.id]];
            console.log('step3 product info mount', lengths);
            this.setState({
                lengths: lengths,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((!prevProps.productsLengths || Object.keys(prevProps.productsLengths).length === 0) && prevProps.productsLengths !== this.props.productsLengths) {
            let product = this.props.product;
            if(product.id in this.props.productsLengths) {
                this.setState({
                    lengths: [...this.props.productsLengths[product.id]],
                })
            }
        }
        if(prevState.lengths != this.state.lengths) {
            this.updateShowAddLength()
            let product = this.props.product;
            this.props.saveProductInfoState(product, [...this.state.lengths])
        }
    }
    updateShowAddLength() {
        let lengths = this.state.lengths;
        let count = 0;
        for(let lengthIndex in lengths) {
            let length = lengths[lengthIndex];
            count += Object.keys(length).length;
        }
        console.log('updateShowAddLength', count)
        let product = this.props.product;
        let cutType = getCutTypeFromAllLengths(count, product.sku);
        let cutProducts = this.props.cutProducts;
        if(cutProducts && product.id in cutProducts && cutType in cutProducts[product.id]) {
            this.setState({
                showAddLength: true
            })
        }
        else {
            this.setState({
                showAddLength: false
            })
        }
    }
    getScriptValue() {
        let {categoryInfo, product} = this.props;
        console.log('script', categoryInfo, product)
        if(product.sku.toLowerCase().includes('amex')) {
            console.log('amex', categoryInfo, product)
            return categoryInfo.amex_script;
        }
        else {
            console.log('citi', categoryInfo, product)
            return categoryInfo.citi_script;
        }
    }
    getCutPackage() {
        let productsToCartShows = this.props.productsToCartShows;
        console.log('venueCapacity show', productsToCartShows)
        if(productsToCartShows && productsToCartShows.length > 0) {
            let show = productsToCartShows[0];
            console.log('venueCapacity show', show)
            if(FIELDS_CODE.venue_capacity.code in show) {
                let venueCapacity = show[FIELDS_CODE.venue_capacity.code];
                console.log('venueCapacity', venueCapacity)
                return venueCapacity;
            }
        }
        return '';
    }
    addNewLength()
    {
        let lengths = [...this.state.lengths];
        let isFirst = true;
        if(lengths && lengths.length > 0) {
            isFirst = false;
        }
        let length = {none: {...LENGTH_INFORMATION, isFirst: isFirst}};
        lengths.push(length);
        this.setState({
            lengths: lengths
        })
    }
    resetLengths() {
        let length = {none: {...LENGTH_INFORMATION, isFirst: true}};
        let itemsToDelete = this.props.itemsToDelete;
        let lengths = this.state.lengths;

        for (let lengthIndex in lengths) {
            let lengthData = lengths[lengthIndex];
            for (let dataIndex in lengthData) {
                let data = lengthData[dataIndex];
                if(data.item_id && itemsToDelete.indexOf(data.item_id) === -1) {
                    itemsToDelete.push(data.item_id);
                }
            }
        }
        this.setState({
            lengths: [length]
        })
    }
    removeLength(index)
    {
        let lengths = [...this.state.lengths];
        if(index === 0) {
            this.resetLengths()
        }
        else {
            lengths.splice(index,1);
            this.setState({
                lengths: lengths
            })
        }
    }

    updateLength(index, data)
    {
        let lengths = [...this.state.lengths];
        console.log(lengths)
        if(FIELDS_CODE.cuts.sku.cuts_special_presale in data){
            let script_presale =  data[FIELDS_CODE.cuts.sku.cuts_special_presale][FIELDS_CODE.script_presale];
            if(script_presale === '') {
                script_presale = this.getScriptValue();
                data[FIELDS_CODE.cuts.sku.cuts_special_presale][FIELDS_CODE.script_presale] = script_presale;
            }
        }
        console.log('updateLength', data)
        lengths[index] = data;
        this.setState({
            lengths: lengths
        })
    }
    toggleAccordion()
    {
        let {accordionExpanded, accordionGroupClasses} = this.state;
        if(accordionExpanded) {
            accordionGroupClasses.splice(accordionGroupClasses.indexOf('expanded'));
        }
        else {
            accordionGroupClasses.push('expanded');
        }
        this.setState({
            accordionExpanded: !this.state.accordionExpanded,
            accordionGroupClasses: accordionGroupClasses,
        })
    }
    optionsDescription() {
        let product = this.props.product;
        if(product) {
            let productOptions = product.options;
            if(productOptions) {
                let allDescriptions = {};
                productOptions.map((option) => {
                    if(option.extension_attributes && option.extension_attributes.description) {
                        allDescriptions[option.extension_attributes.option_code] = option.extension_attributes.description;
                    }
                })
                return allDescriptions;
            }
        }

    }
    optionsRequired() {
        let product = this.props.product;
        if(product) {
            let productOptions = product.options;
            if(productOptions) {
                let allRequired = {};
                productOptions.map((option) => {
                    if(option.is_require && option.extension_attributes) {
                        allRequired[option.extension_attributes.option_code] = true;
                    }
                })
                console.log('options allRequired', allRequired)
                return allRequired;
            }
        }
        return {}

    }

    showCutForm() {
        let product = this.props.product;
        let cutProducts = this.props.cutProducts;
        if(product) {
            let cutType = getCutTypeFromAllLengths(0, product.sku);
            console.log('cut type true', cutType);
            console.log('cut type true', cutProducts);
            if(cutProducts && product.id in cutProducts) {
                let cutProduct =  cutProducts[product.id];
                if(cutType && cutProduct && cutType in cutProduct) {
                    console.log('returning true')
                    return true;
                }
            }
        }
        console.log('returning false')
        return false;

    }

    render(){
        let {product, index, productCustomOptions, lengthConfig, categoryInfo} = {...this.props};
        let lengths = [...this.state.lengths];
        let customErrors = [];
        let customOptionsErrors = {};
        let lengthInformationErrors = {};
        let lengthErrors = [];
        if(this.props.formErrors) {
            lengthInformationErrors = this.props.formErrors['lengthInformation']
            customOptionsErrors = this.props.formErrors['customOptions']
        }
        if(lengthInformationErrors && lengthInformationErrors[product.sku]) {
            lengthErrors = lengthInformationErrors[product.sku];
        }
        if(customOptionsErrors && customOptionsErrors[product.sku]) {
            customErrors = customOptionsErrors[product.sku];
        }
        console.log('customErrors', customErrors);

        let {shippingProducts, customerId, customerEmail,  itemsToDelete} = this.props;
        let shippingProductOriginal = product.extension_attributes.shipping;
        let formShippingErrors = []
        if(this.props.formErrors && this.props.formErrors.shipping) {
            formShippingErrors = this.props.formErrors.shipping;
        }
        let optionsDescription = this.state.optionsDescription;
        let optionsRequired = this.state.optionsRequired;

        let cutProducts = this.props.cutProducts;
        let showAddLength = this.state.showAddLength;

        return (
            <SlideToggle
                duration={1000}
                collapsed={false}
                onMount={({ toggleState, toggle }) => {
                    let element = document.getElementById(`slide-toggle-box-`+product.id)
                    element.style.setProperty('overflow',"unset")
                }}
                render={({
                             toggle,
                             setCollapsibleElement,
                             toggleState,
                             isMoving,
                             hasReversed,
                             range,
                             progress
                         }) => (
                    <div

                        className={
                            "slide-toggle slide-toggle--special accordion-group " +
                            (toggleState || "").toLowerCase()
                        }
                    >
                        <div className="slide-toggle__header">
                            <div className="accordion-header" onClick={toggle} >
                                <h2 dangerouslySetInnerHTML={{__html: getProductLabel(product)}}></h2>
                            </div>
                        </div>
                        <div className="slide-toggle__box" style={{overflow: "unset"}} ref={setCollapsibleElement} id={`slide-toggle-box-`+product.id}>
                            <div
                                className="slide-toggle__box-inner accordion-content"
                                style={{ opacity: Math.max(0.5, range) }}
                            >

                                {<Product
                                    product={product}
                                    productCustomOptions={productCustomOptions}
                                    customErrors={customErrors}
                                    categoryInfo={categoryInfo}
                                    optionsDescription={optionsDescription}
                                    optionsRequired={optionsRequired}
                                />
                                }

                                {this.showCutForm() &&
                                <cut-length-section>
                                    <div className="form-section repeat">
                                        {lengths &&
                                        lengths.map((length, index) =>
                                            <OrderFormStep3Cuts
                                                updateLength={this.updateLength}
                                                key={index}
                                                lengthindex={index}
                                                removeLength={this.removeLength}
                                                lengthInformation={length}
                                                productsku = {product.sku}
                                                product={product}
                                                lengthErrors={lengthErrors?lengthErrors[index]:null}
                                                amountOfCuts={ lengths.length}
                                                categoryInfo={categoryInfo}
                                                itemsToDelete={itemsToDelete}
                                                resetLengths={this.resetLengths}

                                                cutProducts={cutProducts[product.id]}
                                            />
                                        )
                                        }
                                    </div>
                                    {showAddLength && <div className="row">
                                        <div className="col-xs-12">
                                            <div className="repeat-control">
                                                <button type="button" onClick={this.addNewLength}>
                                                    Add Another Length
                                                </button>
                                            </div>
                                        </div>
                                    </div>}
                                </cut-length-section>
                                }
                                {shippingProducts[product.id] && <div className="form-section">
                                    <div className="row">
                                        <div className="form-group col-xs-12">
                                            <h3 className="h-five blue mb-10">{shippingProductOriginal.sku}</h3>
                                        </div>
                                    </div>

                                     <ProductShipping
                                        shippingProduct={shippingProducts[product.id]}
                                        shippingProductOriginal={shippingProductOriginal}
                                        selectedProduct={product}
                                        productsToCartShipping={this.props.shippingProducts}
                                        customerId={customerId}
                                        customerEmail={customerEmail}
                                        itemsToDelete={itemsToDelete}
                                        formErrors={formShippingErrors[product.id]}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                )}
            />
        )
    }
}

export default connect(null, mapDispatchToProps)(OrderFormStep3ProductInfo)

