import {
    VALIDATE_RESET_PASSWORD_TOKEN_FAILURE,
    VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
    VALIDATE_RESET_PASSWORD_TOKEN_REQUEST
} from "./actions";

export default (state = {
    validatePasswordTokenData: null,
    isFetchingValidatePasswordToken: false,
    validatePasswordTokenError: null
}, action) => {
    switch (action.type) {
        case VALIDATE_RESET_PASSWORD_TOKEN_REQUEST:
            return {
                ...state,
                isFetchingValidatePasswordToken: true,
                validatePasswordTokenError: null
            }
        case  VALIDATE_RESET_PASSWORD_TOKEN_FAILURE:
            return {
                ...state,
                isFetchingValidatePasswordToken: false,
                validatePasswordTokenError: action.error,
                validatePasswordTokenData: null
            }
        case VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS:
            return {
                ...state,
                isFetchingValidatePasswordToken: false,
                validatePasswordTokenData: true,
                validatePasswordTokenError: null
            }
        default:
            return state;
    }
}