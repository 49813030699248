import React from 'react';
import LoginForm from "../../components/LoginForm/index";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {userLogoutChangeSuccess} from "../../components/Logout/actions";

const mapDispatchToProps = {
    userLogoutChangeSuccess,
}


class Login extends React.Component
{
    componentWillUnmount() {
        this.props.userLogoutChangeSuccess()
    }

    render(){
        return (
            <div className="row">
                <LoginForm history={this.props.history}/>
            </div>
        )

    }

}

export default withRouter(connect(null, mapDispatchToProps)(Login));
