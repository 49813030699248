import {request} from "../../axiosRequest";
import {shoppingCartAttempt} from "../ShoppingCartListItems/actions";

export const ADD_PRODUCTS_TO_CART_REQUEST = 'ADD_PRODUCTS_TO_CART_REQUEST';
export const ADD_PRODUCTS_TO_CART_SUCCESS = 'ADD_PRODUCTS_TO_CART_SUCCESS';
export const ADD_PRODUCTS_TO_CART_FAILURE = 'ADD_PRODUCTS_TO_CART_FAILURE';
export const CLEANUP_ADD_PRODUCTS_TO_CART = 'CLEANUP_ADD_PRODUCTS_TO_CART';

export const addProductToCartSuccess = (data) =>({
    type: ADD_PRODUCTS_TO_CART_SUCCESS,
    data: data
});

export const addProductToCartRequest = () =>({
    type: ADD_PRODUCTS_TO_CART_REQUEST,
});

export const addProductToCartFailure = (error) =>({
    type: ADD_PRODUCTS_TO_CART_FAILURE,
    error: error
});

export const cleanUpAddProductsToCart = () => ({
   type: CLEANUP_ADD_PRODUCTS_TO_CART
});

export const addProductToCart = (products, customerId, itemsToDelete = [], cartId = null, callBack) => {
    return (dispatch) => {
        dispatch(addProductToCartRequest());
        request.post('carts/addItems',
            {
                products: products,
                'itemsToDelete': itemsToDelete,
                'customer_id': customerId,
                'cart_id': cartId
            },true)
            .then(response => { return response; })
            .then(result => {
                let data = result.data;
                if(result.status == 200){
                    console.log("addCart")
                    console.log(data);
                    console.log(data[0]);
                    if(data.success && data.code === 200) {
                        if(callBack && typeof callBack == "function") {
                            callBack();
                        }
                        else {
                            dispatch(shoppingCartAttempt(customerId));
                            dispatch(cleanUpAddProductsToCart());
                            dispatch(addProductToCartSuccess(data.data));
                        }
                    }
                    else {
                        dispatch(addProductToCartFailure(data.data.message))
                    }
                }
                else {
                    dispatch(addProductToCartFailure(result))
                }
            })
            .catch(error => {
                    if(error.response && error.response.data) {
                        dispatch(addProductToCartFailure(error.response.data));
                    } else {
                        // let message = error.response.data.data.message
                        dispatch(addProductToCartFailure(error));
                    }

                }
            )

    }
};
