import { combineReducers } from 'redux';
import ordersList from "./containers/Orders/reducer";
import customerInformation from "./containers/CustomerInformation/reducer";
import auth from "./components/LoginForm/reducer";
import createAccount from "./components/CreateAccountForm/reducer";
import cart from "./components/ShoppingCartListItems/reducer";
import cartTotals from "./components/shoppingCartTotals/reducer";
import {routerReducer} from "react-router-redux";
import checkout from "./containers/Checkout/reducer";
import orderDetails from "./components/OrderDetails/reducer";
import tours from "./containers/Tours/reducer";
import subCategories from "./components/ToursDetailsCenter/reducer";
import orderProcess from "./containers/OrderFormStep1/reducer";
import tourDesignCenter from "./components/ToursDetailsCenter/reducer";
import productsToCart from "./components/UpdateCartItemButton/reducer";
import shoppingCartEditItem from "./containers/ShoppingCartEditItem/reducer";
import accountEditAddressForm from "./components/AccountEditAddressForm/reducer";
import artistTours from "./containers/ArtistTours/reducer";
import roster from "./containers/ArtistRoster/reducer"
import resetPassword from "./components/AccountResetPassword/reducer"
import dashboard from "./containers/Dashboard/reducer"
import countriesData from "./components/Countries/reducer"
import categoriesHome from "./components/Home/reducer"
import orderProcessTour from "./components/OrderProcessTour/reducer"
import staticPages from "./components/StaticPage/reducer"
import orderToPrint from "./components/OrderToPrint/reducer"
import validatePasswordToken from "./containers/AccountValidateToken/reducer"
import footerBlock from "./components/Footer/reducer"
import addShipping from "./containers/OrderShipping/reducer"
import reviseOrder from "./containers/OrderRevise/reducer"
import holidayMessageReducer from "./components/HolidayMessage/reducer"

export default combineReducers({
    auth,
    ordersList,
    customerInformation,
    resetPassword,
    cart,
    cartTotals,
    routerReducer,
    createAccount,
    checkout,
    orderDetails,
    tours,
    subCategories,
    orderProcess,
    tourDesignCenter,
    productsToCart,
    shoppingCartEditItem,
    accountEditAddressForm,
    artistTours,
    roster,
    dashboard,
    countriesData,
    categoriesHome,
    orderProcessTour,
    staticPages,
    orderToPrint,
    validatePasswordToken,
    footerBlock,
    addShipping,
    reviseOrder,
    holidayMessageReducer

})
