import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";
import {FIELDS_CODE} from "../../actions/Constants";
import {getInfoToShow} from "../../helpers"
import OrderItemShipping from "../../components/OrderItemShipping"

class OrderItem extends React.Component{

    constructor(props) {
        super(props);
    }

    isShipping(item)
    {
        if(item.extension_attributes.product_type == 'shipping') {
            return true;
        }
        return false;
    }
    isRevision(item)
    {
        if(item.extension_attributes.product_type == 'revision_product') {
            return true;
        }
        return false;
    }
    isCut(item)
    {
        if(item.extension_attributes.product_type == 'cut') {
            return true;
        }
        return false;
    }
    render(){
        let {orderItem} = this.props;
        let data = getInfoToShow(orderItem);
        console.log("data", data)
        let isShipping = this.isShipping(orderItem);
        let isRevision = this.isRevision(orderItem);
        let isCut = this.isCut(orderItem);
        if(isShipping || isRevision || isCut)
        {
            return (
                <OrderItemShipping orderItem={orderItem}/>
            )
        }
        else {
            return (
                <tr>
                    <td className="left">
                        <h4>{orderItem.name}</h4>
                        {data && <dl>
                            <dt>Show Date: </dt>
                            <dd>{data[FIELDS_CODE.show_date]}</dd>
                            <dt>Venue Name:</dt>
                            <dd>{data[FIELDS_CODE.venue_name]}</dd>
                            <dt>City / Market:</dt>
                            <dd>{data[FIELDS_CODE.city_market_name]}</dd>
                        </dl>
                        }
                    </td>
                    <td className="right">${orderItem.price}</td>
                </tr>
            )
        }
    }
}

export default connect(null, null)(OrderItem)
