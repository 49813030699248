import React from "react"
import {connect} from "react-redux"
import {
    getProductsByCategory,
    setProductsToCart,
    setProductsShows,
    setProductsToCartDetails,
    cleanUpOrderProcessState
} from "./actions";
import OrderFormStep1ProductList from "../../components/OrderFormStep1ProductList"
import {setSelectedProducts} from "../../components/OrderFormStep1ProductList/actions";
import OrderFormStep2 from "../OrderFormStep2";
import OrderFormStep3 from "../OrderFormStep3";
import {CDN_URL, getFieldsByProductSku} from "../../actions/Constants";
import {getFieldsToShowOnVenueSection} from "../../helpers";

const mapDispatchToProps = {
    getProductsByCategory,
    setSelectedProducts,
    setProductsToCart,
    setProductsShows,
    setProductsToCartDetails,
    cleanUpOrderProcessState,
}

const mapStateToProps = state => ({
    ...state.orderProcess,
    ...state.cart
})


class OrderFormStep1 extends React.Component{

    constructor(props)
    {
        super(props)
        this.state = {
          currentStep: "step1",
            productsToCart: [],
            backgroundImage: `${CDN_URL}assets/backgrounds/bg-order.jpg`

        };
        this.nextStep = this.nextStep.bind(this);
        this.goBack = this.goBack.bind(this);
        this.setProductsToCart = this.setProductsToCart.bind(this);
    }

    componentDidMount() {
        let {categoryId} = this.props.match.params;
        console.log('propsss' ,this.props);
        console.log(this.props.categoryId);
        this.props.cleanUpOrderProcessState();
        if(this.props.categoryId == null || categoryId != this.props.categoryId){
            this.props.setSelectedProducts({});
            this.props.getProductsByCategory(categoryId);
        }
        if(this.props.location.backgroundImage && this.props.location.backgroundImage !== '') {
            this.setState({
                backgroundImage: this.props.location.backgroundImage
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {categoryId} = this.props.match.params;
        if(prevProps.match.params.categoryId !== categoryId){
            this.props.getProductsByCategory(categoryId);
            this.props.setSelectedProducts({});
            this.setState({categoryId: categoryId});
        }
        if(this.state.currentStep != prevState.currentStep) {
            window.scrollTo(0,0);
        }
        let categoryInfo = this.props.categoryInfo;
        if(prevProps.categoryInfo != categoryInfo) {
            if(categoryInfo.category_background_image) {
                this.setState({
                    backgroundImage: categoryInfo.category_background_image
                })
            }
        }
    }
    componentWillUnmount() {
        this.props.cleanUpOrderProcessState()
    }

    getFieldsToShow() {
        let selectedProducts = this.props.selectedProducts;
        let allFields = getFieldsToShowOnVenueSection(selectedProducts);
        this.fieldsToShow = allFields;
        return allFields;
    }

    nextStep(data = null){
        let {currentStep} = this.state;
        switch (currentStep) {
            case 'step1':
                let fieldsToShow = this.getFieldsToShow();
                if(fieldsToShow.length > 0){
                    currentStep = 'step2';
                } else {
                    currentStep = 'step3';
                }
                this.setProductsToCart();
                break;
            case 'step2':
                if(data.shows){
                    this.setProductsShows(data.shows);
                }
                if(data.details) {
                    this.props.setProductsToCartDetails(data.details)
                }
                currentStep = 'step3';
                break;
            default:
                currentStep = 'step1';
        }
        this.setState({
            currentStep: currentStep
        })
    }

    goBack()
    {
        let {currentStep} = this.state;
        switch (currentStep) {
            case 'step3':
                let fieldsToShow = this.getFieldsToShow();
                if(fieldsToShow.length > 0){
                    currentStep = 'step1';
                } else {
                    currentStep = 'step2';
                }
                break;
            case 'step2':
                currentStep = 'step1';
                break;
            default:
                currentStep = 'step1';
        }
        this.setState({
            currentStep: currentStep
        })
    }
    setProductsShows(shows)
    {
        this.props.setProductsShows(shows)
    }
    setProductsToCart()
    {
        let {selectedProducts, cart} = this.props;
        let productsToCart = [];
        Object.keys(selectedProducts).map((index) =>{
            let selectedProduct = selectedProducts[index];
            let product = {
                sku: selectedProduct.sku,
                qty: 1,
                name: selectedProduct.name,
                price: selectedProduct.price,
                product_type: selectedProduct.type_id,
                quote_id: cart.id,
            };
            productsToCart.push(product)
        })
        this.props.setProductsToCart(productsToCart);
        this.setState({
            productsToCart: productsToCart,
        })
    }

    render() {
        let {currentStep} = this.state;
        let {isFetchingProductByCategory, categoryInfo, isFetchingCartItemsList} = this.props;
        let backgroundImage = this.state.backgroundImage;
        switch (currentStep) {
            case 'step1':
                let {productsTypes} = this.props;
                let {categoryId} = this.props.match.params;
                let {categoryName} = this.props.location;
                if(isFetchingProductByCategory) {
                    return (
                        <main role="main" style={{backgroundImage: `url(${backgroundImage})`, backgroundColor: 'black'}}>
                            <div id="content" className="container fullscreen-checkout">
                                <div id="order-form" className="step-one row">
                                    <div className="col-xs-12">
                                        <div className="loading-black">
                                            <i className="icon-loading"></i>
                                            <span>Loading Order Form</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    )
                }
                else {
                    return (
                        <main role="main" style={{backgroundImage: `url(${backgroundImage})`, backgroundColor: 'black'}}>
                            <div id="content" className="container fullscreen-checkout">
                                <div id="order-form" className="step-one row">
                                    <div className="col-xs-12">
                                        {productsTypes &&
                                        <OrderFormStep1ProductList nextStep={this.nextStep}
                                                                   productsTypes={productsTypes}
                                                                   categoryId={categoryId}
                                                                   categoryInfo={categoryInfo}
                                                                   isFetchingCartItemsList={isFetchingCartItemsList}

                                        />}
                                    </div>
                                </div>
                            </div>
                        </main>
                    )
                }
                break;
            case 'step2':
                return (
                    <OrderFormStep2 nextStep={this.nextStep} goBack={this.goBack}
                                    selectedProducts={this.props.selectedProducts}
                                    categoryInfo={categoryInfo}
                                    cart={this.props.cart}
                    />
                )
                break;
            case 'step3':
                return (
                    <OrderFormStep3
                        goBack={this.goBack}
                        categoryInfo={categoryInfo}
                    />
                )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFormStep1)
