import {
    PAYMENT_INFORMATION_AVAILABLE_FAILURE,
    PAYMENT_INFORMATION_AVAILABLE_SUCCESS,
    PAYMENT_INFORMATION_AVAILABLE_REQUEST,
    PLACE_ORDER_REQUEST,
    PLACE_ORDER_SUCCESS,
    PLACE_ORDER_FAILURE,
    CHECKOUT_FINISHED
} from "./actions";

export default (state = {
    paymentInformation: null,
    isFetchingPlaceOrder: false,
    processedOrders: null,
    placeOrderError: null,
    placeOrder: false,
    checkOutSuccess: false,
    error: null
}, action) => {
    switch (action.type) {
        case PAYMENT_INFORMATION_AVAILABLE_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case  PAYMENT_INFORMATION_AVAILABLE_FAILURE:
            return {
                ...state,
                isFetching: false,
                paymentInformation: null,
                error: action.error
            }
        case PAYMENT_INFORMATION_AVAILABLE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                paymentInformation: action.data
            }
        case PLACE_ORDER_REQUEST:
            return {
                ...state,
                isFetchingPlaceOrder: true
            }
        case PLACE_ORDER_SUCCESS:
            return {
                ...state,
                isFetchingPlaceOrder: false,
                processedOrders: action.data,
                placeOrder: true,
                checkOutSuccess: true,

            }
        case PLACE_ORDER_FAILURE:
            return {
                ...state,
                isFetchingPlaceOrder: false,
                placeOrderError: action.error
            };
        case CHECKOUT_FINISHED:
            return {
                ...state,
                checkOutSuccess: false,
                placeOrderError: null,
                isFetchingPlaceOrder: false
            }
        default:
            return state;
    }
}