export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_CHANGE_SUCCESS = 'USER_LOGOUT_CHANGE_SUCCESS'

export const userLogout = () => {
    return {
        type: USER_LOGOUT
    }
}
export const userLogoutChangeSuccess = () => {
    return {
        type: USER_LOGOUT_CHANGE_SUCCESS
    }
}