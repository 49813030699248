import React from "react"
import {connect} from "react-redux"
import CheckoutCartItems from "../CheckoutCartItems"

class CheckoutOrderReview extends React.Component{
    render(){
        let {cart} = this.props;
        return (
            <div className="col-md-4">
                <h2 className="order-review h-five">Order Review</h2>
                <p>Please review everything before completing your purchase.</p>
                {cart && <CheckoutCartItems items={cart.items} cartTotals={cart.totals}/>}
            </div>
        )
    }
}

export default connect(null, null)(CheckoutOrderReview)