import {request} from "../../axiosRequest";
import {shoppingCartTotalsSuccess} from "../shoppingCartTotals/actions";

export const SHOPPING_CART_REQUEST = 'SHOPPING_CART_REQUEST';
export const SHOPPING_CART_SUCCESS = 'SHOPPING_CART_SUCCESS';
export const SHOPPING_CART_FAILURE = 'SHOPPING_CART_FAILURE';
export const SHOPPING_CART_CLEANUP = 'SHOPPING_CART_CLEANUP';
export const SET_SHOPPING_CART_ITEMS = 'SET_SHOPPING_CART_ITEMS';

export const shoppingCartRequest = () => ({
    type: SHOPPING_CART_REQUEST
});
export const shoppingCartSuccess = (data) => ({
    type: SHOPPING_CART_SUCCESS,
    data: data
});
export const shoppingCartFailure = (error) => ({
    type: SHOPPING_CART_FAILURE,
    error: error
});

export const shoppingCartCleanUp = () => ({
    type: SHOPPING_CART_CLEANUP
})

export const setShoppingCartItems = (data) => ({
    type: SET_SHOPPING_CART_ITEMS,
    data: data
})

export const shoppingCartCleanUpRequest = (customerId) => {
    return (dispatch) => {
        let url = `carts/create`;
        return request.get(url,true)
          .then(response => {return response})
          .then(result => {
                if(result.data) {
                    dispatch(shoppingCartAttempt(customerId, result.data.id))
                }
          })
          .catch(error => {
              console.log(error);
              dispatch(shoppingCartFailure(error))
          })
    }
}

export const shoppingCartAttempt = (customerId, cartId) => {
    return (dispatch) => {
        dispatch(shoppingCartRequest());
        console.log('requesting cart');
        let url = `customers/${customerId}/cart`;
        if(cartId) {
            url += `/${cartId}`;
        }
        return request.get(url,true)
            .then(response => {return response})
            .then(result => {
                console.log(result);
                let data = result.data
                if(data.success)
                {
                    console.log("updating cart");
                    switch (data.code) {
                        case 200:
                            dispatch(shoppingCartSuccess(data.data));
                            dispatch(shoppingCartTotalsSuccess(data.data.totals));
                            break;
                        case 404:
                            dispatch(shoppingCartSuccess([]));
                            break;
                        default:
                            dispatch(shoppingCartFailure(data.data))
                    }
                    console.log(data)
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(shoppingCartFailure(error))
            })
    }
};
