import {
    CREATE_ACCOUNT_FAILURE,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_FETCHING,
    CREATE_ACCOUNT_CLEANUP,
} from "../../components/CreateAccountForm/actions";

export default (state = {
    newCustomerInfo: [],
    isFetchingCreateAccount: false,
    createAccountSuccess: false,
    createAccountError: null
}, action) => {
    switch (action.type) {
        case CREATE_ACCOUNT_FETCHING:
            return {
                ...state,
                isFetchingCreateAccount: true,
                createAccountError: null
            }
        case  CREATE_ACCOUNT_FAILURE:
            return {
                ...state,
                isFetchingCreateAccount: false,
                orders: [],
                createAccountError: action.error,
                createAccountSuccess: false
            }
        case CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isFetchingCreateAccount: false,
                newCustomerInfo: action.data,
                createAccountSuccess: true,
                createAccountError: null
            }
        case CREATE_ACCOUNT_CLEANUP:
            return {
                ...state,
                isFetchingCreateAccount: false,
                newCustomerInfo: null,
                createAccountSuccess: false,
                createAccountError: null
            }
        default:
            return state;
    }
}