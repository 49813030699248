import {request} from "../../axiosRequest";

export const ORDER_TO_PRINT_REQUEST = 'ORDER_TO_PRINT_REQUEST';
export const ORDER_TO_PRINT_SUCCESS = 'ORDER_TO_PRINT_SUCCESS';
export const ORDER_TO_PRINT_FAILURE = 'ORDER_TO_PRINT_FAILURE';

export const orderToPrintRequest = () =>({
    type: ORDER_TO_PRINT_REQUEST
});
export const orderToPrintSuccess = (data) =>({
    type: ORDER_TO_PRINT_SUCCESS,
    data: data
});
export const orderToPrintFailure = (error) =>({
    type: ORDER_TO_PRINT_FAILURE,
    error: error
});


export const getOrderToPrintAttempt = (orderToPrintId) => {
    return (dispatch) => {
        dispatch(orderToPrintRequest());
        return request.get(`orders/${orderToPrintId}`,true)
            .then(response => {return response})
            .then(result => {
                let data = result.data
                if(data.success && data.code == 200){
                    dispatch(orderToPrintSuccess(data.data));
                    console.log(data.data)
                }
                else{
                    dispatch(orderToPrintFailure(data.data))
                }
                console.log(result);
            })
            .catch(error => {
                console.log(error);
                dispatch(orderToPrintFailure(error));
            })
    }
}
