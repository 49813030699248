import {
    GET_CATEGORY_BY_ARTIST_FAILURE,
    GET_CATEGORY_BY_ARTIST_SUCCESS,
    GET_CATEGORY_BY_ARTIST_REQUEST
} from "./actions";

export default (state = {
    artistTours: null,
    isFetchingArtistTours: false,
    artistToursRequestError: null,
    artistData: null
}, action) => {
    switch (action.type) {
        case GET_CATEGORY_BY_ARTIST_REQUEST:
            return {
                ...state,
                isFetchingArtistTours: true,
            }
        case  GET_CATEGORY_BY_ARTIST_FAILURE:
            return {
                ...state,
                isFetchingArtistTours: false,
                artistRostersRequestError: action.error
            }
        case GET_CATEGORY_BY_ARTIST_SUCCESS:
            return {
                ...state,
                isFetchingArtistTours: false,
                artistTours: action.data.items,
                artistData: action.data.artist
            }
        default:
            return state;
    }
}