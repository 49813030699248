import {
    CATEGORIES_HOME_FAILURE,
    CATEGORIES_HOME_SUCCESS,
    CATEGORIES_HOME_REQUEST
} from "./actions";

export default (state = {
    homeDemos: null,
    isFetchingHomeDemos: false,
    homeDemosError: null
}, action) => {
    switch (action.type) {
        case CATEGORIES_HOME_REQUEST:
            return {
                ...state,
                isFetchingHomeDemos: true,
            }
        case  CATEGORIES_HOME_FAILURE:
            return {
                ...state,
                isFetchingHomeDemos: false,
                homeDemosError: action.error
            }
        case CATEGORIES_HOME_SUCCESS:
            return {
                ...state,
                isFetchingHomeDemos: false,
                homeDemos: action.data,
                homeDemosError: null
            }
        default:
            return state;
    }
}