export const field_codes = {
    delivery_options: {
        code: 'delivery_options',
        sku: {
            email_address: 'email_address'
        }
    },
    email_address: 'email_address',
};

export const shipping_fields = {

    [field_codes.delivery_options.code]: 'email_address',
    [field_codes.email_address]: '',
}