import {request} from "../../axiosRequest";

export const ORDER_REQUEST = 'ORDER_REQUEST';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_FAILURE = 'ORDER_FAILURE';

export const orderRequest = () =>({
    type: ORDER_REQUEST
});
export const orderSuccess = (data) =>({
    type: ORDER_SUCCESS,
    data: data
});
export const orderFailure = (error) =>({
    type: ORDER_FAILURE,
    error: error
});


export const getOrderAttempt = (orderId) => {
    return (dispatch) => {
        dispatch(orderRequest());
        return request.get(`orders/${orderId}`,true)
            .then(response => {return response})
            .then(result => {
                let data = result.data
                if(data.success && data.code == 200){
                    dispatch(orderSuccess(data.data));
                    console.log(data.data)
                }
                else{
                    dispatch(orderFailure(data.data))
                }
                console.log(result);
            })
            .catch(error => {
                console.log(error);
                dispatch(orderFailure(error));
            })
    }
}
