import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";
import {footerBlockAttempt} from "./actions"
import footerBlock from "./reducer";
import {func} from "prop-types";
import {CDN_URL} from "../../actions/Constants";
import ConvertParseObject from '.././../components/ConvertParseObject'

const HtmlToReactParser = require('html-to-react').Parser;
let htmlToReactParser = new HtmlToReactParser();

const mapDispatchToProps = {
    footerBlockAttempt,
}

const mapStateToProps = state => ({
    ...state.footerBlock
})


class Footer extends React.Component{
    componentDidMount() {
        if(!this.props.footerBlockData) {
            this.props.footerBlockAttempt();
        }
    }

    render(){
        let footerBlock = this.props.footerBLockData
        const today = new Date();
        let reactElement = null;
        if(footerBlock)
        {
            footerBlock = footerBlock.replace(/[\r\n]+/gm, "");
            console.log('footerBlock', footerBlock)
            reactElement = htmlToReactParser.parse(footerBlock);
        }
        console.log('reactElement', reactElement)
        return (
            <footer id="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div id="footer-legal" className="col-lg-5 col-md-4">
                            <small>&copy; {today.getFullYear()} TourDesign Creative. All Rights Reserved.</small>
                        </div>
                        <div id="footer-social" className="col-lg-2 col-md-4">
                            <nav>
                                <ul>
                                    <li key={0}><a href="https://www.facebook.com/TourdesignCreative/" target="_blank"><img src={`${CDN_URL}assets/icon-footer-facebook.svg`} alt="Facebook"/></a></li>
                                    <li key={1}><a href="https://www.instagram.com/tourdesigncreative/" target="_blank"><img src={`${CDN_URL}assets/icon-footer-instagram.svg`} alt="Instagram"/></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div id="footer-navigation" className="col-lg-5 col-md-4">
                            <nav>
                                {reactElement && <ConvertParseObject element={reactElement}/>}
                                {/*<ul>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    <li><Link to="/terms">Terms &amp; Conditions</Link></li>
                                    <li><Link to="/ad-choices">Ad Choices</Link></li>
                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                    <li><Link to="/faqs">FAQs</Link></li>
                                </ul>*/}
                            </nav>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)


