import {custom_options} from "../AlbumCommonOptions/definition"
import {fields as common_fields} from "../AlbumCommonOptions/definition"

export const custom_fields = {
    ...custom_options,
    on_sale_date_time_text: 'on_sale_date_time_text',
    audio_tags: {
        code: 'audio_tags',
        sku: {
            spotify: 'spotify',
            apple: 'apple',
            itunes: 'itunes',
            amazon: 'amazon',
            pandora: 'pandora',
            deezer: 'dezeer',
            youtube: 'youtube',
            available_now: 'available_now'
        }
    }
};

export const fields = {
    ...common_fields,
    [custom_fields.on_sale_date_time_text]: '',
    [custom_fields.audio_tags.code]: '',
}
