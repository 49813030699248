/*import {
    SUBCATEGORIES_REQUEST,
    SUBCATEGORIES_SUCCESS,
    SUBCATEGORIES_FAILURE
} from "./actions";

export default (state = {
    subCategories: null,
    isFetchingToursCenter: false
}, action) => {
    switch (action.type){
        case SUBCATEGORIES_REQUEST:
            return {
                ...state,
                isFetchingToursCenter: true,
            }
        case  SUBCATEGORIES_SUCCESS:
            return {
                ...state,
                isFetchingToursCenter: false,
                subCategories: action.data.items,
            }
        case  SUBCATEGORIES_FAILURE:
            return {
                ...state,
                isFetchingToursCenter: false,
                error: action.error
            }
        default:
            return state;
    }
}*/

import {
    TOUR_DEMO_REQUEST,
    TOUR_DEMO_SUCCESS,
    TOUR_DEMO_FAILURE
} from "./actions";

export default (state = {
    subCategories: null,
    isFetchingToursCenter: false
}, action) => {
    switch (action.type){
        case TOUR_DEMO_REQUEST:
            return {
                ...state,
                isFetchingToursCenter: true,
            }
        case  TOUR_DEMO_SUCCESS:
            return {
                ...state,
                isFetchingToursCenter: false,
                subCategories: action.data,
            }
        case  TOUR_DEMO_FAILURE:
            return {
                ...state,
                isFetchingToursCenter: false,
                error: action.error
            }
        default:
            return state;
    }
}