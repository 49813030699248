import {
    ADD_PRODUCTS_TO_CART_FAILURE,
    ADD_PRODUCTS_TO_CART_SUCCESS,
    ADD_PRODUCTS_TO_CART_REQUEST, CLEANUP_ADD_PRODUCTS_TO_CART
} from "./actions";

export default (state = {
    addProductsToCart: null,
    success: false,
    isFetchingAddProductToCart: false,
    addProductsToCartError: null
}, action) => {
    switch (action.type) {
        case ADD_PRODUCTS_TO_CART_REQUEST:
            return {
                ...state,
                isFetchingAddProductToCart: true,
                success: false
            };
        case  ADD_PRODUCTS_TO_CART_FAILURE:
            return {
                ...state,
                isFetchingAddProductToCart: false,
                addProductsToCartError: action.error,
                success: false
            };
        case ADD_PRODUCTS_TO_CART_SUCCESS:
            return {
                ...state,
                isFetchingAddProductToCart: false,
                addProductsToCart: action.data,
                success: true,
                addProductsToCartError: null
            };
        case CLEANUP_ADD_PRODUCTS_TO_CART:
            return {
                ...state,
                isFetchingAddProductToCart: false,
                addProductsToCart: null,
                success: false,
                addProductsToCartError: null
            };
        default:
            return state;
    }
}