import React from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom";
import {resetPassword, resetPasswordCleanUp} from "./actions";
import {validEmailRegex} from "../../helpers";

const mapDispatchToProps = {
    resetPassword,
    resetPasswordCleanUp
}

const mapStateToProps = state => ({
    ...state.resetPassword
})

class AccountResetPassword extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: null
        };
        this.goToLogin = this.goToLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
    }
    componentWillUnmount() {
        this.props.resetPasswordCleanUp()
    }

    handleChange(event)
    {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    isFormValid()
    {
        let email = this.state.email;
        let error = undefined;
        if(email == '') {
            error = "Please enter the email address associated with your account.";
        }
        if(email != '' && !validEmailRegex.test(email)){
            error = 'Please enter a valid email address.';
        }
        this.setState({
            errors: error
        })
        if (error) {
            return false;
        }
        return true;
    }
    resetPassword(event) {
        event.preventDefault();
        if(this.isFormValid()) {
            this.props.resetPassword(this.state.email)
        }
    }

    goToLogin()
    {
        this.props.history.push('/account/login');
    }
    getErrorMessage() {
        let resetPasswordError = this.props.resetPasswordError;
        let message = resetPasswordError.message;
        if(resetPasswordError.parameters) {
            for(let parameter in resetPasswordError.parameters) {
                message = message.replace(`%${parameter}`, resetPasswordError.parameters[parameter])
            }
        }
        return message;
    }
    render(){
        let {isFetchingResetPassword, resetPasswordError, emailSent} = this.props;
        let {errors} = this.state;
        if(isFetchingResetPassword) {
            return (
                <div className="row">
                    <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 center">
                        <div className="loading-small">
                            <i className="icon-loading"></i>
                            <span>Sending New Password</span>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="row">
                <div className="col-xs-12 center">
                    <h2>Reset Password</h2>
                </div>
                <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 center">
                    {resetPasswordError && <div className="alert error">
                        <p>{resetPasswordError}</p>
                    </div>}
                    {resetPasswordError && <div className="alert error">
                        <p>{resetPasswordError}</p>
                    </div>}
                    {errors && <div className="alert error">
                        <p>{errors}</p>
                    </div>}
                    {emailSent && <div className="alert success">
                        <p>Instructions on resetting your password will be sent to the email address provided if it exists in our system.</p>
                    </div>}
                    <form onsubmit={this.resetPassword}>
                        <input type="email" placeholder="Email Address"
                               name='email'
                               value={this.state.email}
                               onChange={this.handleChange}
                               className={errors?'error':''}
                        />
                        <button type="submit" className="button small blue-white" onClick={this.resetPassword}>
                            Reset Password
                        </button>
                        <button id="button-cancel-reset-password" type="button" onClick={this.goToLogin}
                                className="button-text light">I remember my password.
                        </button>
                    </form>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountResetPassword))
