import React from "react"
import {connect} from "react-redux"
import {numberFormat} from "../../helpers";

class CheckoutCartTotal extends React.Component{
    render(){
        let cartTotals = this.props.cartTotals;
        return (
            <div className="cart-options">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="checkout-total">
                            <table width="100%">
                                <tbody>
                                <tr className="subtotal">
                                    <td>Subtotal:</td>
                                    <td width="10">{numberFormat(cartTotals.subtotal)}</td>
                                </tr>
                                <tr className="total">
                                    <td>Estimated Grand Total:</td>
                                    <td width="10">{numberFormat(cartTotals.grand_total)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <button type="submit" className="button medium blue">Place Order</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, null)(CheckoutCartTotal)
