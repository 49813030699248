import React from "react"
import {connect} from "react-redux"
import {addProductToCart} from "./actions";
import {cleanUpAddProductsToCart} from "./actions";
import {withRouter} from 'react-router-dom'
import {FIELDS_CODE, isPkgProduct} from "../../actions/Constants";
import uuid from "uuid";
import {cleanUpOrderProcessState} from "../../containers/OrderFormStep1/actions"
import {setCutTypeToAllCutLength} from "../../helpers";
import moment from "moment";

const mapDispatchToProps = {
    addProductToCart,
    cleanUpAddProductsToCart,
    cleanUpOrderProcessState,
};

const mapStateToProps = state => ({
    ...state.productsToCart,
    ...state.cart,
    ...state.customerInformation,
})


class UpdateCartItemProduct extends React.Component{
    constructor(props) {
        super(props);
        this.addProductsToCart = this.addProductsToCart.bind(this);
        this._getOptionByCode = this._getOptionByCode.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.success)
        {
            let categoryInfo = this.props.categoryInfo;
            let {itemId} = this.props;
            this.props.cleanUpAddProductsToCart();
            this.props.cleanUpOrderProcessState();
            this.props.history.push({
                pathname: '/cart',
                artistName: categoryInfo.category_artist_name,
                categoryName: categoryInfo.category_name,
                itemsAdded: itemId?false:true,
                itemUpdated: itemId?true:false,
            })
        }
    }

    _getOptionByCode(product, code) {
        let option = null;
        for(let optionIndex in product.options) {
            let option = product.options[optionIndex];
            if(option.extension_attributes && option.extension_attributes.option_code == code) {
                return option;
            }
        }
        if(option == null) {
            console.log("option id null")
            console.log(code);
        }
        return option
    }
    _generateItems(productData, selectedProducts, customOptionsList) {
        let newOptions = [];
        let selectedProduct = null;
        for (let selectedProductId in selectedProducts) {
            if (selectedProducts[selectedProductId].sku == productData.sku) {
                console.log('selected product', selectedProducts);
                selectedProduct = selectedProducts[selectedProductId];
                break;
            }
        }
        customOptionsList = customOptionsList.concat([{group_item: 'groupItem'}])
        console.log('customOptionsList', customOptionsList);
        for(let customOptionIndex in customOptionsList) {
            let customOption = customOptionsList[customOptionIndex];
            if(customOption) {
                let custom_option = Object.keys(customOption).map((fieldCode) => {

                    let option = this._getOptionByCode(selectedProduct, fieldCode);
                    console.log('option', option);
                    console.log('fieldCode: ', fieldCode);
                    let fieldValue = customOption[fieldCode];
                    if (fieldValue == null) {
                        return;
                    }
                    console.log('customOption:', customOption);
                    let optionValueWithQTY = {};
                    if (option && (option.type == "checkbox" || option.type == 'radio' || option.type == 'drop_down')) {
                        let optionValues = option.values.map((optionValue) => {
                            let optionTypeId = optionValue.option_type_id;
                            let optionValueSku = optionValue.sku;

                            if (option.type == "checkbox" && fieldValue[optionValueSku]) {
                                if(option.extension_attributes && option.extension_attributes.has_qty) {

                                    if((optionValueSku + '_qty') in customOption){
                                        console.log('has_qty', customOption[optionValueSku + '_qty'])
                                        optionValueWithQTY[optionTypeId] = customOption[optionValueSku + '_qty'];
                                    }
                                }
                                return optionTypeId.toString();
                            }
                            if ((option.type == 'radio' || option.type == 'drop_down') && fieldValue == optionValueSku) {
                                return optionTypeId;
                            }
                            console.log("checkbox", [optionValue, fieldValue]);
                        });
                        console.log("OptionValues", optionValues);
                        fieldValue = optionValues.filter((optionValueItem) => {
                            if (optionValueItem && optionValueItem != '') {
                                return optionValueItem;
                            }
                        });

                        fieldValue = fieldValue.toString();

                    }
                    if (option) {
                        let optionToReturn = {};
                        switch (option.type) {
                            case 'file':
                                console.log('logo file', fieldValue)
                                if (fieldValue && typeof fieldValue != "string") {
                                    console.log('logo file', fieldValue)
                                    optionToReturn = {
                                        option_code: fieldCode,
                                        option_id: option.option_id,
                                        option_value: "file",
                                        extension_attributes: {
                                            file_info: {
                                                base64_encoded_data: fieldValue.base64_encoded_data,
                                                type: fieldValue.type,
                                                name: fieldValue.name
                                            },
                                        }
                                    }
                                }
                                else{
                                    if(typeof fieldValue == 'string' && fieldValue !== "") {
                                        optionToReturn = {
                                            option_code: fieldCode,
                                            option_id: option.option_id,
                                            option_value: 'save_old',
                                        }
                                    }
                                    else {
                                        optionToReturn = {
                                            option_code: fieldCode,
                                            option_id: option.option_id,
                                            option_value: null
                                        }
                                    }
                                }
                                break
                            case 'date_time':
                                if(fieldValue !== ''){
                                    let date = new Date(fieldValue);
                                    let minutes = date.getMinutes().toString();
                                    if(minutes.length === 1) {
                                        minutes = "0" + minutes;
                                    }
                                    console.log('date_time option', fieldValue)
                                    let hours = date.getHours();
                                    let dateFormat = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${hours}:${minutes}:00`
                                    if(moment(fieldValue).isValid()) {
                                        let momentDate = moment(fieldValue);
                                        dateFormat = momentDate.format('YYYY-MM-DD HH:mm:ss')
                                    }
                                    fieldValue = dateFormat;
                                }
                                console.log('date_time', fieldValue)
                                optionToReturn = {
                                    option_code: fieldCode,
                                    option_id: option.option_id,
                                    option_value: fieldValue
                                }
                                break
                            default:
                                optionToReturn = {
                                    option_code: fieldCode,
                                    option_id: option.option_id,
                                    option_value: fieldValue
                                }
                        }
                        console.log('optionValueWithQTY', optionToReturn);
                        if(Object.keys(optionValueWithQTY).length > 0) {
                            if(optionToReturn.extension_attributes) {
                                optionToReturn.extension_attributes = {
                                    ...optionToReturn.extension_attributes,
                                    option_qty: JSON.stringify({values: optionValueWithQTY})
                                }
                            }
                            else {
                                optionToReturn.extension_attributes = {
                                    option_qty: JSON.stringify({values: optionValueWithQTY})
                                }
                            }
                        }
                        return optionToReturn;
                    }

                });
                custom_option = custom_option.filter((item) => {
                    console.log(item);
                    if (item && (item.option_value && item.option_value != '')) {
                        return item
                    }
                });

                newOptions = newOptions.concat(custom_option);
            }
        }
        console.log('custom_option_aaa', newOptions);
        let oldProductsOptions = [];
        if(productData.product_option && productData.product_option.extension_attributes
            && productData.product_option.extension_attributes.custom_options) {
            oldProductsOptions = productData.product_option.extension_attributes.custom_options;
        }
        let productOptions = {
            extension_attributes: {
                custom_options: oldProductsOptions.concat([...newOptions])
            }
        };
        let productToReturn = {
            name: productData.name,
            group_item: productData.group_item,
            cart_group_item: productData.cart_group_item,
            product_type: productData.product_type,
            price: productData.price,
            qty: 1,
            quote_id: productData.quote_id,
            sku: productData.sku,
            product_option: {...productOptions},
            tour_id: this.props.categoryInfo.category_id,
            tour_name: this.props.categoryInfo.category_name,
            product_id: selectedProduct.id
        };
        if(productData.item_id) {
            productToReturn["item_id"] = productData.item_id
        }
        return productToReturn;
    }
    isAValidForm() {
        let errors = this.props.isValidForm();
        console.log('isValidForm', errors);
        let lengthErrors = errors['lengthInformation'];
        let customErrors = errors['customOptions'];
        let shipping = errors['shipping'];
        let shows = errors['shows'];
        let sharedDetails = errors['sharedDetails'];
        let formValid = true;
        if((lengthErrors && Object.keys(lengthErrors).length > 0)
            || customErrors && Object.keys(customErrors).length > 0
            || shipping && Object.keys(shipping).length > 0
            || shows && Object.keys(shows).length > 0
            || sharedDetails && Object.keys(sharedDetails).length > 0
        ){
            formValid = false;
        }
        return formValid;
    }

    getProductType(productData, selectedProducts) {
        for (let selectedProductId in selectedProducts) {
            if (selectedProducts[selectedProductId].sku == productData.sku) {
                console.log('selected product', selectedProducts);
                let selectedProduct = selectedProducts[selectedProductId];
                if(selectedProduct.extension_attributes && selectedProduct.extension_attributes.product_type) {
                    return selectedProduct.extension_attributes.product_type.id
                }
            }
        }
    }

    addProductsToCart(event)
    {
        event.preventDefault();
        if(this.isAValidForm()) {

            let cartItems = [];
            let {productsToCart, productsToCartShows,
                selectedProducts, productsToCartSharedDetails, productsLengths,
                productsToCartShipping} = this.props;
            let showInfoToReturn = [];
            if (productsToCartShows) {
                showInfoToReturn = productsToCartShows.map((showInfo) => {
                    return {
                        [FIELDS_CODE.show_date]: showInfo[FIELDS_CODE.show_date],
                        [FIELDS_CODE.show_time]: showInfo[FIELDS_CODE.show_time],
                        [FIELDS_CODE.special_guests]: showInfo[FIELDS_CODE.special_guests],
                        [FIELDS_CODE.venue_capacity.code]: showInfo[FIELDS_CODE.venue_capacity.code],
                        [FIELDS_CODE.venue_name]: showInfo[FIELDS_CODE.venue_name],
                        [FIELDS_CODE.ticket_locations]: showInfo[FIELDS_CODE.ticket_locations],
                        [FIELDS_CODE.city_market_name]: showInfo[FIELDS_CODE.city_market_name],
                        [FIELDS_CODE.mentions.sku.mention_general_admission]: showInfo[FIELDS_CODE.mentions.sku.mention_general_admission],
                        [FIELDS_CODE.mentions.sku.mention_reserved_seats]: showInfo[FIELDS_CODE.mentions.sku.mention_reserved_seats],

                    }
                });
            }

            if(!this.props.itemId && this.props.saveLengthsInfo != undefined) {
                this.props.saveLengthsInfo();
            }
            let quoteId = this.props.cart.id;
            if(this.props.comeFrom != 'addShipping' && productsToCart) {
                let allOptions = productsToCartSharedDetails;
                let allGeneratedItems = [];
                productsToCart.map((item) => {
                    let productData = {...item};
                    let generatedItemsFromLengths = this._generateItems(productData, selectedProducts, [allOptions]);
                    allGeneratedItems.push(generatedItemsFromLengths);
                });


                let categoryId = this.props.categoryInfo.category_id;
                let categoryOption = {
                    [FIELDS_CODE.category_id]: categoryId
                };
                let productsToCartCustomOptions = this.props.productsToCartCustomOptions;
                console.log('productsToCartCustomOptions', productsToCartCustomOptions);

                let productsWithCategoryOption = [];
                [...allGeneratedItems].map((item) => {
                    let customOptions = productsToCartCustomOptions?productsToCartCustomOptions[item.sku]:{};
                    let itemsWithCategoryOption = this._generateItems(
                        {...item},
                        selectedProducts,
                        [{...categoryOption, ...customOptions}]);

                    productsWithCategoryOption.push(itemsWithCategoryOption);

                    if(isPkgProduct(item.sku)) {
                        let groupItemOption = this._getOptionByCode(selectedProducts[item.product_id], 'group_item');
                        let pkgAdditionalProducts = (customOptions && customOptions.additionalProducts)?customOptions.additionalProducts:{};
                        Object.keys(pkgAdditionalProducts).map(productSku => {
                            let customOptions =  item['product_option']['extension_attributes']['custom_options'];
                            for (let customOptionIndex in customOptions) {
                                let customOption = customOptions[customOptionIndex];
                                if(customOption.option_id === groupItemOption.option_id) {
                                    delete customOptions[customOptionIndex];
                                }
                            }
                            if(item.item_id) {
                                delete item.item_id;
                                delete item.group_item;
                            }
                            let generatedPkg = this._generateItems(
                              {...item},
                              selectedProducts,
                              [{...categoryOption, ...pkgAdditionalProducts[productSku]}]);
                            productsWithCategoryOption.push(generatedPkg);
                        })
                    }

                });

                cartItems = productsWithCategoryOption.map((item) => {
                    if(this.getProductType(item, selectedProducts) != "shipping"){
                        item['extension_attributes'] = {};
                        item['extension_attributes']['show_information'] = showInfoToReturn;
                        return {
                            ...item,
                            show_information: showInfoToReturn,
                        }
                    }
                }).filter((item) => {
                    if(item) {
                        return item
                    }
                });
            }
            let shippingItems = this.generateShippingItems(selectedProducts, productsToCartShipping, quoteId);
            let cutItems = this.generateCutItems(selectedProducts, productsLengths, quoteId);


            this.setUuidToCarItems(selectedProducts, cartItems, shippingItems);
            this.setUuidToCarItems(selectedProducts, cartItems, cutItems);
            cartItems = cartItems.concat(shippingItems);
            cartItems = cartItems.concat(cutItems);

            cartItems = this.addGroupItemOption(cartItems);

            cartItems = cartItems.map((item) => {
                if(!item.extension_attributes){
                    item['extension_attributes'] = {};
                }
                if(item.extension_attributes && !item.extension_attributes.show_information) {
                    item['extension_attributes']['show_information'] = [];
                }
                item['extension_attributes']['group_item'] = item.group_item;
                item['extension_attributes']['tour_id'] = item.tour_id;
                item['extension_attributes']['tour_name'] = item.tour_name;
                // delete item.group_item;
                // delete item.tour_id;
                // delete item.tour_name;
                // delete item.show_information;
                // delete item.cart_group_item;
                delete item.product_id;

                return item;
            })

            console.log("shippingItems", shippingItems);
            console.log('CarItems', cartItems)
            console.log('cutItems generated', cutItems);
            this.addItemsToCart(cartItems);
        }
        else {
            setTimeout(() => {
                window.scrollTo({top: 0, behavior: "smooth", block: 'start'})
            }, 100)

        }
    }
    addItemsToCart(cartItems)  {
        let itemsToDelete = this.props.itemsToDelete;
            let cartItemsTemp = cartItems.splice(0, 5);
            if(cartItemsTemp.length === 5 && cartItems.length !== 0) {
                this.props.addProductToCart(cartItemsTemp, this.props.customerInfo.id, itemsToDelete, this.props.cart.id, () => {
                        this.addItemsToCart(cartItems)
                    });
            }
            else {
                this.props.addProductToCart(cartItemsTemp, this.props.customerInfo.id, itemsToDelete, this.props.cart.id);
            }
    }
    addGroupItemOption(allItems) {
        for (let itemIndex in allItems) {
            let item = allItems[itemIndex];
            let item_options = item.product_option.extension_attributes.custom_options;
            item_options = item_options.map((option) => {
                if(option.option_code === 'group_item') {
                    option.option_value = item.group_item
                }
                delete option['option_code']
                return option;
            })
        }
        return allItems;
    }
    setUuidToCarItems(selectedProducts, cartItems, shippingItems) {
        let uuidCartGroupItem = uuid.v4();
        for(let pIndex in selectedProducts) {
            let product = selectedProducts[pIndex];
            for(let cIndex in cartItems) {
                let cartItem = cartItems[cIndex];
                if(cartItem.sku == product.sku) {
                    let group_item = uuid.v4();
                    if(cartItem.group_item) {
                        group_item = cartItem.group_item
                    }
                    if(cartItem.cart_group_item) {
                        uuidCartGroupItem = cartItem.cart_group_item
                    }
                    console.log('cartItem.cart_group_item', cartItem.cart_group_item);
                    cartItem['group_item'] = group_item;
                    cartItem['cart_group_item'] = uuidCartGroupItem;
                    for(let sIndex in shippingItems) {
                        let shippingItem = shippingItems[sIndex];
                        if(shippingItem.productId == product.id) {
                            shippingItem['group_item'] = group_item;
                            shippingItem['cart_group_item'] = uuidCartGroupItem;
                            delete shippingItem['productId'];
                        }
                    }
                }
            }
        }
        for(let sIndex in shippingItems) {
            let shippingItem = shippingItems[sIndex];
            delete shippingItem['productId'];

        }
        return {'cartItems': cartItems, 'shippingItems': shippingItems};
    }

    generateCutItems(selectedProducts, productsToCartLengths, quoteId) {
        let items = [];
        let cutProducts = this.props.cutProducts
        let count = 0;
        for(let sProductId in selectedProducts) {
            let selectedProduct = selectedProducts[sProductId];
            if(cutProducts && selectedProduct.id in cutProducts){
                console.log('product in firstcut....')

                let cutProduct = cutProducts[selectedProduct.id];

                let allLengths = productsToCartLengths[selectedProduct.id];
                allLengths = setCutTypeToAllCutLength(allLengths, selectedProduct.sku);
                console.log('product in allLengths....', allLengths)
                for(let lengthIndex in allLengths) {
                    console.log('product in lengthIndex....', lengthIndex)
                    let lengthCutType = allLengths[lengthIndex]
                    for(let cIndex in lengthCutType) {
                        console.log('product in lengthCutType....', lengthCutType)
                        let lengthData = lengthCutType[cIndex];

                        let cutType = lengthData['cut_type'];
                        console.log('product in selectedProduct....', selectedProduct.sku);
                        console.log('product in lengthData....', lengthData);
                        console.log('product in cutType....', cutType);
                        if(cutType in cutProduct)
                        {
                            let targetCut = cutProduct[cutType];
                            if(targetCut) {
                                let productData = {
                                    name: targetCut.name,
                                    product_type: 'virtual',
                                    qty: 1,
                                    quote_id: quoteId,
                                    sku: targetCut.sku,
                                    price: targetCut.price
                                };
                                let categoryId = this.props.categoryInfo.category_id;
                                let extraOptions = {
                                    [FIELDS_CODE.category_id]: categoryId,
                                    [FIELDS_CODE.for_product]: selectedProduct.id
                                };

                                let cutItem = this._generateItems(productData,
                                    {[targetCut.id]: targetCut},
                                    [{...lengthData, ...extraOptions}]
                                );
                                cutItem.productId = selectedProduct.id;
                                if(lengthData && lengthData.item_id) {
                                    cutItem['item_id'] =   lengthData.item_id
                                }
                                if(lengthData && lengthData.group_item) {
                                    cutItem['group_item'] =  lengthData.group_item
                                }

                                items.push({...cutItem})

                            }
                        }
                        count+=1;
                    }
                }
            }
            count = 0;
        }
        return items;
    }

    generateShippingItems(selectedProducts, productsToCartShipping, quoteId) {
        let items = [];
        console.log('selected products shipping', selectedProducts);
        for(let productId in selectedProducts) {
            let product = selectedProducts[productId];
            let shippingArray = productsToCartShipping[productId];
            let shippingOriginalProduct = product.extension_attributes.shipping;
            if(shippingArray && shippingOriginalProduct){
                let productData = {
                    name: shippingOriginalProduct.name,
                    product_type: 'virtual',
                    qty: 1,
                    quote_id: quoteId,
                    sku: shippingOriginalProduct.sku,
                    price: shippingOriginalProduct.price
                };
                let categoryId = this.props.categoryInfo.category_id;
                let categoryOption = {
                    [FIELDS_CODE.category_id]: categoryId
                };
                shippingArray.map((shippingInfo) => {
                    let shippingItem = this._generateItems(productData,
                    {[shippingOriginalProduct.id]: shippingOriginalProduct},
                    [{...shippingInfo, ...categoryOption}]
                                );
                    shippingItem.productId = productId;
                    if(shippingInfo && shippingInfo.item_id) {
                        shippingItem['item_id'] =   shippingInfo.item_id
                    }
                    if(shippingInfo && shippingInfo.group_item) {
                        shippingItem['group_item'] =  shippingInfo.group_item
                    }
                    console.log('shippingOriginalProduct', shippingItem);

                    items.push({...shippingItem})

                })
            }
        }
        return items;

    }

    render(){
        let {itemId} = this.props;
        console.log("itemId", itemId)
        return (
            <div className="col-sm-3 col-sm-offset-6">
                <button className="button medium blue-white" id='nextStepButton' type="submit" onClick={this.addProductsToCart}>
                    {itemId && 'Update Cart'}
                    {!itemId && 'Add to Cart'}
                </button>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateCartItemProduct))
