import {
    GET_ARTISTS_FAILURE,
    GET_ARTISTS_SUCCESS,
    GET_ARTISTS_REQUEST
} from "./actions";

export default (state = {
    artistRoster: null,
    isFetchingArtistRosters: false,
    artistRosterRequestError: null,
}, action) => {
    switch (action.type) {
        case GET_ARTISTS_REQUEST:
            return {
                ...state,
                isFetchingArtistRoster: true,
                artistRosterRequestError: null
            }
        case  GET_ARTISTS_FAILURE:
            return {
                ...state,
                isFetchingArtistRoster: false,
                artistRosterRequestError: action.error
            }
        case GET_ARTISTS_SUCCESS:
            return {
                ...state,
                isFetchingArtistRoster: false,
                artistRoster: action.data.items,
                artistRosterRequestError: null
            }
        default:
            return state;
    }
}