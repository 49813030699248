import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";

class OrderItemShipping extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            dataToShow: {}
        }
    }

    componentDidMount() {
        if(this.props.orderItem) {
            let dataToShow = {};
            let cartItem = this.props.orderItem;
            let originalProduct = cartItem.extension_attributes.original_product;
            let cartItemOptions = cartItem.product_option.extension_attributes.custom_options;
            for (let cartItemOptionIndex in cartItemOptions) {
                let cartItemOption = cartItemOptions[cartItemOptionIndex];
                let option = this.getProductOptionById(originalProduct, cartItemOption.option_id);
                if(option && (
                    cartItemOption.extension_attributes.visibility === 'frontend'
                    || cartItemOption.extension_attributes.visibility === 'both'
                )) {
                    switch (option.type) {
                        case 'field':
                        case 'area':
                            dataToShow[option.title] = cartItemOption.option_value;
                            break;
                        case 'radio':
                        case 'select':
                        case 'drop_down':
                        case 'checkbox':
                            let value = [];
                            let cartItemOptionValues = cartItemOption.option_value.split(',');
                            for(let valueIndex in option.values) {
                                let optionValue = option.values[valueIndex];
                                if(cartItemOptionValues.indexOf(optionValue.option_type_id.toString()) != -1) {
                                    value.push(optionValue.title);
                                }
                            }
                            dataToShow[option.title] = value.join(', ');
                            break;
                    }

                }
            }
            this.setState({
                dataToShow: dataToShow
            })

        }
    }
    getProductOptionById(product, optionId) {

        let options = product.options;
        for(let optionIndex in options) {
            let option = options[optionIndex];
            if(option.option_id == optionId) {
                console.log('getProductOptionById', option)
                return option;
            }
        }
        return null;
    }
    render(){
        let {dataToShow} = this.state;
        let orderItem = this.props.orderItem;
        return (
            <tr>
                    {orderItem &&
                        <td className="left">
                            <h4>{orderItem.name}</h4>

                            {Object.keys(dataToShow).map((index) => {
                                let data = dataToShow[index];
                                return <dl key={index}>
                                    <dt>{index}:</dt>
                                    <dd> {data}</dd>
                                </dl>}
                            )}

                        </td>
                    }
                <td className="right">${orderItem.price}</td>
            </tr>
        )
    }
}

export default connect(null, null)(OrderItemShipping)
