import React from "react"
import {connect} from "react-redux";
import OrderBillingAddress from "../OrderBillingAddress"
import {getOrderToPrintAttempt} from "./actions"
import {Link} from "react-router-dom";
import {FIELDS_CODE} from "../../actions/Constants";
import {getInfoToShow} from "../../helpers";

const  mapStateToProps = state => ({
    ...state.orderToPrint
});


const mapDispatchToProps = {
    getOrderToPrintAttempt,
}

class OrderToPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPrintDialog: false
        }
        this.openDialog = this.openDialog.bind(this);
    }


    componentDidMount() {
        let {orderId} = this.props.match.params;
        this.props.getOrderToPrintAttempt(orderId);
    }
    componentWillUnmount() {
        document.title = `TourDesign Creative`;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.orderId != this.props.match.params.orderId) {
            this.props.getOrderToPrintAttempt(this.props.orderId);
        }
        if(prevProps.orderToPrint != this.props.orderToPrint) {
            document.title = `TourDesign Creative Print Order: ${this.props.orderToPrint.increment_id}.pdf`;
            setTimeout(() => {
                this.setState({
                    showPrintDialog: true
                })
            }, 1000)

        }
        if(prevState.showPrintDialog != this.state.showPrintDialog && this.state.showPrintDialog) {
            this.printPartOfPage()
        }
    }
    openDialog() {
        this.setState({
            showPrintDialog: true
        })
    }
    printPartOfPage(event){
        window.print();
    }
    isShipping(item)
    {
        if(item.extension_attributes.product_type == 'shipping') {
            return true;
        }
        return false;
    }

    isCut(item)
    {
        if(item.extension_attributes.product_type == 'cut') {
            return true;
        }
        return false;
    }
    dataToShow(orderItem) {
        let dataToShow = {};
        let cartItem = orderItem;
        let originalProduct = cartItem.extension_attributes.original_product;
        let cartItemOptions = cartItem.product_option.extension_attributes.custom_options;
        for (let cartItemOptionIndex in cartItemOptions) {
            let cartItemOption = cartItemOptions[cartItemOptionIndex];
            let option = this.getProductOptionById(originalProduct, cartItemOption.option_id);
            if(option) {
                switch (option.type) {
                    case 'field':
                        dataToShow[option.title] = cartItemOption.option_value;
                        break;
                    case 'radio':
                    case 'select':
                    case 'drop_down':
                    case 'checkbox':
                        let value = [];
                        let cartItemOptionValues = cartItemOption.option_value.split(',');
                        for(let valueIndex in option.values) {
                            let optionValue = option.values[valueIndex];
                            if(cartItemOptionValues.indexOf(optionValue.option_type_id.toString()) != -1) {
                                value.push(optionValue.title);
                            }
                        }
                        dataToShow[option.title] = value.join(', ');
                        break;
                }

            }
        }
        return dataToShow;
    }
    getProductOptionById(product, optionId) {

        let options = product.options;
        for(let optionIndex in options) {
            let option = options[optionIndex];
            if(option.option_id == optionId) {
                console.log('getProductOptionById', option)
                return option;
            }
        }
        return null;
    }

    render() {
        let {orderToPrint,isFetchingOrderToPrintDetails, orderId} = this.props;
        if(isFetchingOrderToPrintDetails) {
            return (
                <main role="main">
                    <section id="content" className="container content-wrapper loading-wrapper">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="loading-white">
                                    <i className="icon-loading"></i>
                                    <span>Loading Order Details</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        if(orderToPrint) {
            return (
                <main id="print-order" role="main">
                    <section id="content" className="container content-wrapper">
                        <div className="row section-header">
                            <div className="col-md-10 col-sm-9">
                                <h1 className="h-two blue">#{orderToPrint.increment_id}</h1>
                                <p className="order-date">Order Date: {orderToPrint.created_at}</p>
                            </div>
                        </div>
                        {orderToPrint &&
                            <OrderBillingAddress
                                billingAddress={orderToPrint.billing_address}
                                paymentMethod={orderToPrint.payment.additional_information}
                        />}
                        {orderToPrint && <section className="row section-account">
                            <div className="col-xs-12">
                                <header className="mb-10">
                                    <h3 className="h-five">Items Ordered</h3>
                                </header>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <table className="item-details" width="100%">
                                            <thead>
                                            <tr>
                                                <th className="left">Item(s)</th>
                                                <th className="right">Price</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {orderToPrint.items.map((orderItem) =>
                                                {
                                                    let data = getInfoToShow(orderItem);
                                                    if(this.isShipping(orderItem) || this.isCut(orderItem)) {
                                                        let dataToShow = this.dataToShow(orderItem);
                                                        return (
                                                            <tr>
                                                                {orderItem &&
                                                                <td className="left">
                                                                    <h4>{orderItem.name}</h4>

                                                                    {Object.keys(dataToShow).map((index) => {
                                                                        let data = dataToShow[index];
                                                                        return <dl key={index}>
                                                                            <dt>{index}:</dt>
                                                                            <dd> {data}</dd>
                                                                        </dl>}
                                                                    )}

                                                                </td>
                                                                }
                                                                <td className="right">${orderItem.price}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <tr>
                                                                <td className="left">
                                                                    <h4>{orderItem.name}</h4>
                                                                    {data && <dl>
                                                                        <dt>Show Date: </dt>
                                                                        <dd>{data[FIELDS_CODE.show_date]}</dd>
                                                                        <dt>Venue Name:</dt>
                                                                        <dd>{data[FIELDS_CODE.venue_name]}</dd>
                                                                        <dt>City / Market:</dt>
                                                                        <dd>{data[FIELDS_CODE.city_market_name]}</dd>
                                                                    </dl>
                                                                    }
                                                                </td>
                                                                <td className="right">${orderItem.price}</td>
                                                            </tr>
                                                        )
                                                    }
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>}
                    </section>
                </main>
            )
        }
        else {
            return (
                <main role="main">
                    <section id="content" className="container content-wrapper">
                        <div className="row section-header">
                            <div className="col-md-10 col-sm-9">
                                <ul className="breadcrumb">
                                    <li><Link to="/dashboard">Account Dashboard</Link></li>
                                    <li><Link to="/orders">Orders</Link></li>
                                </ul>
                                <h1 className="h-two blue">Order Not Found</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <p className="p-three grey">Sorry, we can't find that order. Please go back and <a className="blue" href="/orders">select another order</a> from your list.</p>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderToPrint)
