import React from "react"
import {connect} from "react-redux"
import CustomCheckbox from "../CustomCheckbox";
import {getCustomAttributeValue, getProductLabel} from "../../helpers";
import {isPkgProduct} from "../../actions/Constants";

const mapStateToProps = state => ({
    ...state.orderProcess
})
class OrderFormStep1SelectProduct extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            selectedProducts: props.selectedProducts
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.setState({
            selectedProducts: this.props.selectedProducts
        })
    }

    handleChange(info)
    {
        let product = this.props.products[info.index];
        this.props.toggleSelectProduct(info.name, product);
    }

    componentWillUnmount() {
        console.log("unmount.......");
        this.setState({
            selectedProducts: {}
        })
    }

    render(){
        let {products, typeName} =  this.props
        let {selectedProducts} = this.state
        return (
            <div className="order-option col-md-4 col-sm-6 col-xs-12">
                <h2 className="h-three white">{typeName}</h2>
                <ul>
                {products.map((item, key) =>
                    <li key={key}>
                            <CustomCheckbox checked={selectedProducts[item.id]? true:false}
                                            label={getProductLabel(item)}
                                            onchange={this.handleChange}
                                            feedBackInfo={{
                                                index: key,
                                                name: item.id
                                            }}
                            />
                    </li>
                )}
                </ul>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(OrderFormStep1SelectProduct)
