import React from "react"
import {connect} from "react-redux"
import {CATEGORY_IMAGES_SERVICES, CDN_URL, TOUR_DEMO_IMAGE} from "../../actions/Constants";
import {Link, withRouter} from "react-router-dom";
import ToursModal from "../../containers/ToursModal";
import NotFound from "../../containers/NotFound";


class ToursDetailsCenterItem extends React.Component{
    constructor(props) {
        super(props);
        this.onClickHandle = this.onClickHandle.bind(this);
    }

    _getCategoryImage() {
        let category = this.props.category;
        /*let image = '';
        let customAttributes = category.custom_attributes;
        console.log('customAttribute', category)
        for(let customAttributeIndex in customAttributes) {
            let customAttribute = customAttributes[customAttributeIndex];

            if(customAttribute.attribute_code == 'image') {
                image = CATEGORY_IMAGES_SERVICES + customAttribute.value;
                break;
            }
        }*/
        let imageUrl = category.tour_image;
        if(imageUrl && imageUrl != ''){
            imageUrl = TOUR_DEMO_IMAGE + imageUrl;
        }
        else {
            imageUrl = ''
        }

        return imageUrl
    }
    onClickHandle(event){
        event.preventDefault();
        this.props.showDemosModal(this.props.categoryIndex)
    }
    addDefaultSrc(ev){
        ev.target.src = `${CDN_URL}assets/default-thumbnail.png`;
    }
    render(){
        let category = this.props.category;
        let categoryImage = this._getCategoryImage();
        if(!category) {
            return <NotFound/>
        }
        return (
            <div className="col-md-2 col-sm-4 col-xs-6" key={category.id}>
                <div className="tour-preview-item">
                    <Link to="#" onClick={this.onClickHandle}>
                        <img
                            src={categoryImage != ''? categoryImage: `${CDN_URL}assets/default-thumbnail.png`}  alt=""
                            onError={this.addDefaultSrc}
                        />
                        <h2>{category && category.artist_name}</h2>
                        <p>{category.tour_name}</p>
                    </Link>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null, null)(ToursDetailsCenterItem))
