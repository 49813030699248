import React from "react";
import { withRouter } from "react-router-dom";

class ScrollRestoration extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot){
        if (
            this.props.location.pathname !== prevProps.location.pathname
        ) {
            window.scrollTo(0, 0);
            this.setMinHeightToMain();

        }
    }
    setMinHeightToMain() {
        let footerHeight = this.footerHeight();
        let mainElement = document.querySelectorAll('[role="main"]');
        if(mainElement && mainElement.length > 0) {
            mainElement = mainElement[0];
            mainElement.style.setProperty('min-height', `calc(100vh - ${footerHeight}px`)
        }
    }

    footerHeight() {
        let footer = document.getElementById('footer');
        if(footer){
            return footer.offsetHeight
        }
        return 0;
    }
    render() {
        return null;
    }
}

export default withRouter(ScrollRestoration);
