import React from "react"
import {connect} from "react-redux"
import {userLogout} from "../Logout/actions";
import {setLoginSuccess} from "../LoginForm/actions";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {CDN_URL} from "../../actions/Constants";

const mapDispatchToProps = {
    userLogout,
    setLoginSuccess,
}

class Header extends React.Component{
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.showAccountModal = this.showAccountModal.bind(this);
        this.showAccountButton = this.showAccountButton.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    logout(){
        this.closeMenu();
        this.props.userLogout();
        // this.props.history.push('/')
    }

    showAccountModal()
    {
        this.props.history.push('/account/login');
        this.props.setLoginSuccess(false);
    }
    showAccountButton()
    {
        let pathname = this.props.location.pathname;
        if(pathname.includes('/account/')) {
            return false;
        }
        if(!this.props.isAuthenticated) {
            return true;
        }
    }
    closeMenu() {
        let mainNavigation = document.getElementById("main-navigation");
        let body = document.getElementsByTagName("body")[0];
        let headerTriggerMenu = document.getElementById('header-trigger-menu');
        headerTriggerMenu.setAttribute('aria-expanded', 'false');
        if(body.classList.contains('prevent-scroll')) {
            body.classList.remove('prevent-scroll');
        }
        if(mainNavigation) {
            if(mainNavigation.classList.contains('open')){
                mainNavigation.classList.remove('open');
            }
        }
    }
    openMenu() {
        let headerTriggerMenu = document.getElementById('header-trigger-menu');
        let mainNavigation = document.getElementById("main-navigation");
        let body = document.getElementsByTagName("body")[0];

        headerTriggerMenu.setAttribute('aria-expanded', 'true');
        if(!body.classList.contains('prevent-scroll')) {
            body.classList.add('prevent-scroll');
        }
        if(mainNavigation) {
            if(!mainNavigation.classList.contains('open')){
                mainNavigation.classList.add('open');
            }
        }
    }
    toggleMenu() {
        let headerTriggerMenu = document.getElementById('header-trigger-menu');
        if(headerTriggerMenu.getAttribute('aria-expanded') === 'true') {
            this.closeMenu()
        }
        else {
            this.openMenu()
        }
    }
    render(){
        console.log(this.props);
        let {isAuthenticated, cartItemQty, firstName} = this.props;
        let firstLetter = '';
        if(firstName) {
            firstLetter = firstName.charAt(0);
        }
        let showAccountButton = this.showAccountButton();
        return (
            <header id="header">
                <div id="header-logo">
                    <Link to="/">
                        <img src={`${CDN_URL}assets/tourdesign.svg`} alt="TourDesign Creative"/>
                    </Link>
                </div>
                <div id="header-livenation">
                    <span>A Live Nation Company</span>
                    <img src={`${CDN_URL}assets/livenation.svg`} alt="TourDesign Creative"/>
                </div>
                <div id="header-navigation">
                    <button id="header-trigger-account" className="header-button" type="button"
                            onClick={this.showAccountModal}
                    style={{display: showAccountButton? "block": "none"}}
                    >Account/Login</button>
                    {isAuthenticated &&
                        <button
                            id="header-trigger-menu"
                            className="header-button"
                            type="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={this.toggleMenu}
                        >
                            Menu
                        </button>
                    }
                    {isAuthenticated &&
                        <nav id="main-navigation">
                            <ul role="menu">
                                <li role="menuitem"><Link to="/dashboard" onClick={this.closeMenu}>Home</Link></li>
                                <li role="menuitem"><Link to="/artists/" onClick={this.closeMenu}><i className="icon-roster" aria-hidden="true"></i> Tour
                                    Roster</Link></li>
                                <li role="menuitem">
                                    <Link to={"/cart"} onClick={this.closeMenu}><i className="icon-cart" aria-hidden="true"></i> Cart <span
                                        className="count">{cartItemQty}</span></Link>
                                </li>
                                <li role="menuitem">
                                    <Link to="/dashboard"><i className="icon-initial" aria-hidden="true">{firstLetter}</i> {firstName}</Link>
                                    <ul>
                                        <li role="menuitem"><Link to="/dashboard" onClick={this.closeMenu}>Account Dashboard</Link></li>
                                        <li role="menuitem"><Link to="/customer/information" onClick={this.closeMenu}>Account Information</Link></li>
                                        <li role="menuitem"><Link to="/customer/addressBook" onClick={this.closeMenu}>Address Book</Link></li>
                                        <li role="menuitem"><Link to="/orders" onClick={this.closeMenu}>My Orders</Link></li>
                                        <li role="menuitem" style={{display: 'none'}}><Link to="#" onClick={this.closeMenu}>Downloads</Link></li>
                                        <li role="menuitem" style={{display: 'none'}}><Link to="#" onClick={this.closeMenu}>Customizable Products</Link></li>
                                        <li role="menuitem" className="logout">
                                            <Link to="#" onClick={this.logout}>Logout</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    }
                </div>
            </header>
        )
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Header))
