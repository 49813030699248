import {fields as amex_tv_fields} from "../components/Product/AMEX_TV/definition";
import {fields as amex_hd_tv_fields} from "../components/Product/AMEX_HD_TV/definition";
import {fields as custom_audio_fields} from "../components/Product/CUSTOM_AUDIO/definition";
import {fields as custom_video_fields} from "../components/Product/CUSTOM_VIDEO/definition";
import {fields as custom_art_fields} from "../components/Product/CUSTOM_ART/definition";
import {fields as hd_tv_fields} from "../components/Product/HD_TV/definition";
import {fields as theatrical_hd_tv_fields} from "../components/Product/HD_TV/definition";
import {fields as international_radio_fields} from "../components/Product/INTERNATIONAL_RADIO/definition";
import {fields as international_tv_fields} from "../components/Product/INTERNATIONAL_TV/definition";
import {fields as online_audio_fields} from "../components/Product/ONLINE_AUDIO/definition";
import {fields as online_video_fields} from "../components/Product/ONLINE_VIDEO/definition";
import {fields as theatrical_online_video_fields} from "../components/Product/ONLINE_VIDEO/definition";
import {fields as radio_spot_fields} from "../components/Product/RADIO_SPOT/definition";
import {fields as amex_radio_spot_fields} from "../components/Product/AMEX_RADIO_SPOT/definition";
import {fields as theatrical_radio_spot_fields} from "../components/Product/RADIO_SPOT/definition";
import {fields as sd_tv_fields} from "../components/Product/SD_TV/definition";
import {fields as custom_tv_fields} from "../components/Product/CUSTOM_TV/definition";
import {fields as video_capture_fields} from "../components/Product/VIDEO_CAPTURE/definition";
import {fields as online_art_fields} from "../components/Product/ONLINE_ART/definition";
import {fields as album_art_fields} from "../components/Product/ALBUM_ART/definition";
import {fields as album_video_fields} from "../components/Product/ALBUM_VIDEO/definition";
import {fields as album_audio_fields} from "../components/Product/ALBUM_AUDIO/definition";
import {fields as revision_fields} from "../components/Product/REVISION/definition";
import {fields as digital_fields} from "../components/Product/DIGITAL/definition";
import {fields as digital_2023_fields} from "../components/Product/DIGITAL_2023/definition";
import {fields as pkg_a_art_fields} from "../components/Product/PKG_A_ART/definition";
import {fields as pkg_b_art_fields} from "../components/Product/PKG_B_ART/definition";
import {fields as customizable_social_templates_fields} from "../components/Product/CUSTOMIZABLE_SOCIAL_TEMPLATES/definition";
import {fields as key_art_package} from "../components/Product/KEY_ART_PACKAGE/definition";
import {fields as custom_invoice_fields} from "../components/Product/CUSTOM/definition";
import {shipping_fields as hd_tv_shipping_fields} from "../components/ProductShipping/HD_TV_ENCODING/definition";
import {shipping_fields as online_video_shipping_fields} from "../components/ProductShipping/ONLINE_VIDEO_ENCODING/definition";
import {shipping_fields as online_audio_shipping_fields} from "../components/ProductShipping/ONLINE_AUDIO_ENCODING/definition";
import {shipping_fields as online_art_shipping_fields} from "../components/ProductShipping/ONLINE_ART_ENCODING/definition";
import {shipping_fields as sd_tv_shipping_fields} from "../components/ProductShipping/SD_TV_ENCODING/definition";
import {shipping_fields as amex_tv_shipping_fields} from "../components/ProductShipping/AMEX_TV_ENCODING/definition";
import {shipping_fields as amex_hd_tv_shipping_fields} from "../components/ProductShipping/AMEX_HD_TV_ENCODING/definition";
import {shipping_fields as radio_shipping_fields} from "../components/ProductShipping/RADIO_ENCODING/definition";
import {shipping_fields as amex_radio_shipping_fields} from "../components/ProductShipping/AMEX_RADIO_ENCODING/definition";
import {shipping_fields as theatrical_hd_tv_shipping_fields} from "../components/ProductShipping/THEATRICAL_HD_TV_ENCODING/definition";
import {shipping_fields as theatrical_online_vide_shipping_fields} from "../components/ProductShipping/THEATRICAL_ONLINE_VIDEO_ENCODING/definition";
import {shipping_fields as theatrical_radio_shipping_fields} from "../components/ProductShipping/THEATRICAL_RADIO_ENCODING/definition";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const DEFAULT_PAGE_SIZE = 5;
export const RECENT_ORDERS_AMOUNT = 4;
export const DEFAULT_CATEGORY_PAGE_SIZE = 100;

/**
 * Url to serve categories's image.
 * @type {string}
 */
export const CATEGORY_IMAGES_SERVICES = process.env.REACT_APP_CATEGORY_IMAGES_SERVICES;
export const CATEGORY_DEMO_FILE_SERVICES = process.env.REACT_APP_CATEGORY_DEMO_FILES_SERVICES;

export const TOUR_DEMO_IMAGE = process.env.REACT_APP_TOUR_DEMO_IMAGE;
export const TOUR_DEMO_DEMO = process.env.REACT_APP_TOUR_DEMO_DEMO;


export const HOME_TOUR_DEMO_IMAGE = process.env.REACT_APP_HOME_TOUR_DEMO_IMAGE;
export const HOME_TOUR_DEMO_DEMO = process.env.REACT_APP_HOME_TOUR_DEMO_DEMO;

/**
 * CAPTCHA SITE KEY
 */

export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

/**
 * CDN URL
 */

export const CDN_URL = process.env.REACT_APP_CDN_URL;

/**
 * CONST TO SAVE THE "CODE" OF EACH CUSTOM OPTION THAT WE NEED IN MAGENTO
 *
 */
export const FIELDS_CODE = {
    /***
     * SHOW INFORMATION
     */
    show_time: "show_time",
    show_date: 'show_date',
    special_guests: "special_guests",
    venue_capacity: {
        code: "venue_capacity",
        sku: {
            venue_capacity_3000: 'package2',
            venue_capacity_1000_2999: 'package3',
            venue_capacity_under_1000: 'package4'
        }
    },
    venue_name: "venue_name",
    ticket_locations: 'ticket_locations',
    city_market_name: 'city_market_name',
    mentions: {
        code: 'mentions',
        sku: {
            mention_general_admission: 'mention_general_admission',
            mention_reserved_seats: 'mention_reserved_seats'
        }
    },

    /**
     * PRODUCT SHARED DETAILS
     */
    special_instructions: 'special_instructions',
    intro: 'intro',
    logos: 'logos',
    outro: 'outro',
    logo_file: {
        code: 'logo_file',
        extensions: '.png, .jpg, .gif'
    },
    station_id: 'station_id',
    include_station_id_mention: {
        code: 'include_station_id_mention',
        sku: {
            include_station_id_mention_in_tv: 'include_station_id_mention_in_tv',
        }
    },
    generic_radio_check: {
        code: 'generic_radio',
        sku: {
            additional_generic_radio: 'additional_generic_radio'
        },
    },

    /**
     * LENGTH AND CUTS INFORMATION
     */
    length: {
        code: "length",
        /**
         * THIS IS NECESSARY WHEN THE OPTION IS A CHECKBOX
         */
        sku: {
            length_60: 'length_60',
            length_45: 'length_45',
            length_30: 'length_30',
            length_20: 'length_20',
            length_15: 'length_15',
            length_10: 'length_10',
            length_other: 'length_other'
        }
    },
    custom_length: 'custom_length',
    cuts: {
        code: "cuts",
        /**
         * THIS IS NECESSARY WHEN THE OPTION IS A CHECKBOX
         */
        sku: {
            cuts_special_presale: 'cuts_special_presale',
            cuts_amex_presale: 'cuts_amex_presale',
            cuts_amex_presale_1: 'cuts_amex_presale_1',
            cuts_presale: 'cuts_presale',
            cuts_on_sale_now: 'cuts_onsale_now',
            cuts_week_of_show: 'cuts_week_of_show',
            cuts_day_prior: 'cuts_day_prior',
            cuts_day_of: 'cuts_day_of',
            cuts_music_bed_only: 'cuts_music_bed_only',
            cuts_superless_master: 'cuts_superless_master',
            cuts_hd_superless_master: 'cuts_hd_superless_master',
            cuts_b_roll: 'cuts_b_roll',
            cuts_other: 'cuts_other',
        }
    },
    cut_verbiage: "cut_verbiage",
    script_presale: "script_presale",
    isci_code: "isci_code",
    for_product: "for_product",

    /**
     * CATEGORY INFORMATION
     */
    category_id: 'category_id',

    group_item: 'group_item',
    video_size: {
        code: "video_size",
        sku: {
            '16x9_horizontal': '16x9_horizontal',
            '1x1_square': '1x1_square',
            '9x16_vertical_story': '9x16_vertical_story',
            '4x5_vertical': '4x5_vertical',
        }
    }
};

export const CUTS_LENGTH_AMOUNT = 10;

export const isPkgProduct = (sku) => {
    let pkgProducts = getPkgArtProductsName();
    return pkgProducts.indexOf(sku.toLowerCase()) !== -1;
};


/**
 *
 * @param string sku. Product sku lowercase
 * @returns {Array|*[]}
 */
export const getPkgArtProductsName = () => {
    return ['pkg a art', 'pkg b art', 'key art package', 'customizable social templates']
};

export const getCustomArtProduct = () => {
    return 'custom art';
};

export const getTvAndRadioProductsType = () => {
    return ['broadcast_television', 'broadcast_radio']
};
export const getFieldsByProductSku = (productSku) => {
    switch (productSku.toLowerCase()) {
        case 'custom':
            return []
        case 'pkg a art':
        case 'pkg b art':
        case 'key art package':
        case 'customizable social templates':
            return [
                FIELDS_CODE.show_date,
                FIELDS_CODE.venue_name,
                FIELDS_CODE.city_market_name,
            ];
        case 'online art':
            return [
                FIELDS_CODE.show_date,
                FIELDS_CODE.special_guests,
                FIELDS_CODE.city_market_name,
                FIELDS_CODE.venue_name,
                FIELDS_CODE.ticket_locations,
            ];
        default:
            return [
                FIELDS_CODE.show_date,
                FIELDS_CODE.special_guests,
                FIELDS_CODE.venue_capacity.code,
                FIELDS_CODE.city_market_name,
                FIELDS_CODE.venue_name,
                FIELDS_CODE.ticket_locations,
            ];
    }
}
export const getShowRequiredFieldsByProductSku = (productSku = '') => {
    switch (productSku.toLowerCase()) {
        case 'custom':
            return []
        case 'pkg a art':
        case 'pkg b art':
        case 'key art package':
        case 'customizable social templates':
            return [
                FIELDS_CODE.show_date,
                FIELDS_CODE.venue_name,
                FIELDS_CODE.city_market_name,
            ]
        case 'online art':
            return [
                FIELDS_CODE.show_date,
                FIELDS_CODE.special_guests,
                FIELDS_CODE.city_market_name,
                FIELDS_CODE.venue_name,
                FIELDS_CODE.ticket_locations,
            ]
        default:
            return [
                FIELDS_CODE.show_date,
                FIELDS_CODE.special_guests,
                FIELDS_CODE.venue_capacity.code,
                FIELDS_CODE.city_market_name,
                FIELDS_CODE.venue_name,
                FIELDS_CODE.ticket_locations,
            ]
    }
}

export const getProductsWithOneShow = () => {
    return ['pkg a art', 'pkg b art', 'customizable social templates', 'key art package', 'online art', 'custom art']
}

export const LENGTH_INFORMATION = {
    [FIELDS_CODE.length.code]: '',
    [FIELDS_CODE.custom_length]: '',
    [FIELDS_CODE.cuts.code]: '',
    [FIELDS_CODE.cut_verbiage]: '',
    [FIELDS_CODE.script_presale]: '',
    [FIELDS_CODE.for_product]: '',
    [FIELDS_CODE.video_size.code]: '',
};

export const SHARED_INFORMATION = {
    [FIELDS_CODE.special_instructions]: '',
    [FIELDS_CODE.intro]: '',
    [FIELDS_CODE.logos]: '',
    [FIELDS_CODE.outro]: '',
    [FIELDS_CODE.logo_file.code]: '',
    [FIELDS_CODE.station_id]: '',
    [FIELDS_CODE.include_station_id_mention.sku.include_station_id_mention_in_tv]: false,
    [FIELDS_CODE.include_station_id_mention.code]: {},
    [FIELDS_CODE.generic_radio_check.code]: {},
};

export const PRODUCTS_FIELDS = {
    AMEX_HD_TV: {
        ...amex_hd_tv_fields
    },
    AMEX_TV: {
        ...amex_tv_fields
    },
    CUSTOM_AUDIO: {
        ...custom_audio_fields
    },
    CUSTOM_VIDEO: {
        ...custom_video_fields
    },
    CUSTOM_ART: {
        ...custom_art_fields
    },
    HD_TV: {
        ...hd_tv_fields
    },
    THEATRICAL_HD_TV: {
        ...theatrical_hd_tv_fields
    },
    INTERNATIONAL_RADIO: {
        ...international_radio_fields
    },
    INTERNATIONAL_TV: {
        ...international_tv_fields
    },
    ONLINE_AUDIO: {
        ...online_audio_fields
    },
    ONLINE_VIDEO: {
        ...online_video_fields
    },
    THEATRICAL_ONLINE_VIDEO: {
        ...theatrical_online_video_fields
    },
    RADIO_SPOT: {
        ...radio_spot_fields
    },
    AMEX_RADIO_SPOT: {
        ...amex_radio_spot_fields
    },
    THEATRICAL_RADIO_SPOT: {
        ...theatrical_radio_spot_fields
    },
    SD_TV: {
        ...sd_tv_fields
    },
    CUSTOM_TV: {
        ...custom_tv_fields
    },
    VIDEO_CAPTURE: {
        ...video_capture_fields
    },
    ONLINE_ART: {
        ...online_art_fields
    },
    ALBUM_ART: {
        ...album_art_fields
    },
    ALBUM_VIDEO: {
        ...album_video_fields
    },
    ALBUM_AUDIO: {
        ...album_audio_fields
    },
    DIGITAL: {
        ...digital_fields
    },
    DIGITAL_2023: {
        ...digital_2023_fields
    },
    PKG_A_ART: {
        ...pkg_a_art_fields
    },
    PKG_B_ART: {
        ...pkg_b_art_fields
    },
    CUSTOMIZABLE_SOCIAL_TEMPLATES: {
        ...customizable_social_templates_fields
    },
    KEY_ART_PACKAGE: {
        ...key_art_package
    },
    HD_TV_ENCODING: {
        ...hd_tv_shipping_fields
    },
    ONLINE_VIDEO_ENCODING: {
        ...online_video_shipping_fields
    },
    ONLINE_AUDIO_ENCODING: {
        ...online_audio_shipping_fields
    },
    ONLINE_ART_ENCODING: {
        ...online_art_shipping_fields
    },
    RADIO_ENCODING: {
        ...radio_shipping_fields
    },
    AMEX_RADIO_ENCODING: {
        ...amex_radio_shipping_fields
    },
    SD_TV_ENCODING: {
        ...sd_tv_shipping_fields
    },
    AMEX_TV_ENCODING: {
        ...amex_tv_shipping_fields
    },
    AMEX_HD_TV_ENCODING: {
        ...amex_hd_tv_shipping_fields
    },
    THEATRICAL_HD_TV_ENCODING: {
        ...theatrical_hd_tv_shipping_fields
    },
    THEATRICAL_ONLINE_VIDEO_ENCODING: {
        ...theatrical_online_vide_shipping_fields
    },
    THEATRICAL_RADIO_ENCODING: {
        ...theatrical_radio_shipping_fields
    },
    REVISION: {
        ...revision_fields
    },
    CUSTOM: {
        ...custom_invoice_fields
    },

};

export const ordersStatuses = {
    processing: 'Processing',
    pending: 'Pending',
    complete: 'Complete',
    closed: 'Closed',
    canceled: 'Canceled',
    invoiced: 'Invoiced',
    in_production: 'In Production'
}
