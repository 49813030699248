import {request} from "../../axiosRequest";

export const SHOPPING_CART_TOTALS_REQUEST = 'SHOPPING_CART_TOTALS_REQUEST'
export const SHOPPING_CART_TOTALS_SUCCESS = 'SHOPPING_CART_TOTALS_SUCCESS'
export const SHOPPING_CART_TOTALS_FAILURE = 'SHOPPING_CART_TOTALS_FAILURE'
export const SHOPPING_CART_TOTALS_CLEANUP = 'SHOPPING_CART_TOTALS_CLEANUP'


export const shoppingCartTotalsRequest = () => ({
    type: SHOPPING_CART_TOTALS_REQUEST
})
export const shoppingCartTotalsFailure = (error) => ({
    type: SHOPPING_CART_TOTALS_FAILURE,
    error: error
})
export const shoppingCartTotalsSuccess = (data) => ({
    type: SHOPPING_CART_TOTALS_SUCCESS,
    data: data
})

export const shoppingCartTotalsCleanup = () => ({
    type: SHOPPING_CART_TOTALS_CLEANUP
})

export const shoppingCartTotalsAttempt = () => {
    return (dispatch) => {
        dispatch(shoppingCartTotalsRequest())
        return request.get('carts/totals',true)
            .then( response => {return response;})
            .then(result => {
                let data = result.data
                if(data.success && data.code == 200){
                    dispatch(shoppingCartTotalsSuccess(data.data))
                }
                else{
                    dispatch(shoppingCartTotalsFailure(data.data))
                }
            })
            .catch(error => {
                dispatch(shoppingCartTotalsFailure(error))
            })
    }
}