import React from "react"
import {connect} from "react-redux"
import ToursDetailsCenter from "../../components/ToursDetailsCenter";
import {getToursAttempt} from "../Tours/actions";
import {Link} from "react-router-dom";
import NotFound from "../NotFound";
import {CDN_URL} from "../../actions/Constants";

const mapDispatchToProps = {
    getToursAttempt,
}
const mapStateToProps = state => ({
    ...state.tours,
})
class ToursPage extends React.Component{
    constructor(props) {
        super(props);
        this.tours = {
            touring: {
                title:'Touring',
                background: `${CDN_URL}assets/backgrounds/touring.jpg`
            },
            festivals: {
                title: 'Festivals',
                background: `${CDN_URL}assets/backgrounds/festival.jpg`
            },
            'album-releases': {
                title: 'Label Relations',
                background: `${CDN_URL}assets/backgrounds/album.jpg`
            },
            'video-shoots': {
                title: 'Video Shoots',
                background: `${CDN_URL}assets/backgrounds/video_shoot.jpg`
            },
            'theatrical-and-family': {
                title: 'Theatrical and Family',
                background: `${CDN_URL}assets/backgrounds/theater_family.jpg`
            },
            'custom-projects': {
                title: 'Custom Projects',
                background: `${CDN_URL}assets/backgrounds/custom_project.jpg`
            },
        }
        this.state = {
            categoryName: '',
            categoryDescription: ''
        }
        this.setCategoryName = this.setCategoryName.bind(this);
        this.setCategoryData = this.setCategoryData.bind(this);
    }
    componentDidMount() {
        let tourId = this.props.match.params.tourId;
        this.setState({
            categoryName: this.tours[tourId]['title']
        })
    }
    setCategoryData(categories, tourId) {
        let categoryName, categoryDescription = '';
        if(categories) {
            for(let categoryIndex in categories) {
                let category = categories[categoryIndex];
                if (category.id === tourId) {
                    categoryName = category.name;
                    let customAttributes = category.custom_attributes;
                    for(let customAttributeIndex in customAttributes) {
                        if(customAttributes[customAttributeIndex].attribute_code === 'description') {
                            categoryDescription = customAttributes[customAttributeIndex].value
                        }
                    }
                }
            }
        }
        this.setState({
            categoryName: categoryName,
            categoryDescription: categoryDescription
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let categories = this.props.categories;
        let {tourId} = this.props.match.params;

        if(prevProps.match.params.tourId !== tourId) {
            this.setState({
                categoryName: this.tours[tourId]['title']
            })
        }    }

    setCategoryName(name) {
        this.setState({
            categoryName: name
        })
    }
    render(){
        console.log(this.props);
        let {tourId} = this.props.match.params;
        let isFetchingTours = this.props.isFetchingTours;
        let {categoryName, categoryDescription} = this.state;
        let backgroundImage = '';
        if(!(tourId in this.tours)) {
            return (
                <NotFound/>
            )
        }
        backgroundImage = this.tours[tourId]['background'];
        if(isFetchingTours) {
            return (
                <main role="main" style={{backgroundImage: `url('${backgroundImage}')`}} className="fullscreen-responsive">
                    <div className="container-fluid fullscreen-tour">
                        <div className="loading-small">
                            <i className="icon-loading"></i>
                            <span>Loading {categoryName}</span>
                        </div>
                    </div>
                </main>
            )
        }
        return (
            <main role="main" style={{backgroundImage: `url('${backgroundImage}')`}} className="fullscreen-responsive">

                <ToursDetailsCenter
                    categoryId={tourId}
                    categoryName={categoryName}
                    categoryDescription={categoryDescription}
                />

                <ul id="navigation-tours-horizontal" role="menu">
                    <li role="menuitem"><Link to="/tours" >Home</Link></li>
                    <li role="menuitem"><Link to="/tours/touring" className={ 'touring' === tourId? "active": ''}>Touring</Link></li>
                    <li role="menuitem"><Link to="/tours/festivals" className={ 'festivals' === tourId? "active": ''}>Festivals</Link></li>
                    <li role="menuitem"><Link to="/tours/album-releases" className={ 'album-releases' === tourId? "active": ''}>Label Relations</Link></li>
                    <li role="menuitem"><Link to="/tours/video-shoots" className={ 'video-shoots' === tourId? "active": ''}>Video Shoots</Link></li>
                    <li role="menuitem"><Link to="/tours/theatrical-and-family" className={ 'theatrical-and-family' === tourId? "active": ''}>Theatrical and Family</Link></li>
                    <li role="menuitem"><Link to="/tours/custom-projects" className={ 'custom-projects' === tourId? "active": ''}>Custom Projects</Link></li>
                </ul>
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToursPage)
