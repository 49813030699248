import {
    SHIPPING_ORDER_REQUEST,
    SHIPPING_ORDER_FAILURE,
    SHIPPING_ORDER_SUCCESS
} from "./actions";

export default ( state = {
    shippingOrderDetails: null,
    isFetchingShippingOrderDetails: false,
    shippingOrderError: null
}, action) => {
    switch (action.type) {
        case SHIPPING_ORDER_REQUEST:
            return {
                ...state,
                isFetchingShippingOrderDetails: true,
            }
        case SHIPPING_ORDER_SUCCESS:
            return {
                ...state,
                isFetchingShippingOrderDetails: false,
                shippingOrderDetails: action.data,
                shippingOrderError: null
            }
        case SHIPPING_ORDER_FAILURE:
            return {
                ...state,
                isFetchingShippingOrderDetails: false,
                shippingOrderError: action.error
            }

        default:
            return state;

    }

}