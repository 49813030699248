export const PRODUCT = {
    sku: "Custom Audio",
}
export const custom_options = {
    on_sale_date_time_text: 'on_sale_date_time_text',
    today_day: 'today_day',
    radio_production: 'radio_production',
};

export const fields = {
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.today_day]: '',
    [custom_options.radio_production]: '',
}
