export const PRODUCT = {
    sku: "ONLINE VIDEO",
}
export const custom_options = {
    due_date: 'due_date',
    on_sale_date_time_text: 'on_sale_date_time_text',
    ticketing_info: 'ticketing_info',
    platform: 'platform',
    voice_over_tag: 'voice_over_tag',
    send_for_approval: {
        code: 'send',
        sku: {
            send_tv_for_approval: 'send_for_approval'
        }
    },
    send_for_approval_to: 'send_for_approval_to',
}

export const fields = {
    [custom_options.due_date]: '',
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.platform]: '',
    [custom_options.ticketing_info]: '',
    [custom_options.voice_over_tag]: '',
    [custom_options.send_for_approval.code]: null,
    [custom_options.send_for_approval.sku.send_tv_for_approval]: false,
    [custom_options.send_for_approval_to]: '',
}
