export const PRODUCT = {
    sku: "Digital 2023",
}
export const custom_options = {
    ordered_by: 'ordered_by',
    due_date: 'due_date',
    sponsors: {
        code: 'sponsors',
        sku: {
            'sponsors_amex': 'sponsors_amex',
            'sponsors_citi': 'sponsors_citi',
            'sponsors_none': 'sponsors_none',
            'sponsors_other': 'sponsors_other',
        }
    },
    national_assets_instructions: 'national_assets_instructions',
    national_assets: {
        code: 'national_assets',
        sku: {
            national_assets_display_banners:'national_assets_display_banners',
            national_assets_website_banners:'national_assets_website_banners',
            national_assets_social_banners:'national_assets_social_banners',
            national_assets_additional_sets:'national_assets_additional_sets',
            national_assets_revisions:'national_assets_revisions',
            national_assets_units:'national_assets_units',
        }
    },
    regional_assets_instructions: 'regional_assets_instructions',
    regional_assets: {
        code: 'regional_assets',
        sku: {
            regional_assets_display_banners:'regional_assets_display_banners',
            regional_assets_social_banners:'regional_assets_social_banners',
            regional_assets_additional_sets:'regional_assets_additional_sets',
            regional_assets_revisions:'regional_assets_revisions',
            regional_assets_localized_markets:'regional_assets_localized_markets',
            regional_assets_unit:'regional_assets_unit',
        }
    },
    tour_poster_instructions: 'tour_poster_instructions',
    tour_poster: {
        code: 'tour_poster',
        sku: {
            tour_poster: 'tour_poster_tour_poster',
        }
    },
    single_album_banners_instructions: 'single_album_banners_instructions',
    single_album_banners: {
        code: 'single_album_banners',
        sku: {
            single_album_banners_standard_set: 'single_album_banners_standard_set',
            single_album_banners_revisions: 'single_album_banners_revisions',
            single_album_banners_units: 'single_album_banners_units',
        }
    },
    approved_admat_instructions: 'approved_admat_instructions',
    approved_admat: {
        code: 'approved_admat',
        sku: {
            approved_admat: 'approved_admat_approved_admat',
        }
    },
    paid_digital_instructions: 'paid_digital_instructions',
    paid_digital: {
        code: 'paid_digital',
        sku: {
            paid_digital_takeovers: 'paid_digital_takeovers',
            paid_digital_ln_tm_pushdowns: 'paid_digital_ln_tm_pushdowns',
            paid_digital_revisions: 'paid_digital_revisions',
            paid_digital_units: 'paid_digital_units',
        }
    },
    billboards_instructions: 'billboards_instructions',
    billboards: {
        code: 'billboards',
        sku: {
            billboards_sa_print: 'billboards_sa_print',
            billboards_sa_video: 'billboards_sa_video',
            billboards_revisions: 'billboards_revisions',
            billboards_units: 'billboards_units',
            billboards_custom: 'billboards_custom',
            billboards_sv_second_cuts: 'billboards_sv_second_cuts'
        }
    },
    poster_art_boards_instructions: 'poster_art_board_instructions',
    poster_art_boards: {
        code: 'poster_art_boards',
        sku: {
            creative: 'poster_art_boards_poster_art_creative'
        }
    },
    online_video_instructions: 'online_video_instructions',
    online_video: {
        code: 'online_video',
        sku: {
            online_video_1_1_presale: 'online_video_1_1_presale',
            online_video_1_1_second_cut: 'online_video_1_1_second_cut',
            online_video_16_9_presale: 'online_video_16_9_presale',
            online_video_16_9_second_cut: 'online_video_16_9_second_cut',
            online_video_9_16_presale: 'online_video_9_16_presale',
            online_video_9_16_second_cut: 'online_video_9_16_second_cut',
            online_video_4_5_presale: 'online_video_4_5_presale',
            online_vide_4_5_second_cut: 'online_vide_4_5_second_cut',
            online_video_16_9_ctv_presale: 'online_video_16_9_ctv_presale',
            online_video_16_9_ctv_second_cut: 'online_video_16_9_ctv_second_cut',
            online_video_9_16_tiktok_presale: 'online_video_9_16_tiktok_presale',
            online_video_9_16_tiktok_second_cut: 'online_video_9_16_tiktok_second_cut',
            online_video_oneoff_video: 'online_video_oneoff_video',
        }
    },
    video_liners_instructions: 'video_liners_instructions',
    video_liners: {
        code: 'video_liners',
        sku: {
            video_liners_tour_liners: 'video_liners_tour_liners',
            video_liners_no_footage: 'video_liners_no_footage',
            video_liners_w_footage: 'video_liners_w_footage'
        }
    },
    ppt_instructions: 'ppt_instructions',
    ppt: {
        code: 'ppt',
        sku: {
            ppt_decks: 'ppt_decks',
            ppt_updates: 'ppt_updates'
        }
    },
    online_audio_instructions: 'online_audio_instructions',
    online_audio: {
        code: 'online_audio',
        sku: {
            online_audio_sp_first_cut: 'online_audio_sp_first_cut',
            online_audio_sp_second_cut: 'online_audio_sp_second_cut',
            online_audio_outside_vo_charge: 'online_audio_outside_vo_charge',
        }
    },
    today_day: 'today_day',
    additional_details: 'additional_details',
    upload_documents: 'upload_documents',
    upload_documents1: 'upload_documents1',
    upload_template: 'upload_template'
}

function createFieldCopy() {
    let copy = {};
    for (let customOptionIndex in custom_options) {
        let customOption = custom_options[customOptionIndex];
        if(typeof customOption === "object") {
            if('code' in customOption) {
                copy[customOption.code] = null;
            }
            if('sku' in customOption) {
                for (let customOptionSkuIndex in customOption['sku']) {
                    let customOptionSku = customOption['sku'][customOptionSkuIndex];
                    copy[customOptionSku + '_qty'] = 0;
                }
            }
        } else {
            copy[customOption] = null;
        }
    }
    return copy;
}

export const fieldCopy = createFieldCopy();

export const fields = JSON.parse(JSON.stringify(fieldCopy));
