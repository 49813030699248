import React from "react"
import {connect} from "react-redux"
import CheckoutCartItem from "../CheckoutCartItem";
import CheckoutCartTotal from "../CheckoutCartTotal";
import {checkIsMainProduct} from "../../helpers";

class CheckoutCartItems extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            expandedItemId: null
        }
    }

    componentDidMount() {
    }
    getProductsRelated(group_item, product_type, item = null) {
        if(item)
        {   if(item.extension_attributes.product_type === 'shipping'){
                return [];
            }
            if(item.extension_attributes.product_type === 'revision_product'){
                return [];
            }
        }

        let shippingRelated = [];
        let cartItems = this.props.items;
        shippingRelated = cartItems.map((item) =>{
                if(item.extension_attributes.group_item == group_item
                    && item.extension_attributes.product_type == product_type) {
                    return item
                }
            }
        )
        shippingRelated = shippingRelated.filter((item) =>{
            if(item)
            {
                return item;
            }
        })
        return shippingRelated;
    }

    isItemToShow(item) {
        if(checkIsMainProduct(item.extension_attributes.product_type)) {
            return true;
        }
        else {
            let items = this.props.items;
            for(let index in items) {
                let cartItem = items[index];
                if(cartItem.extension_attributes.group_item === item.extension_attributes.group_item
                && cartItem.item_id !== item.item_id
                && cartItem.extension_attributes.product_type !== 'shipping'
                    && cartItem.extension_attributes.product_type !== 'revision_product'
                )
                {
                    return false;
                }
            }
            return true
        }
    }
    render(){
        let items = this.props.items;
        let cartTotals = this.props.cartTotals;
        let {expandedItemId} = this.state;
        return (
            <div-cartitems>
                <ol id="order-review-list">
                    {items.map((item, key) =>{
                        if(this.isItemToShow(item)){
                            return <CheckoutCartItem
                                item={item}
                                key={key}
                                index={key}
                                expanded={expandedItemId==item.item_id?true:false}
                                shippingRelated={this.getProductsRelated(item.extension_attributes.group_item, 'shipping', item)}
                                cutsRelated={this.getProductsRelated(item.extension_attributes.group_item, 'cut', item)}
                            />
                        }
                    })}
                </ol>
                {cartTotals && <CheckoutCartTotal cartTotals={cartTotals}/>}
            </div-cartitems>
        )
    }
}

export default connect(null, null)(CheckoutCartItems)