import React from "react"
import {connect} from "react-redux";
import OrderBillingAddress from "../OrderBillingAddress"
import OrderItemsList from "../OrderDetailsItemsList";
import {getOrderAttempt} from "./actions"
import {Link} from "react-router-dom";
import {CDN_URL} from "../../actions/Constants";

const  mapStateToProps = state => ({
    ...state.orderDetails
});


const mapDispatchToProps = {
    getOrderAttempt,
}

class OrderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextOrderId: null,
            prevOrderId: null
        }
    }


    componentDidMount() {
        let {orderId} = this.props;
        this.props.getOrderAttempt(orderId);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.orderId != this.props.orderId) {
            this.props.getOrderAttempt(this.props.orderId);
        }
        if(prevProps.orderDetails != this.props.orderDetails) {
            let nextOrderId = this.getNextOrderId();
            let prevOrderId = this.getPrevOrderId();
            this.setState({
                nextOrderId: nextOrderId,
                prevOrderId: prevOrderId
            })
        }
    }
    printPartOfPage(event){
        event.preventDefault()
        let elementId = 'content';
        const content = document.getElementById(elementId);
        let iframe = undefined;
        if(document.getElementById('frame-to-print')) {
            iframe = document.getElementById('frame-to-print')
        }
        else {
            iframe = document.createElement('iframe');
            iframe.setAttribute('title', 'Order to print');
            iframe.setAttribute('id', 'frame-to-print');
            document.body.appendChild(iframe);
        }
        let print = iframe.contentWindow;

        print.document.open();
        print.document.write(content.innerHTML);
        print.document.close();
        print.focus();
        print.print()
        document.getElementById('frame-to-print').remove()
    }
    getNextOrderId() {
        let currentOrderId = this.props.orderId;
        let orderDetails = this.props.orderDetails;
        let nextOrderId = null;
        if(orderDetails) {
            let ids = orderDetails.extension_attributes.customer_orders_ids;
            console.log("ids", ids);
            let index = ids.indexOf(parseInt(currentOrderId));
            console.log('indexIds', index)
            if(index != -1 && index != ids.length - 1) {
                nextOrderId = ids[index + 1]
            }
        }
        return nextOrderId;
    }
    getPrevOrderId() {
        let currentOrderId = this.props.orderId;
        let orderDetails = this.props.orderDetails;
        let prevOrderId = null;
        if(orderDetails) {
            let ids = orderDetails.extension_attributes.customer_orders_ids;
            let index = ids.indexOf(parseInt(currentOrderId));
            if(index != -1 && index != 0) {
                prevOrderId = ids[index - 1]
            }
        }
        return prevOrderId;
    }

    render() {
        let {orderDetails,isFetchingOrderDetails, orderId} = this.props;
        let {nextOrderId, prevOrderId} = this.state;
        if(isFetchingOrderDetails) {
            return (
                <main role="main">
                    <section id="content" className="container content-wrapper loading-wrapper">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="loading-white">
                                    <i className="icon-loading"></i>
                                    <span>Loading Order Details</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        if(orderDetails) {
            return (
                <main id="print-order" role="main">
                    <section id="content" className="container content-wrapper">
                        <div className="row section-header">
                            <div className="col-md-10 col-sm-9">
                                <ul className="breadcrumb">
                                    <li><Link to="/dashboard">Account Dashboard</Link></li>
                                    <li><Link to="/orders">Orders</Link></li>
                                </ul>
                                <h1 className="h-two blue">#{orderDetails.increment_id}</h1>
                                <p className="order-date">Order Date: {orderDetails.created_at}</p>
                            </div>
                            <div className="col-md-2 col-sm-3">
                                <ul id="recent-order-navigation">
                                    <li>
                                        {prevOrderId && <Link to={`/orderDetails/${prevOrderId}`} title="Previous Order">Previous Order</Link>}
                                    </li>
                                    <li>
                                        {nextOrderId && <Link to={`/orderDetails/${nextOrderId}`} title="Next Order">Next Order</Link>}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row section-order-options">
                            <div className="col-sm-6">
                                <Link to={{
                                    pathname: `/reOrder/${orderDetails.entity_id}`,
                                    state: {
                                        order: orderDetails
                                    }
                                }} className="button large blue icon">
                                    Reorder
                                    <i aria-hidden="true">
                                        <img src={`${CDN_URL}assets/icon-button-reorder.svg`} alt="Reorder"/>
                                    </i>
                                </Link>
                            </div>
                            <div className="col-sm-6">
                                <Link to={`/orders/print/${orderDetails.entity_id}`} target={"_blank"} className="button large blue icon">
                                    Print Order
                                    <i aria-hidden="true">
                                        <img src={`${CDN_URL}assets/icon-button-print.svg`} alt="Print Order"/>
                                    </i>
                                </Link>
                            </div>
                        </div>

                        {orderDetails && <OrderBillingAddress billingAddress={orderDetails.billing_address}
                                                              paymentMethod={orderDetails.payment.additional_information}
                                                              poNumber={orderDetails.payment.po_number}
                        />}
                        {orderDetails && <OrderItemsList ordersItems={orderDetails.items}/>}
                    </section>
                </main>
            )
        }
        else {
            return (
                <main role="main">
                    <section id="content" className="container content-wrapper">
                        <div className="row section-header">
                            <div className="col-md-10 col-sm-9">
                                <ul className="breadcrumb">
                                    <li><Link to="/dashboard">Account Dashboard</Link></li>
                                    <li><Link to="/orders">Orders</Link></li>
                                </ul>
                                <h1 className="h-two blue">Order Not Found</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <p className="p-three grey">Sorry, we can't find that order. Please go back and <a className="blue" href="/orders">select another order</a> from your list.</p>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails)
