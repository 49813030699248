import React, {Component} from 'react'
import {Media, Player, controls, utils} from 'react-media-player'
import PlayPause from './PlayPause'
import MuteUnmute from './MuteUnmute'

const {
    CurrentTime,
    Progress,
    SeekBar,
    Duration,
    Volume,
    Fullscreen,
} = controls
const {formatTime} = utils

class Panner {
    constructor({source, audioContext, panningAmount = 0}) {
        this._source = source
        this._audioContext = audioContext
        this._initialPanningAmount = panningAmount
    }

    connect() {
        this._splitter = this._audioContext.createChannelSplitter(2)
        this._gainLeft = this._audioContext.createGain()
        this._gainRight = this._audioContext.createGain()
        this._merger = this._audioContext.createChannelMerger(2)
        this._source.connect(
            this._splitter,
            0,
            0
        )
        this._splitter.connect(
            this._gainLeft,
            0
        )
        this._splitter.connect(
            this._gainRight,
            1
        )
        this._gainLeft.connect(
            this._merger,
            0,
            0
        )
        this._gainRight.connect(
            this._merger,
            0,
            1
        )
        return this._merger
    }

    setPosition(amount) {
        this._gainLeft.gain.value = amount <= 0 ? 1 : 1 - amount
        this._gainRight.gain.value = amount >= 0 ? 1 : 1 + amount
    }
}

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.mediaRef = React.createRef();
        this.state = {
            error: null
        }
    }

    _handlePannerChange = ({target}) => {
        const x = +target.value
        const y = 0
        const z = 1 - Math.abs(x)
        this.panner.setPosition(x, y, z)
    }

    _connectSource = (source, audioContext) => {
        this.panner = new Panner({source, audioContext})
        return this.panner.connect()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.stopAllMedia != this.props.stopAllMedia) {
            console.log('stop', this.props.stopAllMedia)
            if(this.props.stopAllMedia) {
                this.stopMedia();
            }
        }
    }
    stopMedia()
    {
        if(this.mediaRef.current) {
            console.log('Current', this.mediaRef.current )
            if(this.mediaRef.current.state.isPlaying) {
                this.mediaRef.current.playPause();
            }
        }
    }
    render() {
        let src = this.props.src;
        return (
            <Media ref={this.mediaRef}

            >
                {({playPause}) =>
                    <div
                        tabIndex="0"
                    >
                        <Player
                          onError={(e) => {
                            this.setState({
                                error: true
                            })
                          }}
                            src={src}
                            onClick={() => {
                                if(this.state.error) {
                                    window.open(src);
                                } else {
                                    playPause()
                                }
                            }}
                            vendor={'audio'}

                        />
                        <div className="media-controls"
                             onMouseEnter={this.props.onMouseEnterMediaControls}
                             onMouseLeave={this.props.onMouseLeaveMediaControls}
                        >
                            {this.state.error? <>
                                <PlayPause className="media-control media-control--play-pause" src={src}/>
                                <div>
                                    <span>
                                        To listen to this media option, press play to download.
                                    </span>
                                </div>
                            </>: <>
                                <PlayPause className="media-control media-control--play-pause" src={src}/>
                                <CurrentTime className="media-control media-control--current-time"/>
                                <div className="media-control-group media-control-group--seek">
                                    <Progress className="media-control media-control--progress"/>
                                    <SeekBar className="media-control media-control--seekbar"/>
                                </div>
                                <Duration className="media-control media-control--duration"/>
                                <MuteUnmute className="media-control media-control--mute-unmute"/>
                                <Volume className="media-control media-control--volume"/>
                            </>}

                        </div>
                    </div>
                }
            </Media>
        )
    }
}

export default AudioPlayer
