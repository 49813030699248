import {custom_options as common_custom_options} from "../PKGCOMMON/definition"
import {fields as common_fields} from "../PKGCOMMON/definition"
export const custom_options = {
    ...common_custom_options

};

export const fields = {
    ...common_fields
}
