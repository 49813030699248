import React from "react"
import {connect} from "react-redux"
import {getCategoryByArtist} from "./actions";
import {Link} from "react-router-dom";

const mapDispatchToProps = {
    getCategoryByArtist,
};

const mapStateToProps = state => ({
    ...state.artistTours
})


class ArtistTours extends React.Component{

    componentDidMount() {
        console.log(this.props.match.params.artistId);
        let artistId = this.props.match.params.artistId;
        this.props.getCategoryByArtist(artistId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.artistTours !== this.props.artistTours) {
            if(this.props.artistTours && this.props.artistTours.length === 1) {
                if(this.props.history.action === 'PUSH') {
                    this.props.history.push('/order/process/tours/' + this.props.artistTours[0].id);
                } else {
                    this.props.history.goBack();
                }

            }
        }
    }

    render(){
        let {artistTours, isFetchingArtistTours, artistToursRequestError,
        artistData} = this.props;
        if(isFetchingArtistTours)
        {
            return (
                <main id="main" role="main">
                    <section id="content" className="container content-wrapper loading-wrapper">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="loading-white">
                                    <i className="icon-loading"></i>
                                    <span>Finding Tours</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        if(artistToursRequestError) {
            return (
                <main id="main" role="main">
                    <section id="content" className="container content-wrapper">
                        <div className="row">
                            <div className="alert error">
                                <p>{artistToursRequestError}</p>
                            </div>
                        </div>
                    </section>
                </main>
            )
        }
        return (
            <main id="main" role="main">
                <section id="content" className="container content-wrapper">
                    <div className="row section-header">
                        <div className="col-md-10 col-sm-9">
                            <ul className="breadcrumb">
                                <li>
                                    <Link to="/artists">
                                        Tour Roster
                                    </Link>
                                </li>
                            </ul>
                            <h1 className="h-two blue mb-20">{artistData && artistData.name}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            {artistTours && artistTours.length == 0 &&
                            <p className="p-three grey">Sorry, we didn't find any tours for {artistData && artistData.name}. Please return to the full <Link to="/artists" className="blue">tour roster</Link> list to find another artist.</p>
                            }
                            {artistTours &&
                            artistTours.map((tour) => {
                                return <ul key={tour.id} id="artist-tours-list">
                                    <li>
                                        <Link to={`/order/process/tours/${tour.id}`}>
                                            {tour.name +' [Tour]' }
                                        </Link>
                                    </li>
                                </ul>
                            })
                            }
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistTours)
