import reducer from './reducer';
import {applyMiddleware, createStore} from 'redux';
import thunk from "redux-thunk";
import {authInterceptor, tokenMiddleware} from "./middleware/middleware";

const store = createStore(
  reducer,
  applyMiddleware(thunk,tokenMiddleware, authInterceptor)
);

export default store;
