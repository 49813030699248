import {request} from "../../axiosRequest";

export const VALIDATE_RESET_PASSWORD_TOKEN_REQUEST = 'VALIDATE_RESET_PASSWORD_TOKEN_REQUEST';
export const VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS';
export const VALIDATE_RESET_PASSWORD_TOKEN_FAILURE = 'VALIDATE_RESET_PASSWORD_TOKEN_FAILURE';

export const validatePasswordTokenSuccess = () =>({
    type: VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
})

export const validatePasswordTokenRequest = () =>({
    type: VALIDATE_RESET_PASSWORD_TOKEN_REQUEST,
})

export const validatePasswordTokenFailure = (error) =>({
    type: VALIDATE_RESET_PASSWORD_TOKEN_FAILURE,
    error: error
})


export const validatePasswordToken = (token, email, password) => {
    return (dispatch) => {
        dispatch(validatePasswordTokenRequest());
        request.post('customers/resetPasswordWithToken', {email: email, resetToken: token, newPassword: password})
            .then(response => {
                return response;
            })
            .then(result => {
                let data = result.data;
                console.log(data);
                if(data.success && data.code == 200 ){
                    dispatch(validatePasswordTokenSuccess());
                }
                if(data.code == 400) {
                    dispatch(validatePasswordTokenFailure(data.data.message));
                }
            })
            .catch(error => {
                    dispatch(validatePasswordTokenFailure(error))
                }
            )

    }
};