import React from "react"
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom";
import {getTourDemos} from "./actions";
import ToursDetailsCenterItem from "../ToursDetailsCenterItem";
import ToursModal from "../../containers/ToursModal";

const mapStateToProps = state => ({
    ...state.tourDesignCenter,
    ...state.auth
})


const mapDispatchToProps = {
    getTourDemos,
}
class ToursDetailsCenter extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            visibleModal: false,
            itemSelected: 0
        };
        this.showDemosModal = this.showDemosModal.bind(this);
        this.closeDemosModal = this.closeDemosModal.bind(this);
    }

    componentDidMount() {
        let {categoryId} = this.props;
        if(categoryId){
            this.props.getTourDemos(categoryId);
        }
        this.setMinHeightToContainer();
    }
    setMinHeightToContainer() {
        let navigationHorizontal = document.getElementById("navigation-tours-horizontal");
        let h = 0;
        if(navigationHorizontal) {
            h = navigationHorizontal.offsetHeight;
        }
        let footer = document.getElementById('footer');
        let footerH = 0;
        if(footer) {
            footerH = footer.offsetHeight;
        }
        let total = h + footerH;
        document.getElementById("container").style.setProperty('min-height', `calc(100vh - ${total}px`)
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.categoryId != null && prevProps.categoryId != this.props.categoryId) {
            this.props.getTourDemos(this.props.categoryId);
        }
    }
    showDemosModal(categoryIndex) {
        this.setState({
            visibleModal: true,
            itemSelected: categoryIndex,
        })
    }
    closeDemosModal() {
        this.setState({
            visibleModal: false
        })
    }
    render(){
        let {subCategories} = this.props;
        if(subCategories && subCategories.length > 6) {
            subCategories = subCategories.slice(0,6)
        }
        let {categoryName, categoryDescription, isFetchingToursCenter} = this.props;
        let {visibleModal, itemSelected} = this.state;
        if(isFetchingToursCenter) {
            return (
                <div className="container-fluid fullscreen-tour" id="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="row">
                                <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 center">
                                    <div className="loading-small">
                                        <i className="icon-loading"></i>
                                        <span>Loading {categoryName}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="container-fluid fullscreen-tour" id="container">
                <div className="row">
                    <div className="col-sm-10 col-sm-offset-1">
                        <div className="row">
                            <div className="heading-tours-two col-lg-12">
                                <h1 className="h-one-fluid white">{categoryName}</h1>
                                {subCategories && subCategories.length == 0 &&
                                    <p className="p-one-fluid white">We're working to add {categoryName} examples. Please use the menu below to view others examples.</p>
                                }
                            </div>
                        </div>
                        {subCategories && visibleModal &&
                            <ToursModal
                                type={'demo'}
                                categories={subCategories}
                                closeDemosModal={this.closeDemosModal}
                                itemSelected={itemSelected}
                            />
                        }
                        {subCategories && subCategories.length > 0 && <div id="tour-preview-wrapper" className="row">
                            {subCategories && subCategories.map((category, index) =>{
                                if(index < 6 ){
                                    return <ToursDetailsCenterItem
                                        category={category}
                                        key={index}
                                        showDemosModal={this.showDemosModal}
                                        categoryIndex={index}
                                    />
                                }}
                            )}
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ToursDetailsCenter))
