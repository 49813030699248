import {request} from "../../axiosRequest";

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE= 'GET_COUNTRIES_FAILURE';

export const getCountriesRequest = () => ({
    type: GET_COUNTRIES_REQUEST
});

export const getCountriesSuccess = (data) => ({
    type: GET_COUNTRIES_SUCCESS,
    data: data
});
export const getCountriesFailure = (data) => ({
    type: GET_COUNTRIES_FAILURE,
    error: data
});

export const getCountries = () => {
    return (dispatch) => {
        dispatch(getCountriesRequest());
        request.get(`countries`)
            .then((response) => {return response;})
            .then(result => {
                let data = result.data;
                if(data.success && data.code == 200){
                    let countries = data.data;
                    let countriesTemp = countries.filter((country) => {
                        if(country.full_name_english != "") {
                            return country;
                        }
                    })
                    dispatch(getCountriesSuccess(countriesTemp))
                }
                else{
                    dispatch(getCountriesFailure(data.data))
                }

            })
            .catch(error => {
                console.log(error);
                dispatch(getCountriesFailure(error))
            })
    }
};