import React from 'react';
import {connect} from 'react-redux';
import {CDN_URL, FIELDS_CODE, LENGTH_INFORMATION} from "../../actions/Constants";
import CustomCheckbox from "../CustomCheckbox";
import {getCutTypeProduct, getOptionByCode, getOptionTitleByCode} from "../../helpers";
import {setItemsToDelete} from "../../containers/OrderFormStep1/actions";
import {array} from "prop-types";

const mapDispatchToProps = {
    setItemsToDelete
}

const default_verbiage = {
    cuts_special_presale: '',
    cuts_amex_presale: '',
    cuts_amex_presale_1: '',
    cuts_presale: 'Tickets On Sale (Day, Date, Time) or ("This" Day/Time)',
    cuts_on_sale_now: 'Tickets On Sale Now',
    cuts_week_of_show: 'This (Day of show)',
    cuts_day_prior: 'Tomorrow',
    cuts_day_of: 'Tonight',
    cuts_music_bed_only: '',
    cuts_superless_master: '',
    cuts_hd_superless_master: '',
    cuts_b_roll: '',
    cuts_other: '',
}

class LengthFields extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            cutLength: {...props.lengthInformation},
            custom_length: this.getFirstFieldValue('custom_length', props.lengthInformation),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextBoxChange = this.handleTextBoxChange.bind(this);
        this.getLengthChecked = this.getLengthChecked.bind(this);
        this.cutOption = this.getOption(FIELDS_CODE.cuts.code);
        this.verbiageOption = this.getOption(FIELDS_CODE.cut_verbiage);
        this.lengthOption = this.getOption(FIELDS_CODE.length.code);
        this.optionsDescription = this.getAllOptionsDescription()
    }
    componentDidMount() {
        let lengthInformation = this.props.lengthInformation;
        if(Object.keys(lengthInformation).length > 0) {
            let lengthData = lengthInformation[Object.keys(lengthInformation)[0]];
            if(FIELDS_CODE.video_size.code in lengthData) {
                this.setState({
                    [FIELDS_CODE.video_size.code]: lengthData[FIELDS_CODE.video_size.code]
                })
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.lengthInformation != this.props.lengthInformation) {
            this.setState({
                cutLength: {...this.props.lengthInformation},
            })
        }
    }
    getCutProduct(cut_type) {
        let firstCutProduct = this.props.cutProducts;
        if(firstCutProduct && cut_type in firstCutProduct) {
            return firstCutProduct[cut_type]
        }
        return undefined;
    }

    getOption(optionCode) {
        let cutProduct = this.getCutProduct('first');
        if(cutProduct){
            let option = getOptionByCode(cutProduct.options, optionCode);
            return option;
        }
    }
    updateItemsToDelete(item_id) {
        let idsToDelete = this.props.itemsToDelete;
        if(item_id) {
            idsToDelete.push(item_id)
            this.props.setItemsToDelete(idsToDelete);
        }

    }
    getAllOptionsDescription() {
        let product = this.getCutProduct('first');
        if(product) {
            console.log('optionsDescription', product)
            let productOptions = product.options;
            console.log('optionsDescription', productOptions)
            if(productOptions) {
                let allDescriptions = {};
                productOptions.map((option) => {
                    if(option.extension_attributes && option.extension_attributes.description) {
                        allDescriptions[option.extension_attributes.option_code] = option.extension_attributes.description;
                    }
                })
                return allDescriptions;
            }
        }
        return {};
    }

    handleChange(info) {
        let state = {...this.state};
        let code = info.code;
        let fieldCode = info['field-code'];
        console.log('length State', this.state)
        let cutLength = state.cutLength;
        if(fieldCode === 'cuts') {
            if(code in cutLength && cutLength[code]['isFirst']) {
                this.props.resetLengths();
                return;
            }
            if( code in cutLength){
                console.log('code in length', cutLength);
                this.updateItemsToDelete(cutLength[code].item_id);
                if(Object.keys(cutLength).length === 1){
                    cutLength = {
                        'none': {...cutLength[code]}
                    }
                    cutLength.none.cuts = "";
                    console.log('code in length', cutLength)
                }
                else{
                    delete cutLength[code];
                }
            }
            else
            {
                if(!(code in cutLength))
                {
                    cutLength[code] = {
                        ...LENGTH_INFORMATION
                    };
                    cutLength[code].length = this.getFieldValue(cutLength, 'length');
                    cutLength[code].custom_length = this.getFieldValue(cutLength, 'custom_length');
                    cutLength[code].group_item = this.getFieldValue(cutLength, 'group_item');
                    cutLength[code].cuts = code;
                    cutLength[code].cut_verbiage = default_verbiage[code];
                    cutLength[code].video_size= this.getFieldValue(cutLength, 'video_size');
                }
                if('none' in cutLength) {
                    cutLength.none.cuts = code;
                    cutLength[code] = {
                        ...cutLength.none
                    }
                    delete cutLength['none'];
                    cutLength[code].cut_verbiage = default_verbiage[code];
                }
                if(code === FIELDS_CODE.cuts.sku.cuts_special_presale) {
                    cutLength[code]['script_presale'] = this.props.categoryInfo.citi_script;
                }
                if(code === FIELDS_CODE.cuts.sku.cuts_amex_presale) {
                    cutLength[code]['script_presale'] = this.props.categoryInfo.amex_script;
                }
                if(code === FIELDS_CODE.cuts.sku.cuts_amex_presale_1) {
                    cutLength[code]['script_presale_1'] = this.props.categoryInfo.amex_script;
                }
            }
        }
        if(fieldCode === 'length') {

            for(let indexCutLength in cutLength) {
                console.log('cutLength', cutLength, indexCutLength);
                cutLength[indexCutLength]['length'] = code;
            }
        }
        if(fieldCode === 'video_size') {
            for(let indexCutLength in cutLength) {
                console.log('cutLength', cutLength, indexCutLength);
                cutLength[indexCutLength]['video_size'] = code;
            }
            state['video_size'] = code;
        }
        state.cutLength = cutLength;
        this.setState({
            ...state
        })
        this.props.updateLength(this.props.lengthindex, {...state.cutLength});
    }
    getFirstFieldValue(field, cutLength) {
        for(let index in cutLength) {
            let info = cutLength[index];
            return info[field];
        }
        return ''
    }
    getFieldValue(cutLength, field) {
        for(let index in cutLength) {
            let info = cutLength[index];
            if(info[field] && info[field] !== "") {
                return info[field];
            }
        }
        return ''
    }
    handleTextBoxChange(event) {

        let state = {...this.state};
        let code = event.target.getAttribute('code');
        let name = event.target.name;
        let value = event.target.value;

        console.log('handleTextBoxChange', code, name, value)

        if(name in state.cutLength) {
            state.cutLength[name][code] = value;
        }
        if(name in state) {
            this.setState({
                [name]: value
            })
            let cutLength = state.cutLength;
            for (let cutLengthIndex in cutLength) {
                state.cutLength[cutLengthIndex][code] = value;
            }
        }
        this.props.updateLength(this.props.lengthindex, {...state.cutLength});
    }
    getLengthChecked(sku) {
        let lengthValue = this.getFieldValue(this.state.cutLength, 'length');
        if(lengthValue  === sku) {
            return true;
        }
        return false;
    }
    checkCutDisable() {
        let cutProducts = this.props.cutProducts;
        let product = this.props.product;
        let cutLength = this.state.cutLength;
        if(product.id in cutProducts) {
            let cutProduct = cutProducts[product.id];
            let cutType = getCutTypeProduct(cutLength, product.sku);

            if(cutType in cutProduct) {
                return true;
            }
        }
        return false
    }
    getCutVerbiageDescription(cutValue) {
        console.log('cutValue', cutValue)
        let optionsDescription = this.getAllOptionsDescription();
        console.log('cutValue', optionsDescription)
        if((cutValue.sku === FIELDS_CODE.cuts.sku.cuts_amex_presale || cutValue.sku === FIELDS_CODE.cuts.sku.cuts_amex_presale_1) &&
        optionsDescription && FIELDS_CODE.script_presale in optionsDescription) {
            return {[FIELDS_CODE.script_presale]: optionsDescription[FIELDS_CODE.script_presale]};
        }

        if(
            cutValue.sku !== FIELDS_CODE.cuts.sku.cuts_amex_presale &&
            cutValue.sku !== FIELDS_CODE.cuts.sku.cuts_amex_presale_1 &&
            cutValue.sku !== FIELDS_CODE.cuts.sku.cuts_superless_master &&
            optionsDescription && FIELDS_CODE.cut_verbiage in optionsDescription) {
            return {[FIELDS_CODE.cut_verbiage]: optionsDescription[FIELDS_CODE.cut_verbiage]};
        }
        return {};
    }

    render() {
        let {length} = {...this.state.lengthInformation};
        let state = {...this.state};
        let {lengthindex, productsku} = this.props;
        let optionsDescription = this.getAllOptionsDescription();
        if(productsku) {
            productsku = productsku.replaceAll(' ','_');
        }
        let formErrors = [];
        if(this.props.lengthErrors) {
            formErrors = this.props.lengthErrors;
        }

        let cutOption = this.cutOption;
        let verbiageOption = this.verbiageOption;
        let lengthOption = this.lengthOption;
        return (
            <div-cuts>
                <div className="row">
                    { formErrors.indexOf(FIELDS_CODE.length.code) != -1 &&
                    <div className="col-xs-12">
                        <div className="alert error">
                            <p>Please select a Length from the options below.</p>
                        </div>
                    </div>
                    }
                    <div className="form-group col-xs-12">
                        <label className={formErrors && formErrors.indexOf(FIELDS_CODE.length.code) !== -1?`required error`:`required`}
                        >
                            Length
                        </label>
                        <ul className={formErrors && formErrors.indexOf(FIELDS_CODE.length.code) !== -1?`inline error`:`inline`}>
                            {
                                lengthOption && lengthOption.values && lengthOption.values.map((lengthValue) => {
                                    return (
                                        <li key={lengthValue.option_type_id}>
                                            <label>
                                                <CustomCheckbox
                                                    checked={this.getLengthChecked(lengthValue.sku)}
                                                    label={lengthValue.title}
                                                    type="radio"
                                                    name={`length_${productsku}_${lengthindex}`}
                                                    onchange={this.handleChange}
                                                    feedBackInfo={{
                                                        value: lengthValue.sku,
                                                        code: lengthValue.sku,
                                                        'field-code': lengthOption.extension_attributes.option_code
                                                    }}
                                                />
                                            </label>
                                        </li>
                                    )
                                })
                            }
                            <li>
                                {
                                    this.getLengthChecked(FIELDS_CODE.length.sku.length_other) &&
                                    <input
                                        type="text"
                                        name={FIELDS_CODE.custom_length}
                                        code={FIELDS_CODE.custom_length}
                                        value={state[FIELDS_CODE.custom_length]}
                                        onChange={this.handleTextBoxChange}
                                    />
                                }
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    { formErrors.indexOf(FIELDS_CODE.cuts.code) !== -1 &&
                    <div className="col-xs-12 mt-20">
                        <div className="alert error">
                            <p>Please select and enter information for at least one Cut from the options below.</p>
                        </div>
                    </div>
                    }
                    <div className="form-group col-xs-12">
                        <div className="row">
                            <div className="col-sm-4">
                                <label className={formErrors && formErrors.indexOf(FIELDS_CODE.cuts.code) !== -1?`required error white`:`required white`}>
                                    Cuts
                                </label>
                            </div>
                            <div className="col-sm-4">
                                <label className="white">Cut Verbiage</label>
                            </div>
                        </div>
                        <ul className="cuts">
                            {cutOption && cutOption.values && cutOption.values.map((cutValue) => {
                                let cutVerbiageDescription = this.getCutVerbiageDescription(cutValue);
                                console.log('cutVerbiageDescription', cutVerbiageDescription);
                                console.log('cutVerbiageDescription', cutValue);
                                return (
                                    <li key={cutValue.option_type_id}>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <label>
                                                    <CustomCheckbox
                                                        disabled={this.checkCutDisable(cutValue.sku)}
                                                        checked={state.cutLength[cutValue.sku]}
                                                        label= {cutValue.title}
                                                        onchange={this.handleChange}
                                                        feedBackInfo={{
                                                            value: state.cutLength[cutValue.sku],
                                                            code: cutValue.sku,
                                                            'field-code': 'cuts'
                                                        }}
                                                        setFocus={`${cutValue.sku}_${productsku}_${lengthindex}`}
                                                    />

                                                </label>
                                            </div>
                                            <div className="col-sm-8">
                                                {
                                                    (cutValue.sku === FIELDS_CODE.cuts.sku.cuts_amex_presale ||
                                                    cutValue.sku === FIELDS_CODE.cuts.sku.cuts_amex_presale_1)
                                                &&
                                                <textarea
                                                    id={`${cutValue.sku}_${productsku}_${lengthindex}`}
                                                    code={FIELDS_CODE.script_presale}
                                                    sku={FIELDS_CODE.cuts.sku.cuts_amex_presale_1}
                                                    name={cutValue.sku}
                                                    value={state.cutLength[cutValue.sku] ?
                                                        state.cutLength[cutValue.sku][FIELDS_CODE.script_presale] : ''}
                                                    onChange={this.handleTextBoxChange}
                                                    disabled={!state.cutLength[cutValue.sku]}
                                                ></textarea>
                                                }
                                                {
                                                    'script_presale' in cutVerbiageDescription &&
                                                    <small>{cutVerbiageDescription[FIELDS_CODE.script_presale]}</small>
                                                }
                                                {
                                                    (cutValue.sku !== FIELDS_CODE.cuts.sku.cuts_amex_presale
                                                    && cutValue.sku !== FIELDS_CODE.cuts.sku.cuts_amex_presale_1)
                                                    &&
                                                    <input
                                                        type="text"
                                                        id={`${cutValue.sku}_${productsku}_${lengthindex}`}
                                                        name={cutValue.sku}
                                                        value={state.cutLength[cutValue.sku] ? state.cutLength[cutValue.sku][verbiageOption.extension_attributes.option_code] : ''}
                                                        code={FIELDS_CODE.cut_verbiage}
                                                        onChange={this.handleTextBoxChange}
                                                        disabled={!state.cutLength[cutValue.sku]}
                                                    />
                                                }
                                                {
                                                    'cut_verbiage' in cutVerbiageDescription &&
                                                    <small>{cutVerbiageDescription[FIELDS_CODE.cut_verbiage]}</small>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                { (FIELDS_CODE.cuts.sku.cuts_special_presale in state['cutLength']) && <div className="row">
                    <div className="form-group col-lg-12">
                        <label>
                            {getOptionTitleByCode(this.getCutProduct('first'), FIELDS_CODE.script_presale)}
                        </label>
                        <textarea
                            id={`amex_presale_${productsku}_${lengthindex}`}
                            code={FIELDS_CODE.script_presale}
                            name={FIELDS_CODE.cuts.sku.cuts_special_presale}
                            value={state.cutLength[FIELDS_CODE.cuts.sku.cuts_special_presale] && state.cutLength[FIELDS_CODE.cuts.sku.cuts_special_presale][FIELDS_CODE.script_presale] !== null ?
                                state.cutLength[FIELDS_CODE.cuts.sku.cuts_special_presale][FIELDS_CODE.script_presale] : ''}
                            onChange={this.handleTextBoxChange}
                        ></textarea>
                        {optionsDescription && FIELDS_CODE.script_presale in optionsDescription &&
                        <small>{optionsDescription[FIELDS_CODE.script_presale]}</small>
                        }
                    </div>
                </div>}
                {productsku === 'Online_Video' ||  productsku === 'Theatrical_Online_Video'? <div className="form-group">
                    <div className="row mt-40 mb-20">
                        <div className="col-xs-12">
                            <label className="white">Video Size:</label>
                        </div>
                    </div>
                    { formErrors.indexOf(FIELDS_CODE.video_size.code) != -1 &&
                    <div className="col-xs-12 mb-20">
                        <div className="alert error">
                            <p>Please select a Video Size from the options below.</p>
                        </div>
                    </div>
                    }
                    <div className="row mb-20">
                        <div className="col-md-3 col-sm-6 mb-20">
                            <ul>
                                <li className="mb-10">
                                    <label>
                                        <CustomCheckbox
                                          type={'radio'}
                                          name={FIELDS_CODE.video_size.code + lengthindex}
                                          checked={state[FIELDS_CODE.video_size.code] === FIELDS_CODE.video_size.sku["16x9_horizontal"]}
                                          label= {'16x9 Horizontal'}
                                          onchange={this.handleChange}
                                          feedBackInfo={{
                                              value: state.cutLength['16x9_horizontal'],
                                              code: '16x9_horizontal',
                                              'field-code': 'video_size'
                                          }}
                                        />
                                    </label>
                                </li>
                                <li className="mb-10">
                                    <label>
                                        <CustomCheckbox
                                          type={'radio'}
                                          name={FIELDS_CODE.video_size.code + lengthindex}
                                          checked={state[FIELDS_CODE.video_size.code] === FIELDS_CODE.video_size.sku["1x1_square"]}
                                          label= {'1x1 Square'}
                                          onchange={this.handleChange}
                                          feedBackInfo={{
                                              value: state.cutLength['1x1_square'],
                                              code: FIELDS_CODE.video_size.sku["1x1_square"],
                                              'field-code': 'video_size'
                                          }}
                                        />
                                    </label>
                                </li>
                                <li className="mb-10">
                                    <label>
                                        <CustomCheckbox
                                          type={'radio'}
                                          name={FIELDS_CODE.video_size.code + lengthindex}
                                          checked={state[FIELDS_CODE.video_size.code] === FIELDS_CODE.video_size.sku["9x16_vertical_story"]}
                                          label= {'9:16 Vertical/Story'}
                                          onchange={this.handleChange}
                                          feedBackInfo={{
                                              value: FIELDS_CODE.video_size.sku["9x16_vertical_story"],
                                              code: FIELDS_CODE.video_size.sku["9x16_vertical_story"],
                                              'field-code': 'video_size'
                                          }}
                                        />
                                    </label>
                                </li>
                                <li className="mb-10">
                                    <label>
                                        <CustomCheckbox
                                          type={'radio'}
                                          name={FIELDS_CODE.video_size.code + lengthindex}
                                          checked={state[FIELDS_CODE.video_size.code] === FIELDS_CODE.video_size.sku["4x5_vertical"]}
                                          label= {'4:5 Vertical'}
                                          onchange={this.handleChange}
                                          feedBackInfo={{
                                              value: FIELDS_CODE.video_size.sku["4x5_vertical"],
                                              code: FIELDS_CODE.video_size.sku["4x5_vertical"],
                                              'field-code': 'video_size'
                                          }}
                                        />
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>: null}

            </div-cuts>

        )
    }

}

export default connect(null, mapDispatchToProps)(LengthFields);
