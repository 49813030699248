export const PRODUCT = {
    sku: "Custom VIDEO",
}
export const custom_options = {
    today_day: 'today_day',
    invoice_details: 'invoice_details',
};

export const fields = {
    [custom_options.today_day]: '',
    [custom_options.invoice_details]: '',
}