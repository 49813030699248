import React from "react"
import {connect} from "react-redux"
import {getToursAttempt} from "./actions";
import {Link} from "react-router-dom";
import {CDN_URL} from "../../actions/Constants";

const mapDispatchToProps = {
    getToursAttempt,
}

const mapStateToProps = state => ({
    ...state.tours
})


class Tours extends React.Component{

    componentDidMount() {
        this.props.getToursAttempt();
    }

    render(){
        let {categories, isFetchingTours} = this.props;
        if(isFetchingTours) {
            return (
                <main role="main" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-three.jpg')`}} className="fullscreen-responsive">
                    <div id="content" className="container-fluid fullscreen-tour">
                        <div className="loading-small">
                            <i className="icon-loading"></i>
                            <span>Loading Tour</span>
                        </div>
                    </div>
                </main>
            )
        }
        return (
            <main role="main" style={{backgroundImage: `url('${CDN_URL}assets/backgrounds/bg-three.jpg')`}} className="fullscreen-responsive">
                <div id="content" className="container-fluid fullscreen-tour">
                    <div className="row">
                        <div className="heading-tours-one col-lg-5 col-lg-offset-1 col-md-6 col-md-offset-1 col-sm-12">
                            <h1 className="h-one-fluid white">What We Do</h1>
                            <p className="p-one-fluid">
                                We are the industry leader in the production of art, audio, video
                                and online design for a wide range of entertainment marketing materials.
                            </p>
                        </div>
                        <div className="col-lg-5 col-lg-offset-1 col-md-4 col-sm-12">
                            <ul id="navigation-tours-vertical" role="menu">
                                <li role="menuitem">
                                    <Link to={
                                        {
                                            pathname: "/tours/touring",
                                            categoryName: 'Touring'
                                        }
                                    }>
                                        Touring
                                    </Link>
                                </li>
                                <li role="menuitem"><Link to="/tours/festivals">Festivals</Link></li>
                                <li role="menuitem"><Link to="/tours/album-releases">Label Relations</Link></li>
                                <li role="menuitem"><Link to="/tours/video-shoots">Video Shoots</Link></li>
                                <li role="menuitem"><Link to="/tours/theatrical-and-family">Theatrical and Family</Link></li>
                                <li role="menuitem"><Link to="tours/custom-projects">Custom Projects</Link></li>
                                {/*{categories && categories.map((category) =>
                                    <li role="menuitem" key={category.id}>
                                        <Link to={`/tours/${category.id}`}>{category.name}</Link>
                                    </li>

                                )}*/}
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="tours-button-home">
                    <Link to="/">Home</Link>
                </div>
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tours)
