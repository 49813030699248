import {request} from "../../axiosRequest";
import {customerInformationReceived} from "../../containers/CustomerInformation/actions";

export const EDIT_ADDRESS_REQUEST = 'EDIT_ADDRESS_REQUEST';
export const EDIT_ADDRESS_SUCCESS = 'EDIT_ADDRESS_SUCCESS';
export const EDIT_ADDRESS_FAILURE= 'EDIT_ADDRESS_FAILURE';
export const CLEAN_EDIT_ADDRESS= 'CLEAN_EDIT_ADDRESS';

export const editAddressRequest = () => ({
    type: EDIT_ADDRESS_REQUEST
});

export const editAddressSuccess = (data) => ({
    type: EDIT_ADDRESS_SUCCESS,
    data: data
});
export const editAddressFailure = (data) => ({
    type: EDIT_ADDRESS_FAILURE,
    error: data
});


export const cleanEditAddressState = () => ({
    type: CLEAN_EDIT_ADDRESS,
});

export const  editAddressAttempt = (customerId, address) =>{
    let postData = {'address': address};
    if(customerId){
        return (dispatch) => {
            dispatch(editAddressRequest());
            request.post(`customers/${customerId}/updateAddress`,
                postData, true
            )
            .then((response) => {return response;})
            .then(result => {
                let data = result.data;
                console.log("Edit Address", data);
                if(data.success && data.code == 200){
                    dispatch(editAddressSuccess(data.data));
                    dispatch(customerInformationReceived(data.data));
                }
                else{
                    dispatch(editAddressFailure(data.data))
                }

            })
            .catch(error => {
                console.log(error);
                dispatch(editAddressFailure(error))
            })
        }
    }
    return false;
};


