import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class ConvertParseObject extends React.Component {

    render() {
        let element = this.props.element;
        let elementProps = element.props;
        console.log('elementProps', element, elementProps)
        if(Array.isArray(element)) {
            return getChild(element)
        }
        if(element.type == 'a' && elementProps && elementProps.href && elementProps.href.substring(0, 4) != 'http') {
            return (
              <Link to={elementProps.href} {...element.props}>
                  {getChild(elementProps.children)}
              </Link>
            )
        }

        if(typeof element === 'string') {
            return (
              element
            );
        }

        return (
          <TagHtml type={element.type} children={elementProps.children} props={element.props}/>

        )
    }

}

export default connect(null, null)(ConvertParseObject)


function TagHtml({type, children, props}) {
    let tag = React.createElement(type, {...props},  getChild(children));
    return tag;
}
function getChild(element) {
    if(typeof element == 'string') {
        return element;
    }
    if(Array.isArray(element)) {
        return element.map((item, key) => {return <ConvertParseObject element={item} key={key}/>})
    }
    return <ConvertParseObject element={element}/>;
}
