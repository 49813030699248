import React from "react"
import {connect} from "react-redux"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    HOME_TOUR_DEMO_DEMO,
    HOME_TOUR_DEMO_IMAGE,
    TOUR_DEMO_DEMO,
    TOUR_DEMO_IMAGE
} from "../../actions/Constants";
import ToursModalItem from "../../components/ToursModalItem";

class ToursModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stopAllMedia: false,
            slickSettings: {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                swipe: false,
                draggable: true
            }
        }
        this.stopAllMedia = this.stopAllMedia.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.setSlickDraggableOff = this.setSlickDraggableOff.bind(this);
        this.setSlickDraggableOn = this.setSlickDraggableOn.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.closeModal);
    }
    componentWillUnmount() {
        this.stopAllMedia()
        document.removeEventListener('keydown', this.closeModal);
    }
    closeModal(event) {
        if (event.code == 'Escape') {
            this.props.closeDemosModal()
        }

    }
    getData(){
        let categories = this.props.categories;
        let type = this.props.type;
        let data = [];
        if(categories){
            let itemSelected = this.props.itemSelected;
            let tempCategories = categories.slice(itemSelected, itemSelected + 1);
            tempCategories = tempCategories.concat(categories.slice(itemSelected + 1, categories.length));
            tempCategories = tempCategories.concat(categories.slice(0, itemSelected));
            data = tempCategories.map((category) => {
                let baseUrlImage = '';
                let baseUrlDemo = '';
                if(type === 'demo') {
                    baseUrlImage = TOUR_DEMO_IMAGE;
                    baseUrlDemo = TOUR_DEMO_DEMO;
                }
                if(type === 'home_demo') {
                    baseUrlImage = HOME_TOUR_DEMO_IMAGE;
                    baseUrlDemo = HOME_TOUR_DEMO_DEMO;
                }

                let demo = category.tour_demo;
                let extension = ''
                if(demo) {
                    extension = category.tour_demo.split('.');
                    extension = extension[extension.length - 1]
                }

                return {
                    categoryName: category.tour_name,
                    artistImage: baseUrlImage +  category.tour_image,
                    artistName: category.artist_name,
                    demoFileUrl: baseUrlDemo + category.tour_demo,
                    demoExtension: extension
                }
            });
            return data;
        }

    }
    _extractArtistPhoto(artist) {
        let photoProperty = artist.photo;
        if(photoProperty) {
            photoProperty = JSON.parse(photoProperty);
            if(photoProperty && photoProperty.length > 0) {
                photoProperty = photoProperty[0];
                return photoProperty
            }
        }
        return {}
    }
   stopAllMedia() {
        console.log('stopping')
       this.setState({
           stopAllMedia: true
       }, () => {
           this.setState({
               stopAllMedia: false
           });
       })
   }

    setSlickDraggableOff() {
        let state = {...this.state}
        state.slickSettings.draggable = false;
        this.setState({
            ...state
        })
    }
    setSlickDraggableOn() {
        let state = {...this.state}
        state.slickSettings.draggable = true;
        this.setState({
            ...state
        })
    }

    render() {
        let data = this.getData();
        let {stopAllMedia} = this.state;
        console.log('categories....', data)
        return (
            <div id="modal-tour" className="modal-wrapper" >
                <div id="modal-slider-wrapper" className="row" data-bur="2">
                    <div id="modal-slider">
                        <Slider
                            dots={true}
                            infinite={true}
                            speed= {500}
                            slidesToShow={1}
                            swipe={false}
                            slidesToScroll={1}
                            draggable={true}  beforeChange={this.stopAllMedia}>
                            { data.map((value, index) => {
                                    // if (value) {
                                        return (
                                            <ToursModalItem
                                                key={index}
                                                data={value}
                                                stopAllMedia={stopAllMedia}
                                                onMouseEnterMediaControls={this.setSlickDraggableOff}
                                                onMouseLeaveMediaControls={this.setSlickDraggableOn}
                                        />)
                                    // }
                                }
                            )}
                        </Slider>
                    </div>
                </div>
                <span id="close-modal-account" className="close-modal" onClick={this.props.closeDemosModal}>Close Account Window</span>
            </div>
        )
    }
}

export default connect(null, null)(ToursModal)
