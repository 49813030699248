import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";
import {deleteAddress} from "../../containers/CustomerInformation/actions";
import ModalConfirmation from "../ModalConfirmation";

const mapDispatchToProps = {
    deleteAddress,
}

class CustomerAddressItem extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            hideDialog: true
        }
        this.getFullNameFromAddress = this.getFullNameFromAddress.bind(this);
        this.deleteAddress = this.deleteAddress.bind(this);
        this.showDeleteAddressDialog = this.showDeleteAddressDialog.bind(this);
        this.hideDeleteAddressDialog = this.hideDeleteAddressDialog.bind(this);
    }

    componentDidMount() {
        let {address} = {...this.props};
        this.setState(
            {
                ...address,
                hideDialog: true
            }
        )

    }

    getFullNameFromAddress()
    {
        let address = this.props.address;
        return `${address.firstname} ${address.lastname}`;
    }
    showDeleteAddressDialog() {
        this.setState({hideDialog: false});
    }
    hideDeleteAddressDialog() {
        this.setState({hideDialog: true});
    }
    deleteAddress() {
        let address = this.props.address;
        if(address) {
            this.props.deleteAddress(address.id, this.props.customerInfo)
            this.hideDeleteAddressDialog()
        }
    }
    render(){
        if(this.props.address){
            console.log('address item')
            console.log(this.props);
            let {address} = this.props;
            let hideDialog = this.state.hideDialog;
            let defaultBillingAddressId = this.props.defaultBillingAddressId;
            return (
                <li className="col-md-3 col-sm-6">
                    <h4>{defaultBillingAddressId == address.id ? 'Default Billing Address' : 'Additional Address'}</h4>
                    <span>{this.getFullNameFromAddress()}</span>
                    <span>{address.company}</span>
                    <span>{address.street}</span>
                    <span>{address.city}, {address.region.region}, {address.postcode}</span>
                    <span>{address.country_id}</span>
                    <span>T: {address.telephone}</span>
                    {address.fax && <span>F: {address.fax}</span>}
{/*                    {hideDialog && <div className="address-options">
                        <Link to={`/customer/address/${address.id}`} className="button xxxsmall blue">Edit</Link>
                        {defaultBillingAddressId == address.id ? '' :
                            <Link to={'#'} onClick={this.showDeleteAddressDialog} className="button xxxsmall red ml-5">Delete</Link>
                        }
                    </div>}*/}
 {/*                   <ModalConfirmation
                        onConfirm={this.deleteAddress}
                        onCancel={this.hideDeleteAddressDialog}
                        hidden={hideDialog}
                    />*/}
                </li>
            )
        }
        else {
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="table-no-count">
                            <i aria-hidden="true">
                                <img src="assets/icon-no-addresses-inverse.svg" alt="No Addresses Found"/>
                            </i>
                            <h2>No Addresses Found</h2>
                            <p className="p-three">You don't have any saved addresses. Do you want to <Link to="customer/address/new">add a new address</Link>?</p>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default connect(null, mapDispatchToProps)(CustomerAddressItem)
