export const PRODUCT = {
    sku: "Custom VIDEO",
}
export const custom_options = {
    on_sale_date_time_text: 'on_sale_date_time_text',
    today_day: 'today_day',
    invoice_details: 'invoice_details',
};

export const fields = {
    [custom_options.on_sale_date_time_text]: '',
    [custom_options.today_day]: '',
    [custom_options.invoice_details]: '',
}
