import {
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_CLEAN_UP
} from "./actions";

export default (state = {
    emailSent: false,
    isFetchingResetPassword: false,
    resetPasswordError: null
}, action) => {
    switch (action.type) {
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                isFetchingResetPassword: true,
            }
        case  RESET_PASSWORD_FAILURE:
            return {
                ...state,
                isFetchingResetPassword: false,
                resetPasswordError: action.error,
                emailSent: false
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetchingResetPassword: false,
                resetPasswordError: null,
                emailSent: action.data
            }
        case RESET_PASSWORD_CLEAN_UP:
            return {
                ...state,
                emailSent: false,
                isFetchingResetPassword: false,
                resetPasswordError: null
            }
        default:
            return state;
    }
}