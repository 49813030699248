import React from 'react';
import {setLoginSuccess, userLoginAttempt} from "./actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {validEmailRegex} from "../../helpers";

const mapStateToProps = state => ({
    ...state.auth,
    ...state.customerInformation
})

const mapDispatchToProps = {
    userLoginAttempt,
    setLoginSuccess,
}

class LoginForm extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            username: "",
            password: "",
            authToken: "",
            errors: {},
            redirectTo: ''
        };

        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.goToCreateAccount = this.goToCreateAccount.bind(this);
        this.goToResetPassword = this.goToResetPassword.bind(this);
        this.isValidForm = this.isValidForm.bind(this);
    }
    componentDidMount() {
        let location = this.props.location;
        let redirectTo = null;
        if(location.state && location.state.from && location.state.from.pathname) {
            redirectTo = location.state.from.pathname;
        }
        this.setState({
            redirectTo: redirectTo
        })
        console.log(redirectTo);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.isAuthenticated) {
            if(this.state.redirectTo && this.state.redirectTo != '') {
                this.props.history.push(this.state.redirectTo)
            }
            else {
                this.props.history.push('/dashboard')
            }
        }

    }
    isValidForm() {
        let {username, password} = this.state;
        let errors = {};
        if(username == '') {
            errors['username'] = 'Please enter the email address associated with your account.';
        }
        else {
            if(username != '' && !validEmailRegex.test(username)) {
                errors['username'] = 'Please enter a valid email address.';
            }
            else{
                if(password == '') {
                    errors['password'] = "Please enter your password.";
                }
            }
        }
        this.setState({errors: errors})
        return errors
    }

    login(event){
        if(event != undefined){
            event.preventDefault();
        }
        let errors = this.isValidForm();
        if(Object.keys(errors).length == 0)
        {
            this.props.userLoginAttempt(
                this.state.username,
                this.state.password
            );
        }
        else {
            let inputs = document.getElementsByTagName("input");
            for(let index in inputs) {
                let input = inputs[index];
                let name = input.name;
                console.log(input);
                if(errors[name]) {
                    input.classList.add("error");
                }
                else {
                    if(input.classList) {
                        input.classList.remove("error");
                    }
                }
            }
        }
    }
    goToCreateAccount() {
        this.props.history.push('/account/createAccount');
    }
    goToResetPassword() {
        this.props.history.push('/account/resetPassword');
    }

    handleChange(event){
        this.setState({[event.target.name]: event.target.value});
    }


    render(){
        let {loginError, isFetchingLoginRequest} = this.props;
        let userLogoutSuccessfully = this.props.userLogoutSuccessfully
        let {errors} = this.state;
        if(isFetchingLoginRequest) {
            return (
                <div className="row">
                    <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 center">
                        <div className="loading-small">
                            <i className="icon-loading"></i>
                            <span>Authenticating Login Credentials</span>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 center">
                <h2>Account</h2>
                {loginError &&
                <div className="alert error">
                    <p>{loginError}</p>
                </div>
                }
                {userLogoutSuccessfully &&
                <div className="alert success">
                    <p>You have logged out successfully.</p>
                </div>
                }
                {
                    errors && Object.keys(errors).length > 0 &&
                    <div className="alert error">
                    {Object.keys(errors).map((item) => {
                        return <p>{errors[item]}</p>

                    })}
                    </div>
                }
                <form onSubmit={this.login}>
                    <input type="email" placeholder="Email Address"
                           name="username" value={this.state.username} onChange={this.handleChange} />
                    <input type="password" name="password" placeholder="Password"
                           value={this.state.password} onChange={this.handleChange}/>
                    <button type="submit" className="button small blue-white" onClick={this.login}>
                        Login
                    </button>
                    <button id="button-reset-password" type="button" onClick={this.goToResetPassword}
                            className="button-text light">Forgot Password?
                    </button>
                </form>
                <button id="button-create-account" type="button" className="button-text white"
                        onClick={this.goToCreateAccount}
                >
                    Not a customer? Create an account.
                </button>
            </div>
        )

    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
