import React from "react"
import {connect} from "react-redux"
import OrderDetails from "../../components/OrderDetails"

class OrderDetail extends React.Component {

    render() {
        return (
            <OrderDetails
                orderId={this.props.match.params.orderId}
            />
        )
    }
}

export default connect(null, null)(OrderDetail)
